import type { ThemingProps } from '@chakra-ui/react'
import type { IconType } from '@paper/icons'
import { TooltippedIcon } from '~src/components'
import type { ColumnProps } from '~src/components/table'

/** shared button props */
export const SBP: ThemingProps<'Button'> = {
  colorScheme: 'blackAlpha',
  size: 'sm',
  variant: 'ghost',
} as const

export const SIBP = {
  ...SBP,
  isRound: true,
} as const

export const IconColumnProps: ColumnProps = { align: 'center', width: 32 }

type IconLabelProps = {
  icon: IconType
  textValue: string
}
export function IconLabel(props: IconLabelProps) {
  return (
    <TooltippedIcon
      aria-label={props.textValue}
      as={props.icon}
      fontSize="lg"
      tooltipProps={{ placement: 'top' }}
    />
  )
}
