export const IAP = {
  blanks: `blanks`,
  checktoken: `checktoken`,
  dir: {
    list: 'dir',
    listPackets: 'dir/packets',
    listXpackets: 'dir/xpackets',
  },
  googletoken: 'googletoken',
  imgp: {
    batch_create: `imgp/batch/create`,
  },
  intToken: (as?: 'admin' | 'teacher') => `inttoken${as ? `?as=${as}` : ''}`,
  packetEntry: {
    getPackets: 'getpackets',
    submitPdf: 'submitpdf',
    verifyPdf: 'submitpdf/verify',
    submitStds: 'stds',
    submitAnswerKey: 'submitanswerkey',
    publish: 'publish',
    submitCrossPacket: 'packetentry/links',
  },
  packets: {
    illuminate: (_ill: string) => `packets/illuminate/${_ill}`,
  },
  print: {
    start: `print/start`,
    verify: `print/verify`,
  },
  roster: {
    get: `roster/get`,
  },
  scanbatch: {
    list: `scanbatch`,
    details: `scanbatch/details`,
  },
  scanfix: {
    lookup: 'scanfix/lookup',
    set: 'scanfix/set',
  },
  scanxpacket: {
    list: 'scanxpacket',
  },
  school: {
    getPacketGroup: `school/packetgroup`,
    listPackets: `school/packets`,
  },
  signUrls: `signurls`,
  std: {
    list: `std`,
  },
  tag: `tag`,
  unstable: {
    approver: 'unstable-approver',
    edwPQ: `unstable-expect-this-to-break/packetmeta/v1`,
    fixer: { get: `unstable-fixer/get`, update: `unstable-fixer/update` },
    movePages: {
      students: 'unstable-move-pages/students',
      move: 'unstable-move-pages/move',
    },
    pin: { list: `unstable-pin/list`, set: `unstable-pin/set` },
  },
}
