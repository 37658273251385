import { Box, Button } from '@chakra-ui/react'
import type { DirPacket, Xpacket, Xpage } from '@paper/schema'
import { gray75 } from '@paper/styles'
import { toXpageId } from '@paper/utils'
import { memo, ReactNode, useEffect, useMemo, useRef } from 'react'
import { useVirtual } from 'react-virtual'
import { ListLoadShell, ListRenderer } from '~src/blocks/list'
import { Txt, VStack } from '~src/components'
import type { ScanXpacketDigest } from './data-scanXpackets'
import { measurePageGraph, ScanlogPageGraph } from './scanlogPageGraph'

// todo: initial very messy parameterization
type LIPassThroughProps = {
  canSelectPage?(page: Xpage): boolean
  highlightedXpageIdSet?: Set<string>
  onSelectPage?(xpageId: string): void
  selectedXpageId?: string
  targetXpageId?: string | Set<string>
}

type ScanXpacketColumnProps = {
  digest: ScanXpacketDigest
  empty?(): ReactNode
  header?: ListRenderer
  idle?(): ReactNode
  packet: DirPacket
} & LIPassThroughProps

/**
 * todo: copy/paste with JumpTo and/or UghTable
 */
export function ScanXpacketColumn(props: ScanXpacketColumnProps) {
  const { digest, empty, header, idle, packet, ...liProps } = props

  // Virtualization since there could be a lot of pages...
  const data = digest.success?.items

  const parentRef = useRef()
  const { estimateSize, width } = useMemo(() => {
    const { height, width } = measurePageGraph(packet?.pages.length)
    return { estimateSize: () => height + 48, width: width + 16 }
  }, [packet])

  const rv = useVirtual({
    estimateSize,
    parentRef,
    size: data?.length,
  })

  const selectedIndex = digest.success?.selectedIndex

  useEffect(() => {
    if (selectedIndex >= 0) {
      // todo: i wish this supported offset, though can maybe accomplish with css?
      rv.scrollToIndex(selectedIndex)
    }
  }, [selectedIndex])

  const onSelect = digest.success?.onSelect

  return (
    <ListLoadShell
      digest={digest}
      px={6}
      width={width + 52} // account for padding
    >
      {(status) => {
        if (status === 'empty') {
          return (
            <VStack gap={2} overflow="hidden">
              {header?.(status)}
              {empty?.()}
            </VStack>
          )
        } else if (status === 'idle') {
          return idle?.()
        } else if (status === 'success') {
          return (
            <VStack gap={2} overflow="hidden">
              {header?.(status)}
              <Box
                ref={parentRef}
                role="presentation"
                overflowY="auto"
                width="100%"
              >
                <Box
                  role="presentation"
                  style={{ height: rv.totalSize, position: 'relative' }}
                >
                  {rv.virtualItems.map(({ index, size, start }) => {
                    const xpacket = data[index]
                    return (
                      <Box
                        key={data[index].id}
                        role="presentation"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: `${size}px`,
                          transform: `translateY(${start}px)`,
                          transitionDuration: '.4s',
                          transitionProperty: 'height top',
                        }}
                      >
                        <XpacketListItem
                          data={xpacket}
                          isSelected={digest.success?.selectedItem === xpacket}
                          onSelect={onSelect}
                          {...liProps}
                        />
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </VStack>
          )
        }
      }}
    </ListLoadShell>
  )
}

type LIProps<T> = {
  data: T
  isSelected: boolean
  onSelect?(item: T): void
} & LIPassThroughProps

export const XpacketListItem = memo(function XpacketListItem(
  props: LIProps<Xpacket>
) {
  const { data, isSelected, onSelect } = props
  const xp = data

  // todo: copy/paste with ListItem
  let bg = isSelected ? gray75 : undefined
  return (
    <Box
      bg={bg}
      borderBottomWidth="1px"
      display="flex"
      flexDirection="column"
      gap={1}
      overflow="hidden"
      position="relative"
      pt={1}
      pb={3}
      transition={`background-color .3s ease`}
    >
      <Button
        alignSelf="stretch"
        fontSize="sm"
        height="unset"
        justifyContent="flex-start"
        onClick={() => onSelect(isSelected ? null : xp)}
        px={2}
        py={1.5}
        variant="ghost"
        width="100%"
      >
        <Txt
          as="span"
          display="inline"
          fontFamily="mono"
          mr={1.5}
          opacity={0.8}
        >
          {xp.student?.number}
        </Txt>
        <Txt as="span" fontWeight={400} isTruncated={true}>
          {xp.student?.lastfirst ?? '<unnamed>'}
        </Txt>
      </Button>
      <Box px={2}>
        <ScanlogPageGraph
          data={xp.pages.map((page, idx) => {
            const xpageId = toXpageId(xp.id, idx)

            const isAssignTarget = equalsOrIsInSet(xpageId, props.targetXpageId)
            const isScanImageSelection = xpageId === props.selectedXpageId

            const onSelect = !props.canSelectPage?.(page)
              ? null
              : () => props.onSelectPage?.(xpageId)

            return {
              arrow:
                page.movedIn && page.movedOut
                  ? 'inout'
                  : page.movedIn
                  ? 'in'
                  : page.movedOut
                  ? 'out'
                  : null,
              colorScheme: isAssignTarget
                ? 'blue'
                : page.movedIn
                ? 'cyan'
                : page.fix?.key
                ? 'scanFixedGray'
                : !page.imgp
                ? 'scanMissingRed'
                : props.highlightedXpageIdSet?.has(xpageId)
                ? 'grayBtn'
                : 'scanPresentGray',
              onSelect,
              selected: isScanImageSelection,
              targeted: isAssignTarget,
            }
          })}
        />
      </Box>
    </Box>
  )
})

const equalsOrIsInSet = (value: string, strOrSet: string | Set<string>) => {
  return !strOrSet
    ? false
    : typeof strOrSet === 'string'
    ? value === strOrSet
    : strOrSet.has(value)
}
