import { Icon } from '@chakra-ui/react'
import { IcoCheckmark, IcoExclamationTriangle, IcoLock } from '@paper/icons'
import { Txt, VStack } from '~src/components'
import { useScrollIntoView } from '~src/utils/useScroll'

export type StepSumProps = {
  error?: string
  isCurrent: boolean
  isFuture: boolean
  isLocked?: boolean
  isNew: boolean
  summary?: string
  title: string
  touched: boolean
  uglyOverride?: string
}

export function StepSummary(props: StepSumProps) {
  const { isCurrent, isLocked, isNew, isFuture, title, touched, uglyOverride } =
    props

  let error = props.error ?? uglyOverride

  // Status and text
  const status = isFuture ? 'future' : error ? 'invalid' : 'valid'
  const subtitle =
    isNew && isFuture
      ? null // doesn't make sense to pre-populate values for new packet
      : isFuture
      ? props.summary
      : // todo: handle error formatting
        error?.toString() ?? props.summary

  // Opacity
  const opacity = isCurrent ? 1 : isFuture ? 0.4 : 0.8

  // Colors!
  const invalidColor = touched ? 'red.400' : 'orange.500'
  const subtitleColor = status === 'invalid' ? invalidColor : 'gray.500'
  const statusColor = status === 'invalid' ? invalidColor : 'gray.100'
  const borderColor = isCurrent && status === 'valid' ? 'blue.500' : statusColor

  // todo: status needs a label too
  const statusIcon = isLocked
    ? IcoLock
    : touched && status === 'invalid'
    ? IcoExclamationTriangle
    : status === 'valid' && (!isCurrent || !isNew || touched) // don't show checkmark for new untouched values
    ? IcoCheckmark
    : undefined

  // Scroll item into view if current
  const domRef = useScrollIntoView(
    isCurrent,
    'use-hacky-scroller-because-something-weird-is-going-on'
  )

  return (
    <VStack
      alignItems="stretch"
      borderBottomWidth={1}
      borderRightColor={borderColor}
      borderRightWidth={4}
      flexShrink={0}
      opacity={opacity}
      padding={3}
      position="relative"
      ref={domRef}
      transition="opacity .3s ease"
    >
      {statusIcon && (
        <Icon
          as={statusIcon}
          color={subtitleColor}
          fontSize="sm"
          position="absolute"
          right={1}
          role="presentation"
          top={2}
        />
      )}
      <Txt textAlign="center" userSelect="none">
        {title}
      </Txt>
      {subtitle && (
        <Txt color={subtitleColor} fontSize="xs" textAlign="center">
          {subtitle}
        </Txt>
      )}
    </VStack>
  )
}

/**
 * Summarizes array
 * @example
 * const summary = summarizeArray([1], 'cherries', { emptyIfEmpty: true, singular: 'cherry'})
 * // summary = '1 cherry'
 */
export function summarizeArray(
  arr: any[],
  plural: string,
  opts?: { zero?: 'empty' | 'no' | '0'; singular?: string }
) {
  const length = arr?.length ?? 0
  // Handle empty
  if (length === 0 && opts?.zero === 'empty') {
    return
  }
  // Handle singular vs. plural
  const label =
    length === 1 ? opts?.singular ?? plural.slice(0, plural.length - 1) : plural

  // Handle zero
  const lengthStr =
    length === 0 && opts?.zero === 'no' ? 'No' : length.toString()

  return `${lengthStr} ${label}`
}
