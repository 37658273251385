import { Box } from '@chakra-ui/react'
import { PageItem, Question } from '@paper/schema'
import { CORRECT_COLOR, PASSAGE_COLOR } from '@paper/styles'
import { addToSetDefault, ObjOf } from '@paper/utils'
import { range, uniqBy } from 'lodash'
import { memo, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useIsKeyPressed } from '~src/utils/useKeyboard'
import { Chunk, PassageChunk, PQCheckboxShell, PQCheckboxShellProps } from '.'
import {
  AnswerKeyContainer,
  AnswerKeySizingMode,
  MAGIC_MAX_PASSAGE_LENGTH,
} from './container'
import { PassageLabel, QuestionRow } from './items'

type CheckboxAnswerKeyProps<T = PageItem> = {
  chunks: PassageChunk[]
  unusedIds?: Set<string>
  onChange(values: T[]): void
  sizingMode?: AnswerKeySizingMode
  values: T[]
}

type CheckTuple = [idx: number, wasChecked: boolean]

export const CheckboxAnswerKey = memo<CheckboxAnswerKeyProps>(
  function CheckboxAnswerKey(props) {
    const { chunks, onChange, sizingMode, unusedIds, values } = props

    // Keep track of shift key state
    const shiftKeyPressed = useIsKeyPressed('shift')
    // Keep track of hovered index
    const [hoveredTuple, setHoveredTuple] = useState<CheckTuple>()
    // Keep track of most recent selection for shift+click
    const [lastActionTuple, setLastActionTuple] = useState<CheckTuple>()

    // Flat array of all rendered buttons for shift+click multi-select
    // todo: feel like i already have this somewhere
    const flatItems = chunks.flatMap((chunk) => [
      ...(chunk.passages ?? []).map(
        (item) => ({ item, type: 'passage' } as const)
      ),
      ...(chunk.questions ?? []).map(
        (item) => ({ item, type: 'question' } as const)
      ),
    ])

    const getAffected = (idx: number, wasChecked: boolean) => {
      let isShiftRange = shiftKeyPressed && lastActionTuple

      // get affected indexes
      let affectedIndexes = !isShiftRange
        ? [idx]
        : range(
            Math.min(idx, lastActionTuple[0]),
            Math.max(idx, lastActionTuple[0]) + 1
          )

      // convert that to affected items
      let affectedItems = affectedIndexes.map((idx) => ({
        id: flatItems[idx].item.id,
        type: flatItems[idx].type,
      }))

      // for questions, we're adding/removing all qs with labelMaj
      // to avoid having to click A1,A2,A3,A4
      if (flatItems[idx].type === 'question') {
        // @ts-expect-error boo typescript 4.8
        const labelMaj = flatItems[idx].item.labelMaj

        // look in both directions for change to mitigate #219
        const search = [
          [-1, (c: number) => c >= 0],
          [1, (c: number) => c < flatItems.length],
        ] as const
        search.forEach(([dir, pred]) => {
          for (let curs = idx + dir; pred(curs); curs += dir) {
            if (flatItems[curs].type === 'question') {
              let cursItem = flatItems[curs].item as Question
              if (cursItem.labelMaj === labelMaj) {
                affectedItems.push({ id: cursItem.id, type: 'question' })
              } else {
                break
              }
            }
          }
        })
      }

      let affectedIdSet = new Set(affectedItems.map((pi) => pi.id))

      return { affectedItems, affectedIdSet }
    }

    // Set of ids of checked items for convenient lookup
    const checkedIds = new Set<string>(values.map((pageItem) => pageItem.id))

    // get currently hovered
    const hoveredSet = hoveredTuple
      ? getAffected(...hoveredTuple).affectedIdSet
      : null

    // Show unused if specified
    const showUnused = !!unusedIds

    // onHover/onClick factory
    const onFactory = (
      idx: number,
      wasChecked: boolean
    ): Pick<
      PQCheckboxShellProps,
      | 'isLastAction'
      | 'onMouseEnter'
      | 'onMouseLeave'
      | 'onClick'
      | 'pendingAction'
    > => {
      let pendingAction: 'check' | 'uncheck'
      if (hoveredTuple && hoveredSet.has(flatItems[idx].item.id)) {
        // if shift key pressed with cursor and different item, toggle based on cursor, else toggle based on target
        let toggleFrom =
          shiftKeyPressed &&
          lastActionTuple &&
          lastActionTuple[0] !== hoveredTuple[0]
            ? !lastActionTuple[1] // same as cursor
            : hoveredTuple[1]
        pendingAction = toggleFrom ? 'uncheck' : 'check'
      }

      return {
        isLastAction: lastActionTuple?.[0] === idx,
        onMouseEnter: () => setHoveredTuple([idx, wasChecked]),
        // i would guess onMouseEnter fires after, but check value for resetting just in case
        onMouseLeave: () => hoveredTuple?.[0] === idx && setHoveredTuple(null),
        onClick: () => {
          const { affectedItems, affectedIdSet } = getAffected(idx, wasChecked)
          let nextValues: PageItem[]

          let action = pendingAction ?? (wasChecked ? 'uncheck' : 'check')

          // unchecking
          if (action === 'uncheck') {
            nextValues = values.filter((pi) => !affectedIdSet.has(pi.id))
          }
          // checking
          else {
            nextValues = uniqBy([...values, ...affectedItems], (pi) => pi.id)
          }

          unstable_batchedUpdates(() => {
            setLastActionTuple([idx, !wasChecked])
            setHoveredTuple([idx, !wasChecked])
            onChange(nextValues)
          })
        },
        pendingAction,
      }
    }

    let globalIdx = 0

    return (
      <AnswerKeyContainer minWidth="280px" sizingMode={sizingMode}>
        {chunks.map(({ passages, questions }, idx) => {
          return (
            <Chunk key={idx} clearance="lots">
              {passages.map((passage) => {
                const isChecked = checkedIds.has(passage.id)
                const isPaged = !unusedIds?.has(passage.id)
                return (
                  <PQCheckboxShell
                    color={PASSAGE_COLOR}
                    isChecked={isChecked}
                    key={passage.id}
                    type="passage"
                    {...onFactory(globalIdx++, isChecked)}
                  >
                    <Box display="flex">
                      {showUnused && <PPaged isPaged={isPaged} />}
                      <PassageLabel
                        data={passage}
                        maxLength={Math.round(
                          MAGIC_MAX_PASSAGE_LENGTH / passages.length
                        )}
                      />
                    </Box>
                  </PQCheckboxShell>
                )
              })}
              {questions.map((q) => {
                const isChecked = checkedIds.has(q.id)
                const isPaged = !unusedIds?.has(q.id)
                return (
                  <PQCheckboxShell
                    color={CORRECT_COLOR}
                    displayMode="compact"
                    isChecked={isChecked}
                    key={q.id}
                    {...onFactory(globalIdx++, isChecked)}
                    type="question"
                  >
                    {showUnused && <QPaged isPaged={isPaged} />}
                    <QuestionRow data={q} />
                  </PQCheckboxShell>
                )
              })}
            </Chunk>
          )
        })}
      </AnswerKeyContainer>
    )
  }
)

const PagedStatus = ({ isPaged }) => {
  return (
    <Box
      as="span"
      fontFamily="mono"
      fontSize="xs"
      color={isPaged ? undefined : 'red.600'}
    >
      {isPaged ? '✓' : '○'}
    </Box>
  )
}

const PPaged = ({ isPaged }) => {
  return (
    <Box mr="1px" transform="translate(-3px, -1px)">
      <PagedStatus isPaged={isPaged} />
    </Box>
  )
}

const QPaged = ({ isPaged }) => {
  return (
    <Box pos="absolute" top="50%" transform={`translate(-40%, -60%)`}>
      <PagedStatus isPaged={isPaged} />
    </Box>
  )
}
