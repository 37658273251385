import { Box, ListItem, UnorderedList } from '@chakra-ui/react'
import { DirPacket } from '@paper/schema'
import { DEFAULT_FG } from '@paper/styles'
import { sumBy } from 'lodash'
import { HStack, Txt, VStack } from '~src/components'
import { Tags } from './tags'

type PctBarProps = {
  bars: { color: string; count: number }[]
  fillGap?: string
  label?: string
  total: number
}

export function PctBar(props: PctBarProps) {
  const { bars: data, fillGap, label, total } = props

  let runningTotal = 0

  let rects = data.map(({ count, color }) => {
    const width = count / total
    const x = runningTotal
    runningTotal += width
    return { color, x, width }
  })

  const gap = total - runningTotal
  if (fillGap) {
    rects.push({ color: fillGap, x: runningTotal, width: gap })
  }

  return (
    <svg
      aria-label={label}
      height="100%"
      viewBox="0 0 1 1"
      preserveAspectRatio="none"
      width="100%"
    >
      {rects.map(({ color, ...rectProps }, idx) => (
        <Box as="g" key={idx} color={color}>
          <rect fill="currentcolor" key={idx} height={1} {...rectProps} />
        </Box>
      ))}
    </svg>
  )
}

type TagBarProps = DirPacket

/**
 * Tagged-ness indicator for set of tickets
 */
export function TagBar(props: TagBarProps) {
  const pkt = props
  const denom = pkt.scan?.count

  // no bar if not scanned
  if (!denom || !pkt.tag) {
    return null
  }

  // calculate bars
  let bars: PctBarProps['bars'] = []

  Tags.forEach((tag) => {
    let count = pkt.tag[tag.id]
    if (count > 0) {
      bars.push({ ...tag, count })
    }
  })

  return (
    <PctBar
      bars={bars}
      fillGap="gray.200"
      label={bars.length ? 'Tagged' : 'Not tagged'}
      total={denom}
    />
  )
}

/**
 * Scan rate indicator
 */
export function ScanBar(props: DirPacket) {
  const pkt = props
  const denom = pkt.print.count
  // no scan bar if not printed or scanned
  if (!denom || !pkt.scan) {
    return null
  }

  let barSpecs = [
    { value: pkt.scan.success },
    { value: pkt.scan.gte90 },
    { value: pkt.scan.lt90 },
    { value: Math.max(0, denom - pkt.scan.count) },
  ]

  // 1 bar for tickets
  if (pkt.type === 'ticket') {
    barSpecs = barSpecs.slice(0, 1)
  }

  return <GoofyBar barSpecs={barSpecs} denom={denom} />
}

export function ScanBarLegend() {
  return (
    <VStack fontSize="xs" gap={4} mb={1} p={2} width="300px">
      <Txt fontSize="sm">Counts of packets that are:</Txt>
      <GoofyBar
        barSpecs={[
          { label: 'Fully scanned', value: 25 },
          { label: '≥ 90% scanned', value: 25 },
          { label: '< 90% scanned', value: 25 },
          { label: 'Fully missing', value: 25 },
        ]}
        denom={100}
      />
      <UnorderedList>
        <ListItem>
          Buckets with no packets are displayed as{' '}
          <Box
            aria-label="An empty dotted line placeholder"
            as="span"
            // todo: copy/paste
            borderLeftColor="gray.300"
            borderLeftStyle="dotted"
            borderLeftWidth={'2px'}
            mx={1.5}
            role="presentation"
          ></Box>
        </ListItem>
        <ListItem>
          Tickets only have a bar for{' '}
          <Txt display="inline" fontFamily="mono">
            Fully scanned
          </Txt>
        </ListItem>
        <ListItem>
          The column is empty for packets that haven't been printed yet.
        </ListItem>
      </UnorderedList>
      <Txt>
        Click on a packet's graph to navigate to its scan log for more details
        on what's missing.
      </Txt>
    </VStack>
  )
}

type GoofyBarProps = {
  barSpecs: { label?: string; value: number }[]
  denom: number
}

function GoofyBar(props: GoofyBarProps) {
  const { barSpecs, denom } = props

  // todo: obviously not reusable with these hardcoded, but avoiding copy/paste for the moment
  const colors = ['gray.300', 'orange.200', 'red.200', 'red.300']
  const sumOfBars = sumBy(barSpecs, (b) => b.value)
  const space = Math.max(0, denom - sumOfBars)

  return (
    <HStack
      alignItems="stretch"
      color={DEFAULT_FG}
      fontSize="xs"
      minHeight="24px"
      width="100%"
    >
      {barSpecs.map(({ label, value }, idx) => (
        <HStack
          key={idx}
          borderLeftColor={colors[idx]}
          borderLeftStyle="dotted"
          borderLeftWidth={value === 0 ? '2px' : null}
          bg={value === 0 ? null : colors[idx]}
          flexGrow={value}
          justifyContent="center"
          mx={value === 0 ? '1px' : null}
          px={value === 0 ? 0 : 0.5}
          py={1}
          textAlign="center"
        >
          {(label ?? value) || ''}
        </HStack>
      ))}
      {space > 0 && (
        <Box
          // borderRight={sumOfBars === 0 ? null : `1px dashed ${DEFAULT_FG}`}
          flexGrow={space}
        ></Box>
      )}
    </HStack>
  )
}
