import {
  FileLocation,
  IllXQ,
  LastUpdate,
  PrintedStatus,
  Section,
  Student,
} from '..'

export type XpacketStatus = 'success' | 'partial' | 'missing'
export type XpacketTag = -1 | 1 | 2 | 3

/** Db version of the Xpacket */
export type Xpacket = {
  _manifestIds: string[]
  /** move status */
  _moved?:
    | 'empty' // ineligible for moving
    | 'hidden' // moving has hidden some images
    | 'in' // all images moved in
    | 'out' // all images moved out
    | 'inout' // all images swapped
    | 'partial' // some images moved
    | 'pending' // unnamed and some images moved out
    | 'resolved' // unnamed and all images moved out
    | 'unexpected' // maybe a bug?
  /** has any manual changes */
  _overriden?: boolean
  _printed: PrintedStatus
  _scanDate?: number
  /** mongo update timestamp */
  _ts: any
  id: string
  packetId: string
  pages: Xpage[]
  section: Section
  status: XpacketStatus
  /** [0,1] where 0 is missing, 1 is success */
  statusPct: number
  student: Student
  tag: XpacketTag
  teacherId: string
}

/** Xpacket "Student Work" */
export type XpacketSW = Pick<
  Xpacket,
  | 'id'
  | 'packetId'
  | 'status'
  | 'student'
  | 'tag'
  | 'teacherId'
  | '_moved'
  | '_overriden'
  | '_printed'
  | '_scanDate'
> & {
  pages: XpageSW[]
  qs?: IllXQ[]
}

export type XpageSW = {
  _overridenBy?: LastUpdate
  _overrideType?: 'fix' | 'move-in' | 'move-out'
  key: string
}

/** Xpacket "Packet list" */
export type XpacketPL = Pick<
  Xpacket,
  '_scanDate' | '_moved' | 'packetId' | 'statusPct' | 'tag'
> & {
  student: { id: string }
}

export type Xpage = {
  id: string
  /** fixed from scanlog */
  fix?: { by: LastUpdate; key: string }
  /** image assigned via imgp */
  imgp: FileLocation
  /** moved in e.g. from unnamed */
  movedIn?: { by: LastUpdate; key: string }
  /** moved out */
  movedOut?: { by: LastUpdate; to: string }
  /** backup code */
  qrb: string
}
