import { BoxProps } from '@chakra-ui/layout'
import { Fragment, ReactNode } from 'react'
import {
  Error404Page,
  ErrorPrintRecalledPage,
  NotFoundError,
} from '~src/blocks/errors'
import { FetchErrorPage } from '~src/blocks/errors/errorPageFetch'
import { PrintRecalledError } from '~src/data/_printPdf'
import { Txt, VStack } from '..'
import { FullPageMessage } from './fullPage'

type FullPageLoadingProps = {
  children?: ReactNode | (() => ReactNode)
  loadMsg?: ReactNode
  qResult: {
    error?: unknown
    isError?: boolean
    isLoading: boolean
    reset?: () => void
  }
  type?: 'opaque' | 'transparent'
}

export function FullPageLoading(props: FullPageLoadingProps) {
  const { error, isError, isLoading } = props.qResult
  const { loadMsg, type = 'opaque' } = props

  // todo: need to survey other FullPageLoading usages on whether to always show the error on fetch
  // todo: or i guess maybe make this opt-in for everyone, in case there's a submit that shouldn't be blindly retried?
  const showError = error && !!props.qResult.reset

  const isActive = showError || isLoading
  const renderChildren = !isError && (!isLoading || type === 'transparent')

  let msg: ReactNode

  if (error instanceof NotFoundError) {
    msg = <Error404Page error={error} />
  } else if (error instanceof PrintRecalledError) {
    msg = <ErrorPrintRecalledPage error={error} />
  } else if (showError) {
    msg = <FetchErrorPage {...props.qResult} />
  } else if (isLoading) {
    msg = (
      <FullPageMessage isActive={isActive} type={type}>
        <VStack justifyContent="flex-end" height="50%">
          {loadMsg}
          <LoadingDots data-cy="full-page-loading" />
        </VStack>
      </FullPageMessage>
    )
  }

  return (
    <Fragment>
      {msg}
      {renderChildren
        ? typeof props.children === 'function'
          ? props.children()
          : props.children
        : null}
    </Fragment>
  )
}

export const LoadingDots = (props: BoxProps) => {
  return (
    <Txt
      as="span"
      color="gray.200"
      fontSize="6xl"
      fontWeight="bold"
      role="presentation"
      textAlign="center"
      userSelect="none"
      {...props}
    >{`·  ·  ·`}</Txt>
  )
}
