import { orderBy } from 'lodash'

export function sortKeys<T extends {}>(unsorted: T): T {
  return Object.fromEntries(orderBy(Object.entries(unsorted), ([k]) => k)) as T
}

export function pareUndefined<T extends {}, U extends {} = T>(obj: T): U {
  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v !== undefined)
  ) as U
}

export function filterObject<T extends {}, U extends {} = T>(
  obj: T,
  filter: (value: [key: any, value: unknown]) => boolean
): U {
  return obj && (Object.fromEntries(Object.entries(obj).filter(filter)) as U)
}

/**
 * { k: 'v' } -> { v: 'k' }
 */
export function invertMap<K, V>(map: Map<K, V>): Map<V, K> {
  // @ts-expect-error
  return new Map([...map].map((entry) => entry.reverse()))
}
