import { RefObject, useEffect, useRef } from 'react'

export const useScrollToSelected = (
  ref: RefObject<HTMLElement>,
  isSelected: boolean,
  delay = 0
) => {
  // todo: Should I worry at all about the overhead of this being called for 100+ items
  useEffect(() => {
    let unmounted = false
    // todo: Delay to allow height a chance to expand (Initially used with packetListItem)
    if (isSelected) {
      setTimeout(() => {
        !unmounted && ref.current.scrollIntoView({ block: 'nearest' })
      }, delay)
    }

    return () => (unmounted = true)
  }, [isSelected])
}

/**
 * Scrolls into view if `isCurrent`
 * @example
 * const domRef = useScrollIntoView(isCurrent)
 * ...
 * return <div ref={domRef}>...</div>
 */
export function useScrollIntoView(
  isCurrent: boolean,
  options?:
    | ScrollIntoViewOptions
    | 'use-hacky-scroller-because-something-weird-is-going-on'
) {
  const domRef = useRef<any>() // todo: ref and html element type
  useEffect(() => {
    let unmounted = false
    if (isCurrent && domRef.current) {
      // todo: having any issue with StepSummary
      // first, it wouldn't work without a timeout
      // (i think this could be at least partially due to init/validation being async so heights change)
      // then when i added dnd-kit, it was somehow scrolling unscrollable areas
      // presumably this means it's the wrong general approach...
      if (
        options === 'use-hacky-scroller-because-something-weird-is-going-on'
      ) {
        setTimeout(() => {
          !unmounted &&
            domRef.current &&
            scrollIntoViewIfNeeded(domRef.current, true)
        }, 200)
      } else {
        domRef.current.scrollIntoView(
          options ?? { behavior: 'smooth', inline: 'center' }
        )
      }
    }
    return () => (unmounted = true)
  }, [isCurrent])

  return domRef
}

/**
 * Haven't vetted, but needed something to mitigate weird StepSummary issues
 * @see https://gist.github.com/KilianSSL/774297b76378566588f02538631c3137
 */
function scrollIntoViewIfNeeded(el: HTMLElement, centerIfNeeded = false) {
  let parent = el.parentNode as HTMLElement,
    parentComputedStyle = window.getComputedStyle(parent, null),
    parentBorderTopWidth = parseInt(
      parentComputedStyle.getPropertyValue('border-top-width')
    ),
    parentBorderLeftWidth = parseInt(
      parentComputedStyle.getPropertyValue('border-left-width')
    ),
    overTop = el.offsetTop - parent.offsetTop < parent.scrollTop,
    overBottom =
      el.offsetTop - parent.offsetTop + el.clientHeight - parentBorderTopWidth >
      parent.scrollTop + parent.clientHeight,
    overLeft = el.offsetLeft - parent.offsetLeft < parent.scrollLeft,
    overRight =
      el.offsetLeft -
        parent.offsetLeft +
        el.clientWidth -
        parentBorderLeftWidth >
      parent.scrollLeft + parent.clientWidth,
    alignWithTop = overTop && !overBottom

  if ((overTop || overBottom) && centerIfNeeded) {
    parent.scrollTop =
      el.offsetTop -
      parent.offsetTop -
      parent.clientHeight / 2 -
      parentBorderTopWidth +
      el.clientHeight / 2
  }

  if ((overLeft || overRight) && centerIfNeeded) {
    parent.scrollLeft =
      el.offsetLeft -
      parent.offsetLeft -
      parent.clientWidth / 2 -
      parentBorderLeftWidth +
      el.clientWidth / 2
  }

  if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
    el.scrollIntoView(alignWithTop)
  }
}
