import { Box, BoxProps } from '@chakra-ui/react'
import { KeyCap } from '~src/blocks/keyboardInstructions'
import { HStack, Txt } from '~src/components'
import type { Tag } from '.'

type TagInstructionProps = {
  fontSize?: BoxProps['fontSize']
  meta: Tag
  showKey?: boolean
  size?: BoxProps['fontSize']
  wrap?: boolean
}
export function TagInstruction(props: TagInstructionProps) {
  const { fontSize, showKey, size = 'lg', meta, wrap } = props

  return (
    <HStack
      flexWrap={wrap ? 'wrap' : null}
      fontSize={fontSize}
      columnGap={1.5}
      rowGap={1}
      justifyContent="center"
    >
      {showKey && <KeyCap keyChar={meta.keyChar} flexShrink={0} />}
      <Box as={meta.icon} color={meta.color} flexShrink={0} fontSize={size} />
      <Txt as="span">{meta.label}</Txt>
    </HStack>
  )
}
