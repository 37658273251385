import { IcoHelp, IcoReportIssue } from '@paper/icons'
import { getSupportMailTo } from '~src/utils/contactSupport'
import { useHelpLink } from '~src/utils/useHelpLink'
import { getMenuItemExternalLinkProps, MenuItem } from './menu'

export function MIReportIssue() {
  // todo: probably rollbar here too for its instrumentation?
  return (
    <MenuItem
      icon={<IcoReportIssue />}
      onSelect={() => {
        window.location.href = getSupportMailTo({})
      }}
    >
      Report an issue
    </MenuItem>
  )
}

type MIHelpProps = { message?: string }

export function MIHelp(props: MIHelpProps) {
  let href = useHelpLink()
  return (
    <MenuItem {...getMenuItemExternalLinkProps(href)} icon={<IcoHelp />}>
      {props.message || 'Help'}
    </MenuItem>
  )
}

/**
 * MenuItems for Help and Report Issue
 * todo: maybe make this a section?
 */
export function MISupport() {
  return (
    <>
      <MIHelp />
      <MIReportIssue />
    </>
  )
}
