import {
  Button,
  ButtonGroup,
  ButtonProps,
  Icon,
  Tooltip,
} from '@chakra-ui/react'
import { IcoExclamationTriangle, IcoFunnel, IcoHelp } from '@paper/icons'
import { useRouter } from '@paper/route'
import { CrunchBatch, ScanImageAbbr, StatusChunk } from '@paper/schema'
import { DEFAULT_FG } from '@paper/styles'
import { getFullName } from '@paper/utils'
import { ReactNode } from 'react'
import { IcoScanIssue } from '~src/blocks/icoScanIssue'
import { ImageViewer, imageViewerColumnProps } from '~src/blocks/imageViewer'
import { ListLoadShell } from '~src/blocks/list'
import { SWMenu } from '~src/blocks/swMenu'
import {
  AppTitle,
  BaseHeader,
  Column,
  HStack,
  Separator,
  TextStack,
  Txt,
  VStack,
} from '~src/components'
import { LoadingDots } from '~src/components/status'
import { ExpandeeProps, UghTable } from '~src/components/table'
import { useDirectoryData } from '~src/data/data-directory'
import { formatPastVsNow } from '~src/utils/messages'
import { useScrollIntoView } from '~src/utils/useScroll'
import { RD_Scanlog } from '../routes'
import { useScanlogContext } from './scanlogAirlock'
import { scanBatchColumns } from './scanlogCols'
import {
  ScanIncompletePicker,
  ScanPacketPicker,
  ScanTeacherPicker,
  ScanUserPicker,
} from './scanlogFilters'
import { ScanlogFixDialog } from './scanlogFixDialog'
import { ScanlogXpacketColumn } from './scanlogXpacketColumn'

export function Scanlog() {
  const { dispatchStay, routeData } = useRouter<RD_Scanlog>()
  const { sbDigest, selectedPacket, selectedScanImage } = useScanlogContext()
  const selectedBatch = sbDigest.success?.selectedItem
  const selectedImage = selectedScanImage?.key

  // workaround for shorter expandee if everything is fine, or in progress
  const expandeeHeight =
    (selectedBatch?.chunks.length === 1 && selectedBatch?.stats.success > 0) ||
    (selectedBatch && !selectedBatch.settledDate)
      ? 90
      : 360

  return (
    <BaseHeader.Container>
      <AppTitle title={['Scan log']} />
      <BaseHeader hideLogo={true} minWidth="unset" stackGap="2rem">
        <SWMenu icon={IcoScanIssue} />
        <Txt fontSize="lg">Scan log</Txt>
        <Separator orientation="vertical" style={{ marginBlock: '1rem' }} />
        {sbDigest.success && (
          <>
            <Txt fontSize="xs">Batch filters:</Txt>
            <ScanUserPicker data={sbDigest.success?.otherData.scanUsers} />
            <ScanTeacherPicker />
            <ScanPacketPicker />
            <ScanIncompletePicker />
            <Separator orientation="vertical" style={{ marginBlock: '1rem' }} />
          </>
        )}
      </BaseHeader>
      <Column.Container colProps={{ flexGrow: 1, flexShrink: 0 }} flexGrow={1}>
        <ScanlogXpacketColumn />
        <ListLoadShell digest={sbDigest} fontSize="sm" px={6} width="500px">
          {(status) => {
            if (status === 'empty') {
              // base data is empty
              return <Txt textAlign="center">No matching batches</Txt>
            } else if (status === 'success') {
              const { noMatches, problems } = sbDigest.success.otherData

              return (
                <VStack gap={4} height="100%">
                  <HStack
                    alignSelf="stretch"
                    gap={1}
                    justifyContent="center"
                    position="relative"
                  >
                    {
                      <ButtonGroup alignItems="center" size="sm">
                        <Txt fontSize="sm">Jump to issue:</Txt>
                        <Button
                          isDisabled={!problems.hasPrev}
                          onClick={problems.onPrev}
                        >
                          Prev
                        </Button>
                        <Button
                          isDisabled={!problems.hasNext}
                          onClick={problems.onNext}
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    }
                    {routeData.xpacketId && selectedPacket && (
                      // Warn that we're filtering
                      <Tooltip
                        label={
                          noMatches
                            ? `Showing all batches for this teacher + packet, because there are no batches containing the selected student's packet.`
                            : 'Showing only batches for the selected student'
                        }
                      >
                        <Txt
                          fontSize="sm"
                          left={2}
                          position="absolute"
                          role="presentation"
                        >
                          <Icon as={IcoFunnel} />
                          <Icon
                            as={noMatches ? IcoExclamationTriangle : IcoHelp}
                            color={noMatches ? 'yellow.500' : null}
                          />
                        </Txt>
                      </Tooltip>
                    )}
                  </HStack>
                  <UghTable
                    aria-label="Scan batch list"
                    colorScheme="gray"
                    columns={scanBatchColumns}
                    data={sbDigest.success.items}
                    Expandee={ScanBatchExpandee}
                    expandeeHeight={expandeeHeight}
                    getId={(item) => item.id}
                    height="100%"
                    onSelect={(item: CrunchBatch) =>
                      dispatchStay({ sb_batchId: item.id, si_imageId: null })
                    }
                    selectedId={routeData.sb_batchId}
                    spacingX="snug"
                    spacingY="airy"
                  />
                </VStack>
              )
            }
          }}
        </ListLoadShell>
        <Column {...imageViewerColumnProps}>
          <ImageViewer imageType="scanlog" key_={selectedImage} />
        </Column>
      </Column.Container>
    </BaseHeader.Container>
  )
}

function ScanBatchExpandee(props: ExpandeeProps<CrunchBatch>) {
  // packet and teacher buttons
  const { item } = props
  const { chunks } = item
  const dirData = useDirectoryData().data

  const { isEditable, sbDetails: qResult } = useScanlogContext()

  const { dispatchStay, routeData } = useRouter<RD_Scanlog>()

  let body: ReactNode

  if (!item.processDate) {
    // todo: would be nice to have an indication of queue position/detect if it's stuck/contact support button
    // todo: probably ideally separate queued from other, and recheck those
    body = (
      <NotVeryHelpfulQueueMessage
        info={`We've received this batch, but processing has not begun yet.`}
      />
    )
  } else if (!item.settledDate) {
    body = (
      <NotVeryHelpfulQueueMessage
        info={<>Processing began {formatPastVsNow(item.processDate)}</>}
      />
    )
  } else if (qResult.isLoading) {
    body = <LoadingDots />
  } else if (qResult.isSuccess || !item.packetIds?.length) {
    const packetLabels = qResult.data?.packetLabels ?? []

    const teachers = item.teacherIds.map(
      (teacherId) =>
        dirData?.teacher.map.get(teacherId)?.item ?? {
          id: teacherId,
          firstName: 'Unknown',
          lastName: `(${teacherId})`,
        }
    )

    body = (
      <>
        <HStack overflowX="auto" gap={3}>
          <ButtonSection
            items={teachers}
            itemToLabel={(item) => getFullName(item)}
            itemToOn={(item) => item.id === routeData.teacherId}
            name="Teachers"
            onClick={(item) => dispatchStay({ teacherId: item.id })}
          />
          <Separator orientation="vertical" />
          <ButtonSection
            // todo: i'm currently reusing other infrastructure that requires a teacher to be selected in order to select a packet
            // todo: a possibly better fix would be to store packetIds/teachers together on scanbatch
            disabled={!routeData.teacherId}
            items={packetLabels}
            itemToLabel={(item) => item.name}
            itemToOn={(item) => item.id === routeData.packetId}
            name="Packets"
            onClick={(item) => dispatchStay({ packetId: item.id })}
          />
        </HStack>
        <HStack gap={2}>
          <Txt fontSize="xs">Images:</Txt>
          <HStack gap={1} overflowX="auto">
            {chunks.map((item, idx) => {
              return <Chunk key={idx} {...item} />
            })}
          </HStack>
        </HStack>
        <Separator />
        {isEditable && <ScanlogFixDialog />}
      </>
    )
  }

  return (
    <VStack alignItems="stretch" gap={4} px={4} py={3}>
      {body}
    </VStack>
  )
}

type ButtonSectionProps<T> = {
  disabled?: boolean
  items: T[]
  itemToLabel(item: T): string
  itemToOn(item: T): boolean
  name: string
  onClick(item: T): void
}

function ButtonSection<T>(props: ButtonSectionProps<T>) {
  const { disabled, itemToLabel, itemToOn, items, name, onClick } = props
  return (
    <HStack gap={2}>
      <Txt fontSize="xs">{name}:</Txt>
      {items.length > 0 ? (
        items.map((item, idx) => (
          <Button
            key={idx}
            isDisabled={disabled}
            onClick={() => onClick(item)}
            size="xs"
            variant={itemToOn(item) ? 'solid' : 'ghost'}
          >
            {itemToLabel(item)}
          </Button>
        ))
      ) : (
        <Txt fontSize="xs">None found</Txt>
      )}
    </HStack>
  )
}

type PageBtnProps = {
  colorScheme: ButtonProps['colorScheme']
  item: ScanImageAbbr
  imageNumber: number
}

const PageBtn = (props: PageBtnProps) => {
  const { colorScheme, imageNumber, item } = props

  const { dispatchStay, routeData } = useRouter<RD_Scanlog>()
  const isSelected = routeData.si_imageId === item.id

  const domRef = useScrollIntoView(isSelected, {
    behavior: 'smooth',
    block: 'nearest',
  })

  const adjColor = new Set(['scanPresentGray'])

  return (
    <Button
      borderColor={adjColor.has(colorScheme) ? `${colorScheme}.500` : null}
      color={adjColor.has(colorScheme) ? DEFAULT_FG : null}
      minWidth="34px"
      onClick={() => dispatchStay({ si_imageId: item.id })}
      ref={domRef}
      variant={isSelected ? 'solid' : 'outline'}
    >
      {imageNumber}
    </Button>
  )
}

function Chunk(props: StatusChunk) {
  const { items, status, startIndex } = props
  const colorScheme =
    status === 'manual'
      ? 'scanFixedGray'
      : status === 'success'
      ? 'scanPresentGray'
      : 'scanMissingRed'

  const size = 'xs'

  return (
    <ButtonGroup
      colorScheme={colorScheme}
      fontFamily="mono"
      isAttached
      size={size}
    >
      <PageBtn
        colorScheme={colorScheme}
        item={items[0]}
        imageNumber={startIndex + 1}
      />
      {items.length > 2 && (
        <Txt bg="gray.50" px={1}>
          …
        </Txt>
      )}
      {items.length > 1 && (
        <PageBtn
          colorScheme={colorScheme}
          item={items[items.length - 1]}
          imageNumber={startIndex + items.length}
        />
      )}
    </ButtonGroup>
  )
}

function NotVeryHelpfulQueueMessage({ info }) {
  return (
    <TextStack>
      <TextStack.Top>{info}</TextStack.Top>
      <TextStack.Bottom fontStyle="italic" opacity={0.8} variant="loose">
        If that was a long time ago, something probably went wrong!
      </TextStack.Bottom>
    </TextStack>
  )
}
