import { Button, useBoolean } from '@chakra-ui/react'
import { LastUpdate } from '@paper/schema'
import { maxBy } from 'lodash'
import { AnswerKey, useChunked } from '~src/blocks/answerKey'
import { TabImageViewer } from '~src/blocks/imageViewer'
import { LastUpdateDisplay } from '~src/blocks/lastUpdate'
import { BaseHeader, Column, HStack, Txt, VStack } from '~src/components'
import { PublishList } from './publish'
import { PublishShell, Wiz } from './publishLayout'
import { PublishMenu } from './publishMenu'
import { usePublishContext } from './publishProvider'
import { StdDialog, StdTagList } from './stds'

export function PublishHome() {
  const { canEdit, curriculum, goToEditAnswerKey, goToEditPdf, packetData } =
    usePublishContext()
  const { focusPacket, signedImageUrls, packets } = packetData

  // use first packet for displaying shared data
  const { chunks } = useChunked(focusPacket?.content)
  // show answer key only for assessements
  const isTicket = focusPacket?.content.type === 'ticket'
  // keep track of standards dialog
  // todo: i guess probably add this to publishContext?
  const [isStdOpen, setIsStdOpen] = useBoolean(false)

  return (
    <PublishShell
      header={
        <BaseHeader mb={0} stackGap="2rem">
          <PublishMenu />
          <Txt fontSize="lg">{focusPacket.content.name}</Txt>
        </BaseHeader>
      }
    >
      <Wiz.FormArea display="flex" overflow="hidden">
        <Column.Container colProps={{ flexShrink: 0 }} flexGrow={1} gap={12}>
          <Column
            alignItems="stretch"
            data-cy="sec-pdf"
            flexGrow={1}
            width="360px"
          >
            <GridHeading
              hideEdit={!canEdit}
              lastUpdate={focusPacket._updates.pdf}
              onEdit={goToEditPdf}
              text="PDF"
            />
            <TabImageViewer
              imageType="blank"
              pkt={focusPacket.content}
              urls={signedImageUrls}
            />
          </Column>
          {isTicket ? (
            <Column alignItems="stretch" data-cy="sec-std" maxWidth="250px">
              <GridHeading
                hideEdit={!canEdit}
                onEdit={setIsStdOpen.on}
                lastUpdate={focusPacket._updates.stds}
                text="Standards"
              />
              <StdDialog
                contentId={focusPacket.contentId}
                data={{ stds: focusPacket?.content.stds }}
                isOpen={isStdOpen}
                onClose={setIsStdOpen.off}
              />
              <StdTagList readonly={true} stds={focusPacket?.content.stds} />
            </Column>
          ) : (
            <Column alignItems="stretch" data-cy="sec-answerkey">
              <GridHeading
                hideEdit={!canEdit}
                lastUpdate={focusPacket._updates.answerKey}
                onEdit={goToEditAnswerKey}
                text="Answer Key"
              />
              <AnswerKey.Container
                minWidth="280px"
                sizingMode="dont-measure-width"
              >
                <AnswerKey.Guts chunks={chunks} />
              </AnswerKey.Container>
            </Column>
          )}
          <Column alignItems="stretch" flexGrow={2} maxWidth="700px">
            <GridHeading
              hideEdit={true}
              lastUpdate={maxBy(
                packets.map((p) => p._updates.publish),
                (u) => u?.time
              )}
              text="Publishing"
            />
            <PublishList
              canEdit={canEdit}
              curriculum={curriculum}
              packets={packets}
            />
          </Column>
        </Column.Container>
      </Wiz.FormArea>
    </PublishShell>
  )
}

type HeadingProps = {
  hideEdit?: boolean
  lastUpdate?: LastUpdate
  onEdit?(): void
  text: string
}

const HeadingHeight = 64

function GridHeading(props: HeadingProps) {
  const { hideEdit, lastUpdate, onEdit, text } = props
  return (
    <VStack
      as="h3"
      alignItems="stretch"
      flexShrink={0}
      fontSize="xl"
      fontWeight={300}
      height={`${HeadingHeight}px`}
      userSelect="none"
    >
      <HStack alignItems="baseline" gap={2}>
        <Txt as="span">{text}</Txt>
        {!hideEdit && (
          <Button onClick={onEdit} size="sm" variant="link" colorScheme="red">
            Edit
          </Button>
        )}
      </HStack>
      {<LastUpdateDisplay lastUpdate={lastUpdate} />}
    </VStack>
  )
}
