import { Box, Fade, Icon } from '@chakra-ui/react'
import { IcoFunnel } from '@paper/icons'
import { ReactNode, useEffect, useState } from 'react'
import { AnswerKey } from '~src/blocks/answerKey'
import { BaseHeader, HStack, Txt, VStack } from '~src/components'
import { LoadingDots } from '~src/components/status'
import { QDigest, StdDigest } from '~src/data/data-questions'
import { AnswerFilter } from './answerFilter'
import { useQStdContext } from './qStdProvider'
import { CharLabel, StdQNav } from './stdQNav'

export function BargraphHud() {
  const { digest, mode } = useQStdContext()
  // todo: as with <BarGraph /> still figuring how to handle the different modes
  const isStdMode = mode === 'std'
  const selectedItem = digest.success?.selectedItem

  const delayMs = 100
  const widthMs = 500

  let children: ReactNode = null
  if (!selectedItem) {
    children = <LoadingDots mt={-1.5} />
  } else if (isStdMode) {
    children = (
      <DelayedFadeIn key="std" delay={delayMs + widthMs}>
        <StdModeHud />
      </DelayedFadeIn>
    )
  } else {
    children = (
      <DelayedFadeIn key="q" delay={delayMs + widthMs}>
        <QModeHud />
      </DelayedFadeIn>
    )
  }

  return (
    <Box
      alignItems="center"
      backgroundColor="RGBA(255, 255, 255, .8)"
      boxShadow={BaseHeader.BoxShadow}
      data-cy="bargraph-hud"
      display="grid"
      justifyItems="stretch"
      overflow="hidden"
      py={2}
      position="relative"
      roundedBottom="10px"
      transition={`width ${widthMs}ms ${delayMs}ms ease`}
      width={isStdMode ? '400px' : '250px'}
    >
      {children}
    </Box>
  )
}

/**
 * Assumes we're already in jump-to-q with a selected item
 */
function QModeHud() {
  const { digest, packet } = useQStdContext()
  const qDigest = digest as QDigest // todo: goofy...
  // Check if we have answer data
  // todo: there's probably a better way, and should probably be part of context
  const hasAnswers = !qDigest.success?.items.every((item) => isNaN(item.outOf))

  if (hasAnswers) {
    const qAgg = qDigest.success.selectedItem
    return (
      <HStack flexGrow={1} gap={4} justifyContent="center" p={4}>
        {/* Answer label */}
        <CharLabel
          char="A"
          icon={<Icon as={IcoFunnel} fontSize="xs" />}
          subtext={qAgg.type === 'GRID-IN' ? '(pts)' : null}
        />
        {/* Answer filter */}
        <HStack flexGrow={1} justifySelf="center">
          <AnswerFilter {...qAgg} />
        </HStack>
      </HStack>
    )
  } else {
    // No answer data so show AnswerKey snippet
    const qAgg = qDigest.success.selectedItem
    const pmQ = packet.questions[qAgg.qIndex]
    const pmPs = [] // could include passages, but need to fix layout
    //packetmeta.content.passages.filter((p) =>
    //   pmQ.passageIds.includes(p.id)
    // )
    const chunks = [{ fingerprint: qAgg.id, passages: pmPs, questions: [pmQ] }]
    return (
      <VStack justifyContent="center">
        <Box fontSize="xs" pl={2} textAlign="center">
          No answer data for this packet
        </Box>
        <Box>
          <AnswerKey.Guts chunks={chunks} />
        </Box>
      </VStack>
    )
  }
}

/**
 * Assumes we're already in jump-to-std with a selected item
 */
function StdModeHud() {
  const { digest } = useQStdContext()
  const stdDigest = digest as StdDigest
  const stdAgg = stdDigest.success?.selectedItem

  return (
    <Box
      alignItems="center"
      display="grid"
      gridTemplateColumns="150px auto 1fr"
      gridTemplateRows="100%"
      justifyItems="center"
    >
      <Txt
        // todo: maybe line-clamp to 4: https://css-tricks.com/almanac/properties/l/line-clamp/#so-whats-the-catch
        fontSize="sm"
        maxHeight="100%"
        overflow="hidden"
        px={2}
        textAlign="center"
        textOverflow="ellipsis"
        whiteSpace="pre-wrap"
        width="100%"
      >
        {stdAgg.label}
      </Txt>
      <Box borderRightWidth={1} className="vr" height="80%" />
      <StdQNav qs={stdAgg.qs} />
    </Box>
  )
}

type DelayedFadeInProps = {
  children: ReactNode
  className?: string
  delay: number
}
function DelayedFadeIn(props: DelayedFadeInProps) {
  const { children, className, delay } = props
  const [isWaiting, setIsWaiting] = useState(!delay)
  useEffect(() => {
    let unmounted = false
    if (delay) {
      setIsWaiting(true)
      setTimeout(() => !unmounted && setIsWaiting(false), delay)
    } else {
      setIsWaiting(false)
    }
    return () => (unmounted = true)
  }, [delay])

  return (
    <Fade className={className} in={!isWaiting}>
      {children}
    </Fade>
  )
}
