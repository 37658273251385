import type { ApiXpacketList } from '@paper/api'
import { useRouter } from '@paper/route'
import {
  IAP,
  PacketAxisItem,
  SectionAxisItem,
  Student,
  XpacketSW,
} from '@paper/schema'
import { addToMapSetDefault } from '@paper/utils'
import { orderBy, uniq } from 'lodash'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useTeacherContext } from '~src/blocks/teacherAirlock'
import { useUser } from '~src/blocks/userProvider'
import { usePacketListData } from '~src/data/data-packets'
import { RD_Tea_Time } from '../routes'

type PacketMaps = Map<
  string,
  {
    axis: PacketAxisItem
    stdPage: Map<string, Set<number>>
    stdQ: Map<string, Set<string>>
  }
>

export type TeaTimeBaseData = {
  empty: boolean
  packetAxis: PacketAxisItem[]
  packetMaps: PacketMaps
  packetNumberPrefixes: string[]
  sections: SectionAxisItem[]
  stds: string[]
  studentAxis: Student[]
  xpackets: XpacketSW[]
}

export const useTimeGridData = () => {
  const { fetchAs } = useUser()
  const { curriculum, teacher } = useTeacherContext()
  const { routeData } = useRouter<RD_Tea_Time>()
  const pld = usePacketListData()

  // Get packet ids with scans
  const { packetIds, packets } = useMemo(() => {
    const packets = pld.data?.filter((p) => p.scan?.count)
    const packetIds = packets?.map((p) => p.id)
    return { packets, packetIds }
  }, [pld.data, routeData.f_packet])

  // todo: copy/paste from data-xpackets
  const url = IAP.dir.listXpackets
  let payload: ApiXpacketList['body'] = {
    curriculumId: curriculum.id,
    excludeMissing: true,
    teacherId: teacher.id,
    packetIds,
  }
  return useQuery(
    [url, payload],
    async () => {
      // Grab data
      let { sections, xpackets } = await fetchAs
        .post(url, { json: payload })
        .json<ApiXpacketList['result']>()

      // Make student axis
      let studentAxis = sections.flatMap((section) => section.students)
      studentAxis = orderBy(studentAxis, (sa) => sa.lastfirst.toLowerCase())

      // And packet axis
      let packetAxis = orderBy(
        packets.map((p): PacketAxisItem => {
          return {
            date: p.scan.date,
            id: p.id,
            name: p.name,
            number: p.number,
            pages: p.pages,
            questions: p.questions,
            stds: p.stds ?? [],
            type: p.type,
          }
        }),
        (pa) => pa.date
      )

      // list of all stds
      let stds = orderBy(uniq(packetAxis.flatMap((pa) => pa.stds)))
      // list of all packet number prefixes
      let packetNumberPrefixes = orderBy(
        uniq(packetAxis.map((pa) => pa.number.split('.')[0] + '.'))
      )

      // produce lookups
      const packetMaps: PacketMaps = new Map()
      packetAxis.forEach((pa) => {
        const stdQ = new Map<string, Set<string>>()
        const stdPage = new Map<string, Set<number>>()
        packetMaps.set(pa.id, { axis: pa, stdPage, stdQ })

        // question ids by std
        pa.questions.forEach((q) =>
          q.stds?.forEach((std) => addToMapSetDefault(stdQ, std, q.id))
        )
        // pages by std
        pa.stds.forEach((std) => {
          const qSet = stdQ.get(std)
          if (qSet) {
            pa.pages.forEach((page, idx) => {
              if (page.items.find((item) => qSet.has(item.id))) {
                // todo: include passage pages here too?
                addToMapSetDefault(stdPage, std, idx)
              }
            })
          }
        })
      })

      return {
        empty: xpackets.length === 0,
        packetAxis,
        packetMaps,
        packetNumberPrefixes,
        sections,
        stds,
        studentAxis,
        xpackets,
      }
    },
    { cacheTime: 0, enabled: packetIds?.length > 0 }
  )
}
