import {
  CrunchBatch,
  DirPacket,
  Student,
  Xpacket,
  XpacketSW,
} from '@paper/schema'
import { ListAdapter } from '~src/blocks/list'
import { XpacketListItem } from '~src/pages/sw/jumpTo/xpacketListItem'
import { RD_JumpToQ, RD_Scanlog } from '~src/pages/sw/routes'

// todo: where should this file live?
// todo: ItemComponent makes this a mismash...
// todo: should maybe be an input to useXXXDigest

const dotId = (item: { id: string }) => item.id

export const laPacket: ListAdapter<DirPacket, RD_JumpToQ> = {
  id: 'packetlist',
  idFromItem: dotId,
  idFromRouter: (routeData) => routeData.packetId,
  ItemComponent: null, // todo: this doesn't exist anymore...but reusing this for the time grid
  itemName: 'packets',
  select: (pkt) => ({ packetId: pkt.id }),
}

export const laXpacket: ListAdapter<XpacketSW, RD_JumpToQ> = {
  id: 'xpacketlist',
  idFromItem: dotId,
  idFromRouter: (routeData) => routeData.xpacketId,
  ItemComponent: XpacketListItem,
  itemName: 'scans',
  select: (xp) => ({ xpacketId: xp.id, studentId: xp.student?.id }),
}

export const laStudent: ListAdapter<Student, RD_JumpToQ> = {
  id: 'studentlist',
  idFromItem: (stu) => stu.id,
  idFromRouter: (routeData) => routeData.studentId,
  ItemComponent: null, // todo: this doesn't exist anymore...but reusing this for the time grid
  itemName: 'students',
  select: (stu) => ({ studentId: stu.id }),
}

export const laScanBatch: ListAdapter<CrunchBatch, RD_Scanlog> = {
  id: 'scanbatchlist',
  idFromItem: dotId,
  idFromRouter: (routeData) => routeData.sb_batchId,
  ItemComponent: null,
  itemName: 'batches',
  select: (sb) => ({ sb_batchId: sb.id }),
}
