import { padToN0 } from './format'

/**
 * Gets `xpageId` from `xpacketId` and `index`
 */
export const toXpageId = (xpacketId: string, index: number) => {
  return `${xpacketId}.${padToN0(index, 2)}`
}

/**
 * Parses `xpageId` into `{ xpacketId, pageIndex }`
 */
export const parseXpageId = (xpageId: string) => {
  let [xpacketId, idxStr] = xpageId.split('.')
  return { xpacketId, pageIndex: parseInt(idxStr) }
}

/**
 * Gets scanImage id from `batchId` and `index`
 */
export const toScanImageId = (batchId: string, index: number) => {
  return `${batchId}.${padToN0(index, 3)}`
}

/**
 * Parses scanImage id into `{ batchId, index }`
 */
export const parseScanImageId = (xpageId: string) => {
  let [batchId, idxStr] = xpageId.split('.')
  return { batchId, batchIndex: parseInt(idxStr) }
}
