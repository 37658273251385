import type {
  PacketContent,
  Packetmeta,
  Section,
  Student,
  Teacher,
  XpacketStatus,
} from '..'

export type StudentPacketStatus = Student & { status?: XpacketStatus }
export type StudentNSection = {
  section: Section
  student: Student
}

export type PrintedStatus = { date: number; is: boolean }
/**
 * Manifest of what's printed (when combined with Xpage and Xpacket)
 */
export type PrintManifest = {
  id: string
  /** Start of printing when we take snapshots (?) todo: is that correct */
  date: number
  /** packetId (key into packetmeta collection) */
  packetId: string
  /** Snapshot of packetmeta at time of printing */
  packetSnap: PacketPrintSnapshot
  /** Email of the user who did the printing */
  printUser: string
  /** Id of the scoremeta at time of printing  */
  scoremetaId?: string
  /** Printing is per Teacher */
  teacher: Teacher
  /** Printing is multi-step; `_printed.is === true if printing has completed */
  _printed: PrintedStatus
}

/**
 * Packetmeta subset snapshotted when printing
 */
export type PacketPrintSnapshot = {
  curriculum: Packetmeta['curriculum']
  name: PacketContent['name']
  number: Packetmeta['number']
  segments: [start: number, length: number][]
  style: PacketContent['style']
  type: PacketContent['type']
}
