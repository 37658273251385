import { filterObject } from '@paper/utils'
import Bowser from 'bowser'
import { serializeError } from 'serialize-error'
import type { ErrorResponse } from '~src/blocks/errors/errorUtils'

const getPlatform = () => {
  const { browser, os, platform } = Bowser.parse(window.navigator.userAgent)
  return { browser, os, platform }
}

const formatDimensions = (obj: any, prefix = '') => {
  return ['Width', 'Height']
    .map(
      (key) => obj[prefix ? `${prefix}${key}` : key.toLowerCase()] ?? 'notfound'
    )
    .join('x')
}

const getPlatformString = () => {
  const { browser, os, platform } = getPlatform()
  return Object.entries({
    Browser: [browser.name, browser.version],
    OS: [os.name, os.version, os.versionName],
    Platform: [platform.type, platform.vendor, platform.model],
    Window: [formatDimensions(window, 'inner')],
    Screen: [
      formatDimensions(window.screen, 'avail'),
      formatDimensions(window.screen),
    ],
  })
    .map(
      ([label, values]) => `* ${label}: ${values.filter((p) => p).join(' | ')}`
    )
    .join('\n')
}

export type SupportLinkProps = {
  error?: Error
  response?: ErrorResponse
  subject?: string
  uuid?: string
}

export function getSupportMailTo(props: SupportLinkProps) {
  const email = 'support@ponder.co'

  let subject = props.subject ?? ''
  let data = filterObject(
    {
      error: props.error && serializeError(props.error),
      href: window.location.href,
      response: props.response,
      uuid: props.uuid,
    },
    (p) => !!p
  )
  let stringified = JSON.stringify(data, undefined, 2)
  let encoded = encodeURIComponent(stringified)

  let sections = [
    '[Let us know what went wrong. Screenshots appreciated!]',
    '',
    `---- Below is some info we've collected to help fix the problem ----`,
    encodeURIComponent(getPlatformString()),
    `${'```'}%0A${encoded}%0A${'```'}`,
  ]

  // add spacing between sections
  let body = sections.join('%0A%0A')

  let link = `mailto:${email}?body=${body}`
  if (subject) {
    link += `&subject=${subject}`
  }
  return link
}

// Add to window for internal folks
// @ts-expect-error
window.copyPlatformData = () =>
  // @ts-expect-error
  copy(getPlatformString())
