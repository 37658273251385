import { routemeta } from '@paper/route'
import { PinGridPage } from './pinGrid'

export type RD_PinGrid = {
  aStr?: string
  contentId: string
  qId?: string
  teaId?: string
}

const pinGrid = routemeta<RD_PinGrid>({
  path: '/network',
  queryParams: ['aStr', 'contentId', 'qId', 'teaId'],
  render() {
    return <PinGridPage />
  },
})

export const cnRoutes = { crossNetwork: pinGrid }
