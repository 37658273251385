/**
 * Increments `obj[key] by `amt` if exists, or initializes to `amt`
 */
export const incrementOrInit = (obj: object, key: string, amt = 1) => {
  if (!(key in obj)) {
    obj[key] = 0
  }
  obj[key] += amt
  return obj[key]
}

/**
 * Increments `map[key]` by `amt` if exists, or initializes to `amt`
 */
export function incrOrInitMap(map: Map<string, number>, key: string, amt = 1) {
  map.set(key, (map.get(key) ?? 0) + amt)
}

export function setDefault<T>(
  obj: Record<string | number, T> | T[],
  key: string | number,
  defaultValue: T
): T {
  if (!(key in obj)) {
    obj[key] = defaultValue
  }
  return obj[key]
}

export type ObjOf<S> = { [key in string | number]: S }

export function pushDefault<T>(
  obj: ObjOf<T[]>,
  key: string | number,
  value: T
): T[] {
  const arr = setDefault(obj, key, [])
  arr.push(value)
  return arr
}

export function addToSetDefault<T>(
  obj: ObjOf<Set<T>>,
  key: string | number,
  value: T
): Set<T> {
  const set = setDefault(obj, key, new Set<T>())
  set.add(value)
  return set
}

export function setDefaultMap<V, K = string>(
  map: Map<K, V>,
  key: K,
  value: V | (() => V)
): V {
  if (!map.has(key)) {
    // @ts-expect-error
    map.set(key, typeof value === 'function' ? value() : value)
  }
  return map.get(key)
}

export function pushToMap<T, K = string>(
  map: Map<K, T[]>,
  key: K,
  value: T
): T[] {
  const arr = setDefaultMap(map, key, [])
  arr.push(value)
  return arr
}

export function addToMapSetDefault<T, K = string>(
  map: Map<K, Set<T>>,
  key: K,
  value: T
): Set<T> {
  const set = setDefaultMap(map, key, new Set())
  set.add(value)
  return set
}
