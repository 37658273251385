import type { ApiListStudentsMovePages, ApiScanXpacketList } from '@paper/api'
import { useRouter } from '@paper/route'
import { BaseData } from '@paper/route/src/types'
import { DirPacket, IAP, Xpacket } from '@paper/schema'
import { orderBy } from 'lodash'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { ListAdapter, ListDigest, useListCallbacks } from '~src/blocks/list'
import {
  ListCallbacks,
  usePrevNext,
  useStaticFn,
} from '~src/blocks/list/listCallbacks'
import { useUser } from '~src/blocks/userProvider'
import { checkDigestAirlock } from '~src/utils/airlock'
import { useFilters } from '~src/utils/useFilters'
import type { RD_SW } from '../routes'
import { QK_IOU } from './data-scanlog'

export type ScanXpacketDigest = ListDigest<Xpacket>

const laScanXpacket: ListAdapter<Xpacket, RD_SW> = {
  id: 'xpacketlist',
  idFromItem: (item) => item.id,
  idFromRouter: (routeData) => routeData.xpacketId,
  ItemComponent: null,
  itemName: 'scans',
  select: (xp) => ({ xpacketId: xp?.id, studentId: xp?.student?.id }),
}

type DigestConfig<T> = {
  process?(items: T[]): { empty: boolean; items: T[] }
  selectSrc?: 'state' | 'url'
}

export const useScanXpacketDigest = (
  packet: DirPacket,
  teacherId: string,
  config?: DigestConfig<Xpacket>
): ScanXpacketDigest => {
  const { fetchAs } = useUser()
  const adapter = laScanXpacket
  const packetId = packet?.id

  const url = IAP.scanxpacket.list
  let payload: ApiScanXpacketList['query'] = {
    packetId,
    teacherId,
  }

  const qResult = useQuery(
    [QK_IOU, url, payload],
    async () => {
      let result = await fetchAs
        .get(url, { searchParams: payload })
        .json<ApiScanXpacketList['result']>()

      return result
    },
    // cacheTime=0 remove from cache immediately when umounted
    { enabled: !!(teacherId && packetId), cacheTime: 0 }
  )

  // sort
  let { empty, items } = useMemo(() => {
    let items = qResult.data
    let empty = !items?.length

    // process if requested
    if (config?.process && items) {
      ;({ empty, items } = config.process?.(items))
    }

    // sort
    items = sortXpacketsForScanXpacketList(items)

    return { empty, items }
  }, [qResult.data])

  ////////////////////////////
  // Selected state and callbacks
  ////////////////////////////
  const listCallbacksHook =
    config?.selectSrc === 'state' ? useLocalListCallbacks : useListCallbacks
  const listCallbacks = listCallbacksHook(items, adapter)

  let digest: ScanXpacketDigest = {
    adapter,
    qResult,
    success: !qResult.isSuccess ? null : { empty, items, ...listCallbacks },
  }

  // airlock todo: probably move this...
  if (config?.selectSrc !== 'state') {
    const { useAirlock } = useRouter<RD_SW>()
    useAirlock({ studentId: null, xpacketId: null }, checkDigestAirlock(digest))
  }

  return digest
}

interface UseListCallbacks {
  <T, RD extends BaseData = BaseData>(
    items: T[],
    adapter: ListAdapter<T, RD>
  ): ListCallbacks<T>
}
/**
 * todo: copy/pasted from useListCallbacks...
 */
export const useLocalListCallbacks: UseListCallbacks = <T, RD>(
  items: T[],
  adapter: ListAdapter<T, RD>
) => {
  const [localData, setLocalData] = useState<RD>({} as RD)

  const selectedId = adapter.idFromRouter(localData)
  const selectedIndex = items?.findIndex(
    (itm) => adapter.idFromItem(itm) === selectedId
  )
  const selectedItem = items?.[selectedIndex]
  const onSelect = useStaticFn((item: T) => {
    setLocalData((old) => ({ ...old, ...adapter.select(item) }))
  })
  const [onPrev, onNext] = usePrevNext(items, selectedIndex, onSelect)

  return { onNext, onPrev, onSelect, selectedId, selectedIndex, selectedItem }
}

const sortXpacketsForScanXpacketList = (items: Xpacket[]) =>
  orderBy(items, [
    (p) =>
      p.status === 'partial'
        ? 0
        : p.status === 'missing'
        ? 1
        : p._overriden
        ? 2
        : Infinity,
    (p) => p.student?.lastfirst,
  ])

// todo: copy/paste with useScanXpacketDigest
export const useMoveDestDigest = (xpacketId: string, nameFilter: string) => {
  const { fetchAs } = useUser()
  const adapter = laScanXpacket
  const url = IAP.unstable.movePages.students
  const payload: ApiListStudentsMovePages['body'] = { xpacketId }
  const qResult = useQuery(
    [QK_IOU, url, payload],
    async () => {
      let xpackets = await fetchAs
        .post(url, { json: payload })
        .json<ApiListStudentsMovePages['result']>()

      xpackets = sortXpacketsForScanXpacketList(xpackets)

      return xpackets
    },
    { enabled: !!xpacketId }
  )

  let items = qResult.data

  // filter by name
  const filters = useFilters()
  items = useMemo(() => {
    return !items || !nameFilter
      ? []
      : items.filter((p) =>
          filters.startsWithByWord(p.student.lastfirst, nameFilter)
        )
  }, [items, nameFilter])

  const empty = !items?.length

  const listCallbacks = useLocalListCallbacks(items, adapter)

  let digest: ScanXpacketDigest = {
    adapter,
    qResult,
    success: !qResult.isSuccess ? null : { empty, items, ...listCallbacks },
  }

  return digest
}
