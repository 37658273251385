import { routemeta } from '@paper/route'
import { XpacketTag } from '@paper/schema'
import { swAdapters, SWProvider } from '~src/blocks/swContext'
import { TeacherAirlock } from '~src/blocks/teacherAirlock'
import { JumpTo } from './jumpTo'
import { Scanlog } from './scanlog'
import { ScanlogAirlock } from './scanlog/scanlogAirlock'
import { SetStudentView } from './setstudent'
import { TimeGridView } from './timeGrid'

export type RD_SW = {
  f_sectionId?: string
  curriculumId: string
  packetId: string
  teacherId: string
  qId?: string
  studentId?: string
  xpacketId?: string
}

const prefix = '/teacher'

export type ScanNoscan = 'scans' | 'noscans'

export type RD_JumpToQ = RD_SW & {
  f_ans?: string
  f_scans?: ScanNoscan
  f_tag?: XpacketTag
}

export type RD_JumpToStd = RD_JumpToQ & {
  std?: string
}

const jump_q = routemeta<RD_JumpToQ>({
  path: `${prefix}/jump-to-q`,
  queryParams: [
    'curriculumId',
    'packetId',
    'studentId',
    'teacherId',
    'xpacketId',
    'f_ans',
    'f_scans',
    'f_sectionId',
    'f_tag',
    'qId',
  ],
  render() {
    return (
      <TeacherAirlock behavior="unlocked-dont-clear-curriculum">
        <SWProvider adapter={swAdapters.jumpTo}>
          <JumpTo />
        </SWProvider>
      </TeacherAirlock>
    )
  },
})

const jump_std = routemeta<RD_JumpToStd>({
  path: `${prefix}/jump-to-std`,
  queryParams: [
    'curriculumId',
    'packetId',
    'studentId',
    'teacherId',
    'xpacketId',
    'f_ans',
    'f_scans',
    'f_sectionId',
    'f_tag',
    'qId', // todo: c/p
    'std',
  ],
  render() {
    return (
      <TeacherAirlock behavior="locked">
        <SWProvider adapter={swAdapters.jumpTo}>
          <JumpTo />
        </SWProvider>
      </TeacherAirlock>
    )
  },
})

export type RD_Tea_Time = RD_SW & {
  f_packet?: string
  std?: string
  tgf_col?: 'hide-empty'
  tgf_tag?: string
  tgs_rowsBy?: string[]
}

const time = routemeta<RD_Tea_Time>({
  path: `${prefix}/time`,
  queryParams: [
    'curriculumId',
    'packetId',
    'studentId',
    'teacherId',
    'xpacketId',
    'f_sectionId',
    'f_packet',
    'qId',
    'std',
    'tgf_col',
    'tgf_tag',
    'tgs_rowsBy',
  ],
  render() {
    return (
      <TeacherAirlock behavior="locked">
        <SWProvider adapter={swAdapters.time}>
          <TimeGridView />
        </SWProvider>
      </TeacherAirlock>
    )
  },
})

const tag = routemeta<RD_Tea_Time>({
  ...time,
  path: `${prefix}/tag`,
})

const swQPs: (keyof RD_SW)[] = [
  'curriculumId',
  'f_sectionId',
  'packetId',
  'qId',
  'studentId',
  'teacherId',
  'xpacketId',
]

export type RD_Scanlog = Partial<RD_SW> & {
  sb_batchId?: string
  scanUser?: string
  si_imageId?: string
  status?: 'complete' | 'incomplete'
}

const scanlog = routemeta<RD_Scanlog>({
  path: `${prefix}/scanlog`,
  queryParams: [...swQPs, 'sb_batchId', 'scanUser', 'si_imageId', 'status'],
  render() {
    return (
      <TeacherAirlock behavior="unlocked-clear-curriculum">
        <SWProvider adapter={swAdapters.scanlog}>
          <ScanlogAirlock>
            <Scanlog />
          </ScanlogAirlock>
        </SWProvider>
      </TeacherAirlock>
    )
  },
})

const setstudent = routemeta<RD_SW>({
  path: `${prefix}/setstudent`,
  queryParams: [...swQPs],
  render() {
    return (
      <TeacherAirlock behavior="locked">
        <SWProvider adapter={swAdapters.nostudent}>
          <SetStudentView />
        </SWProvider>
      </TeacherAirlock>
    )
  },
})

export const swRoutes = {
  jump_q,
  jump_std,
  scanlog,
  setstudent,
  tag,
  time,
}
