import { Box, BoxProps, ButtonProps } from '@chakra-ui/react'
import type { StrId } from '@paper/schema'
import {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useVirtual } from 'react-virtual'
import { useAlwaysUpdateRef } from '~src/utils/useRefs'
import { Txt } from './txt'

export type ColumnProps = {
  align?: 'start' | 'center' | 'end'
  width?: number
}
export type UghColumn<T> = {
  cell(item: T): ReactNode
  label(): ReactNode
  props?: ColumnProps
}

type TableContext<T = any> = {
  cell: { px: number }
  colorScheme: string
  columns: UghColumn<T>[]
  row: {
    baseHeight: number
    // todo: this api is WIP
    Expandee: UghTableProps<T>['Expandee']
    expandeeHeight: number
    gridTemplateColumns: string
    onSelect(item: T): void
  }
}

export type ExpandeeProps<T> = {
  height: number
  item: T
}

const TableContext = createContext<TableContext>(undefined)

type UghTableProps<T> = {
  'aria-label': string
  colorScheme?: ButtonProps['colorScheme']
  columns: UghColumn<T>[]
  data: T[]
  Expandee?: FC<ExpandeeProps<T>>
  expandeeHeight?: number
  flexShrink?: BoxProps['flexShrink']
  getId?(item: T): any
  height?: BoxProps['height']
  onSelect?(item: T): void
  selectedId?: string
  spacingX?: 'normal' | 'snug'
  spacingY?: 'normal' | 'snug' | 'airy'
}
/**
 * @react-spectrum/table was super-duper slow when filtering
 * I don't think built in <table> mojo supports virtual very well
 * For now, ignoring accessiblity/focus/etc. to get something working
 * Maybe a pay out-of-the-box component?, useTable
 */
export const UghTable = memo(function UghTable<T extends StrId>(
  props: UghTableProps<T>
) {
  // console.log('<UghTable />')
  const {
    columns,
    colorScheme,
    data,
    Expandee,
    expandeeHeight = 0,
    getId,
    height,
    onSelect,
    selectedId,
    spacingX,
    spacingY,
    ...tableElProps
  } = props

  ////////////////////////
  // Selection
  ////////////////////////
  const onSelectRef = useAlwaysUpdateRef(onSelect)
  const selectedIndex = useMemo(() => {
    return !selectedId
      ? -1
      : data.findIndex((p) => (getId ? getId(p) : p.id) === selectedId)
  }, [data, selectedId]) // todo: should i add getId here?

  ////////////////////////
  // Virtualization and scroll
  ////////////////////////
  const rowHeight = spacingY === 'airy' ? 48 : spacingY === 'snug' ? 36 : 40

  const parentRef = useRef<HTMLDivElement>()
  // todo: any perf issues around updating this on every selectedIndex change?
  const estimateSize = useCallback(
    (index) =>
      index === selectedIndex ? rowHeight + expandeeHeight : rowHeight,
    [expandeeHeight, rowHeight, selectedIndex]
  )
  // todo: this hook hammers render
  // if it becomes a problem, could check for memoization/move to a component
  const rv = useVirtual({ estimateSize, parentRef, size: data.length })
  // scroll to selected
  useEffect(() => {
    rv.scrollToIndex(selectedIndex)
  }, [selectedIndex])

  ////////////////////////
  // Context
  ////////////////////////
  const ctx: TableContext<T> = useMemo(() => {
    const cellXPad = spacingX === 'snug' ? 1 : 6
    const widthPad = spacingX === 'snug' ? 0 : 48
    const gridTemplateColumns = columns
      .map((c) =>
        c.props?.width
          ? `minmax(${c.props.width + widthPad}px, ${
              c.props.width > 80 ? '2.5fr' : '1fr'
            })`
          : `minmax(300px, 5fr)`
      )
      .join(' ')

    return {
      cell: {
        px: cellXPad,
      },
      colorScheme,
      columns,
      row: {
        baseHeight: rowHeight,
        Expandee,
        expandeeHeight,
        gridTemplateColumns,
        onSelect: (item) => onSelectRef.current?.(item),
      },
    }
  }, [columns, estimateSize, rowHeight, spacingX])

  return (
    <TableContext.Provider value={ctx}>
      <Box
        aria-colcount={columns.length}
        aria-rowcount={data.length}
        display="grid"
        gridTemplateRows="max-content"
        height={height}
        role="grid"
        overflowX="auto"
        {...tableElProps}
      >
        <UghHeader />
        <Box ref={parentRef} role="presentation" overflowY="auto">
          <Box
            role="presentation"
            style={{ height: rv.totalSize, position: 'relative' }}
          >
            {!data?.length && (
              <Txt fontStyle="italic" opacity={0.8} p={6} textAlign="center">
                Empty
              </Txt>
            )}
            {rv.virtualItems.map(({ index, size, start }) => (
              <Box
                key={index}
                role="presentation"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${size}px`,
                  transform: `translateY(${start}px)`,
                  transitionDuration: '.4s',
                  transitionProperty: 'height top',
                }}
              >
                <UghRow
                  isSelected={index === selectedIndex}
                  item={data[index]}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </TableContext.Provider>
  )
})

type UghHeaderProps = {}
const UghHeader = memo(function UghHeader(props: UghHeaderProps) {
  const { columns } = useContext(TableContext)
  return (
    <Row item={null}>
      {columns.map((c, idx) => (
        <Cell align={c.props?.align} key={idx} role="rowheader">
          {c.label()}
        </Cell>
      ))}
    </Row>
  )
})

type UghRowProps<T> = { isSelected: boolean; item: T }
const UghRow = memo(function UghRow<T extends StrId>(props: UghRowProps<T>) {
  // console.log('<UghRow />')
  const { isSelected, item } = props
  const { columns } = useContext(TableContext)

  return (
    <Row id={item.id} isSelected={isSelected} item={item}>
      {columns.map((c, idx) => (
        <Cell align={c.props?.align} key={idx} role="gridcell">
          {c.cell(item)}
        </Cell>
      ))}
    </Row>
  )
})

type RowProps<T> = {
  children: ReactNode
  id?: string
  isSelected?: boolean
  item: T
}
function Row<T>(props: RowProps<T>) {
  // console.log('<Row />')
  const { id, isSelected, item, ...elProps } = props
  const {
    colorScheme = 'blue',
    row: {
      baseHeight,
      Expandee,
      expandeeHeight,
      gridTemplateColumns,
      onSelect,
    },
  } = useContext(TableContext)

  const isSelectable = !!(id && onSelect)

  let bg = isSelected ? `${colorScheme}.50` : null
  let activeBg: BoxProps['bg'] = bg ?? 'gray.50'

  return (
    // Needed an extra div as a shell for expanding
    <Box
      borderBottomWidth="1px"
      borderInline="4px solid transparent"
      borderInlineStartColor={isSelected && `${colorScheme}.500`}
      height="100%"
      overflowY={isSelected ? 'hidden' : undefined} // note: setting this for all caused each row to scroll horizontally individually
      role="row"
    >
      <Box
        alignItems="center"
        bg={bg}
        display="grid"
        gridTemplateColumns={gridTemplateColumns}
        height={`${baseHeight}px`}
        onClick={isSelectable ? () => onSelect(item) : null}
        justifyItems="center"
        transition="background-color 250ms ease"
        _hover={{ bg: !isSelectable ? null : activeBg }}
        {...elProps}
      />
      {isSelected && Expandee && (
        <Expandee height={expandeeHeight} item={item} />
      )}
    </Box>
  )
}

type CellProps = {
  align?: 'start' | 'center' | 'end'
  children: ReactNode
  role: 'rowheader' | 'gridcell'
}
function Cell(props: CellProps) {
  const { align, ...elProps } = props
  const { px } = useContext(TableContext).cell
  return (
    <Box
      // alignItems="center"
      // display="flex"
      justifySelf={align}
      maxWidth="100%"
      overflow="hidden"
      px={px}
      py={1}
      textAlign={align}
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      {...elProps}
    />
  )
}
