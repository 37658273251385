import { useRouter } from '@paper/route'
import { DirPacket, XpacketSW } from '@paper/schema'
import { createContext, ReactNode, useContext } from 'react'
import { QDigest, StdDigest, useQStdDigests } from '~src/data/data-questions'
import { Routes } from '~src/routes'

type QStdContext = (
  | { mode: 'q'; digest: QDigest }
  | { mode: 'std'; digest: StdDigest }
) & { packet: DirPacket; qDigest: QDigest; stdDigest: StdDigest }

const QStdContext = createContext<QStdContext>(null)
export const useQStdContext = () => useContext(QStdContext)

type QStdProviderProps = {
  children: ReactNode
  packet: DirPacket
  xpackets: XpacketSW[]
}

export function QStdProvider(props: QStdProviderProps) {
  const { children, packet, xpackets } = props
  const { qDigest, stdDigest } = useQStdDigests(packet, xpackets)
  const { currentRoute } = useRouter()
  const mode: QStdContext['mode'] =
    currentRoute === Routes.jump_std ? 'std' : 'q'

  const ctx: QStdContext = {
    // typescript doesn't understand that these are entangled...
    mode: mode as any,
    digest: (mode === 'q' ? qDigest : stdDigest) as any,
    packet,
    qDigest,
    stdDigest,
  }
  return <QStdContext.Provider value={ctx}>{children}</QStdContext.Provider>
}
