import { useLayoutEffect, useRef, useState } from 'react'

export function useStateAndRef<S>(initialValue?: S | (() => S)) {
  const [state, setState] = useState(initialValue)
  // Initialize ref to initial state, hopefully actually fix #61
  const ref = useRef<S>(state)
  useLayoutEffect(() => {
    ref.current = state
  })
  return [state, setState, ref] as const
}
