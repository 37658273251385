import { useCombobox, UseComboboxProps } from 'downshift'

export const alwaysHighlightReducer: UseComboboxProps<any>['stateReducer'] = (
  state,
  actionAndChanges
) => {
  const { changes, type } = actionAndChanges
  // state.selectedItem === changes.selectedItem
  //   ? console.log(type, state.selectedItem)
  //   : console.log(type, 'old', state.selectedItem, 'new', changes.selectedItem)

  /*

    todo: todo: I just realized that `inputValue` can be controlled
    Meaning I think there's a cleaner version where I listen to various events and set `inputValue` how I want accordingly

    todo: this is messy b/c downshift has some weird(?) behavior
    (1) hitting tab or shift+tab selects, which seems weird
      * workaround: don't update selectedItem on blur events

    (2) blurring (esc or click) doesn't reset inputValue to selectedItem's value (so the combobox is displaying a random not selected value)
      * workaround: display selectedItem label as span (like react-widgets) and clear inputValue on any sort of select
      * it's maybe preferable to `onClose() { setInputValue(itemToString(selectedItem))}`, though this would require an extra render
      * another option is to try to keep track of "last good inputValue" and reset to that instead of "" 
  */
  switch (type) {
    // (1) Reset inputValue and don't update selectedItem on cancel blur "events"
    case useCombobox.stateChangeTypes.InputBlur:
    case useCombobox.stateChangeTypes.InputKeyDownEscape:
      // Clear text on blur so we show the correct selectedItem with the box is closed
      // And keep selectedItem: downshift is selecting on tab without this
      return { ...changes, selectedItem: state.selectedItem, inputValue: '' }
    // (2) Reset inputValue on selection "events"
    case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
    case useCombobox.stateChangeTypes.FunctionSelectItem:
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
      // Override setting input text on item selection
      return { ...changes, inputValue: '' }
    // (3) Highlight the first item on typing (?)
    // todo: i wonder if this should just be checking for isOpen: false->true
    case useCombobox.stateChangeTypes.InputChange:
    case useCombobox.stateChangeTypes.FunctionSetInputValue:
      // todo: how to do this properly...
      if (changes.highlightedIndex === -1 && changes.isOpen === true) {
        return { ...changes, highlightedIndex: 0 }
      }
      break
  }

  return changes
}
