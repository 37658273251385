import { Box, IconButton } from '@chakra-ui/react'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  IcoAdd,
  IcoDragHandle,
  IcoSortAmountAsc,
  IcoSortAmountDesc,
  IcoX,
} from '@paper/icons'
import produce from 'immer'
import { SetStateAction } from 'react'
import { HStack, Txt, VStack } from '~src/components'
import { useTeacherTimeContext } from './timeAirlock'

type SortItem = {
  id: string
  label: string
  order: 'asc' | 'desc'
}

const SortItemWidth = '88px'
const SortLength = 3

// todo: this api
type SortListProps = { currentId: string }

export function ColSort(props: SortListProps) {
  const { colSort: items, setColSort } = useTeacherTimeContext()

  const setItems = (next: SetStateAction<SortItem[]>) => {
    if (typeof next === 'function') {
      next = next(items)
    }
    setColSort(next)
  }

  const canAdd = !items.find((item) => item.id === props.currentId)

  //const [items, setItems] = useState(['1', '2', '3'])
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const addButton = (
    <HStack mr="auto" width={SortItemWidth}>
      <IconButton
        aria-label="add to sort"
        icon={<IcoAdd />}
        margin="auto"
        onClick={() => {
          setItems((items) => [
            ...items,
            { id: props.currentId, label: '', order: 'desc' },
          ])
        }}
        size="xs"
      />
    </HStack>
  )

  return (
    <HStack
      border="1px dashed rgba(255,255,255,.2)" // todo: c/p from menu divider
      borderRadius="4px"
      gap={1}
      height="77px" // maintain size irrespective of items
      width="314px"
      p={2}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={horizontalListSortingStrategy}>
          {items.map((item) => (
            <SortablePacket
              key={item.id}
              highlight={item.id === props.currentId}
              item={item}
              onChangeItem={(changed) => {
                setItems((items) =>
                  produce(items, (draft) => {
                    // swap in at index
                    const index = draft.findIndex(
                      (item) => item.id === changed.id
                    )
                    draft[index] = changed
                  })
                )
              }}
              onRemove={(id) =>
                setItems((items) => items.filter((item) => item.id !== id))
              }
            />
          ))}
          {canAdd && items.length < SortLength && addButton}
        </SortableContext>
      </DndContext>
    </HStack>
  )

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((p) => p.id === active.id)
        const newIndex = items.findIndex((p) => p.id === over.id)
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }
}

type SortablePacketProps = {
  highlight?: boolean
  item: SortItem
  onChangeItem(changed: SortItem): void
  onRemove(id: string): void
}

export function SortablePacket(props: SortablePacketProps) {
  const { highlight, item, onChangeItem, onRemove } = props

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <VStack
      alignItems="stretch"
      background="rgba(255,255,255,.04)"
      border={highlight ? '1px solid rgba(255,255,255,.2)' : null} // todo: c/p from menu divider"
      borderRadius="4px"
      cursor={isDragging ? 'grabbing' : 'default'}
      fontSize="sm"
      gap={1}
      ref={setNodeRef}
      px="10px"
      py="2px"
      style={style}
      userSelect="none"
      width={SortItemWidth}
      {...attributes}
    >
      <HStack
        {...listeners}
        cursor={isDragging ? 'grabbing' : 'grab'}
        justifyContent="center"
        py={1}
      >
        <Box as={IcoDragHandle} aria-label="reorder" ml="-3px" />
        <Txt fontFamily="mono" textAlign="center">
          {item.label}
        </Txt>
      </HStack>
      <HStack gap={2} justifyContent="center">
        <IconButton
          aria-label="toggle sort direction"
          icon={
            item.order === 'asc' ? <IcoSortAmountAsc /> : <IcoSortAmountDesc />
          }
          onClick={() =>
            onChangeItem({
              ...item,
              order: item.order === 'asc' ? 'desc' : 'asc',
            })
          }
          size="xs"
          variant="ghost"
        />
        <IconButton
          aria-label="remove sort"
          icon={<IcoX />}
          onClick={() => onRemove(item.id)}
          size="xs"
          variant="ghost"
        />
      </HStack>
    </VStack>
  )
}
