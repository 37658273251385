import type { PacketPrintSnapshot } from '..'
import type { Section, Student, Teacher } from './school'

export enum Pos {
  tl = 'tl',
  tr = 'tr',
  bl = 'bl',
  br = 'br',
}

export enum Margin {
  qtr = '1/4',
  half = '1/2',
  threeqtr = '3/4',
  inch = '1',
}

export enum Padding {
  eighth = '1/8',
  qtr = '1/4',
}

export enum Variant {
  'compact' = 'compact',
  'full' = 'full',
}

export type PdfStyle = {
  margin: Margin
  padding: Padding
  position: Pos
  variant: Variant
}

/**
 * Data required to print a normal page (non-bubble sheet)
 * todo: a little weird that xpageId is in this blob but pageNumber isn't
 */
export type PrintPageData = {
  packetSnap: PacketPrintSnapshot
  qrBackup: string
  section: Section
  student?: Student
  teacher: Teacher
  xpageId: string
}

export type PrintPacketProps = {
  dbId: string
  packetSnap: PacketPrintSnapshot
  srcBuf: ArrayBuffer
  teacher: Teacher
  xpackets: {
    pages: { qrBackup: string; xpageId: string }[]
    section: Section
    student?: Student
  }[]
}
