import { ButtonProps, chakra } from '@chakra-ui/react'
import {
  IcoArrowBack,
  IcoArrowForward,
  IcoLeftRight,
  IconType,
} from '@paper/icons'
import { ReactNode } from 'react'

/**
 * Get size of page graph for virtual rows
 * i dunno, should maybe just measure instead?
 */
export const measurePageGraph = (count: number) => {
  count = count || 0

  const pageSize = 16
  const cols = 20

  const colGap = 1
  const tallyGap = 8
  const rowGap = 4

  const rows = Math.ceil(count / cols)
  const width = cols * (pageSize + colGap) + (cols / 5 - 1) * tallyGap
  const height = rows * (pageSize + rowGap) - rowGap

  return { colGap, cols, height, pageSize, rowGap, tallyGap, width }
}

type PageGraphProps = {
  data: {
    arrow?: 'in' | 'out' | 'inout'
    colorScheme: ButtonProps['colorScheme']
    onSelect?(): void
    selected: boolean
    targeted: boolean
  }[]
}

export function ScanlogPageGraph(props: PageGraphProps) {
  const { data } = props
  if (!data) {
    return null
  }

  const { colGap, cols, height, pageSize, rowGap, tallyGap, width } =
    measurePageGraph(props.data.length)

  const viewBox = `0 0 ${width} ${height}`

  let boxes: ReactNode[] = []
  let x = 0
  let y = 0
  for (let i = 0; i < data.length; i++) {
    let { arrow, colorScheme, onSelect, selected, targeted } = data[i]

    let color500 = `${colorScheme}.500`
    let dashed = arrow === 'out'
    let strokeWidth = arrow ? 1 : 1.5
    let adjSize = pageSize - 2 * strokeWidth
    let adjX = x + strokeWidth
    let adjY = y + strokeWidth

    boxes.push(
      <chakra.rect
        cursor={onSelect ? 'pointer' : null}
        key={i}
        x={adjX}
        y={adjY}
        width={`${adjSize}px`}
        height={`${adjSize}px`}
        fill={selected ? color500 : 'transparent'}
        strokeWidth={strokeWidth}
        stroke={color500}
        strokeDasharray={dashed ? '4 2' : null}
        rx={2}
        onClick={onSelect}
        pointerEvents={onSelect ? null : 'none'}
        sx={{
          _hover: {
            fill: `${colorScheme}.${selected ? 600 : 100}`,
            stroke: `${colorScheme}.600`,
          },
          _active: {
            fill: `${colorScheme}.${selected ? 700 : 200}`,
            stroke: `${colorScheme}.700`,
          },
        }}
      />
    )

    if (targeted) {
      ;[2, 4.25].forEach((r) =>
        boxes.push(
          <chakra.circle
            key={i + '.' + r}
            cx={adjX + adjSize / 2}
            cy={adjY + adjSize / 2}
            pointerEvents="none"
            r={r}
            stroke={color500}
            strokeWidth={1.25}
            fill="none"
          />
        )
      )
    }

    if (arrow) {
      let ArrowIcon: IconType
      switch (arrow) {
        case 'out':
          ArrowIcon = IcoArrowBack
          break
        case 'in':
          ArrowIcon = IcoArrowForward
          break
        case 'inout':
          ArrowIcon = IcoLeftRight
          break
      }
      const arrowSize = pageSize * (2 / 3)

      boxes.push(
        <ArrowIcon
          color={`var(--chakra-colors-${colorScheme}-500)`}
          height={arrowSize}
          key={i + 'arrow'}
          width={arrowSize}
          x={x}
          y={y + (pageSize - arrowSize) / 2}
        />
      )
    }

    x += pageSize + colGap
    if ((i + 1) % 5 === 0) {
      x += tallyGap
    }
    if ((i + 1) % cols === 0) {
      x = 0
      y += pageSize + rowGap
    }
  }

  return (
    <svg viewBox={viewBox} height={height} width={width}>
      {boxes}
    </svg>
  )
}
