import { AuthDomain, AuthUser } from '../types/auth'
import { IllPacket, IllXpacket } from '../types/illuminate'
import { Packetmeta } from '../types/packetmeta'
import { PrintManifest } from '../types/print'
import { Curriculum, Student, Teacher } from '../types/school'
import { Invalid, RunMeta, Stage } from '../types/staging'
import { TeacherSection } from '../types/teacher'
import { CrunchBatch, ScanBatch } from '../types/types.imgp'
import { Pin, PinQCellDb } from '../types/types.pin'
import { FullStd } from '../types/types.std'
import { Xpacket } from '../types/types.xpacket'

/** [C]ollection[M]ap - maps collection names to doc type */
export type CM = {
  _load_invalid: Invalid
  _load_meta: RunMeta
  _load_staging: Stage
  auth_domain: AuthDomain
  auth_user: AuthUser
  curriculum: Curriculum
  ill_qcell: PinQCellDb
  ill_packet: IllPacket
  ill_xpacket: IllXpacket
  packetmeta: Packetmeta
  pin: Pin
  printmanifest: PrintManifest
  scanbatch: ScanBatch
  scanbatch_crunched: CrunchBatch
  section: TeacherSection
  standard: FullStd
  student: Student
  teacher: Teacher
  xpacket: Xpacket
}

/** Map of collection names as strings */
export const Collections: Record<keyof CM, string> = {
  _load_invalid: '_load_invalid',
  _load_meta: '_load_meta',
  _load_staging: '_load_staging',
  auth_domain: 'auth_domain',
  auth_user: 'auth_user',
  curriculum: 'curriculum',
  ill_qcell: 'ill_qcell',
  ill_packet: 'ill_packet',
  ill_xpacket: 'ill_xpacket',
  packetmeta: 'packetmeta',
  pin: 'pin',
  printmanifest: 'printmanifest',
  scanbatch: 'scanbatch',
  scanbatch_crunched: 'scanbatch_crunched',
  section: 'section',
  standard: 'standard',
  student: 'student',
  teacher: 'teacher',
  xpacket: 'xpacket',
}

export const C = Collections
