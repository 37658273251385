import type { ApiTag } from '@paper/api'
import { useKeyboard } from '~src/utils/useKeyboard'
import { Tags } from '.'

type TagKeyboardProps = {
  isActive: boolean
  onNext(): void
  onSubmit(props: ApiTag['body']): void
  xpacketId: string
}
export function TagKeyboard(props: TagKeyboardProps) {
  const { isActive, onNext, onSubmit, xpacketId } = props
  // There are always the same number of tags, so this should be ok from a hooks perspective
  // Might need memo mojo
  Tags.map((tag) => {
    useKeyboard(tag.keyChar, () => {
      if (isActive) {
        onSubmit({ tag: tag.id, xpacketId })
        onNext()
      }
    })
  })
  return null
}
