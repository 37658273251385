import type { ApiPrintStart, ApiPrintVerify } from '@paper/api'
import type { PrintPacketOptions } from '@paper/pdf'
import {
  IAP,
  Student,
  StudentNSection,
  Teacher,
  TeacherSection,
} from '@paper/schema'
import { Fetcher, HTTPError } from '@paper/utils'
import { orderBy, times } from 'lodash'

// todo: messy, but moving this here so because tests have trouble importing it from "~src/..."
export class PrintRecalledError extends Error {
  static defaultFriendly = `Packet generation failed`
  friendly = `Packet generation failed`
}

export type PrintPdfProps = {
  blankCount?: number
  packetId: string
  sections: TeacherSection[]
  teacher: Teacher
}

const toStudentNSections = (
  sections: TeacherSection[],
  noStudentCount: number
): StudentNSection[] => {
  return sections.flatMap(({ students, ...section }) => {
    // Add some blanks
    const studentsWithBlanks: Student[] = [
      ...students,
      ...times(noStudentCount, () => null),
    ]

    return orderBy(studentsWithBlanks, (stu) => stu?.lastfirst).map(
      (student) => ({ section, student })
    )
  })
}

const STEPS = {
  GEN_MANIFEST: 'generating your print manifest',
  FETCH_SRC_PDF: 'fetching the source document',
  GEN_PACKETS: 'generating your packets',
  VERIFY_PACKETS: 'verifying your packets',
}

/**
 * "Prints" a set of packets to PDF
 * Awkardly factored this out because it's useful to call in the headless integration test
 */
export async function _printPdf(
  props: PrintPdfProps,
  options: PrintPacketOptions,
  fetchAs: Fetcher,
  fetch: Fetcher
) {
  const { blankCount, packetId, sections, teacher } = props
  // Keep track of step for error message
  let step = STEPS.GEN_MANIFEST
  try {
    // Construct payload to start printing
    const startBody: ApiPrintStart['body'] = [
      {
        packetId,
        studentNSections: toStudentNSections(sections, blankCount ?? 3),
        teacher,
      },
    ]

    const startResult = await fetchAs
      .post(IAP.print.start, { json: startBody })
      .json<ApiPrintStart['result']>()

    // We're currently only submitting one
    let { downloadUrl, manifest, printPacketProps } = startResult[0]

    // Download raw pdf
    step = STEPS.FETCH_SRC_PDF
    const srcBuf = await fetch(downloadUrl).arrayBuffer()

    // Generate PDF
    step = STEPS.GEN_PACKETS
    const { printPacket } = await import('@paper/pdf')
    await printPacket({ srcBuf, ...printPacketProps }, options)

    // Verify PDF
    step = STEPS.VERIFY_PACKETS
    const verifyBody: ApiPrintVerify['body'] = { id: manifest.id }
    manifest = await fetchAs
      .post(IAP.print.verify, { json: verifyBody })
      .json<ApiPrintVerify['result']>()

    return { manifest, printPacketProps }
  } catch (error) {
    // handle unpublished packet (todo: needs cleanup!)
    if (
      step === STEPS.GEN_MANIFEST &&
      (error as HTTPError).response?.status === 400
    ) {
      throw new PrintRecalledError()
    }

    // c/p from _submitPdf
    error.friendly = `There was a problem ${step}`
    error.values = props
    throw error
  }
}
