import { Box, BoxProps } from '@chakra-ui/react'
import { useDialog } from '@react-aria/dialog'
import { FocusScope } from '@react-aria/focus'
import {
  OverlayContainer,
  useModal,
  useOverlay,
  usePreventScroll,
} from '@react-aria/overlays'
import { ReactNode, useRef } from 'react'
import { useHackyFade } from '~src/utils/useAnimation'
import { VStack } from '..'
import { Txt } from '../txt'

type FPMType = 'opaque' | 'transparent' | 'warning' | 'error-red' | 'error-blue'

type FullPageMessageProps = {
  children?: ReactNode
  isActive?: boolean
  title?: ReactNode
  type: FPMType
}

export function FullPageMessage(props: FullPageMessageProps) {
  const { isActive = true, title } = props
  const ref = useRef()

  // dialog stuff
  const { overlayProps, underlayProps } = useOverlay({ isOpen: isActive }, ref)
  const { modalProps } = useModal()
  const { dialogProps, titleProps } = useDialog({}, ref)
  usePreventScroll()

  // suport fading in
  const { mounted, opacity, fadeTransition } = useHackyFade({
    isActive,
    enabled: true,
  })

  // variant styles
  const styles = useFPMStyles(props.type)

  const oProps = {
    ...overlayProps,
    ...dialogProps,
    ...modalProps,
    ...styles.overlay,
  }

  return !mounted ? null : (
    <OverlayContainer className="overlay-container">
      <VStack
        height="100vh"
        justifyContent="center"
        left={0}
        position="fixed"
        p={8}
        style={{ opacity }}
        ref={ref}
        top={0}
        transition={`background-color .4s ease, ${fadeTransition}`}
        width="100vw"
        zIndex={2000} // todo:
        {...underlayProps}
        {...styles.underlay}
      >
        <FocusScope contain restoreFocus autoFocus>
          <Box {...oProps} sx={{ '.text': styles.text }}>
            {title && <Box {...titleProps}>{title}</Box>}
            {props.children}
          </Box>
        </FocusScope>
      </VStack>
    </OverlayContainer>
  )
}

type FPMStyles = { underlay: BoxProps; overlay: BoxProps; text?: BoxProps }
function useFPMStyles(type: FPMType): FPMStyles {
  switch (type) {
    case 'opaque':
      return { underlay: { bg: 'white' }, overlay: {} }
    case 'transparent':
      return {
        underlay: { bg: 'rgba(0,0,0,.85)' },
        overlay: {},
        text: {
          bg: 'rgba(0,0,0,.1)',
          borderRadius: '12px',
          color: 'gray.300',
          fontWeight: 'bolder',
          px: 8,
          py: 4,
        },
      }
    case 'error-blue':
      return { underlay: { bg: 'blue.500' }, overlay: {} }
    case 'error-red':
      return {
        underlay: { bg: 'red.500' },
        overlay: {},
        text: { color: 'white', fontSize: '3xl', fontWeight: 'bolder' },
      }
    case 'warning':
      return { underlay: { bg: 'yellow.500' }, overlay: {} }
  }
}

function FullPageMessageText(props: BoxProps) {
  return <Txt as="div" className="text" fontSize="2xl" {...props} />
}

FullPageMessage.Text = FullPageMessageText
