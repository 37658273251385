import { routemeta } from '@paper/route'
import { PacketType, PublishStage } from '@paper/schema'
import { Home } from './pages/home'
import { HomeRedirect } from './pages/home/redirect'
import { internalRoutes } from './pages/internal/routes'
import { LinkApi } from './pages/linkApi'
import { cnRoutes } from './pages/pinGrid/routes'
import { pubRoutes } from './pages/publish/routes'
import { swRoutes } from './pages/sw/routes'

export type { RD_SW } from './pages/sw/routes'

type RD_HomeBase = {
  curriculumId?: string
  packetId?: string
  pgId?: string
}

type SharedPacketFilterKeys = {
  pl_filterText?: string
  pl_packetType?: PacketType
  pl_printStatus?: 'not' | 'printed' | 'radioactive'
}

// todo: sharing this stuff and keeping it in sync is way to messy/tedious

const HomeBaseKeys: readonly (keyof RD_HomeBase)[] = [
  'curriculumId',
  'packetId',
  'pgId',
]

const SharedPacketFilterKeys: readonly (keyof SharedPacketFilterKeys)[] = [
  'pl_filterText',
  'pl_packetType',
  'pl_printStatus',
] as const

// todo: ack and this...
export const PacketFilterKeys = [
  ...SharedPacketFilterKeys,
  'pl_pub',
  'pl_scanStatus',
]

////////////////////
// Home Teacher
////////////////////
export type RD_HomeTeacher = RD_HomeBase &
  SharedPacketFilterKeys & {
    pl_scanStatus?: 'scanned' | 'partial' | 'unnamed'
    teacherId?: string
  }

const home_teacher = routemeta<RD_HomeTeacher>({
  path: '/teacher',
  queryParams: [
    ...HomeBaseKeys,
    ...SharedPacketFilterKeys,
    'teacherId',
    'pl_scanStatus',
  ],
  render() {
    return <Home />
  },
})

////////////////////
// Home Curriculum
////////////////////
export type RD_HomeCurriculum = RD_HomeBase &
  SharedPacketFilterKeys & {
    pl_pub?: PublishStage
  }

const home_curriculum = routemeta<RD_HomeCurriculum>({
  path: '/curriculum',
  queryParams: [...HomeBaseKeys, ...SharedPacketFilterKeys, 'pl_pub'],
  render() {
    return <Home />
  },
})

////////////////////
// Home School
////////////////////
export type RD_HomeSchool = RD_HomeBase & {
  schoolId: string
  teacherId: string
}

const home_school = routemeta<RD_HomeSchool>({
  path: '/school',
  queryParams: [...HomeBaseKeys, 'schoolId', 'teacherId'],
  render() {
    return <Home />
  },
})

////////////////////
// Home redirect
////////////////////
const home = routemeta<{}>({
  path: '/',
  render() {
    return <HomeRedirect />
  },
})

export type RD_LinkApi = { _illuminate: string; qLabel?: string }

const linkApi = routemeta<RD_LinkApi>({
  path: '/linkapi/v1/illuminate/:_illuminate',
  queryParams: ['qLabel'],
  render() {
    return <LinkApi />
  },
})

export const Routes = {
  home,
  home_curriculum,
  home_school,
  home_teacher,
  linkApi,
  ...cnRoutes,
  ...pubRoutes,
  ...swRoutes,
  ...internalRoutes,
}
