import { Button } from '@chakra-ui/react'
import { IcoRefresh } from '@paper/icons'
import { ENDASH } from '@paper/utils'
import { ReactNode } from 'react'
import { PrintRecalledError } from '~src/data/_printPdf'
import { ErrorActionSection, ErrorPage } from './errorPageBase'
import { getEAProps } from './errorUtils'

type ErrorPageProps = { actions?: ReactNode; error?: PrintRecalledError }

/**
 * Roughed in handling if someone tries to print a recalled packet
 */
export function ErrorPrintRecalledPage(props: ErrorPageProps) {
  const { actions, error } = props
  const subject = `🤔 Paper 400`

  return (
    <ErrorPage
      actions={
        <ErrorActionSection
          message={`❓ The packet may have been unpublished ${ENDASH} Refresh to check its status.`}
        >
          {window.history.length && (
            <Button
              {...getEAProps(<IcoRefresh />)}
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
          )}
          {actions}
        </ErrorActionSection>
      }
      contactSectionMsg={`🐛 If you already refreshed and ended up here again, you probably found a bug!`}
      data-cy="error-page-print_recalled"
      defaultFriendly={PrintRecalledError.defaultFriendly}
      error={error}
      subject={subject}
    />
  )
}
