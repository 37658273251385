import { useDrag } from '@use-gesture/react'
import { useState } from 'react'

/**
 * Drag transform for ImageViewer
 */
export const useDragTransform = (disabled: boolean) => {
  type Transform = {
    center?: { x: number; y: number }
    isDragging: boolean
    isZoomed: boolean
    rect?: DOMRect
    scale?: number
  }

  const [transform, setTransform] = useState<Transform>({
    isDragging: false,
    isZoomed: false,
  })

  // console.log('<ImagePage />')

  const bind = useDrag((state) => {
    if (disabled) {
      return
    }
    // only drag when zoomed
    if (transform.isZoomed) {
      state.event.preventDefault()
      // todo: may need to do this imperatively if perf issues
      setTransform((old) => ({
        ...old,
        center: {
          x: old.center.x - state.delta[0] / transform.scale,
          y: old.center.y - state.delta[1] / transform.scale,
        },
        isDragging: !state.last,
      }))
    }
    // else handle click to zoom
    else if (state.type === 'pointerup') {
      // @ts-expect-error
      let rect = state.event.target.getBoundingClientRect()
      var x = state.xy[0] - rect.left //x position within the element.
      var y = state.xy[1] - rect.top //y position within the element.}
      setTransform({
        center: { x, y },
        isDragging: false,
        isZoomed: true,
        scale: 3,
        rect,
      })
    }
  }) as any // todo: types are broken?

  const transformCss = {
    transform: transform.isZoomed
      ? `translate(${transform.rect.width / 2 - transform.center.x}px, ${
          transform.rect.height / 2 - transform.center.y
        }px) scale(${transform.scale})`
      : null,
    transformOrigin: transform.center
      ? `${transform.center.x}px ${transform.center.y}px`
      : null,
  }

  return { bind, transform, transformCss, setTransform }
}
