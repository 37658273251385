import type { PacketPrintSnapshot, Page, PdfContentSet } from '@paper/schema'
import { range, times } from 'lodash'

/**
 * Initializes empty `Page` array of `count` length
 */
export function initPages(count: number): Page[] {
  return times(count, () => ({ items: [] }))
}

type WhichPagesProps = Pick<PdfContentSet, 'pages' | 'parts' | 'type'>
/**
 * @deprecated todo: probably move these so they are only accessible by testdata
 * Returns an array of page indexes to print
 */
export function whichPages(props: WhichPagesProps): number[] {
  return range(...whichPagesSlice(props))
}

/**
 * @deprecated todo: probably move these so they are only accessible by testdata
 */
export function whichPagesSlice(
  props: WhichPagesProps
): [start: number, end: number] {
  const { pages, parts, type } = props
  const startIndex = type === 'ticket' ? parts[0] : 0
  return [startIndex, startIndex + pages.length]
}

type Segments = PacketPrintSnapshot['segments']
/**
 * Convert `parts` (array of indexes) to `segments` (array of [startIndex, length])
 * @example
 * const packet = { pages: [{ items: []}], parts: [9], type: 'ticket' }
 * partsToSegments(packet, { postPare: true })
 * // [[0, 1]]
 * partsToSegments(packet, { postPare: false })
 * // [[9, 1]]
 */
export function partsToSegments(
  props: WhichPagesProps,
  options: { postPare: boolean }
): Segments {
  const { pages, parts, type } = props
  if (type === 'ticket') {
    // we pare the pdf for tickets
    // start from 0 if already pared, otherwise start from selected page
    let start = options.postPare ? 0 : parts[0]
    // one segment from first part, with length = pages.length
    return [[start, pages.length]]
  } else {
    let segments: Segments = []
    for (let i = 0; i < parts.length; i++) {
      // one segment per part
      // get length from next part or length of pdf
      let nextPartIndex = parts[i + 1] ?? pages.length
      let segmentLength = nextPartIndex - parts[i]
      segments.push([parts[i], segmentLength])
    }
    return segments
  }
}

/**
 * Returns an array of part lengths
 */
export function getPartLengths(props: WhichPagesProps) {
  const segments = partsToSegments(props, { postPare: true })
  return segments.map(([, segmentLength]) => segmentLength)
}

/**
 * Rounds `pageCount` up to the next even (if > 1) to account for trailing blanks
 * todo: i wonder if i should run this as part of partsToSegments
 * todo: ...the tricky thing is that in the CAT context, it would be confusing to see 4 pages if you know the quiz is 3 pages
 */
export const upToEven = (pageCount: number) => {
  if (pageCount === 1 || pageCount % 2 === 0) {
    return pageCount
  } else {
    return pageCount + 1
  }
}
