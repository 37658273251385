import { Box, ButtonProps, Portal } from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import { useCallback, useState } from 'react'
import { IcoCaretDown, IcoCaretUp, MenuAnimationLength } from '.'

type UseMenuProps = { caret?: boolean; defaultOpen?: boolean; shroud?: boolean }
export function useMenu(props: UseMenuProps = {}) {
  const [open, setOpen] = useState(props.defaultOpen)

  // todo: there's some issue with radix where not debouncing causes the menu to pop open again
  // todo: not sure what the issue is yet, so this delay may not work on every machine!
  const onOpenChange = useCallback(
    debounce(setOpen, 200, { leading: true, trailing: false }),
    []
  )

  const menuButtonProps: Partial<ButtonProps> = {
    // prettier-ignore
    rightIcon: props.caret ? (open ? <IcoCaretUp /> : <IcoCaretDown />) : undefined,
    variant: 'ghost',
  }
  const shroud = !props.shroud ? null : (
    // todo: radix menu doesn't support overlay
    // todo: brittle because radix and chakra don't know about each other's overlays...
    <Portal>
      <Box
        bg={open ? 'rgba(0,0,0,.25)' : 'transparent'}
        data-cy="menu-shroud"
        left="-100vw"
        height="201vh"
        onClick={() => setOpen(false)}
        pointerEvents={open ? null : 'none'}
        position="fixed"
        top="-100vh"
        transition={`background-color ${MenuAnimationLength} ease`}
        width="201vw"
      />
    </Portal>
  )

  return { menuButtonProps, onOpenChange, open, shroud }
}
