import { Curriculum, Packetmeta } from '@paper/schema'
import { orderBy } from 'lodash'
import { useState } from 'react'
import { VStack } from '~src/components'
import { UghTable } from '~src/components/table'
import { CrossPacketDialog } from './crossPacketDialog'
import { CurriculumLister } from './curriculumLister'
import { PublishDialog } from './publishDialog'
import { useCurriulumColumns } from './publishListCols'

type PublishListProps = {
  canEdit: boolean
  curriculum: Curriculum
  packets: Packetmeta[]
}
export function PublishList(props: PublishListProps) {
  let { canEdit, curriculum, packets } = props
  const [selectedPacket, setSelectedPacket] = useState<Packetmeta>()
  const [isLinkEditorOpen, setisLinkEditorOpen] = useState(false)

  const columns = useCurriulumColumns({
    canEdit,
    showCrossPacketEditor: () => setisLinkEditorOpen(true),
    showPublishDialog: (item: Packetmeta) => setSelectedPacket(item),
  })

  // Sort packets by curricula
  packets = orderBy(packets, (p) => p.curriculum.name)

  return (
    <VStack
      alignItems="stretch"
      flexGrow={1}
      fontSize="sm"
      gap={8}
      overflowY="auto"
      p={1}
    >
      <PublishDialog
        onClose={() => setSelectedPacket(undefined)}
        packet={selectedPacket}
      />
      <CrossPacketDialog
        isOpen={isLinkEditorOpen}
        onClose={() => setisLinkEditorOpen(false)}
        packets={packets}
      />
      <UghTable
        aria-label="Publish list"
        columns={columns}
        data={packets}
        flexShrink={0}
        spacingX="snug"
        spacingY="airy"
      />

      {canEdit && (
        <CurriculumLister
          curriculum={curriculum}
          onSelect={(curriculum) =>
            setSelectedPacket(clonePacket(packets[0], curriculum))
          }
          packets={packets}
        />
      )}
    </VStack>
  )
}

const clonePacket = (
  clonee: Packetmeta,
  curriculum: Curriculum
): Packetmeta => {
  const { contentId, content, src } = clonee
  return {
    _printed: { first: undefined },
    _pub: 'creating',
    _updates: {
      ...clonee._updates,
      crossPacket: undefined,
      publish: undefined,
    },
    _uploaded: true,
    content,
    contentId,
    curriculum,
    id: undefined,
    number: '',
    src,
  }
}
