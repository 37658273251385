import { Box, Button } from '@chakra-ui/react'
import { IcoPin } from '@paper/icons'
import { StrId } from '@paper/schema'
import {
  createContext,
  CSSProperties,
  memo,
  ReactNode,
  RefObject,
  SVGProps,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { KeyboardInstructions } from '~src/blocks/keyboardInstructions'
import { Txt, VStack } from '~src/components'
import { useKeyboard } from '~src/utils/useKeyboard'
import { useVirtualGrid } from '~src/utils/useVirtualGrid'
import { SVGGridUtils } from '../sw/timeGrid/svgUtils'
import { ColSortBase } from '../sw/timeGrid/useColSortAirlock'
import { QAxisLabel, TeaAxisLabel } from './axisLabels'
import {
  PinGridDigest,
  usePinGridContext,
  useXYZCursor,
} from './pinGridAirlock'
import { Cell } from './pinGridCell'
import { PinGridMenu } from './pinGridMenu'

type IGridContext = {
  cell: {
    height: number
    width: number
  }
  nonstick: {
    gridTemplateColumns: string
    gridTemplateRows: string
    left: number
    paddingBottom: number
    paddingLeft: number
    paddingRight: number
    paddingTop: number
    top: number
    height: number
    width: number
  }
  parentRef: RefObject<HTMLDivElement>
  stick: {
    height: number
    width: number
  }
  svgUtils: SVGGridUtils
}

const GridContext = createContext<IGridContext>(undefined)
export const useStickyGridContext = () => useContext(GridContext)

type StickyGridProps = { digest: PinGridDigest }
export function StickyGrid(props: StickyGridProps) {
  const { digest } = props
  const { xAxis, yAxis } = digest

  const parentRef = useRef()

  const ctx = useMemo<IGridContext>(() => {
    const cell = { height: 54, width: 108 }
    const stick = { height: 64, width: 180 }

    const paddingLeft = 12
    const paddingTop = 12
    const paddingBottom = 12
    const paddingRight = 12

    const colCount = xAxis.items.length
    const rowCount = yAxis.items.length

    return {
      cell,
      nonstick: {
        gridTemplateColumns: `repeat(${colCount}, ${cell.width}px)`,
        gridTemplateRows: `repeat(${rowCount}, ${cell.height}px)`,
        left: stick.width,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        top: stick.height,
        height: cell.height * rowCount + paddingTop + paddingBottom,
        width: cell.width * colCount + paddingLeft + paddingRight,
      },
      parentRef,
      stick,
      svgUtils: new SVGGridUtils({
        // todo: svg is currently on a different grid system...
        cellHeight: cell.height / 2,
        cellWidth: cell.width / 2,
        colCount,
        paddingBottom: paddingBottom / 2,
        paddingLeft: paddingLeft / 2,
        paddingRight: paddingRight / 2,
        paddingTop: paddingTop / 2,
        rowCount,
      }),
    }
  }, [xAxis.items.length, yAxis.items.length])

  const { move, x, y, z } = useXYZCursor(digest)

  useKeyboard('a', move.left)
  useKeyboard('d', move.right)

  useKeyboard('w', move.up)
  useKeyboard('s', move.down)

  useKeyboard('z', move.prevPin)
  useKeyboard('x', move.nextPin)

  return (
    <GridContext.Provider value={ctx}>
      <Box
        bg="#4c4c4c"
        className="grid-outer"
        height="100vh"
        overflow="auto"
        position="relative"
        ref={parentRef}
        style={{
          scrollPaddingLeft: ctx.stick.width,
          scrollPaddingTop: ctx.stick.height,
        }}
        width="100%"
      >
        <Origin />
        <Axis
          xOrY="x"
          Label={QAxisLabel}
          items={xAxis.items}
          selectedIdx={x}
          z={z}
        />
        <Axis
          xOrY="y"
          Label={TeaAxisLabel}
          items={yAxis.items}
          selectedIdx={y}
          z={z}
        />
        <AxesHighlight xIdx={x} yIdx={y} />
        <Body {...digest} moveCursor={move.to} x={x} y={y} z={z} />
        <Empty />
      </Box>
    </GridContext.Provider>
  )
}

type BodyProps = PinGridDigest & {
  moveCursor(x: number, y: number, z: string): void
  x: number
  y: number
  z: string
}
function Body(props: BodyProps) {
  const { moveCursor, xAxis, yAxis, items2d, x, y, z } = props
  const ctx = useStickyGridContext()

  // quick-and-dirty virtualization
  const indicies = useVirtualGrid({
    itemHeight: ctx.cell.height,
    itemWidth: ctx.cell.width,
    parentRef: ctx.parentRef,
  })
  let children: ReactNode[] = []
  if (indicies) {
    const yStop = Math.min(indicies.yIdx + indicies.yLength, yAxis.items.length)
    const xStop = Math.min(indicies.xIdx + indicies.xLength, xAxis.items.length)

    for (let yIdx = indicies.yIdx; yIdx < yStop; yIdx++) {
      for (let xIdx = indicies.xIdx; xIdx < xStop; xIdx++) {
        const isColSelected = xIdx === x
        const isRowSelected = yIdx === y
        const item = items2d[yIdx][xIdx]
        const isCellSelected = isColSelected && isRowSelected
        if (item?.count) {
          children.push(
            <Cell
              isCellSelected={isCellSelected}
              item={item}
              key={item.cellId}
              onSelect={moveCursor}
              x={xIdx}
              y={yIdx}
              z={isCellSelected && z} // z is only defined if cell is selected
            />
          )
        }
      }
    }
  }

  return (
    <Box
      alignItems="center"
      className="grid-inner"
      display="grid"
      justifyItems="center"
      padding="4px 0 0 4px"
      position="absolute"
      style={ctx.nonstick}
    >
      {children}
    </Box>
  )
}

type AxesHighlightProps = { xIdx: number; yIdx: number }
function AxesHighlight(props: AxesHighlightProps) {
  const { xIdx, yIdx } = props
  const { nonstick, svgUtils } = useStickyGridContext()

  const { height, width } = svgUtils
  const hasSelectedCell = xIdx >= 0 && yIdx >= 0

  const rects: SVGProps<SVGRectElement>[] = []

  if (xIdx >= 0 && yIdx >= 0) {
    const fill = '#333'
    // horizontal
    rects.push({ ...svgUtils.hStripe(yIdx), fill })
    // vertical
    rects.push({ ...svgUtils.vStripe(xIdx), fill })
  }

  // scroll selected into view
  const selectedCellRef = useRef<SVGRectElement>()
  useEffect(() => {
    selectedCellRef.current?.scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    })
  }, [xIdx, yIdx])

  return (
    <svg
      id="svgB"
      pointerEvents="none"
      style={{
        // todo: needs a lot of cleanup obviously!
        ...Object.fromEntries(
          Object.entries(nonstick).filter(([key]) => !key.startsWith('padding'))
        ),
        position: 'absolute',
      }}
      viewBox={`0 0 ${width} ${height}`}
    >
      {rects.map((props, idx) => (
        <rect key={idx} {...props} />
      ))}
      {hasSelectedCell && (
        <rect
          key="selected"
          {...svgUtils.cell(xIdx, yIdx, 1)}
          fill="#333"
          ref={selectedCellRef}
          rx={1}
          ry={1}
          stroke="white"
          strokeWidth={1} // skinnier for this one i thin
          strokeDasharray="5 2 5 2 4 2"
          strokeDashoffset="4"
          visibility="hidden" // todo: don't think i want to show this, but it's currently a load-bearing candy cane for scrollIntoView
        />
      )}
    </svg>
  )
}

function Origin() {
  const ctx = useStickyGridContext()
  return (
    <Box
      alignItems="center"
      bg="#404040"
      boxShadow="rgb(0 0 0 / 8%) 3px 3px 7px 0px"
      display="grid"
      fontSize="sm"
      justifyItems="center"
      left={0}
      position="fixed"
      p={1}
      style={ctx.stick}
      top={0}
      zIndex={3}
    >
      <PinGridMenu
        icon={IcoPin}
        keyboard={
          <KeyboardInstructions
            adName="questions and answers"
            wsName="teachers"
            more={[{ keys: 'zx', icon: 'leftright', name: 'pins' }]}
          />
        }
      />
      <Button
        onClick={() => window.history.back()}
        p={0}
        variant="ghost"
      ></Button>
    </Box>
  )
}

type AxisProps<T extends StrId> = {
  /** todo: goofy for memoization...need to figure out this api... */
  filterMap?: Map<string, unknown>
  items: T[]
  Label: any
  selectedIdx: number
  /** todo: goofy for memoization...need to figure out this api... */
  sortMap?: Map<string, ColSortBase>
  xOrY: 'x' | 'y'
  z?: string
}

const Axis = memo(function Axis<T extends StrId>(props: AxisProps<T>) {
  const { filterMap, items, Label, selectedIdx, sortMap, xOrY, z } = props
  const ctx = useStickyGridContext()
  const style: CSSProperties =
    xOrY === 'x'
      ? {
          boxShadow: 'rgb(0 0 0/ 8%) 0px 3px 7px 0px',
          gridTemplateColumns: ctx.nonstick.gridTemplateColumns,
          height: ctx.stick.height,
          paddingLeft: ctx.nonstick.paddingLeft + ctx.stick.width,
          paddingRight: ctx.nonstick.paddingRight,
          top: 0,
          minWidth: ctx.nonstick.width + ctx.stick.width,
        }
      : {
          boxShadow: 'rgb(0 0 0/ 8%) 3px 0px 7px 0px',
          gridTemplateRows: ctx.nonstick.gridTemplateRows,
          left: 0,
          paddingBottom: ctx.nonstick.paddingBottom,
          paddingTop: ctx.nonstick.paddingTop,
          top: ctx.stick.height,
          width: ctx.stick.width,
        }

  return (
    <Box
      alignItems="stretch"
      bg="#4c4c4c"
      color="rgba(255,255,255,.9)"
      data-cy={`${xOrY}-axis`}
      display="grid"
      overflow="hidden"
      justifyItems="stretch"
      position="sticky"
      style={style}
      zIndex={2}
    >
      {items.map((item, idx) => {
        const isSelected = selectedIdx === idx
        return (
          <Label
            key={item.id}
            isFiltering={filterMap?.has(item.id)}
            isSelected={isSelected}
            item={item}
            sortOrder={sortMap?.get(item.id)?.order}
            z={z}
          />
        )
      })}
    </Box>
  )
})

function Empty() {
  const { digest } = usePinGridContext()
  const ctx = useStickyGridContext()

  const emptyFiltered = digest.xAxis.empty || digest.yAxis.empty
  if (!digest.empty && !emptyFiltered) {
    return null
  }

  const { left, top, width } = ctx.nonstick

  // todo: need to hoist actions as i will forget to update them here...
  return (
    <VStack
      color="rgba(255,255,255,.9)"
      gap={2}
      position="absolute"
      p={6}
      style={{ left, top, minWidth: width }}
    >
      <Txt fontSize="sm" textAlign="center">
        {digest.empty
          ? `It looks like there aren't scans or score data yet!`
          : 'No matching packets'}
      </Txt>
      {/* {!digest.empty && (
        <ButtonGroup display="grid" gridTemplateColumns="1fr 1fr" size="xs">
          <Button disabled={!tagFilter} onClick={() => setTagFilter(null)}>
            Clear column filters
          </Button>
          <Button
            onClick={() => {
              setTagFilter(null)
              dispatchStay({ tgf_col: null, f_packet: null, std: null })
            }}
          >
            Clear all filters
          </Button>
        </ButtonGroup>
      )} */}
    </VStack>
  )
}
