import { ButtonProps } from '@chakra-ui/react'
import type { HTTPError } from '@paper/utils'
import { useEffect, useState } from 'react'
import { getSupportMailTo, SupportLinkProps } from '~src/utils/contactSupport'
import rollbar from '~src/utils/rollbar'

/** Returns standardized error action props */
export const getEAProps = (icon: ButtonProps['leftIcon']) => {
  return { leftIcon: icon, size: 'sm' }
}

export type ErrorResponse = {
  body: string | object
  status: number
}
/**
 * Amends response text if http error #65
 * @deprecated (?) This should probably be part of react-query error handling?
 */
export function useHttpErrorResponse(error: Error) {
  const [errorResponse, setErrorResponse] = useState<ErrorResponse>()

  // Extract response text if http error #65
  let httpError = error as HTTPError
  useEffect(() => {
    let isUnmounted = false
    if (httpError?.response) {
      const { status } = httpError.response
      httpError.response
        .text()
        .then((respTxt) => {
          try {
            const asJson = JSON.parse(respTxt)
            !isUnmounted && setErrorResponse({ body: asJson, status })
          } catch (err) {
            !isUnmounted && setErrorResponse({ body: respTxt, status })
          }
        })
        .catch((err) => console.error(err))
      return () => (isUnmounted = true)
    }
    return () => (isUnmounted = true)
  }, [httpError])

  return errorResponse
}

/////////////////////////////////////////////////////////////

type MailToProps = Omit<SupportLinkProps, 'uuid'>

/**
 * Returns link props for contacting support
 */
export function useContactSupportLink(props: MailToProps) {
  const { error, response, subject } = props
  const email = 'support@ponder.co'

  return {
    href: `mailto:${email}`,
    onClick: (event) => {
      event.preventDefault()
      // send to rollbar
      const { uuid } = rollbar.error('ContactSupport', error, {
        response,
        subject,
      })

      const mailto = getSupportMailTo({ error, response, subject, uuid })
      // open mailto link
      window.location.href = mailto
    },
  }
}
