import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import type { ApiMovePages } from '@paper/api'
import { IcoUnnamed } from '@paper/icons'
import { useRouter } from '@paper/route'
import { DirPacket, IAP, Xpacket } from '@paper/schema'
import {
  doesXpageHavePreMoveImages,
  ENDASH,
  getFullName,
  partsToSegments,
  resolveXpageImage,
} from '@paper/utils'
import { range } from 'lodash'
import { ReactNode, useLayoutEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { TabImageViewer } from '~src/blocks/imageViewer'
import { SWMenu } from '~src/blocks/swMenu'
import { useUser } from '~src/blocks/userProvider'
import {
  AppTitle,
  BaseHeader,
  Column,
  ToggleGroup,
  Txt,
  VStack,
} from '~src/components'
import { usePacketListData } from '~src/data/data-packets'
import { formatUnits } from '~src/utils/messages'
import { RD_SW } from '../routes'
import { QK_IOU } from '../scanlog/data-scanlog'
import {
  useMoveDestDigest,
  useScanXpacketDigest,
} from '../scanlog/data-scanXpackets'
import { ExclaimRed } from '../scanlog/scanlogFixDialog'
import { ScanXpacketColumn } from '../scanlog/scanXpackets'

type PartDetail = {
  hasScans: boolean
  number: number
}

// splitting this out for maybe slightly better readability
const useSetStudentCrunched = (packet: DirPacket, teacherId: string) => {
  const [studentFilter, setStudentFilter] = useState('')
  const [selectedPartIndexes, setSelectedPartIndexes] = useState<number[]>([])

  const srcDigest = useScanXpacketDigest(packet, teacherId, {
    process: (items) => {
      // only allow assigning from unnamed (for now)
      items = items.filter((p) => !p.student)
      return { empty: items.length === 0, items }
    },
    selectSrc: 'url',
  })

  const destDigest = useMoveDestDigest(
    srcDigest.success?.selectedId,
    studentFilter
  )

  const srcXpacket = srcDigest.success?.selectedItem
  const destXpacket = destDigest.success?.selectedItem

  const reset = () => {
    setStudentFilter('')
    if (selectedPartIndexes.length > 0) {
      setSelectedPartIndexes([])
    }
  }

  // reset selected part indexes on source change
  useLayoutEffect(() => {
    reset()
  }, [srcXpacket])

  const { allParts, isMultipart, selectedPageIndexes } = useMemo(() => {
    const allParts: PartDetail[] = []
    let isMultipart: boolean
    const selectedPageIndexes: number[] = []

    // shortcircuit if no sources
    if (!packet || !srcXpacket) {
      return { allParts, isMultipart, selectedPageIndexes }
    }

    // Get "segments", which are `parts` as `[start, length]`
    let segments = partsToSegments(packet, { postPare: true })
    isMultipart = segments.length > 1

    // For non-multipart select all/only
    let selectedPartIndexSet = new Set(isMultipart ? selectedPartIndexes : [0])

    // Go through each part
    // (1) Add to `allParts` calculating `hasScans` value
    // (2) Add pages with scans from selected parts to `selectedPageIndexes`
    segments.forEach(([start, length], partIdx) => {
      let pageIndexes = range(start, start + length)
      let partHasScans = false
      for (let pageIdx of pageIndexes) {
        const srcPage = srcXpacket.pages[pageIdx]
        console.log({ pageIndexes, srcXpacket })
        // page has scans if overriden with key, or not overriden with key
        const pageHasScans = doesXpageHavePreMoveImages(srcPage)

        // a page is "selected" if it's in a selected part and the page has scans
        if (selectedPartIndexSet.has(partIdx) && pageHasScans) {
          selectedPageIndexes.push(pageIdx)
        }
        // update whether the part has scans
        partHasScans ||= pageHasScans
      }
      allParts.push({ hasScans: partHasScans, number: partIdx + 1 })
    })

    return { allParts, isMultipart, selectedPageIndexes }
  }, [packet, selectedPartIndexes, srcXpacket])

  const srcTargets = usePageTargets(srcXpacket, selectedPageIndexes)
  const destTargets = usePageTargets(destXpacket, selectedPageIndexes)

  let wouldOverwrite = selectedPageIndexes.some(
    // has imgp and has src
    (idx) => destXpacket?.pages[idx].imgp?.key
  )
  let isValid =
    destXpacket && // destination selected
    selectedPageIndexes.length > 0 && // at least one page selected to move
    !wouldOverwrite // won't overwrite any officially matched images

  return {
    allParts,
    destDigest,
    destTargets,
    destXpacket,
    isMultipart,
    isValid,
    reset,
    selectedPageIndexes,
    selectedPartIndexes,
    setSelectedPartIndexes,
    setStudentFilter,
    srcDigest,
    srcTargets,
    srcXpacket,
    studentFilter,
    wouldOverwrite,
  }
}

function usePageMoveMutation(reset: () => void) {
  const { fetchAs } = useUser()
  const queryClient = useQueryClient()

  return useMutation(
    async (args: ApiMovePages['body']) => {
      await fetchAs.post(IAP.unstable.movePages.move, { json: args })
      // todo: handle errors
    },
    {
      async onSuccess() {
        // clear inputs
        reset()
        // invalidate all the dependent queries
        await queryClient.invalidateQueries(QK_IOU)
      },
    }
  )
}

export function SetStudentView() {
  const { routeData } = useRouter<RD_SW>()
  const pld = usePacketListData()

  const ctx = useSetStudentCrunched(pld.selectedItem, routeData.teacherId)

  const submitter = usePageMoveMutation(ctx.reset)

  const slots = ctx.srcXpacket?.pages
  const xpageSWs = slots?.map((page) => resolveXpageImage(page))

  let ready = !!(pld.selectedItem && xpageSWs)

  let submitMessage: ReactNode
  let submitMessageType: 'error' | 'warning' = 'error'

  if (submitter.isError) {
    submitMessage = `Something went wrong :(`
  } else if (
    ctx.selectedPartIndexes.length > 0 &&
    ctx.selectedPageIndexes.length === 0
  ) {
    submitMessage = `This selection doesn't have any scans`
  } else if (ctx.wouldOverwrite) {
    submitMessage = `This selection would overwrite successfully scanned images`
  } else if (ctx.isValid) {
    submitMessage = `This cannot be undone`
    submitMessageType = 'warning'
  }

  return (
    <BaseHeader.Container>
      <AppTitle title="Fix unnamed packets" />
      <BaseHeader hideLogo={true} minWidth="unset" stackGap="1rem">
        <SWMenu icon={IcoUnnamed} />
        <Txt fontSize="lg">Fix unnamed packets</Txt>
      </BaseHeader>
      <Column.Container
        colProps={{ flexGrow: 1, flexShrink: 0, px: 4 }}
        flexGrow={1}
      >
        <ScanXpacketColumn
          digest={ctx.srcDigest}
          header={(status) => (
            <ColumnHeader>
              {status === 'empty'
                ? `No unnamed packets for ${pld.selectedItem.name}`
                : `Select a source`}
            </ColumnHeader>
          )}
          packet={pld.selectedItem}
          targetXpageId={ctx.srcTargets}
        />
        {ready && (
          <Column alignItems="stretch" flexGrow={0} width="600px">
            <ColumnHeader alignSelf="center">
              Unnamed {ENDASH} {pld.selectedItem.name}
            </ColumnHeader>
            <TabImageViewer
              hideNumbers={true}
              imageType="sw"
              pages={xpageSWs}
              pkt={pld.selectedItem}
            />
          </Column>
        )}
        {ready && (
          <ScanXpacketColumn
            digest={ctx.destDigest}
            empty={() => (
              <Txt fontSize="sm" opacity={0.8}>
                {ctx.studentFilter
                  ? 'No matching students'
                  : `Enter the student's name above to find their packet`}
              </Txt>
            )}
            header={() => (
              <ColumnHeader alignSelf="stretch">
                <FormControl size="sm">
                  <FormLabel
                    fontWeight={300}
                    htmlFor="student-filter"
                    textAlign="center"
                  >
                    Identify the student
                  </FormLabel>
                  <Input
                    autoFocus={true}
                    name="student-filter"
                    onChange={(event) =>
                      ctx.setStudentFilter(event.target.value)
                    }
                    size="sm"
                    value={ctx.studentFilter}
                    width="100%"
                  />
                </FormControl>
              </ColumnHeader>
            )}
            packet={pld.selectedItem}
            targetXpageId={ctx.destTargets}
          />
        )}
        {ready && ctx.destXpacket && (
          <Column width="300px">
            <ColumnHeader mb={6}>Review and submit</ColumnHeader>
            <VStack fontSize="sm" gap={6}>
              {ctx.isMultipart && (
                <VStack gap={1}>
                  Select parts
                  {
                    <ToggleGroup.Root
                      colorScheme="blue"
                      onChange={(values) =>
                        ctx.setSelectedPartIndexes(
                          values.map((v) => parseInt(v))
                        )
                      }
                      preventNone={true}
                      size="sm"
                      type="multiple"
                      value={ctx.selectedPartIndexes.map((p) => p.toString())}
                    >
                      <ButtonGroup>
                        {ctx.allParts.map((p, idx) => (
                          <ToggleGroup.Button
                            key={idx}
                            disabled={!p.hasScans}
                            value={idx.toString()}
                          >
                            {p.number}
                          </ToggleGroup.Button>
                        ))}
                      </ButtonGroup>
                    </ToggleGroup.Root>
                  }
                </VStack>
              )}
              <VStack gap={1}>
                <Txt>
                  Assign{' '}
                  {formatUnits(ctx.selectedPageIndexes.length || NaN, 'page')}{' '}
                  to:
                </Txt>
                <Txt>{getFullName(ctx.destXpacket.student)}</Txt>
              </VStack>
              <Button
                colorScheme="red"
                isDisabled={!ctx.isValid || submitter.isLoading} // todo: not quite right!
                isLoading={submitter.isLoading}
                onClick={() =>
                  submitter.mutate({
                    destXpacket: ctx.destXpacket,
                    pageIndexes: ctx.selectedPageIndexes,
                    srcXpacketId: ctx.srcXpacket.id,
                  })
                }
              >
                Submit
              </Button>
              {submitMessage && (
                <ExclaimRed textAlign="center" type={submitMessageType}>
                  {submitMessage}
                </ExclaimRed>
              )}
            </VStack>
          </Column>
        )}
      </Column.Container>
    </BaseHeader.Container>
  )
}

const ColumnHeader = (props) => {
  return <Box mb={2} p="1px" {...props} />
}

const usePageTargets = (xpacket: Xpacket, selectedPageIndexes: number[]) => {
  return useMemo(() => {
    return !xpacket
      ? null
      : new Set(selectedPageIndexes.map((idx) => xpacket.pages[idx].id))
  }, [xpacket, selectedPageIndexes])
}
