/** Helper components for showing the wizard */
import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel as BaseLabel,
  FormLabelProps,
  Input,
  InputProps,
} from '@chakra-ui/react'
import {
  Field as FormikField,
  FieldInputProps,
  FieldMetaProps,
  FieldProps,
} from 'formik'
import type { ReactElement, ReactNode } from 'react'
import { HStack, StackProps } from '~src/components'

type WithDataDash<T> = T & { [key: `data-${string}`]: string }

///////////////////////////////////
// Field+label+helptext+error
///////////////////////////////////
type FieldUIProps<T> = {
  helperText?: ReactNode
  input: ReactElement
  label: string | ReactNode
  meta?: FieldMetaProps<T>
} & Omit<FormControlProps, 'children' | 'label'>

export function FieldUI<T>(props: FieldUIProps<T>) {
  let { helperText, input, label, meta, ...formControlProps } = props

  return (
    <FormControl
      isInvalid={meta?.error && meta?.touched}
      position={null} // note: chakra v1 changed defaults which broke things upstream
      width={null} // note: chakra v1 changed defaults which broke things upstream
      {...formControlProps}
    >
      {label && typeof label === 'string' ? fieldUILabeler(label) : label}
      {input}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {meta && (
        <Box minH={8} mt={2}>
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </Box>
      )}
    </FormControl>
  )
}

const fieldUILabeler = (label: string) => <FormLabel mr={0}>{label}</FormLabel>
FieldUI.labeler = fieldUILabeler

const capitalizeFirst = (str: string) =>
  !str ? str : str[0].toUpperCase() + str.slice(1)

export const FormLabel = (props: FormLabelProps) => {
  let { children, ...rest } = props
  // maybe use textTransform instead?
  if (typeof children === 'string') {
    children = capitalizeFirst(children)
  }
  return (
    <BaseLabel fontSize="md" fontWeight={200} opacity={0.8} {...rest}>
      {children}
    </BaseLabel>
  )
}

type SimpleTextFieldProps = {
  field: FieldInputProps<string>
  helperText?: ReactNode
  inputProps?: WithDataDash<InputProps>
  label: string
  meta: FieldMetaProps<string>
}
export function SimpleTextField(props: SimpleTextFieldProps) {
  const { field, helperText, inputProps = {}, label, meta } = props

  return (
    <FieldUI
      helperText={helperText}
      // Avoid uncontrolled React error when value is undefined
      input={
        <Input
          type="text"
          {...field}
          {...inputProps}
          value={field.value ?? ''}
        />
      }
      label={label}
      meta={meta}
      w="100%"
    />
  )
}

type SimpleTextField2Props = {
  helperText?: ReactNode
  inputProps?: WithDataDash<InputProps>
  label: string
  name: string
}
// todo: because the subforms DO use <Formik />
export function SimpleTextField2(props: SimpleTextField2Props) {
  const { helperText, inputProps = {}, label, name } = props

  return (
    <FormikField name={name}>
      {({ field, meta }: FieldProps) => (
        <FieldUI
          helperText={helperText}
          // Avoid uncontrolled React error when value is undefined
          input={
            <Input
              type="text"
              {...field}
              {...inputProps}
              value={field.value ?? ''}
            />
          }
          label={capitalizeFirst(label)}
          meta={meta}
          w="100%"
        />
      )}
    </FormikField>
  )
}

export const ButtonStack = (props: StackProps) => {
  return <HStack gap={1} {...props} />
}
