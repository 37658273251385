import * as Yup from 'yup'
import { ObjectSchemaDefinition } from 'yup'
import { Api } from '..'

export { Yup }

// todo: (?) Does this need to be a function
export const yupMin0Int = Yup.number().integer().min(0)

export const YupApi: {
  /** Type shortcut */
  object<T extends Api.ApiBase>(
    fields?: ObjectSchemaDefinition<Api.ReqData<T>>
  ): Yup.ObjectSchema<Api.ReqData<T> | undefined>
} = {
  object: Yup.object,
}
