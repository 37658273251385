import {
  BoxProps,
  Button,
  ButtonGroup,
  FormLabel,
  Icon,
  Link,
  PinInput,
  PinInputField,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react'
import type { ApiScanFixLookup, ApiScanFixSet } from '@paper/api'
import { IcoExclamationTriangle } from '@paper/icons'
import { ReactNode } from 'react'
import { LastUpdateDisplay } from '~src/blocks/lastUpdate'
import { HStack, Txt, VStack } from '~src/components'
import { LoadingDots } from '~src/components/status'
import config from '~src/utils/config'
import { defaultHelpLink } from '~src/utils/useHelpLink'
import { useScanFixMutation } from './data-scanlog'
import { scanfixSlice, useScanlogContext } from './scanlogAirlock'

export function ScanlogFixDialog() {
  const {
    candidateResult,
    isEditing,
    reset,
    dispatchScanfix,
    selectedPacket,
    selectedScanImage,
  } = useScanlogContext()

  // calculate payload and whether we can submit, in context probably...
  const fixPayload: ApiScanFixSet['body'] = candidateResult.data &&
    selectedScanImage && {
      action: 'fix',
      ...candidateResult.data,
      scanImageId: selectedScanImage.id,
    }

  const fixMutation = useScanFixMutation()

  const dirty =
    fixPayload?.xpageId !== selectedScanImage?.data?.xpageId ||
    fixPayload?.scanImageId !== selectedScanImage.id

  // Interstitial if already manually changed
  if (selectedScanImage?.status === 'manual' && !isEditing) {
    const lastUpdate = selectedScanImage._updates.manual
    return (
      <ScanlogFixArea
        actions={
          <Button
            onClick={() => dispatchScanfix(scanfixSlice.actions.editAgain())}
            variant="outline"
          >
            Edit again
          </Button>
        }
        title="This scan has been assigned to a packet manually"
      >
        <LastUpdateDisplay fontSize="sm" lastUpdate={lastUpdate} />
      </ScanlogFixArea>
    )
  }
  // Waiting for candidate lookup
  else if (candidateResult.isLoading) {
    return <LoadingDots />
  }
  // Have candidate
  else if (candidateResult.isSuccess && candidateResult.data) {
    return (
      <ScanlogFixArea
        title="Review and submit"
        actions={
          <>
            <Button
              colorScheme="blue"
              isDisabled={!fixPayload || !dirty}
              isLoading={fixMutation.isLoading}
              onClick={() => fixMutation.mutate(fixPayload)}
              variant="outline"
            >
              Submit
            </Button>
            <Button onClick={reset} variant="outline">
              Cancel
            </Button>
            {fixMutation.isError && <ExclaimRed>Submit failed</ExclaimRed>}
          </>
        }
      >
        {dirty ? (
          <CandidateTable data={candidateResult.data} />
        ) : (
          <Txt>This image is already assigned to this packet.</Txt>
        )}
      </ScanlogFixArea>
    )
  } else if (candidateResult.isError) {
    // todo:
    return <>Error</>
  }

  // Select a candidate
  return (
    <ScanlogFixArea title="Identify the image" actions={null}>
      <FormLabel mb="none" fontSize="unset" fontWeight="unset">
        Enter the code printed next to the QR code (underneath{' '}
        <Txt as="span" fontFamily="mono" fontSize="sm">
          {config.meta.dbId}
        </Txt>
        )
        <Pinput />
        <Link
          color="teal.500"
          href={defaultHelpLink}
          fontSize="xs"
          isExternal={true}
        >
          What if I don't see{' '}
          <Txt as="span" fontFamily="mono">
            {config.meta.dbId}
          </Txt>
          ?
        </Link>
        {candidateResult.isSuccess && !candidateResult.data && (
          <Txt as="span" color="red.600" ml={2}>
            No pages found with this code
          </Txt>
        )}
      </FormLabel>
      <Txt height="18px">
        {selectedPacket && (
          <>Or, select a missing page from the packets in the left column.</>
        )}
      </Txt>
    </ScanlogFixArea>
  )
}

type ScanlogFixAreaProps = {
  actions: ReactNode
  children: ReactNode
  title: ReactNode
}

function ScanlogFixArea(props: ScanlogFixAreaProps) {
  return (
    <VStack alignItems="stretch" fontSize="xs" gap={6}>
      <HStack fontSize="md">{props.title}</HStack>
      {props.children}
      <ButtonGroup alignItems="center" spacing={2} size="xs">
        {props.actions}
      </ButtonGroup>
    </VStack>
  )
}

function Pinput() {
  const chunkCount = 3
  const chunkLength = 3
  const { selectPage } = scanfixSlice.actions
  const { candidateInputs, dispatchScanfix } = useScanlogContext()

  const children: ReactNode[] = []
  for (let i = 0; i < chunkCount; i++) {
    if (i !== 0) {
      children.push(
        <Txt as="span" fontSize="2xl" key={`${i}.-`}>
          -
        </Txt>
      )
    }
    for (let j = 0; j < chunkLength; j++) {
      children.push(<PinInputField key={`${i}.${j}`} />)
    }
  }

  return (
    <HStack fontFamily="mono" gap={1}>
      <PinInput
        autoFocus={true}
        onChange={(value) =>
          dispatchScanfix(selectPage({ qrb: value.toUpperCase() }))
        }
        onComplete={(value) =>
          dispatchScanfix(
            selectPage({ qrb: value.toUpperCase(), complete: true })
          )
        }
        size="sm"
        type="alphanumeric"
        value={candidateInputs.qrbValue ?? ''}
      >
        {children}
      </PinInput>
    </HStack>
  )
}

function CandidateTable(props: { data: ApiScanFixLookup['result'][0] }) {
  const { packetIndex, packetName, packetNumber, qrb, status, student } =
    props.data
  return (
    <TableContainer>
      <Txt pb={1} pl={1}>
        Assign this image to:
      </Txt>
      <Table size="sm" sx={{ td: { fontSize: 'xs' } }}>
        <Tbody>
          <Tr>
            <Td>Student</Td>
            <Td>{student?.lastfirst ?? '<unnamed>'}</Td>
          </Tr>
          <Tr>
            <Td>Packet</Td>
            <Td>
              <Txt as="span" fontFamily="mono" mr={2}>
                {packetNumber}
              </Txt>
              {packetName}
            </Td>
          </Tr>
          <Tr>
            <Td>Page number</Td>
            <Td>{packetIndex + 1}</Td>
          </Tr>
          <Tr>
            <Td>Previous Status</Td>
            <Td>{status}</Td>
          </Tr>
          <Tr>
            <Td>Printed code</Td>
            <Td fontFamily="mono">
              {config.meta.dbId}: {qrb}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export function ExclaimRed(props: BoxProps & { type?: 'error' | 'warning' }) {
  const { children, type = 'error', ...boxProps } = props
  const color = type === 'error' ? 'red.500' : null
  const iconColor = type === 'error' ? 'red.500' : 'yellow.500'
  return (
    <Txt color={color} {...boxProps}>
      <Icon as={IcoExclamationTriangle} color={iconColor} mr={1} mb="-2px" />
      {children}
    </Txt>
  )
}
