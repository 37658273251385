import { ReactNode } from 'react'
import { UseQueryResult } from 'react-query'
import { StackProps, VStackStretch } from '~src/components'
import { LoadingDots } from '~src/components/status'
import { ListDigest } from '.'
import { ListError } from './listError'

type ListShellProps = {
  children: ListRenderer
  digest: ListDigest
} & StackProps

type ListStatus = UseQueryResult['status'] | 'empty'
export type ListRenderer = (status: ListStatus) => ReactNode

export function ListLoadShell(props: ListShellProps) {
  const { children, digest, ...stackProps } = props
  const { qResult, success } = digest

  let status: ListStatus =
    qResult.isSuccess && success.empty ? 'empty' : qResult.status
  let body = children(status)

  // default fallbacks
  if (!body) {
    if (qResult.isLoading) {
      body = (
        <LoadingDots
          alignSelf="center"
          data-cy="list-loading-dots"
          flexGrow={1}
        />
      )
    } else if (qResult.isError) {
      body = <ListError />
    }
  }

  return (
    <VStackStretch
      as="section"
      flexShrink={0}
      overflow="hidden"
      pt="1px" // for outline
      {...stackProps}
    >
      {body}
    </VStackStretch>
  )
}
