import * as Yup from 'yup'
import { Sect, Section, Student, Teacher, Term } from '../types/school'
import { yupCurriculum } from './v-pe_publish'

export const yupTerm = Yup.object<Term>({
  code: Yup.string().required(),
  firstDay: Yup.date().required(),
  lastDay: Yup.date().required(),
})

export const yupSect = Yup.object<Sect>({
  name: Yup.string().required(),
  sectionId: Yup.string().required(),
})

export const yupSection = Yup.object<Section>({
  course: Yup.string().required(),
  curriculum: yupCurriculum.required(),
  name: Yup.string().required(),
  schoolId: Yup.string().required(),
  schoolName: Yup.string().required(),
  sectionId: Yup.string().required(),
  teacherId: Yup.string().required(),
  term: yupTerm,
})

export const yupStudent = Yup.object<Student>({
  firstName: Yup.string().required(),
  grade: Yup.string().required(),
  homeRoom: Yup.string().required(),
  id: Yup.string().required(),
  lastfirst: Yup.string().required(),
  lastName: Yup.string().required(),
  number: Yup.string().required(),
})

export const yupTeacherSection = yupSection.shape({
  students: Yup.array(yupStudent),
})

export const yupTeacher = Yup.object<Teacher>({
  email: Yup.string().trim().required(),
  id: Yup.string().required(),
  firstName: Yup.string().trim().required(),
  lastName: Yup.string().trim().required(),
})
