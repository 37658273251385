export const ENDASH = '–'

export const elipsize = (s: string, maxLength?: number) => {
  if (!maxLength || !s || s.length <= maxLength) {
    return s
  } else {
    return `${s.slice(0, maxLength - 1)}…`
  }
}

export const formatSchoolYear = (yyyy: number) => {
  const yy = yyyy % 100
  return `${yy - 1}-${yy}`
}

type Named = { firstName: string; lastName: string }

/** Returns `First Last` */
export function getFullName(named: Named) {
  return !named ? null : `${named.firstName} ${named.lastName}`
}

/** Return `Last, First` */
export function getLastFirst(named: Named) {
  return !named ? null : `${named.lastName}, ${named.firstName}`
}

/**
 * @example
 * padTo00(1)
 * // "01"
 */
export const padTo00 = (index: number) => {
  return index?.toString().padStart(2, '0')
}

/**
 * @example
 * padToN0(1, 3)
 * // "001"
 */
export const padToN0 = (index: number, n: number) => {
  return index?.toString().padStart(n, '0')
}
