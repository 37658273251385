export type IllQ = {
  correctStr: string
  label: string
  outOf: number
  stds: string[]
}

export type IllPacket = {
  _id: string
  _asOf: number
  qs: IllQ[]
}

export type IllXQ = {
  filled: number[]
  filledStr: string
  outOf: number
  pts: number
}

export type IllXpacket = {
  _illuminate: string
  packetId: string
  qs: IllXQ[]
  studentId: string
  xpacketId: string
}
