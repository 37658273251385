import { Curriculum, FileLocation, PacketType, PdfStyle } from '..'

export type PorQ = Passage | Question
export type PageItemType = 'passage' | 'question'
export type QType = 'MC' | 'OER' | 'GRID-IN'
export type QCorrect = number[]
export type QOption = { label: string }

export type Page = {
  items: PageItem[]
}

export type PageItem = {
  type: PageItemType
  id: string
}

export type Passage = {
  author?: string
  genre?: string
  id: string
  lexile?: string
  location?: string
  name: string
  source?: string
}

export type Question = {
  correct: QCorrect
  id: string
  label: string
  labelMaj: string
  labelMin?: string
  options: QOption[]
  outOf?: number
  passageIds: string[]
  stds?: string[]
  type: QType
}

export type LastUpdate = {
  time: number
  user: string
}

export type PublishStage = 'creating' | 'uploaded' | 'published' | 'recalled'

export type PdfKeySet = 'name' | 'pages' | 'parts' | 'style' | 'type'
export type AnswerKeySet = 'pages' | 'passages' | 'questions'

export type PdfContentSet = Pick<Packetmeta['content'], PdfKeySet>
export type AnswerKeyContentSet = Pick<Packetmeta['content'], AnswerKeySet>

export type Packetmeta = {
  _linkExternal?: string
  _illuminate?: string
  _printed: { first: number }
  _pub: PublishStage
  _updates: {
    answerKey: LastUpdate
    crossPacket: LastUpdate
    last: LastUpdate
    pdf: LastUpdate
    publish: LastUpdate
    stds: LastUpdate
  }
  _uploaded: boolean
  contentId: string
  content: PacketContent
  curriculum: Curriculum
  id: string
  number: string
  // sched: 'num' | 'week' | 'month' | 'unit' todo: punt!
  src: FileLocation
}

export type PacketContent = {
  images: FileLocation[]
  name: string
  pages: Page[]
  parts: number[]
  passages: Passage[]
  questions: Question[]
  stds?: string[]
  style: PdfStyle
  type: PacketType
}
