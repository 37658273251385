import { useRouter } from '@paper/route'
import { capitalize } from 'lodash'
import { useMemo } from 'react'
import { usePickerBase, useTeacherPicker } from '~src/blocks/pickers'
import { FilterPicker } from '~src/blocks/pickers/filterPicker'
import { ComboBox, HStack, TextStack, Txt, VStack } from '~src/components'
import { usePacketListData } from '~src/data/data-packets'
import { isRQReady } from '~src/utils/airlock'
import { RD_Scanlog } from '../routes'

const sharedPickerProps = {
  caret: true,
  clearable: true,
  size: 'sm',
  width: '200px',
  variant: 'filled',
} as const

type ScanUserPickerProps = { data: string[] }

export function ScanUserPicker(props: ScanUserPickerProps) {
  const { data } = props

  const { dispatchStay, routeData, useAirlock } = useRouter<RD_Scanlog>()

  // reset query param if packet is not in the list
  useAirlock(
    { scanUser: null },
    routeData.scanUser && data != null && !data.includes(routeData.scanUser)
  )

  return (
    <ScanPickerShell>
      <FilterPicker
        {...sharedPickerProps}
        allText="Anyone"
        filterType="contains"
        getId={(email) => email}
        getLabel={(email) => email}
        itemPlural="scanners"
        items={data ?? []}
        label={pickerLabel('Scanned by')}
        onChange={(scanUser) => dispatchStay({ scanUser })}
        renderItem={(item) => {
          const [top, bottom] = item.label.split('@')
          return (
            <TextStack>
              <TextStack.Top fontSize="sm">{top}</TextStack.Top>
              {
                <TextStack.Bottom fontSize="xs">
                  @{bottom ?? 'invalid'}
                </TextStack.Bottom>
              }
            </TextStack>
          )
        }}
        selectedId={routeData.scanUser}
      />
    </ScanPickerShell>
  )
}

/**
 * Picker to filter scan batches by teacher
 */
export function ScanTeacherPicker() {
  const { dispatchStay, routeData } = useRouter<RD_Scanlog>()

  // todo: copy/paste from teacherPanel
  const teaComboProps = useTeacherPicker({ value: routeData.teacherId })

  return (
    <ScanPickerShell>
      <ComboBox
        {...teaComboProps}
        {...sharedPickerProps}
        label={pickerLabel('Teacher')}
        onChange={(teacher) => dispatchStay({ teacherId: teacher?.id })}
        placeholder="Any teacher"
      />
    </ScanPickerShell>
  )
}

/**
 * Picker to filter scan batches by packet
 */
export function ScanPacketPicker() {
  // todo: this is copy-pasted with SWMenuPacketPicker
  // todo: could hookify the useRouter stuff...
  const { dispatchStay, routeData, useAirlock } = useRouter<RD_Scanlog>()

  let { data: packets, qResult } = usePacketListData()
  packets = useMemo(() => packets.filter((p) => p.print?.count), [packets])

  // todo: is this already implemented for the packet list filter?
  const packetPickerProps = usePickerBase(
    {
      data: packets,
      isLoading: false,
    },
    {
      filterer: (filters, item, inputValue) => {
        return (
          filters.startsWith(item.name, inputValue) ||
          filters.startsWith(item.number, inputValue)
        )
      },
      selector: (item, value) => item.id === value,
      value: routeData?.packetId,
    }
  )

  // reset query param if packet is not in the list
  useAirlock(
    { packetId: null },
    routeData.packetId && isRQReady(qResult) && !packetPickerProps.selectedItem
  )

  return (
    <ScanPickerShell>
      <ComboBox
        {...sharedPickerProps}
        {...packetPickerProps}
        itemToString={(item) => item.name}
        label={pickerLabel('Packet')}
        onChange={(item) => dispatchStay({ packetId: item?.id })}
        placeholder="Any packet"
        // todo: copy/pasted from swMenuPickers
        renderItem={(item) => (
          <HStack fontSize="sm" gap={4}>
            <Txt as="span" fontFamily="mono">
              {item.number}
            </Txt>
            <Txt as="span">{item.name}</Txt>
          </HStack>
        )}
        width="225px"
      />
    </ScanPickerShell>
  )
}

export function ScanIncompletePicker() {
  const { dispatchStay, routeData } = useRouter<RD_Scanlog>()

  const data = [{ id: 'complete' } as const, { id: 'incomplete' } as const]

  // todo: is this already implemented for the packet list filter?
  const pickerProps = usePickerBase(
    { data, isLoading: false },
    {
      filterer: (filters, item, inputValue) => {
        return filters.startsWith(item.id, inputValue)
      },
      selector: (item, value) => item.id === value,
      value: routeData?.status,
    }
  )

  return (
    <ScanPickerShell>
      <ComboBox
        {...sharedPickerProps}
        {...pickerProps}
        itemToString={(item) => capitalize(item.id)}
        label={pickerLabel('Status')}
        onChange={(item) => dispatchStay({ status: item?.id })}
        placeholder="Any status"
        width="140px"
      />
    </ScanPickerShell>
  )
}

const pickerLabel = (text: string) => (
  <Txt fontSize=".7rem" ml={0.5}>
    {text}
  </Txt>
)

function ScanPickerShell({ children }) {
  return (
    <VStack alignItems="stretch" gap={0.25}>
      {children}
    </VStack>
  )
}
