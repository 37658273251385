import type { ApiDirListPacket, ApiPacketBlanks } from '@paper/api'
import { useRouter } from '@paper/route'
import { IAP } from '@paper/schema'
import { HTTPError, sortNumeric } from '@paper/utils'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useUser } from '~src/blocks/userProvider'
import { RD_HomeCurriculum, RD_HomeTeacher } from '~src/routes'
import { useFilters } from '~src/utils/useFilters'

/**
 * @deprecated Not really, but need to check for leaky `teacherId` param
 * e.g. Anywhere that isn't teacher-specific needs to lose the `teacherId` param otherwise it'll grab that teacher's data
 */
export function usePacketListData() {
  // todo: may need to split this further for PacketList vs. JumpTo
  // todo: may need explicit params as part of that! (e.g. we may not be on RD_HomeTeacher)
  const { routeData } = useRouter<RD_HomeTeacher & RD_HomeCurriculum>()
  const { fetchAs } = useUser()
  const { contains: existsAndContains } = useFilters()

  const { curriculumId, teacherId } = routeData
  const payload = { curriculumId, teacherId }

  const qResult = useQuery(
    [IAP.dir.listPackets, payload],
    async () => {
      const packets = await fetchAs
        .post(IAP.dir.listPackets, { json: payload })
        .json<ApiDirListPacket['result']>()

      sortNumeric(packets, (item) => item.number)

      // todo: debugging
      // console.log('usePacketListData', teacherContext)

      return packets
    },
    {
      enabled: !!curriculumId,
      useErrorBoundary: true,
    }
  )

  // Apply client-side filtering
  let data = useMemo(() => {
    let packets = qResult.data ?? []

    if (packets.length === 0) {
      return packets
    }

    if (routeData.pl_filterText) {
      packets = packets.filter(
        (p) =>
          existsAndContains(p.name, routeData.pl_filterText) ||
          existsAndContains(p.number, routeData.pl_filterText)
      )
    }
    if (routeData.pl_packetType) {
      packets = packets.filter((p) => p.type === routeData.pl_packetType)
    }
    // scanStatus
    if (routeData.pl_scanStatus === 'scanned') {
      packets = packets.filter((p) => p.scan.count > 0)
    } else if (routeData.pl_scanStatus === 'partial') {
      packets = packets.filter((p) => p.scan.partial > 0)
    } else if (routeData.pl_scanStatus === 'unnamed') {
      packets = packets.filter((p) => p.scan.unnamedOpen > 0)
    }
    // printStatus
    switch (routeData.pl_printStatus) {
      case 'not':
        packets = packets.filter((p) => !p.print.date)
        break
      case 'printed':
        packets = packets.filter((p) => p.print.date)
        break
      case 'radioactive':
        packets = packets.filter((p) => p.pub.radioactive)
        break
    }
    // publish status
    if (routeData.pl_pub) {
      packets = packets.filter((p) => p.pub.stage === routeData.pl_pub)
    }

    return packets
  }, [
    qResult.data,
    routeData.pl_filterText,
    routeData.pl_packetType,
    routeData.pl_printStatus,
    routeData.pl_pub,
    routeData.pl_scanStatus,
  ])

  // todo: make this a "digest" probably
  let selectedItem = data.find((p) => p.id === routeData.packetId)

  return { data, qResult, selectedItem }
}

export function usePacketBlanks(props: { enabled: boolean; packetId: string }) {
  const { enabled, packetId } = props
  const { fetchAs } = useUser()
  const searchParams = { packetId }
  return useQuery(
    [IAP.blanks, searchParams],
    async () => {
      return fetchAs(IAP.blanks, { searchParams }).json<
        ApiPacketBlanks['result']
      >()
    },
    { enabled }
  )
}
