import * as Yup from 'yup'
import { Curriculum, PublishStage } from '..'
import { yupMin0Int } from './v-utils'

export const yupCurriculum = Yup.object<Curriculum>({
  id: Yup.string().required(),
  level: Yup.string().required(),
  name: Yup.string().required(),
  subject: Yup.string().required(),
  years: Yup.array(yupMin0Int).required(),
})

const NumberFormat = `Must be of the format "X.X" (e.g. 1.12)`
export const yupPacketNumber = Yup.string()
  .trim()
  .required(NumberFormat)
  .max(7, `Cannot exceed 7 characters`)
  .matches(/^\w+\.\w+$/i, NumberFormat)

export const yum_Pub = Yup.string()
  .oneOf<PublishStage>(['published', 'recalled'])
  .required()

export const yupPublishSet = Yup.object({
  _pub: yum_Pub,
  contentId: Yup.string(),
  curriculum: yupCurriculum.required(),
  number: yupPacketNumber,
})
