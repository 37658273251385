import { routemeta } from '@paper/route'
import type { PacketType } from '@paper/schema'
import { CurriculumAirlock } from '~src/blocks/curriculumAirlock'
import { PublishProvider } from './publishProvider'
import { ContentFormSchema } from './wizard/entryBaseData'

export type RD_Publish = {
  contentIdOrNew: 'new' | string
  curriculumId: string
  dev_step?: keyof ContentFormSchema
  dev_type?: PacketType
  packetId?: string // for the back button
}

const publish = routemeta<RD_Publish>({
  path: `/curriculum/:curriculumId/publish/:contentIdOrNew`,
  queryParams: ['packetId', 'dev_step', 'dev_type'],
  render: () => {
    return (
      <CurriculumAirlock>
        <PublishProvider />
      </CurriculumAirlock>
    )
  },
})

/** publish route */
export const pubRoutes = { publish }
