export type Curriculum = {
  id: string
  level: string
  name: string
  subject: string
  years: number[]
}

export type School = {
  id: string
  name: string
}

/** minimal section, often all we need is name+sectionId */
export type Sect = Pick<Section, 'name' | 'sectionId'>

export type Section = {
  course: string
  curriculum: Curriculum
  name: string
  schoolId: string
  schoolName: string
  sectionId: string
  teacherId: string
  term: Term
}

export type Student = {
  grade: string
  homeRoom: string
  id: string
  firstName: string
  lastfirst: string
  lastName: string
  number: string
}

export type Teacher = {
  email: string
  id: string
  firstName: string
  lastName: string
}

export type Term = {
  code: string
  firstDay: Date
  lastDay: Date
}
