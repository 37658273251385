import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Input,
} from '@chakra-ui/react'
import { Passage, QType, Question } from '@paper/schema'
import { SELECTION_COLOR } from '@paper/styles'
import {
  makeOptions,
  OptionAlphabet,
  parseLabel,
} from '@paper/utils/questionUtils'
import { Field as FormikField, FormikErrors, useFormikContext } from 'formik'
import { produce } from 'immer'
import { useEffect, useRef, useState } from 'react'
import { ToggleGroup, VStack } from '~src/components'
import { FieldUI } from '../formHelpers'
import { SubFormButtons } from './formListValueHelpers'

export type SingleQSchema = {
  _labelMap: Map<string, string>
  _maxOptionCount: number
  _new?: boolean
  _optionAlphabet: OptionAlphabet
  _optionCount: number
  q: Question
}

// TODO: Wrote this before I understood Yup, could probably be migrated
export const validateSingleQ = (values: SingleQSchema) => {
  const errors: FormikErrors<SingleQSchema> = { q: {} }
  let hasQErrors = false
  //////////////////
  // label
  //////////////////
  const labelsId = values._labelMap?.get(values.q.label)
  // check label for dupes
  if (labelsId && labelsId !== values.q.id) {
    errors.q.label = `There's already a question ${values.q.label}`
    hasQErrors = true
  }
  // And check label for format
  else {
    try {
      parseLabel(values.q.label)
    } catch (err) {
      errors.q.label = err.message
      hasQErrors = true
    }
  }

  //////////////////
  // correct
  //////////////////
  if (values.q.type === 'MC' && values.q.correct.length === 0) {
    errors.q.correct = 'MC questions must have 1 or more answers'
    hasQErrors = true
  }

  // NOTE: Cleaner way to do this...?
  if (!hasQErrors) {
    delete errors.q
  }

  return errors
}

type QAction =
  | { type: 'setCorrect'; value: number[] }
  | { type: 'setLabel'; value: string }
  | { type: 'setOptionCount'; value: number }
  | { type: 'setPassageIds'; value: string[] }
  | { type: 'setType'; value: QType }

const useQFormDispatch = () => {
  const { setValues, values } = useFormikContext<SingleQSchema>()

  return function dispatch(action: QAction) {
    const validateRequests = new Set<string>()
    // Draft changes via immer
    const nextValues = produce(values, (draft) => {
      switch (action.type) {
        case 'setCorrect':
          draft.q.correct = action.value.sort()
          break
        case 'setLabel':
          try {
            const labelItems = parseLabel(action.value)
            draft.q = { ...draft.q, ...labelItems }
          } catch (err) {
            // Set bad value so the textbox makes sense
            draft.q.label = action.value
          }
          break
        case 'setOptionCount':
          const _optionCount = action.value
          draft._optionCount = _optionCount
          // Recalculate options
          draft.q.options = makeOptions(_optionCount, values._optionAlphabet)
          // Unset any `correct` values that have fallen off
          draft.q.correct = values.q.correct.filter((c) => c < _optionCount)
          break
        case 'setPassageIds':
          draft.q.passageIds = action.value.sort()
          break
        case 'setType':
          const type = action.value
          draft.q.type = type
          // Also update `options` (and its alphabet) and reset `correct`
          const _optionAlphabet = type === 'MC' ? 'abc' : '012'
          draft._optionAlphabet = _optionAlphabet

          // no bubbles
          if (type === 'GRID-IN') {
            draft.q.options = []
          } // bubbles
          else {
            draft._optionCount = draft._optionCount || 4 // default to 4
            draft.q.options = makeOptions(draft._optionCount, _optionAlphabet)
          }

          validateRequests.add('q.correct')
          break
      }
    })

    // Set the values
    setValues(nextValues)
  }
}

type SingleQFormProps = { onUnselect(): void; passages: Passage[] }

export const SingleQuestionForm = ({
  onUnselect,
  passages,
}: SingleQFormProps) => {
  return (
    <Box
      display="grid"
      gridColumnGap={4}
      gridRowGap={4}
      gridTemplateAreas="'form passages' 'btn passages'"
      gridTemplateColumns="220px 260px"
      gridTemplateRows="420px 40px"
    >
      <VStack alignItems="stretch" gridArea="form" overflowY="auto" px={1}>
        <LabelField />
        <QTypeField />
        <OptPickerField />
        <CorrectField />
      </VStack>
      <Box gridArea="passages">
        <PassageField passages={passages} />
      </Box>
      <SubFormButtons onUnselect={onUnselect} />
    </Box>
  )
}

const QTypeField = () => {
  const dispatch = useQFormDispatch()
  const choices: QType[] = ['MC', 'OER', 'GRID-IN']

  return (
    <FormikField name="q.type">
      {({ field, meta }) => (
        <FieldUI
          label="type"
          meta={meta}
          w="100%"
          input={
            <ToggleGroup.Root
              onChange={(value) => dispatch({ type: 'setType', value })}
              preventNone={true}
              size="sm"
              type="single"
              value={field.value}
            >
              <ButtonGroup isAttached={true}>
                {choices.map((value) => (
                  <ToggleGroup.Button key={value} value={value}>
                    {value}
                  </ToggleGroup.Button>
                ))}
              </ButtonGroup>
            </ToggleGroup.Root>
          }
        />
      )}
    </FormikField>
  )
}

const LabelField = () => {
  const dispatch = useQFormDispatch()
  const { values } = useFormikContext<SingleQSchema>()
  const { _new } = values

  const ref = useRef<HTMLInputElement>()
  // we want to return focus to the label field when it becomes blank after save
  // todo: but does this workaround have unintended side effects?
  useEffect(() => {
    if (values.q.label === '') {
      ref.current.focus()
    }
  }, [values.q.label])

  return (
    <FormikField name="q.label">
      {({ field, meta }) => (
        <FieldUI
          input={
            <Input
              bg={_new ? undefined : SELECTION_COLOR}
              fontFamily="mono"
              maxLength={3}
              {...field}
              onChange={(e) => {
                dispatch({
                  type: 'setLabel',
                  value: e.target.value?.toUpperCase(),
                })
              }}
              ref={ref}
              type="text"
              value={field.value ?? ''}
              w="60px"
            />
          }
          label="label"
          meta={meta}
          w="100%"
        />
      )}
    </FormikField>
  )
}

const OptPickerField = () => {
  const { values } = useFormikContext<SingleQSchema>()
  const dispatch = useQFormDispatch()
  const { _maxOptionCount, _optionAlphabet } = values
  const optionsForPicking = makeOptions(_maxOptionCount, _optionAlphabet)
  const [hoverIdx, setHoverIdx] = useState(-1)
  return (
    <FormikField name="_optionCount">
      {({ field, meta }) =>
        values.q.type !== 'GRID-IN' && (
          <FieldUI
            label="options"
            meta={meta}
            input={
              <Box data-cy="form-q-opts" fontFamily="mono">
                {optionsForPicking.map((opt, idx) => {
                  const isHighlighted = idx < field.value
                  const isDisabled = idx === 0
                  // todo: i don't think there's a way to force hovered state
                  const hoverStyles: ButtonProps =
                    idx <= hoverIdx ? { bg: 'grayBtn.600', color: 'white' } : {}
                  return (
                    <Button
                      key={idx}
                      borderRadius="50%"
                      onClick={() => {
                        dispatch({ type: 'setOptionCount', value: idx + 1 })
                      }}
                      onMouseEnter={() => setHoverIdx(idx)}
                      onMouseLeave={() => hoverIdx === idx && setHoverIdx(-1)}
                      pointerEvents={isDisabled ? 'none' : undefined}
                      size="xs"
                      variant={isHighlighted ? 'solid' : 'outline'}
                      colorScheme={isHighlighted ? 'grayBtn' : null}
                      {...hoverStyles}
                      _hover={hoverStyles}
                      _active={{ bg: 'grayBtn.700', color: 'white' }}
                    >
                      {opt.label}
                    </Button>
                  )
                })}
              </Box>
            }
          />
        )
      }
    </FormikField>
  )
}

const CorrectField = () => {
  const { setFieldTouched, values } = useFormikContext<SingleQSchema>()
  const dispatch = useQFormDispatch()
  return (
    <FormikField name="q.correct">
      {({ field, meta }) =>
        values.q.type === 'MC' && (
          <FieldUI
            label="answer"
            meta={meta}
            input={
              <ToggleGroup.Root
                colorScheme="purple"
                // @ts-expect-error
                onChange={(value: number[]) => {
                  !meta.touched && setFieldTouched(field.name, true)
                  dispatch({ type: 'setCorrect', value })
                }}
                shape="circle"
                size="xs"
                type="multiple"
                value={field.value}
              >
                <Box data-cy="form-q-correct" fontFamily="mono">
                  {values.q.options.map((opt, idx) => (
                    // @ts-expect-error
                    <ToggleGroup.Button key={idx} value={idx}>
                      {opt.label}
                    </ToggleGroup.Button>
                  ))}
                </Box>
              </ToggleGroup.Root>
            }
          />
        )
      }
    </FormikField>
  )
}

type PassageFieldProps = { passages: Passage[] }
const PassageField = ({ passages }: PassageFieldProps) => {
  const dispatch = useQFormDispatch()
  return (
    <FormikField name="q.passageIds">
      {({ field, meta }) => (
        <FieldUI
          label="passages"
          meta={meta}
          h="100%"
          input={
            <ToggleGroup.Root
              colorScheme="grayBtn"
              onChange={(value: string[]) => {
                dispatch({ type: 'setPassageIds', value })
              }}
              shape="rounded"
              size="xs"
              type="multiple"
              value={field.value}
            >
              <VStack alignItems="stretch" height="100%" overflowY="auto">
                {passages.map((passage) => (
                  <ToggleGroup.Button
                    display="flex"
                    fontWeight={
                      // TODO: This should be a prop on ButtonGroup :/
                      field.value.includes(passage.id)
                        ? 'bolder !important'
                        : undefined
                    }
                    gap={2}
                    height="unset"
                    justifyContent="flex-start"
                    mb={1}
                    p={2}
                    textAlign="start"
                    whiteSpace="pre-wrap"
                    key={passage.id}
                    value={passage.id}
                  >
                    <Avatar name={passage.name} size="2xs" />
                    {passage.name}
                  </ToggleGroup.Button>
                ))}
              </VStack>
            </ToggleGroup.Root>
          }
        />
      )}
    </FormikField>
  )
}
