import type { PDFDocument } from '@paper/pdf'
import type { Curriculum } from '@paper/schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { PublishContext } from '../publishProvider'
import { steps, StepSpec } from './steps'

type GenericPA<T, K extends keyof T> = PayloadAction<Pick<T, K>>
type PA<K extends keyof WizState> = GenericPA<WizState, K>

export type WizWhich = 'answerKey' | 'pdf'

type WizStateFull = {
  _blanksPdfBlob: Blob
  _capturedBlanks: Blob[]
  _savedBlanks: string[]
  _savedPdfUrl: string
  contentId: string
  curriculum: Curriculum
  curStep: StepSpec
  curStepError: string
  isNew: boolean
  rawImages: Blob[]
  srcBuf: ArrayBuffer
  srcDoc: PDFDocument
  type: WizWhich
}

export type WizState = Partial<WizStateFull>

const getInitialState = (): WizState => ({
  curStep: steps._idle,
})

export const wizSlice = createSlice({
  name: 'wiz',
  initialState: getInitialState,
  reducers: {
    reset(draft, action: PayloadAction<PublishContext>) {
      const {
        contentId,
        curriculum,
        openedPdf,
        packetData,
        page,
        __wizStub__,
      } = action.payload ?? {}

      console.log(action.payload)

      if (__wizStub__) {
        return __wizStub__
      }

      const isNew = !packetData.packets?.length

      let curStep =
        page === 'entryAnswerKey'
          ? steps.passages
          : isNew
          ? steps.type
          : steps.name

      return {
        // so here's the deal: if we're editing the pdf,
        // then we need to regenerate blank images..., so pretend we don't have blanks here
        _savedBlanks: page === 'entryPdf' ? null : packetData.signedImageUrls,
        _savedPdfUrl: packetData.signedPdfUrl,
        contentId,
        curriculum,
        curStep,
        curStepError: null,
        isNew,
        srcBuf: openedPdf?.srcBuf,
        srcDoc: openedPdf?.srcDoc,
        type: page === 'entryAnswerKey' ? 'answerKey' : 'pdf',
      }
    },
    /**
     * @example
     * const setRawImages = (images: Blob[]) => {
     *  dispatch(_shallowMerge({ state: { rawImages: images }}))
     * }
     */
    _shallowMerge(draft, action: PayloadAction<{ state: WizState }>) {
      return { ...draft, ...action.payload.state }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      () => true,
      (state, action) => {
        // logging for dev
        //console.log(`[${action.type}]`, action.payload, current(state))
      }
    )
  },
})
