// todo: whole thing very WIP

import { CM } from '../mongo/collections'

export type RunSrc = 'airtable' | 'scheduled'

export interface Stage<T = any> {
  collection: string
  errorPaths?: string[]
  errors?: string[]
  modifiedKeys?: string[]
  next?: T
  old?: T
  parentId?: string
  query: any
  runId: number
  runSrc: RunSrc
  status: 'added' | 'modified' | 'removed' | 'unchanged' | 'frozen'
  valid: 'error' | 'valid' | 'na'
}

export interface Invalid<T = any> {
  errorPaths?: string[]
  errors?: string[]
  runId: number
  runSrc: RunSrc
  src: string
  value: T
}

export type BulkSummary = {
  ok: number
  writeErrors?: any[]
  writeConcernErrors?: any[]
  nInserted: number
  nUpserted: number
  nMatched: number
  nModified: number
  nRemoved: number
}

export type RunDirect = { collectionName: keyof CM; bulkSummary?: BulkSummary }

export type RunStatus =
  | 'applied'
  | 'applying'
  | 'creating'
  | 'failed-apply'
  | 'failed-create'
  | 'open'
  | 'superseded'

export interface RunMeta {
  appliedAt?: number
  appliedBy?: string
  error?: any
  direct: RunDirect[]
  prevAppliedRunId?: number
  runId: number
  runSrc: RunSrc
  status: RunStatus
}
