import { extendTheme, theme } from '@chakra-ui/react'

export const mainFont = "'Helvetica', Calibri, sans-serif"
export const monoFont = 'Inconsolata, monospace'

export const chakraTheme = extendTheme({
  components: {
    Button: {
      baseStyle: { fontWeight: 300 },
    },
    Table: {
      sizes: {
        sm: {
          th: { py: '.5rem' },
          td: { py: '.1875rem' },
        },
      },
    },
    Modal: {
      sizes: {
        xl: {
          dialog: { maxW: '80vw' },
        },
      },
    },
    Menu: {
      baseStyle: {
        item: {
          borderLeftColor: 'transparent',
          borderLeftStyle: 'solid',
          borderLeftWidth: '5px',
          '>.chakra-menu__icon-wrapper': {
            display: 'flex',
          },
        },
      },
    },
    // Tabs: {
    //   variants: {
    //     enclosed: {
    //       tab: {
    //         // match my "dark mode" colors
    //         _selected: { borderBottomColor: 'pureGray.600' },
    //       },
    //     },
    //   },
    // },
  },
  fonts: {
    heading: mainFont,
    body: mainFont,
    mono: monoFont,
  },
  colors: {
    pureGray: {
      '50': '#fafafa',
      '100': '#ededed',
      '600': '#4c4c4c',
      '700': '#404040',
      '800': '#333333',
    },
    grayBtn: theme.colors.gray, // circumvent chakra's special handling around gray buttons
    // purple from the react charts library
    purple: {
      '50': '#f1f0fa',
      '100': '#dbdaf3',
      '200': '#c4c2ec',
      '300': '#aca9e4',
      '400': '#9a96de',
      '500': '#8884d8',
      '600': '#807cd4',
      '700': '#7571ce',
      '800': '#6b67c8',
      '900': '#5854bf',
    },
    passageBlue: {
      50: '#e7f5f9',
      100: '#c2e6f1',
      200: '#9ad5e7',
      300: '#71c4dd',
      400: '#52b8d6',
      500: '#34abcf',
      600: '#2fa4ca',
      700: '#279ac3',
      800: '#2191bd',
      900: '#1580b2',
    },
    // https://github.com/chakra-ui/chakra-ui/blob/v0/packages/chakra-ui/src/theme/colors.js
    scanMissingRed: {
      50: '#fff5f5',
      100: '#fff5f5',
      200: '#fed7d7',
      300: '#feb2b2',
      400: '#fc8181',
      500: '#f56565',
      600: '#e53e3e',
      700: '#c53030',
      800: '#9b2c2c',
      900: '#822727',
    },
    scanPresentGray: {
      50: '#F7FAFC',
      200: '#F7FAFC',
      300: '#EDF2F7',
      400: '#E2E8F0',
      500: '#CBD5E0',
      600: '#A0AEC0',
      700: '#718096',
      800: '#4A5568',
      900: '#2D3748',
    },
    scanFixedGray: {
      50: '#f8f8f7',
      100: '#dfdddc',
      200: '#c6c2c1',
      300: '#ada8a5',
      400: '#948d8a',
      500: '#7C736F',
      600: '#635c58',
      700: '#4a4542',
      800: '#312e2c',
      900: '#181616',
    },
  },
})
