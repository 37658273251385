import { Box, Button } from '@chakra-ui/react'
import { useRouter } from '@paper/route'
import { CORRECT_COLOR } from '@paper/styles'
import { Fragment } from 'react'
import { RD_JumpToQ } from '../../routes'
import type { QAgg } from './bargraphTypes'

type AnswerFilterProps = QAgg
export function AnswerFilter(props: AnswerFilterProps) {
  const { answerCounts, answerDenom } = props
  const { dispatchStay, routeData } = useRouter<RD_JumpToQ>()

  const minBtnWidth = 10

  const answerSpecs = answerCounts
    .filter((ac) => ac.count)
    .map((ac) => {
      // Chrome (at least) seems to handle %s with minWidths magically
      const pct = (100 * ac.count) / answerDenom
      return { ...ac, width: `${pct}%` }
    })

  return (
    <Fragment>
      {answerSpecs.map(({ isCorrect, label, width }, idx) => {
        const isSelected = routeData.f_ans === label
        return (
          <Button
            borderRight={idx === answerSpecs.length - 1 ? undefined : 'none'}
            colorScheme={
              !isSelected ? 'gray' : isCorrect ? 'purple' : 'grayBtn'
            }
            fontFamily="body"
            fontSize="xs"
            key={idx}
            minWidth={`${minBtnWidth * label.length}px`}
            onClick={() => dispatchStay({ f_ans: isSelected ? null : label })}
            px={0}
            roundedLeft={idx === 0 ? undefined : 'none'}
            roundedRight={idx === answerSpecs.length - 1 ? undefined : 'none'}
            size="sm"
            style={{ width }}
            transitionDuration=".3s"
            transitionProperty="width, var(--chakra-transition-property-common)"
            variant={isSelected ? 'solid' : 'outline'}
          >
            {label}
            {isCorrect && (
              <Box
                as="span"
                color={isSelected ? 'white' : CORRECT_COLOR}
                fontSize=".55rem"
                position="absolute"
                left="2px"
                top="1px"
                zIndex={1}
              >
                ✱
              </Box>
            )}
          </Button>
        )
      })}
    </Fragment>
  )
}
