import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'
import type { ApiStdList } from '@paper/api'
import { IAP, StdInst } from '@paper/schema'
import { Yup, yupStds } from '@paper/schema/validation'
import { STD_BUCKETS, STD_LEVEL } from '@paper/utils'
import { Form, Formik, useFormikContext } from 'formik'
import { orderBy } from 'lodash'
import { useState } from 'react'
import { useCurriculumContext } from '~src/blocks/curriculumAirlock'
import { useFetchPicker } from '~src/blocks/pickers'
import {
  ComboBox,
  HStack,
  Separator,
  TextStack,
  ToggleGroup,
  VStack,
} from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { FullPageMessage } from '~src/components/status/fullPage'
import { useSubmitStds } from '../data-publish'
import { ButtonStack } from '../formHelpers'
import { StdTagList } from './stdTagList'

type FormData = { stds: string[] }

type StdDialogProps = {
  contentId: string
  data: FormData
  isOpen: boolean
  onClose(): void
}

const validationSchema = Yup.object({
  stds: yupStds,
})

export function StdDialog(props: StdDialogProps) {
  const { contentId, data, isOpen, onClose } = props
  const { mutateAsync, ...qResult } = useSubmitStds({ onSuccess: onClose })
  return (
    <FullPageLoading
      loadMsg={<FullPageMessage.Text>Submitting...</FullPageMessage.Text>}
      qResult={qResult}
      type="transparent"
    >
      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered={true}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay>
          <Formik
            initialValues={data}
            onReset={onClose}
            onSubmit={(values) => mutateAsync({ contentId, ...values })}
            validateOnMount={true}
            validationSchema={validationSchema}
          >
            <StdForm />
          </Formik>
        </ModalOverlay>
      </Modal>
    </FullPageLoading>
  )
}

function StdForm() {
  const { dirty, isSubmitting, isValid, setFieldValue, values } =
    useFormikContext<FormData>()

  const [bucket, setBucket] = useState<StdInst['bucket']>(STD_BUCKETS[0])
  const { curriculum } = useCurriculumContext()
  const [level, setLevel] = useState(curriculum.level)

  const { stds } = values

  // todo: probably change this to toggle
  const updateStds = (std: string, action: 'add' | 'delete') => {
    const stdSet = new Set(stds)
    stdSet[action](std)
    setFieldValue('stds', orderBy(Array.from(stdSet)))
  }

  const searchParams = { bucket, level }

  const pickerProps = useFetchPicker(
    ['stds_list', searchParams],
    (fetchAs) => async () => {
      const fullStds = await fetchAs(IAP.std.list, { searchParams }).json<
        ApiStdList['result']
      >()
      return orderBy(
        fullStds.map((p) => ({ id: p.std, ...p })),
        (full) => full.std
      )
    },
    {
      filterer: (filters, item, value) => filters.contains(item.std, value),
      limit: 25,
    }
  )

  return (
    <ModalContent as={Form} borderRadius={4} height="700px" maxWidth="640px">
      <ModalBody overflow="hidden" pt={6}>
        <Heading
          as="h3"
          fontSize="xl"
          fontWeight={300}
          mb={4}
          userSelect="none"
        >
          Standards
        </Heading>

        <VStack alignItems="stretch" gap={6}>
          <StdTagList
            onRemove={(item) => updateStds(item, 'delete')}
            readonly={false}
            stds={values.stds}
          />
          <Separator />
          <HStack gap={6}>
            <Heading as="h3" fontSize="sm" fontWeight={300} userSelect="none">
              Standard set:
            </Heading>
            <ToggleGroup.Root
              type="single"
              onChange={(value) => setBucket(value)}
              preventNone={true}
              size="sm"
              value={bucket}
            >
              <ButtonGroup isAttached={true}>
                {STD_BUCKETS.map((b) => (
                  <ToggleGroup.Button key={b} value={b}>
                    {b}
                  </ToggleGroup.Button>
                ))}
              </ButtonGroup>
            </ToggleGroup.Root>
            <ToggleGroup.Root
              type="single"
              onChange={setLevel}
              preventNone={true}
              size="xs"
              value={level}
            >
              <ButtonGroup isAttached={true}>
                {STD_LEVEL.map((b) => (
                  <ToggleGroup.Button key={b} value={b}>
                    {b}
                  </ToggleGroup.Button>
                ))}
              </ButtonGroup>
            </ToggleGroup.Root>
          </HStack>
          <ComboBox
            {...pickerProps}
            autoFocus={true}
            itemToString={(item) => item.std}
            onSelect={(selectedItem) => updateStds(selectedItem.std, 'add')}
            placeholder="Add standards"
            renderItem={(item) => (
              <TextStack>
                <TextStack.Top fontSize="sm">{item.std}</TextStack.Top>
                <TextStack.Bottom fontSize="xs" opacity={0.8} variant="sardine">
                  {item.desc}
                </TextStack.Bottom>
              </TextStack>
            )}
          />
        </VStack>
      </ModalBody>
      <ModalFooter>
        <ButtonStack>
          <Button size="sm" type="reset" variant="outline">
            Cancel
          </Button>
          <Button
            data-cy="cp-submit"
            isDisabled={!dirty || isSubmitting || !isValid}
            size="sm"
            type="submit"
          >
            Submit
          </Button>
        </ButtonStack>
      </ModalFooter>
    </ModalContent>
  )
}
