import { useRouter } from '@paper/route'
import { getFullName } from '@paper/utils'
import { orderBy } from 'lodash'
import { PacketTable } from '~src/blocks/packetTable'
import { teacherPacketColumns } from '~src/blocks/packetTable/packetTableCols'
import { usePickerBase, useTeacherPicker } from '~src/blocks/pickers'
import { useTeacherContext } from '~src/blocks/teacherAirlock'
import { ComboBox } from '~src/components'
import { useDirectoryData } from '~src/data/data-directory'
import { RD_HomeTeacher } from '~src/routes'
import { HomePanel } from './homePanel'
import { PacketListFilters } from './packetListFilters'

export function TeacherPanel() {
  const { curriculum, teacher } = useTeacherContext()
  const { dispatchStay } = useRouter<RD_HomeTeacher>()
  const teaComboProps = useTeacherPicker({ value: teacher?.id })
  const curricula = useTeacherCurricula(teacher?.id)
  const curComboProps = usePickerBase(
    { isLoading: false, data: curricula },
    // todo: copy/paste with useCurriculaPicker
    {
      filterer: (filters, c, inputValue) =>
        filters.startsWithByWord(c.name, inputValue),
      selector: (item, value) => item.id === value,
      value: curriculum?.id,
    }
  )

  //console.log('<TeacherPanel/>')

  return (
    <HomePanel
      pickers={
        <>
          <ComboBox
            {...teaComboProps}
            caret={true}
            inputTextAlign="center"
            onChange={(item) => dispatchStay({ teacherId: item.id })}
            openIfNoSelection={true}
            placeholder="Select a teacher"
            round={true}
            size="md"
            variant="filled"
            width="256px"
          />
          <ComboBox
            {...curComboProps}
            caret={curComboProps.items.length > 1}
            fontFamily="mono"
            inputTextAlign="center"
            itemToString={(c) => c.name}
            onChange={(item) => dispatchStay({ curriculumId: item.id })}
            round={true}
            size="md"
            variant="filled"
            width="240px"
          />
        </>
      }
      filters={<PacketListFilters />}
      name={getFullName(teacher)}
      table={<PacketTable columns={teacherPacketColumns} />}
    />
  )
}

// todo: move this
function useTeacherCurricula(teacherId) {
  const { data } = useDirectoryData()
  if (!data) {
    return []
  }
  const ids = data.teacher.map.get(teacherId)?.curriculumIds ?? []
  return orderBy(
    Array.from(ids).map((id) => data.curriculum.map.get(id).item),
    (c) => c.name
  )
}
