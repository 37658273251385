import { Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react'
import type { Question } from '@paper/schema'
import { Formik } from 'formik'
import { produce } from 'immer'
import { Separator, VStack } from '~src/components'
import { Wiz } from '../publishLayout'
import type { ContentFormSchema } from './entryBaseData'
import {
  FormListState,
  ListAction,
  useFormListValue,
} from './formListValueHelpers'
import {
  SingleQSchema,
  SingleQuestionForm,
  validateSingleQ,
} from './formSingleQuestion'
import { loadQ } from './questionUtils/loadQ'
import { SortableQList } from './questionUtils/sortableQs'

const statNum = (value: number) => (
  <StatNumber fontSize="lg" fontWeight={500}>
    {value}
  </StatNumber>
)

type State = FormListState<SingleQSchema, ContentFormSchema, Question>

export function FormQuestions() {
  // Initialize reducer with last saved Qs
  const formListHelpers = useFormListValue(reducer, (values) =>
    loadQ({ mode: 'increment', questions: values.questions })
  )

  const { dispatch, selected, values } = formListHelpers

  const { passages, questions } = values

  return (
    <Wiz.FormArea
      alignItems="stretch"
      display="grid"
      gridColumnGap={10}
      gridTemplateColumns="minmax(360px, max-content) max-content 1fr"
      justifyContent="stretch"
      overflowX="auto"
    >
      <VStack alignItems="stretch" gap={2} overflow="hidden">
        <StatGroup>
          <Stat>
            <StatLabel>Bubble rows</StatLabel>
            {statNum(questions.length)}
          </Stat>
        </StatGroup>
        <Separator />
        <SortableQList {...formListHelpers} />
      </VStack>
      <Separator orientation="vertical" />
      <Formik
        enableReinitialize={true}
        initialValues={selected}
        initialTouched={{ q: { label: true } }}
        onSubmit={({ _new, q }) => {
          dispatch({ type: _new ? 'add' : 'save', item: q })
        }}
        validate={validateSingleQ}
        validateOnMount={true}
      >
        <SingleQuestionForm
          passages={passages}
          onUnselect={() => dispatch({ type: 'unselect' })}
        />
      </Formik>
    </Wiz.FormArea>
  )
}

const reducer = (state: State, action: ListAction<Question>): State => {
  return produce(state, (draft) => {
    const { selected, values } = draft
    const { questions } = values
    const plainQ = action.item

    switch (action.type) {
      case 'add':
        questions.push(plainQ)
        draft.selected = loadQ({ mode: 'increment', questions })
        draft.lastAdded = action.item
        break
      case 'delete':
        values.questions = questions.filter((p) => p.id !== action.item.id)
        // Also remove from pages
        values.pages.forEach(
          (page) =>
            (page.items = page.items.filter(
              (item) => item.id !== action.item.id
            ))
        )
        // If deleting selected, then reset it
        if (selected?.q.id === action.item.id) {
          draft.selected = loadQ({ mode: 'increment', questions })
        }
        break
      case 'move':
        draft.values.questions = action.mover(questions)
        break
      case 'save':
        const editedIndex = questions.findIndex((p) => p.id === action.item.id)
        questions.splice(editedIndex, 1, plainQ)
        draft.selected = loadQ({ mode: 'increment', questions })
        break
      case 'selectToEdit':
        draft.selected = loadQ({ Q: action.item, mode: 'parse', questions })
        break
      case 'unselect':
        draft.selected = loadQ({ mode: 'increment', questions })
        break
    }

    // note: no longer
    // keep questions sorted
    // sortNumeric(values.questions, (q) => q.label)
  })
}
