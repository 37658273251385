import { Passage } from '@paper/schema'
import { yupPassage } from '@paper/schema/validation'
import { Formik } from 'formik'
import immer from 'immer'
import { nanoid } from 'nanoid/non-secure'
import { Reducer, useMemo } from 'react'
import { AnswerKey } from '~src/blocks/answerKey'
import { Wiz } from '../publishLayout'
import type { ContentFormSchema } from './entryBaseData'
import {
  FormListState,
  ListAction,
  useFormActionFactory,
  useFormListValue,
} from './formListValueHelpers'
import { SinglePassageForm, SinglePassageSchema } from './formSinglePassage'

const getEmptyPassage = (): SinglePassageSchema => {
  return {
    _new: true,
    id: nanoid(11),
    name: '',
    genre: '',
    lexile: '',
  }
}

type State = FormListState<SinglePassageSchema, ContentFormSchema, Passage>

const reducer: Reducer<State, ListAction<Passage>> = (state, action) => {
  const { item } = action

  return immer(state, (draft) => {
    const { selected, values } = draft

    switch (action.type) {
      case 'add':
        values.passages.push(action.item)
        draft.selected = getEmptyPassage()
        break
      case 'delete':
        values.passages = values.passages.filter((p) => p.id !== item.id)
        // If deleting selected, then reset it
        if (selected?.id === action.item.id) {
          draft.selected = getEmptyPassage()
        }
        // Remove passage from any questions
        values.questions.forEach((q) => {
          q.passageIds = q.passageIds.filter((p) => p !== action.item.id)
        })
        // Remove passage from any pages
        values.pages.forEach(
          (page) =>
            (page.items = page.items.filter((p) => p.id !== action.item.id))
        )
        break
      case 'save':
        const editedIndex = values.passages.findIndex((p) => p.id === item.id)
        values.passages.splice(editedIndex, 1, item)
        draft.selected = getEmptyPassage()
        break
      case 'selectToEdit':
        draft.selected = item
        break
      case 'unselect':
        draft.selected = getEmptyPassage()
        break
    }
  })
}

type FormPassagesProps = {}

export function FormPassages(props: FormPassagesProps) {
  const { dispatch, lastAdded, selected, values } = useFormListValue(
    reducer,
    getEmptyPassage
  )
  const actionFactory = useFormActionFactory(dispatch, 'passage')
  const { passages } = values

  // "chunks" without questions - can't use useChunked b/c all passages would be "unused"
  const chunks = useMemo(() => {
    return (
      passages?.map((passage) => ({
        fingerprint: passage.id,
        passages: [passage],
        questions: [],
      })) ?? []
    )
  }, [passages])

  return (
    <Wiz.FormArea
      // todo: copy/pasted from formQuestions
      alignItems="stretch"
      display="grid"
      gridColumnGap={12}
      gridTemplateColumns="minmax(280px, max-content) 1fr"
      justifyContent="stretch"
      overflowX="auto"
    >
      <AnswerKey.Container minWidth="280px">
        <AnswerKey.Guts
          actionFactory={actionFactory}
          chunks={chunks}
          highlightedId={selected.id}
          scrolledToId={lastAdded?.id}
        />
      </AnswerKey.Container>
      <Formik
        enableReinitialize={true}
        initialValues={selected}
        onSubmit={({ _new, ...values }) => {
          dispatch({ type: _new ? 'add' : 'save', item: values })
        }}
        validateOnMount={true}
        validationSchema={yupPassage}
      >
        <SinglePassageForm onUnselect={() => dispatch({ type: 'unselect' })} />
      </Formik>
    </Wiz.FormArea>
  )
}
