export const NumericCollator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
})

export function sortNumeric<T>(arr: T[], pred: (item: T) => any) {
  return arr.sort((a, b) => NumericCollator.compare(pred(a), pred(b)))
}

export const startsWithInsensitive = (value: string, query = '') => {
  return value?.toLowerCase().startsWith(query.toLowerCase())
}

export const includesInsensitive = (value: string, query = '') => {
  return value?.toLowerCase().includes(query.toLowerCase())
}
