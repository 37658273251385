import {
  Button,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { IcoSecretDoor, IcoUser } from '@paper/icons'
import { RouteMeta, useRouter } from '@paper/route'
import { invertMap } from '@paper/utils'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useMemo } from 'react'
import { CurriculumAirlock } from '~src/blocks/curriculumAirlock'
import { IcoScanIssue } from '~src/blocks/icoScanIssue'
import {
  MenuBody,
  MenuSeparator,
  RouteActionMenuItem,
  useMenu,
} from '~src/blocks/menu'
import { MISupport } from '~src/blocks/miSupport'
import { MISignOut } from '~src/blocks/swMenu/swMenuItems'
import { TeacherAirlock } from '~src/blocks/teacherAirlock'
import { useUser } from '~src/blocks/userProvider'
import { Routes } from '~src/routes'
import { CurriculumPanel } from './curriculumPanel'
import { SchoolPanel } from './schoolPanel'
import { TeacherPanel } from './teacherPanel'

function useRouteToTab() {
  const { currentRoute, dispatchRoute } = useRouter()
  const { routeTabMap, setTabIndex } = useMemo(() => {
    // route <-> tabIndex
    const routeTabMap = new Map<RouteMeta<any>, number>([
      [Routes.home_teacher, 0],
      [Routes.home_curriculum, 1],
      [Routes.home_school, 2],
    ])
    const tabRouteMap = invertMap(routeTabMap)

    const setTabIndex = (tabIndex: number) => {
      dispatchRoute(tabRouteMap.get(tabIndex).mergeAction())
    }

    return { routeTabMap, setTabIndex }
  }, [])

  const tabIndex = routeTabMap.get(currentRoute)

  return { tabIndex, setTabIndex }
}

export function Home() {
  const { user } = useUser()
  const { tabIndex, setTabIndex } = useRouteToTab()

  return (
    <Tabs
      display="flex"
      flexDirection="column"
      flexGrow={1}
      height="100%" // important for `overflow:auto`
      index={tabIndex}
      isLazy={true}
      onChange={setTabIndex}
      overflow="hidden"
      pt={2} // pad here so we don't occlude the focus indicator
      px={12} // and here
      size="lg"
      sx={{ 'button[role=tab]': { fontSize: '1.0625rem' } }}
      variant="enclosed"
    >
      <TabList>
        <HomeMenu />
        <Tab fontSize="1.1rem">Teacher</Tab>
        <Tab>Curriculum</Tab>
        <Tab>School</Tab>
        <Tab
          data-cy="tab-user"
          as="span"
          fontFamily="body"
          fontSize="md"
          ml="auto"
          pointerEvents="none"
        >
          <Icon as={IcoUser} mr={1} />
          {user.givenName}
        </Tab>
      </TabList>
      <TabPanels display="flex" flexGrow={1} overflow="hidden">
        <TabPanel flexGrow={1} tabIndex={-1} pb={0}>
          <TeacherAirlock behavior="unlocked-clear-curriculum">
            <TeacherPanel />
          </TeacherAirlock>
        </TabPanel>
        <TabPanel flexGrow={1} tabIndex={-1}>
          <CurriculumAirlock behavior="unlocked">
            <CurriculumPanel />
          </CurriculumAirlock>
        </TabPanel>
        <TabPanel flexGrow={1} tabIndex={-1}>
          <SchoolPanel />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

function HomeMenu() {
  const { isInternal, user } = useUser()
  let { menuButtonProps, onOpenChange, open, shroud } = useMenu({
    caret: true,
    shroud: true,
  })

  return (
    <DropdownMenu.Root
      modal={false} // some sort of perf issue with modal...
      onOpenChange={onOpenChange}
      open={open}
    >
      <DropdownMenu.Trigger asChild>
        <Button
          data-cy="menu-home"
          {...menuButtonProps}
          fontFamily="body"
          flexShrink={0}
          mr={3}
          px={3}
          size="lg"
        >
          Ponder Paper
        </Button>
      </DropdownMenu.Trigger>
      {shroud}
      <DropdownMenu.Content asChild>
        <MenuBody width="272px">
          <RouteActionMenuItem
            action={Routes.scanlog.navigateAction({ scanUser: user.email })}
            icon={<IcoScanIssue />}
          >
            Scan log
          </RouteActionMenuItem>
          {isInternal && (
            <RouteActionMenuItem
              action={Routes.internal_approver_staging.navigateAction({
                runSrc: 'airtable',
              })}
              icon={<IcoSecretDoor />}
            >
              Staging approver
            </RouteActionMenuItem>
          )}
          <MenuSeparator />
          <MISupport />
          <MenuSeparator />
          <MISignOut />
        </MenuBody>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
