import { Curriculum, School, StrId, Teacher } from '@paper/schema'
import { getFullName } from '@paper/utils'
import { ComboBoxProps, TextStack } from '~src/components'
import { DirData, useDirectoryData } from '~src/data/data-directory'
import { PickerProps, usePickerBase } from './usePicker'

function useDirectoryPicker<T extends StrId, V = string>(
  // tried typing this with K keyof, but wasn't working
  // ...i'm sure i've done this successfully somewhere
  selector: (data: DirData) => T[],
  props: PickerProps<T, V>
) {
  const { data, isLoading } = useDirectoryData()
  return usePickerBase({ data: selector(data), isLoading }, props)
}

/////////////////////
// Curried helpers
/////////////////////
type PartialPickerProps<T> = Pick<
  PickerProps<T, string>,
  'excludeSet' | 'includeSet' | 'limit' | 'value'
>
type CurriedPicker<T> = (props?: PartialPickerProps<T>) => ComboBoxProps<T>
/**
 * Returns picker props to select from all curricula
 */
export const useCurriculaPicker: CurriedPicker<Curriculum> = (props) => {
  props = props ?? {}

  const pickerProps = useDirectoryPicker((data) => data?.curriculum.items, {
    filterer: (filters, c, inputValue) =>
      filters.startsWithByWord(c.name, inputValue),
    selector: (c, value) => c.id === value,
    ...props,
  })

  return {
    ...pickerProps,
    fontFamily: 'mono',
    itemToString: (c: Curriculum) => c.name,
  }
}

/**
 * Returns picker props to select from all teachers
 */
export const useTeacherPicker: CurriedPicker<Teacher> = (props) => {
  props = props ?? {}

  const pickerProps = useDirectoryPicker((data) => data?.teacher.items, {
    filterer: (filters, item, inputValue) => {
      return (
        filters.startsWith(item.firstName, inputValue) ||
        filters.startsWith(item.lastName, inputValue) ||
        filters.startsWith(getFullName(item), inputValue) ||
        filters.startsWith(item.email, inputValue) ||
        filters.startsWith(item.email.split('@')[1], inputValue)
      )
    },
    selector: (item, value) => item.id === value,
    ...props,
  })
  return {
    ...pickerProps,
    itemToString: getFullName,
    renderItem: renderUserListItem,
  }
}

export const getSchoolDisplayName = (school: School) =>
  school?.name.replace('Charter School', '')

/**
 * Returns picker props to select from all schools
 */
export const useSchoolPicker: CurriedPicker<School> = (props) => {
  props = props ?? {}

  const pickerProps = useDirectoryPicker((data) => data?.school.items, {
    filterer: (filters, item, inputValue) => {
      return filters.startsWith(item.name, inputValue)
    },
    selector: (item, value) => item.id === value,
    ...props,
  })
  return {
    ...pickerProps,
    itemToString: getSchoolDisplayName,
  }
}

// todo: move!
export function renderUserListItem(item: Teacher) {
  return (
    <TextStack>
      <TextStack.Top>
        {item.firstName} {item.lastName}
      </TextStack.Top>
      <TextStack.Bottom fontSize="xs" opacity={0.8} variant="sardine">
        {item.email}
      </TextStack.Bottom>
    </TextStack>
  )
}
