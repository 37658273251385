{
  "api": {
    "origin": "https://us-east4-datagen-scansies-com.cloudfunctions.net",
    "prefix": "https://us-east4-datagen-scansies-com.cloudfunctions.net/api"
  },
  "cdnOrigin": "https://paper.ponder-cdn.com",
  "googleClientId": "605333750518-nds9pnjii9au72m7geu6ivtfp239bopv.apps.googleusercontent.com",
  "meta": {
    "branch": "live/main",
    "data": "live",
    "dbId": "exa-S",
    "host": "datagen.scansies.com",
    "ns": "exa",
    "type": "dev",
    "version": "6.0",
    "where": "dev"
  },
  "rollbarClientKey": "900af1794ea245b5b8200fd25561d14f"
}
