import { getAggPrintState } from '@paper/utils'
import { useFormik } from 'formik'
import { isEqual, pick } from 'lodash'
import { MutableRefObject, useLayoutEffect, useReducer, useRef } from 'react'
import { useStaticFn } from '~src/blocks/list/listCallbacks'
import { useBlobsToUrls, useBlobUrl, useBufferToUrl } from '~src/utils/blob'
import { ReduxStateTuple, usePublishContext } from '../publishProvider'
import { useFetchPdf } from '../data-publish'
import { ContentFormSchema, contentToFormik } from './entryBaseData'
import { CaptureProps } from './pdfImageCapture'
import { getPrereqQueue, resolveSteps, StepSpec } from './steps'
import { usePdfPreview } from './usePdfPreview'
import { validationSchemas } from './validation'
import { wizSlice, WizState } from './wizReducer'

export type WizFormik = ReturnType<typeof useFormik>

/**
 * Helper hook for WizContext
 * DO NOT CALL DIRECTLY
 */
export function useWizState_INTERNAL() {
  const { actions, getInitialState, reducer } = wizSlice
  const [state, dispatch] = useReducer(
    reducer,
    undefined,
    getInitialState
  ) as ReduxStateTuple<WizState>

  const blankFieldsRef = useRef<BlankFields>()

  const pubContext = usePublishContext()
  const { contentId, curriculum, packetData, page } = pubContext

  // console.log('[pubContext (useWizState)]', pubContext)
  // todo: ugh this is a mess, but want to be able to stub as if new
  const _isStubNew =
    pubContext.__wizStub__ && pubContext._contentIdOrNew === 'new'
  const initialValues = contentToFormik(
    _isStubNew ? null : packetData?.focusPacket?.content
  )

  const formik = useFormik<ContentFormSchema>({
    initialValues,
    onSubmit: (values) => {
      console.error(`not using formik.submit at the moment...`)
    },
    validationSchema: validationSchemas[state.type],
    validateOnMount: false, // `true` infinite loops for some reason
  })

  // nicer type experience...
  // redux has a bindActionCreators function that would wrap all of these, but it seems to break types
  const dispatchShallowMerge = useStaticFn((state: Partial<WizState>) => {
    dispatch(actions._shallowMerge({ state }))
  })

  const goToStep = useStaticFn((step: StepSpec) =>
    dispatchShallowMerge({ curStep: step, curStepError: null })
  )

  const setRawImages = useStaticFn((images: Blob[]) => {
    dispatchShallowMerge({ rawImages: images })
  })

  const setBlankImages = useStaticFn((images: Blob[]) => {
    dispatchShallowMerge({ _capturedBlanks: images })
  })

  const setCurStepError = useStaticFn((error: string) => {
    dispatchShallowMerge({ curStepError: error })
  })

  /////////////////////////
  // listen for print fields change
  // this resets generated images if there are changes
  /////////////////////////
  useHandleBlankFieldChanges(
    blankFieldsRef,
    formik,
    state,
    dispatchShallowMerge
  )
  const printFields = checkPrintFields(formik)

  /////////////////////////
  // reconcile saved vs. opened
  /////////////////////////
  const openedPdfUrl = useBufferToUrl(state.srcBuf)
  const pdfUrl = state._savedPdfUrl ?? openedPdfUrl
  const blankPdfUrl = useBlobUrl(state._blanksPdfBlob)
  const rawImageUrls = useBlobsToUrls(state.rawImages)
  const blankImageUrls =
    useBlobsToUrls(state._capturedBlanks) ?? state._savedBlanks

  const { curPrereq, error } = getPrereqQueue(state)
  ;(curPrereq || error) &&
    console.log({ curPrereq, error, curStep: state.curStep.key })
  // Set up props if capturing
  let isCapturing = curPrereq === 'captureBlanks' || curPrereq === 'captureRaw'
  let captureProps: CaptureProps
  if (isCapturing) {
    captureProps = {
      maxPage:
        curPrereq === 'captureRaw'
          ? state.srcDoc.getPageCount()
          : formik.values.pages.length,
      onCaptured: curPrereq === 'captureRaw' ? setRawImages : setBlankImages,
      pdfUrl: curPrereq === 'captureRaw' ? pdfUrl : blankPdfUrl,
    }
  }

  // rough in fetching pdf
  useFetchPdf(
    { contentId: state.contentId, url: state._savedPdfUrl },
    curPrereq === 'fetchPdf',
    (qResult) => {
      if (qResult.status === 'success') {
        const { srcBuf, srcDoc } = qResult.data
        dispatchShallowMerge({ srcBuf, srcDoc })
      } else if (qResult.status === 'error') {
        // todo: probably send this to pubContext?
        console.error(qResult.error)
        //dispatch(actions.error(qResult.error))
      }
    }
  )

  // rough in generate blanks
  const pdfPreviewResult = usePdfPreview({
    ctx: {
      contentId: state.contentId,
      curriculum,
      formik,
      srcDoc: state.srcDoc,
    },
    disabled: curPrereq !== 'generateBlanks',
  })

  // similar to useGetPacketEntryData, may be better to use onSettled...
  const havePdfPreviewResult =
    curPrereq === 'generateBlanks' && pdfPreviewResult.isSuccess
  useLayoutEffect(() => {
    if (havePdfPreviewResult) {
      dispatchShallowMerge({ _blanksPdfBlob: pdfPreviewResult.data })
    }
  }, [havePdfPreviewResult])

  /////////////////////////
  // Post-async derived values
  /////////////////////////
  const { isPrinted, radioactive: alreadyRadioactive } = getAggPrintState(
    packetData?.packets
  )
  const willBeRadioactive =
    isPrinted && (printFields.dirty || alreadyRadioactive)

  //////////////////////////////
  // Calculate steps and wizard
  //////////////////////////////
  const { getFieldMeta, isValid, submitForm } = formik
  const { curStep, curStepError, isNew } = state

  // Get list of steps (which can vary based on values)
  const stepList = resolveSteps(formik.values.type, state.type)

  // Keep track of step index
  let curIdx = stepList.findIndex((p) => p === curStep)
  const isSubmitStep = curIdx === stepList.length - 1

  const curStepMeta = getFieldMeta(curStep.key)

  const canNext =
    !curStep.noWizardNav && !isSubmitStep && !curStepMeta.error && !curStepError
  const canPrev =
    !curStep.noWizardNav &&
    !curStep.noPrev &&
    curIdx > 0 &&
    !stepList[curIdx - 1].getLocked?.({ isNew, isPast: true })
  const canSubmit = isSubmitStep && !curStepError && isValid

  const goNext = () =>
    canNext && (isSubmitStep ? submitForm() : goToStep(stepList[curIdx + 1]))
  const goPrev = () => canPrev && goToStep(stepList[curIdx - 1])

  //////////////////////////////
  // Initialize
  //////////////////////////////
  useLayoutEffect(() => {
    // ugh this seems bad...but i need formik to treat these values as dirty
    if (_isStubNew) {
      formik.setValues(
        contentToFormik(pubContext.packetData?.focusPacket?.content)
      )
    }
    // reset if page or contentId change
    // todo: is this safe? (obviously not idempotent) or do I need to store refs?
    dispatch(actions.reset(pubContext))

    setTimeout(() => {
      // validate form here to avoid formik validateOnMount explosion
      // todo: for some reason it's not working unless i do it async...
      formik.validateForm()
    }, 0)
  }, [page, contentId])

  return {
    ...state,
    canNext,
    canPrev,
    canSubmit,
    captureProps,
    curIdx,
    curStep,
    goNext,
    goPrev,
    isSubmitStep,
    imageUrls: {
      blank: blankImageUrls,
      raw: rawImageUrls,
    },
    formik,
    isInit: !!state.type,
    isLoading: !state.type || !!curPrereq,
    isPrinted,
    printFields,
    setCurStepError,
    stepList,
    willBeRadioactive,
  }
}

/**
 * Checks print fields for dirty/valid
 */
function checkPrintFields(formik: WizFormik) {
  const printDirtyFields: (keyof ContentFormSchema)[] = [
    'name',
    'pages',
    'parts',
    'style',
    'type',
  ]

  // dirty just checks value vs. initial value
  const dirty = printDirtyFields.some((p) => {
    let { initialValue, value } = formik.getFieldMeta(p)
    return !isEqual(value, initialValue)
  })

  const valid = printDirtyFields.some((p) => !formik.getFieldMeta(p).error)
  return { dirty, valid }
}

type BlankFields = Pick<
  ContentFormSchema,
  'name' | 'parts' | 'style' | 'type'
> & { length: number }

/**
 * Ugh this is a disaster...
 * Extracts values that blank generation depends on
 */
function extractBlanksFields(values: ContentFormSchema): BlankFields {
  const blanksDirtyFields: (keyof ContentFormSchema)[] = [
    'name',
    'parts.0' as any, // blanks doesn't care about subsequent parts
    'style',
    'type',
  ]
  let subset = pick(values, blanksDirtyFields)
  // need to include page length
  // todo: this feels react-query's query-key-ish...maybe migrate there?
  return { ...subset, length: values.pages.length }
}

/**
 * Formik doesn't have a nice way to monitor changes to a subset of fields
 * I could instead try wrapping formik's various 'set' methods, but that seems brittle
 * Maybe worth factoring out the 'listen' mechanism...
 * But feels inefficient to do the compare on every render
 * ...
 * Anyway calculate dirty-ness, and reset all things blanks if they've changed
 * @returns `true` if the print fields are dirty
 */
function useHandleBlankFieldChanges(
  ref: MutableRefObject<BlankFields>,
  formik: WizFormik,
  state: WizState,
  dispatchShallowMerge: (state: WizState) => void
) {
  const subset = extractBlanksFields(formik.values as ContentFormSchema)

  // changed has to check against the last known value
  let haveChanged = !isEqual(subset, ref.current)

  useLayoutEffect(() => {
    if (haveChanged) {
      ref.current = subset

      // check whether there are any saved blanks to reset (avoid unnecessary dispatches)
      let hasBlanks =
        state._blanksPdfBlob || state._capturedBlanks || state._savedBlanks

      if (hasBlanks) {
        console.log('uHBFC', subset, ref.current)
        // reset everything blanks
        dispatchShallowMerge({
          _blanksPdfBlob: null,
          _capturedBlanks: null,
          _savedBlanks: null,
        })
      }
    }
  })
}
