import { useRouter } from '@paper/route'
import type { SectionAxisItem } from '@paper/schema'
import { RD_SW } from '~src/routes'
import { FilterPicker, FilterPickerProps } from './filterPicker'

/**
 * Section Picker wired up to f_sectionId
 */
export function SWSectionPicker(
  props: Omit<
    FilterPickerProps<SectionAxisItem>,
    'normalizer' | 'filterType' | 'itemPlural' | 'onChange' | 'selectedId'
  >
) {
  const { dispatchStay, routeData } = useRouter<RD_SW>()
  return (
    <FilterPicker
      filterType="startsWith"
      getId={(section) => section.sectionId}
      getLabel={(section) => section.name}
      itemPlural="sections"
      onChange={(sectionId) => dispatchStay({ f_sectionId: sectionId })}
      selectedId={routeData.f_sectionId}
      {...props}
    />
  )
}
