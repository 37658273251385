import { IconButton, IconButtonProps, useClipboard } from '@chakra-ui/react'
import { IcoCheckmark, IcoCopy } from '@paper/icons'
import { forwardRef } from 'react'

type CopyButtonProps = { value: string } & Omit<
  IconButtonProps,
  'icon' | 'onClick'
>

/**
 * Copies `props.value` to clipboard on click
 */
export const CopyButton = forwardRef<HTMLButtonElement, CopyButtonProps>(
  function CopyButton(props, ref) {
    const { value, ...btnProps } = props
    const { onCopy, hasCopied } = useClipboard(value)
    const CopyIcon = hasCopied ? IcoCheckmark : IcoCopy
    return (
      <IconButton
        ref={ref}
        icon={<CopyIcon />}
        isRound={true}
        onClick={onCopy}
        {...btnProps}
      />
    )
  }
)
