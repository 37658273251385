import { BoxProps, Button, HStack, Icon, Tooltip } from '@chakra-ui/react'
import { IcoTrafficLight } from '@paper/icons'
import { BigIconFontSize } from '@paper/styles'
import { Fragment } from 'react'
import { TagInstruction, Tags } from '~src/blocks/tags'
import { Separator } from '~src/components'
import { useTagGridContext } from './tagGridProvider'

export function TagGridStuff() {
  const { canUserTag, isCurrentCellTaggable, isTagModeActive, toggleTagMode } =
    useTagGridContext()

  // todo: need better instructions!
  // todo: this is flashing when going from a ticket to an assessment, and i'm not sure why!
  // (cont) it's only noticeable when i change the opacity
  // (cont) (of course that doesn't mean that's the cause)
  // (cont) component doesn't appear to be unmounting or having a bad intermediate state
  // (cont) also repros regardless of tooltip, animation, filter, etc.
  // (cont) setting opacity via filter seems to hide it
  // (cont) but presumably the underlying issue is still there :/

  // todo: is it going to be confusing when the button isn't here?
  if (!canUserTag) {
    return null
  }

  // todo: dimming when can't tag current cell
  const isDimmed = !isCurrentCellTaggable && isTagModeActive
  const dimProps: BoxProps = {
    filter: `grayscale(${isDimmed ? 1 : 0}) opacity(${isDimmed ? 0.3 : 1})`,
  }

  return (
    <HStack
      data-cy="tag-instruction-area"
      gap={2}
      justifyContent="flex-end"
      justifySelf="end"
      {...dimProps}
      transitionProperty="filter"
      transitionDuration=".35s"
      transitionTimingFunction="ease"
    >
      <Tooltip
        // todo: tooltip doesn't render when button is disabled...
        label={`${isTagModeActive ? 'Exit' : 'Enter'} tag mode`}
        placement="left"
      >
        <Button
          aria-label="Toggle tagging mode"
          colorScheme={isTagModeActive ? 'blue' : null}
          data-cy="tag-toggle"
          isDisabled={!isCurrentCellTaggable && !isTagModeActive}
          height="48px"
          onClick={toggleTagMode}
          variant="ghost"
          width="48px"
        >
          <Icon as={IcoTrafficLight} fontSize={BigIconFontSize} />
        </Button>
      </Tooltip>
      {isTagModeActive && (
        // todo: would be nice to animate at some point
        <Tooltip
          label={
            isCurrentCellTaggable
              ? 'Use the keyboard to tag your ticket'
              : `This packet isn't taggable`
          }
        >
          <HStack
            data-cy="tag-instructions"
            color="white"
            gap={2}
            overflow="hidden"
            userSelect="none"
          >
            {Tags.map((meta) => (
              <Fragment key={meta.label}>
                <Separator orientation="vertical" />
                <TagInstruction
                  fontSize="sm"
                  key={meta.label}
                  meta={meta}
                  showKey={true}
                  size="md"
                  wrap={true}
                />
              </Fragment>
            ))}
          </HStack>
        </Tooltip>
      )}
    </HStack>
  )
}
