export { C } from './mongo/collections'
export type { CM } from './mongo/collections'
export * from './paths/internalApi'
export * from './paths/s3'
export * from './types/auth'
export * from './types/illuminate'
export * from './types/location'
export * from './types/packetmeta'
export * from './types/pdf'
export * from './types/print'
export * from './types/school'
export * from './types/staging'
export * from './types/teacher'
export * from './types/types.imgp'
export * from './types/types.packet'
export * from './types/types.pin'
export * from './types/types.std'
export * from './types/types.xpacket'
export { Api, V }

// TODO: @paper/api babel-loader apparently doesn't support `export * as` yet
import * as Api from './api'
import * as V from './validation'
