import {
  Margin,
  Packetmeta,
  PacketType,
  Padding,
  PageItem,
  Passage,
  Pos,
  Question,
  Variant
} from '@paper/schema'
import { makeOptions, parseLabel } from '@paper/utils/questionUtils'
import times from 'lodash/times'
import { contentToFormik, SB } from '../wizard/entryBaseData'

export { ContentFormSchema, SB } from '../wizard/entryBaseData'

type PacketsStubProps = {
  contentId: string
  curriculumId?: string
  curStepBit: SB
  isNew: boolean
  omitAnswerKey?: boolean
  pdfLength: number
  type: PacketType
}

export function getPacketsStub(props: PacketsStubProps) {
  const {
    contentId,
    curriculumId,
    curStepBit,
    isNew,
    omitAnswerKey,
    pdfLength,
    type,
  } = props
  const isTicket = type === 'ticket'
  const pageCount = isTicket ? 1 : pdfLength

  // initialize to empty
  let content = contentToFormik()

  // construct based on which step we're on
  // that is, if testing a step (and not new),
  // we want everything prior to that step filled out
  let shouldStub = (candidateStep: SB) => candidateStep < curStepBit || !isNew

  if (shouldStub(SB.PACKET_TYPE)) {
    content.pages = times(pageCount, () => ({ items: [] }))
    content.type = type
    // the ticket is at index 2, assessments are initialized to a single part
    content.parts = isTicket ? [2] : [0]
  }
  if (shouldStub(SB.PACKET_NAME)) {
    content.name = `Dev Stub ${type[0].toUpperCase()}`
  }
  if (shouldStub(SB.QR)) {
    content.style = {
      margin: Margin.qtr,
      padding: Padding.eighth,
      position: Pos.tr,
      variant: Variant.compact,
    }
  }
  if (type === 'assessment') {
    if (shouldStub(SB.PARTS)) {
      content.parts = [0, 2, 4]
    }
    if (!omitAnswerKey) {
      let { passages, questions } = getPQs()
      if (shouldStub(SB.PASSAGES)) {
        content.passages = passages
      }
      if (shouldStub(SB.QUESTIONS)) {
        content.questions = questions
      }
      if (shouldStub(SB.PAGES)) {
        // hardcoding something for now
        content.pages[0].items.push({ id: passages[0].id, type: 'passage' })
        content.pages[1].items.push(
          { id: passages[0].id, type: 'passage' },
          { id: questions[0].id, type: 'question' }
        )
        content.pages[2].items.push({ id: passages[1].id, type: 'passage' })
        content.pages[3].items.push(
          ...[1, 2].map(
            (idx): PageItem => ({ id: questions[idx].id, type: 'question' })
          )
        )
        content.pages[3].items.push({ id: questions[3].id, type: 'question'})
      }
    }
  } else if (type === 'ticket') {
    if (shouldStub(SB.STDS)) {
      // todo:
    }
  }

  // a bunch of scenarios in here (printed, published, etc.)
  let lastUpdate = { time: new Date().valueOf(), user: 'stub@ponder.co' }
  let year = 2022
  let packetmeta: Packetmeta = {
    _linkExternal: 'https://www.example.com',
    _illuminate: 'Math.8.Sth.Stub.Pad.ding',
    _printed: { first: new Date(2022, 1, 1).valueOf() },
    _pub: 'published',
    _updates: {
      answerKey: lastUpdate,
      crossPacket: lastUpdate,
      last: lastUpdate,
      pdf: lastUpdate,
      publish: lastUpdate,
      stds: lastUpdate,
    },
    _uploaded: true,
    // todo: should parse off non-content fields
    // todo: images aren't directly used with the new api, so maybe get rid of them?
    content: { images: [], ...content },
    contentId,
    curriculum: {
      id: curriculumId ?? 'stub',
      name: curriculumId
        ? `${curriculumId} (stubbed)`
        : `Stubbed Curriculum ${year} Radioactive`,
      subject: 'Math',
      level: 'Grade 8',
      years: [year],
    },
    id: 'stub',
    number: '1.1',
    src: null,
  }
  return { packets: [packetmeta], formValues: packetmeta.content }
}

function getPQs() {
  let { passages, passageIds } = stubPassages([
    {
      name: 'Passage Awesome: A study of how longer passage names render',
      genre: 'Narrative: Memoir/Non-Fiction',
      lexile: 'abc',
    },
    {
      name: `Surely you're joking`,
      genre: 'Narrative: Memoir/Non-Fiction',
      lexile: 'xyz',
    },
  ])

  let questions = stubQuestions(
    times(1, (i): Partial<Question>[] => [
      {
        correct: [0, 1],
        label: '1',
        labelMaj: '1',
        options: makeOptions(4, 'abc'),
        passageIds: passageIds.slice(0, 1),
        type: 'MC',
      },
      {
        correct: [],
        label: '2A',
        labelMaj: '2',
        labelMin: 'A',
        options: makeOptions(3, '012'),
        passageIds: passageIds,
        type: 'OER',
      },
      {
        correct: [],
        label: '2B',
        labelMaj: '2',
        labelMin: 'B',
        options: makeOptions(3, '012'),
        passageIds: passageIds,
        type: 'OER',
      },
      {
        correct: [],
        label: '3',
        labelMaj: '3',
        options: [],
        passageIds: passageIds.slice(1),
        type: 'GRID-IN'
      }
    ]).flat(),
    passageIds
  )

  return { passages, questions }
}

function stubPassages(template: Partial<Passage>[]) {
  let passages = template.map((partial, idx) => {
    return {
      id: `stub-p-${idx}`,
      name: `Passage ${idx}`,
      ...partial,
    }
  })
  return { passages, passageIds: passages.map((p) => p.id) }
}

function stubQuestions(template: Partial<Question>[], passageIds: string[]) {
  let lastLabel = '0'
  let questions = template.map((partial, idx): Question => {
    let type = partial.type ?? (idx >= 5 ? 'OER' : 'MC')
    let optionsLength = type === 'OER' ? 3 : 4
    let q: Question = {
      correct: type === 'OER' ? [] : [],
      id: `stub-q-${idx}`,
      ...parseLabel(lastLabel, 'increment'),
      options: makeOptions(optionsLength, type === 'OER' ? '012' : 'abc'),
      passageIds,
      type,
      ...partial,
    }
    lastLabel = q.label
    return q
  })
  return questions
}
