import { FileLocation } from './location'
import { LastUpdate } from './packetmeta'

export type ScanStatus =
  | 'error'
  | 'manual'
  | 'no-qr'
  | 'pending'
  | 'success'
  | 'wrong-location'

export type ScanImageEmbed = {
  _updates?: { manual: LastUpdate }
  data?: Partial<{
    packetId: string
    pageIndex: number
    teacherId: string
    xpacketId: string
    xpageId: string
  }>
  id: string
  loc?: FileLocation
  status: ScanStatus
}

/** Result of imgp processing one image */
export type ImgpOneResult = Omit<ScanImageEmbed, '_updates' | 'id'>

/** Abbreviated ScanImage for scanlog view api */
export type ScanImageAbbr = Omit<ScanImageEmbed, 'loc'> & { key: string }

export type ScanBatch = {
  _ts: any
  id: string
  /** process start date */
  processDate: number
  /** process time */
  processMs: number
  /** received/queued */
  receiveDate: number
  /** date in the manifest file */
  scanDate: number
  /** processing complete */
  settledDate: number
  scans: ScanImageEmbed[]
  scanUser: string
  scanUserId: string
  sourceName: string
  year: number
}

export type CrunchBatch = Omit<ScanBatch, 'scans'> & {
  chunks: StatusChunk[]
  packetIds: string[]
  stats: { [key in ScanStatus]?: number }
  teacherIds: string[]
  xpacketIds: string[]
}

export type StatusChunk = {
  items: ScanImageAbbr[]
  startIndex: number
  status: ScanImageEmbed['status']
}
