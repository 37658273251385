import { BoxProps, Button } from '@chakra-ui/react'
import { useRouter } from '@paper/route'
import { DirPacket, StrId } from '@paper/schema'
import { Fragment } from 'react'
import { ComboBox, HStack, Txt, VStack } from '~src/components'
import { RD_SW } from '~src/routes'
import { useStaticFn } from '../list/listCallbacks'
import { MenuScrollContainer } from '../menu'
import { usePickerBase, useTeacherPicker } from '../pickers'
import { useSWContext } from '../swContext'
import { useTeacherContext } from '../teacherAirlock'

const EMPTY_SET = new Set<any>()

type MenuPacketPickerProps = { packets: DirPacket[] }

const sharedShellProps: BoxProps = {
  as: VStack,
  alignItems: 'stretch',
  gap: '.75rem',
  overflow: 'hidden',
  p: '1px',
  width: '100%',
}

export function SWMenuPacketPicker(props: MenuPacketPickerProps) {
  const { dispatchStay, routeData } = useRouter<RD_SW>()
  const { packets } = props

  // todo: is this already implemented for the packet list filter?
  const packetPickerProps = usePickerBase(
    {
      data: packets?.filter((p) => p.id !== routeData.packetId),
      isLoading: false,
    },
    {
      filterer: (filters, item, inputValue) => {
        return (
          filters.startsWith(item.name, inputValue) ||
          filters.startsWith(item.number, inputValue)
        )
      },
      selector: (item, value) => item.id === value,
    }
  )

  return (
    <MenuScrollContainer>
      <ComboBox.Root
        {...packetPickerProps}
        isOpen={true}
        itemToString={(item) => item.name}
        onSelect={(item) => dispatchStay({ packetId: item.id })}
        renderItem={(item) => (
          <HStack fontSize="sm" gap={4}>
            <Txt as="span" fontFamily="mono">
              {item.number}
            </Txt>
            <Txt as="span">{item.name}</Txt>
          </HStack>
        )}
        round={true}
        size="xs"
      >
        <ComboBox.Shell {...sharedShellProps}>
          <ComboBox.Label>Switch packets</ComboBox.Label>
          <ComboBox.Input placeholder="Filter by name or number" />
          <ComboBox.List pt={0} pb={1} type="permanent" />
        </ComboBox.Shell>
      </ComboBox.Root>
    </MenuScrollContainer>
  )
}

export function SWMenuTeacherPicker() {
  const { dispatchStay } = useRouter<RD_SW>()
  const tc = useTeacherContext()
  const swc = useSWContext()
  const { teacher } = tc

  const showBlankOption = teacher && !swc.noBlankSwitch

  const teacherPickerProps = useTeacherPicker({
    excludeSet: new Set([teacher?.id]),
    includeSet: tc.curriculumRecord?.teacherIds ?? EMPTY_SET,
  })

  const onSelectTeacher = useStaticFn((item: StrId | any) => {
    // todo: airlock filters?
    dispatchStay({ f_sectionId: null, teacherId: item?.id })
  })

  return (
    <MenuScrollContainer>
      <ComboBox.Root
        {...teacherPickerProps}
        isOpen={true}
        onSelect={onSelectTeacher}
        round={true}
        size="xs"
      >
        <ComboBox.Shell {...sharedShellProps}>
          <ComboBox.Label>
            Switch to{' '}
            {showBlankOption && (
              <Fragment>
                the{' '}
                <Button
                  borderRadius="100px"
                  mt="-2px"
                  onClick={onSelectTeacher}
                  size="xs"
                >
                  blank images
                </Button>{' '}
                or{' '}
              </Fragment>
            )}
            a teacher with this curriculum
          </ComboBox.Label>
          <ComboBox.Input placeholder="Filter" />
          <ComboBox.List pt={0} pb={1} type="permanent" />
        </ComboBox.Shell>
      </ComboBox.Root>
    </MenuScrollContainer>
  )
}
