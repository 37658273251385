import { formatRelative } from 'date-fns'

export const title = process.env.NODE_ENV === 'production' ? 'Paper' : ':2345'

export const makeTitle = (...segments: string[]) => {
  // add prefix if empty or dev
  if (segments.length === 0 || process.env.NODE_ENV !== 'production') {
    segments = [title, ...segments]
  }
  return segments.join(' | ')
}

export const pluralS = (label: string, amt: number) =>
  amt === 1 ? label : `${label}s`

export const formatUnits = (amt: number, label: string) =>
  `${isNaN(amt) ? '??' : amt} ${pluralS(label, amt)}`

export const formatPercent = (num: number, den: number, ifNan?: string) => {
  if (den === 0) {
    return ifNan
  }
  const pct = `${Math.round((100 * num) / den)}%`
  // Handle %s that round to 100
  if (pct === '100%' && den - num > Number.EPSILON) {
    return '99%+'
  }
  return pct
}

/**
 * Returns date-fns `formatRelative` with 'last' and 'at' removed for a more compact string
 * When it's obvious from context that the date is in the past, there's no need for 'last'
 * @param date Date to compare
 * @param now Optionally specify date compared against
 */
export const formatPastVsNow = (date: number | Date, now?: number | Date) => {
  return !date
    ? undefined
    : formatRelative(date, now ?? new Date())
        .replace('last ', '')
        .replace(' at', '')
}
