import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Link,
  List,
  ListItem,
  useDisclosure,
} from '@chakra-ui/react'
import { IcoAdd, IcoLink } from '@paper/icons'
import { useLink, useRouter } from '@paper/route'
import { School, Teacher } from '@paper/schema'
import { getFullName } from '@paper/utils'
import { groupBy, orderBy } from 'lodash'
import { useRef } from 'react'
import { useCurriculumContext } from '~src/blocks/curriculumAirlock'
import { PacketTable } from '~src/blocks/packetTable'
import { curriculumPacketColumns } from '~src/blocks/packetTable/packetTableCols'
import { useCurriculaPicker } from '~src/blocks/pickers'
import { ComboBox, Txt } from '~src/components'
import { useDirectoryData } from '~src/data/data-directory'
import { RD_HomeCurriculum, Routes } from '~src/routes'
import { useGoToNew } from '../publish/utils'
import { HomePanel } from './homePanel'
import { PacketListFilters } from './packetListFilters'

export function CurriculumPanel() {
  const { curriculum } = useCurriculumContext()
  const { dispatchStay } = useRouter<RD_HomeCurriculum>()
  const curComboProps = useCurriculaPicker({ value: curriculum?.id })
  //console.log('<TeacherPanel/>')
  const goToNew = useGoToNew()

  return (
    <HomePanel
      pickers={
        <>
          <ComboBox
            {...curComboProps}
            caret={curComboProps.items.length > 1}
            inputTextAlign="center"
            onChange={(item) => dispatchStay({ curriculumId: item.id })}
            openIfNoSelection={true}
            placeholder="Select a curriculum"
            round={true}
            size="md"
            variant="filled"
            width="240px"
          />
        </>
      }
      filters={
        <>
          <PacketListFilters />
          {/* <Separator orientation="vertical" /> */}
          <TeacherDrawer />
          {goToNew && (
            <Button
              data-cy="btn-add-packet"
              borderRadius="5rem"
              leftIcon={<IcoAdd />}
              onClick={goToNew}
              pr={6}
            >
              Add
            </Button>
          )}
        </>
      }
      name={curriculum?.name ?? 'Curriculum'}
      table={<PacketTable columns={curriculumPacketColumns} />}
    />
  )
}

function TeaLink(props: Teacher) {
  const item = props
  const linkProps = useLink(
    Routes.home_teacher.mergeAction({ teacherId: props.id })
  )
  return <Link {...linkProps}>{getFullName(item)}</Link>
}

function SchLink(props: School) {
  const item = props
  const linkProps = useLink(
    Routes.home_school.mergeAction({ schoolId: props.id })
  )
  return (
    <Link fontSize="sm" {...linkProps}>
      {item.name}
    </Link>
  )
}

function TeacherDrawer() {
  const { curriculumRecord } = useCurriculumContext()

  // drawer
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  if (!curriculumRecord) {
    return null
  }

  return (
    <>
      <Button
        ref={btnRef}
        borderRadius="5rem"
        leftIcon={<IcoLink />}
        onClick={onOpen}
        size="sm"
      >
        Teachers
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontWeight={300}>
            Teacher links for{' '}
            <Txt fontFamily="mono" fontSize="lg">
              {curriculumRecord.item.name}
            </Txt>
          </DrawerHeader>
          <DrawerBody>
            <TeacherLinkList />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

function TeacherLinkList() {
  const { curriculumRecord } = useCurriculumContext()
  const dirData = useDirectoryData().data

  const teachers = Array.from(curriculumRecord.teacherIds).map((teacherId) => {
    const teacherRecord = dirData.teacher.map.get(teacherId)
    const schools = Array.from(teacherRecord.schoolIds).map(
      (schoolId) => dirData.school.map.get(schoolId).item
    )

    return { schools, teacher: teacherRecord.item }
  })

  const flattened = teachers.flatMap(({ schools, teacher }) =>
    schools.map((school) => ({ school, teacher }))
  )

  const schools = orderBy(
    Object.values(groupBy(flattened, ({ school }) => school.id)),
    (items) => items[0].school.name
  )

  return (
    <List overflowY="auto">
      {schools.map((teachers) => {
        const school = teachers[0].school // already grouped by school...
        teachers = orderBy(
          teachers,
          ({ teacher }) => teacher.firstName + teacher.lastName
        )
        return (
          <ListItem borderTopWidth="1px" key={school.id} py={4}>
            <SchLink {...school} />
            <List mt={1} mx={2}>
              {teachers.map(({ teacher }) => (
                <ListItem key={teacher.id} py={2}>
                  <TeaLink {...teacher} />
                </ListItem>
              ))}
            </List>
          </ListItem>
        )
      })}
    </List>
  )
}
