import { Box, BoxProps } from '@chakra-ui/react'
import { IcoCrumbRight } from '@paper/icons'
import { ReactNode } from 'react'
import { HStack, StackProps } from '.'

const HeaderHeight = 64
const HeaderGap = 48
const BoxShadow = 'rgba(67, 117, 151, 0.08) 0px 3px 7px 0px;'
const toPx = (value: number) => `${value}px`

type HeaderProps = {
  hideLogo?: boolean
  stackGap?: StackProps['gap']
  user?: ReactNode
} & BoxProps

export function BaseHeader(props: HeaderProps) {
  const { children, stackGap, hideLogo, user, ...boxProps } = props

  return (
    <Box
      alignItems="flex-start"
      as="header"
      boxShadow={BoxShadow}
      display="flex"
      flexShrink={0}
      height={toPx(HeaderHeight)}
      mb={toPx(HeaderGap)}
      minWidth="800px"
      position="relative"
      userSelect="none"
      {...boxProps}
    >
      <HStack gap={stackGap} height="100%" px={4} width="100%">
        {/* todo: logo */}
        {children}
        {user}
      </HStack>
    </Box>
  )
}

BaseHeader.BoxShadow = BoxShadow
BaseHeader.Gap = HeaderGap
BaseHeader.Height = HeaderHeight

BaseHeader.BoxShadow = BoxShadow
BaseHeader.Height = HeaderHeight
BaseHeader.Gap = HeaderGap

/**
 * Handy container for BaseHeader
 * <BaseHeader.Container>
 *   <BaseHeader>...</BaseHeader>
 *   <BaseHeader.Body>
 *     <MoreContent />
 *   </ BaseHeader.Body>
 * </BaseHeader.Container>
 */
const Container = (props: BoxProps) => {
  return <Box display="flex" flexDir="column" h="100%" {...props} />
}
BaseHeader.Container = Container

/**
 * Handy body for BaseHeader
 */
const Body = (props: BoxProps) => {
  return (
    <Box
      display="flex"
      flexGrow={1} // Fill the rest of `Container`
      overflowX="auto"
      overflowY="hidden"
      p={1}
      {...props}
    />
  )
}
BaseHeader.Body = Body

BaseHeader.Divider = <Box as={IcoCrumbRight} color="gray.300" fontSize="xl" />
