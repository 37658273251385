import { Question } from '@paper/schema'
import {
  makeOptions,
  ParsedLabel,
  parseLabel,
} from '@paper/utils/questionUtils'
import { pick } from 'lodash'
import { nanoid } from 'nanoid/non-secure'
import type { SingleQSchema } from '../formSingleQuestion'

type QToSchema = (props: {
  Q?: Question
  mode: 'increment' | 'parse'
  questions: Question[]
}) => SingleQSchema

export const loadQ: QToSchema = ({ Q, mode, questions }) => {
  // if Q isn't defined, we use the last question to seed the "loaded Q"
  // because in most cases, you'll be entering the questions in order
  Q = Q ?? questions[questions.length - 1]

  // Parse label
  let labelParts: ParsedLabel
  try {
    labelParts = parseLabel(Q?.label || '0', mode)
  } catch (err) {
    // todo: i don't think parsing can fail anymore?
    // fallback to lastQ values
    labelParts = pick(Q, ['label', 'labelMaj', 'labelMin'])
  }

  // Use lastQ or default
  const _optionCount = Q?.options.length ?? 4
  // TODO: Should I store this on Q? Currently _optionAlphabet won't always be defined
  // TODO: The reason options is an array was because someday we might associate other metadata
  // TODO: e.g. why it was the wrong answer, the value of the answer
  // TODO: Full support would include decoupling the value of the answer from its label & position
  const _optionAlphabet = Q?.options[0]?.label === '0' ? '012' : 'abc' ?? 'abc'
  const options = Q?.options ?? makeOptions(_optionCount, _optionAlphabet)
  const type = Q?.type ?? 'MC'
  const passageIds = Q?.passageIds.slice() ?? []

  // Use lastQ only if existing mode (nextQ isn't likely to have the same answers)
  const id = mode === 'parse' ? Q.id : nanoid(11)
  const correct = mode === 'increment' ? [] : Q?.correct ?? []

  // Parse existing questions for label metadata
  const _labelMap = new Map<string, string>(
    questions.map((q) => [q.label, q.id])
  )

  return {
    _labelMap,
    _new: mode !== 'parse',
    _maxOptionCount: 8,
    _optionAlphabet,
    _optionCount,
    q: {
      id,
      correct,
      ...labelParts,
      options,
      passageIds,
      type,
    },
  }
}
