import {
  Button,
  Icon,
  List,
  ListIcon,
  ListItem,
  ThemingProps,
} from '@chakra-ui/react'
import type { ApiRosterGet } from '@paper/api'
import {
  IcoChevronRight,
  IcoDownload,
  IcoExclamationTriangle,
  IcoMultiparter,
  IcoPrint,
  IcoStaple,
} from '@paper/icons'
import type { PrintPacketGrain, PrintPacketOptions } from '@paper/pdf'
import { useRouter } from '@paper/route'
import { DirPacket, IAP } from '@paper/schema'
import { fetcher, getPartLengths, upToEven } from '@paper/utils'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import slugify from '@sindresorhus/slugify'
import { saveAs } from 'file-saver'
import { sumBy } from 'lodash'
import { ReactNode, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  GhostTag,
  HStack,
  Separator,
  TextStack,
  Txt,
  VStack,
} from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { FullPageMessage } from '~src/components/status/fullPage'
import { useLookupInDirData } from '~src/data/data-directory'
import { PrintPdfProps, _printPdf } from '~src/data/_printPdf'
import { Routes } from '~src/routes'
import { bytesToBlob } from '~src/utils/blob'
import { formatUnits, pluralS } from '~src/utils/messages'
import rollbar from '~src/utils/rollbar'
import { MenuBody, MenuItem, MenuSeparator, useMenu } from '../menu'
import { MISupport } from '../miSupport'
import { useUser } from '../userProvider'

const UNNAMED_CT = 3

export const expandeeBtnProps: ThemingProps<'Button'> = {
  size: 'xs',
  variant: 'outline',
}

type Progress = { count: number; pdf: string; section: string }

const initProgress = (): Progress => ({
  count: undefined,
  pdf: ' ',
  section: ' ',
})

export function PrintDialog(item: DirPacket) {
  let { menuButtonProps, onOpenChange, open, shroud } = useMenu({
    caret: false,
    defaultOpen: false,
    shroud: true,
  })

  // todo: goofy api, but stopgap to give some sense that something's happening
  const [progress, onProgress] = useState<Progress>(initProgress)
  // todo: aaah it gets worse!
  const [progressPdfCount, setProgressPdfCount] = useState<number>()

  ////////////////////
  // get sections
  ////////////////////
  const { curriculum, teacher } = useLookupInDirData(item)

  const { fetchAs } = useUser()
  const qResult = useQuery(
    [
      'roster',
      {
        curriculumId: curriculum.id,
        teacherId: teacher.id,
      },
    ],
    async () => {
      const searchParams: ApiRosterGet['query'] = {
        curriculumId: curriculum.id,
        teacherId: teacher.id,
      }

      let sections = await fetchAs
        .get(IAP.roster.get, { searchParams })
        .json<ApiRosterGet['result']>()

      return {
        sections,
        studentCount: sumBy(sections, (sec) => sec.students.length),
      }
    }
  )

  const { pages, parts } = item
  const partLengths = getPartLengths(item)
  const studentCount = qResult.data?.studentCount
  const sectionCount = qResult.data?.sections.length
  const studentPlusUnnamedCount = studentCount + UNNAMED_CT * sectionCount

  const isStapled = partLengths.some((partLength) => partLength > 2)
  const isMultiPart = parts.length > 1
  const isComplicated = isStapled || isMultiPart

  const fact = (fact: ReactNode) => (
    <ListItem>
      <ListIcon as={IcoChevronRight} />
      {fact}
    </ListItem>
  )

  const redFact = (fact: ReactNode) => (
    <ListItem // todo: configurable... ml={2}>
      lineHeight="18px !important" // todo: configurable...
      pt="7px"
    >
      <ListIcon as={IcoExclamationTriangle} color="red.600" />
      {fact}
    </ListItem>
  )

  // https://web.dev/file-system-access/
  const canSaveToDisk = !!window.showDirectoryPicker

  const mutationResult = usePrintPdf({
    isMultiPart,
    onProgress,
    props: {
      blankCount: UNNAMED_CT,
      packetId: item.id,
      sections: qResult.data?.sections,
      teacher,
    },
  })

  const copyCenterWarning = isStapled && (
    <>
      This option requires stapling{' '}
      <Txt as="span" fontFamily="mono">
        every N pages
      </Txt>
      , which is not supported by most printers, but can generally be handled by
      copy centers.
    </>
  )

  let printSections: (Omit<PrintSectionProps, 'isMultiPart' | 'onClick'> & {
    grain: PrintPacketGrain
  })[] = [
    // per teacher
    {
      disabled: isMultiPart && !canSaveToDisk,
      grain: 'teacher',
      pageCounts: partLengths.map(
        (partLength) => upToEven(partLength) * studentPlusUnnamedCount
      ),
      pdfCount: parts.length,
      title: isMultiPart ? '1 pdf per part' : isStapled ? '1 pdf' : null,
      warning: copyCenterWarning,
    },
    // todo: i mistakenly thought we supported this :)
    // // per section
    // {
    //   disabled: !canSaveToDisk,
    //   grain: 'section',
    //   pageCounts: [], // todo: this doesn't fit into the paradigm i had...
    //   // qResult.data.sections?.flatMap(({ students }) =>
    //   // segments.map(
    //   //   ([, segLength]) => (students.length + UNNAMED_CT) * segLength
    //   // ))
    //   pdfCount: parts.length * sectionCount,
    //   pdfCountAnnot: isMultiPart && `${parts.length} per section`,
    //   title: `1 pdf ${isMultiPart ? 'per part, ' : ''} per section`,
    //   warning: copyCenterWarning,
    // },
    // per student
    isStapled && {
      disabled: !canSaveToDisk,
      grain: 'student',
      pageCounts: partLengths,
      pdfCount: studentPlusUnnamedCount * parts.length,
      pdfCountAnnot: isMultiPart && `${parts.length} per student`,
      title: `1 pdf ${isMultiPart ? 'per part, ' : ''} per student`,
      warning: `This option can be used with a tool that automatically sends a folder of files to the printer.`,
    },
  ]

  const makeOnClick = (grain: PrintPacketGrain) => async () => {
    onProgress(initProgress()) // reset
    setProgressPdfCount(printSections.find((ps) => ps.grain === grain).pdfCount) // todo: aaahh/cleanup
    await mutationResult.mutateAsync({ grain })
    onOpenChange(false)
  }

  const summarySeparator = (
    <Separator
      orientation="vertical"
      style={{ marginBlock: '.5rem', marginInline: '.25rem' }}
    />
  )

  let summaryInfo = (
    <List fontSize="xs" mx={4} py={2} sx={{ li: { lineHeight: '32px' } }}>
      {fact(
        qResult.data ? (
          <HStack display="inline-flex" gap={2}>
            {formatUnits(sectionCount, 'section')}
            {summarySeparator}
            {formatUnits(studentCount, 'student')}
            {summarySeparator}
            {UNNAMED_CT} unnamed per section
          </HStack>
        ) : (
          'Loading...'
        )
      )}
      {fact(
        <HStack display="inline-flex" gap={2}>
          {formatUnits(pages.length, 'page')}
          {isMultiPart && (
            <>
              {summarySeparator}
              <GhostTag
                color="yellow.500"
                label={`${parts.length} parts`}
                leftIcon={IcoMultiparter}
              />
            </>
          )}
          {isStapled && (
            <>
              {summarySeparator}
              <GhostTag
                color="yellow.500"
                label="Stapling"
                leftIcon={IcoStaple}
              />
            </>
          )}
        </HStack>
      )}
      {!canSaveToDisk &&
        isComplicated &&
        redFact(
          `Your browser doesn't support saving multiple files. Some options are only available in desktop Chrome.`
        )}
    </List>
  )

  return (
    <FullPageLoading
      loadMsg={
        <FullPageMessage.Text textAlign="center" width="400px">
          Generating PDFs
          <VStack fontFamily="mono" fontSize="sm">
            <Txt fontSize="lg">
              {progress.count ?? '-'} of {progressPdfCount ?? '??'}
            </Txt>
            {!progress.count && (
              <Txt fontFamily="body">
                Downloading source and generating manifest
              </Txt>
            )}
            <Txt>{progress.section}</Txt>
            <Txt>{progress.pdf}</Txt>
          </VStack>
        </FullPageMessage.Text>
      }
      qResult={mutationResult}
      type="transparent"
    >
      <DropdownMenu.Root modal={false} onOpenChange={onOpenChange} open={open}>
        <DropdownMenu.Trigger asChild>
          <Button
            data-cy="pdf-dialog-trigger"
            {...menuButtonProps}
            {...expandeeBtnProps}
            leftIcon={<IcoPrint />}
          >
            Generate {pluralS('PDF', parts.length)} for printing
          </Button>
        </DropdownMenu.Trigger>
        {shroud}
        <DropdownMenu.Content asChild sideOffset={8}>
          <MenuBody maxWidth="512px" minWidth="200px">
            {summaryInfo}
            <MenuSeparator />
            {printSections
              .filter((p) => p)
              .map(({ disabled, grain, ...rest }) => (
                <PrintSection
                  key={grain}
                  disabled={qResult.isLoading || disabled}
                  isMultiPart={isMultiPart}
                  onClick={makeOnClick(grain)}
                  {...rest}
                />
              ))}
            <MISupport />
          </MenuBody>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </FullPageLoading>
  )
}

// this may need to be more flexible, but avoiding copy/paste for now
type PrintSectionProps = {
  disabled?: boolean
  isMultiPart: boolean
  title?: ReactNode
  onClick: () => void
  pageCounts: number[]
  pdfCount: number
  pdfCountAnnot?: ReactNode
  warning: ReactNode
}

function PrintSection(props: PrintSectionProps) {
  const {
    disabled,
    isMultiPart,
    onClick,
    pageCounts,
    pdfCount,
    pdfCountAnnot,
    title,
    warning,
  } = props
  const pageCountsLabel = pageCounts
    .map((c) => formatUnits(c, 'page'))
    .join(', ')

  return (
    <>
      {title && (
        <List fontSize="xs" mx={4} py={3} spacing={4}>
          <ListItem fontSize="sm">{title}</ListItem>
        </List>
      )}
      <MenuItem
        alignItems="center"
        data-cy="download-pdf"
        disabled={disabled}
        display="flex"
        icon={<IcoDownload style={{ flexShrink: 0 }} />}
        onClick={onClick}
      >
        <TextStack flexShrink={0} minWidth={isMultiPart ? '150px' : null}>
          <TextStack.Top>
            {formatUnits(pdfCount, 'PDF')}{' '}
            {pdfCountAnnot && (
              <Txt as="span" fontSize="xs">
                {pdfCountAnnot}
              </Txt>
            )}
          </TextStack.Top>
          <TextStack.Bottom variant="loose">{pageCountsLabel}</TextStack.Bottom>
        </TextStack>
        {warning && (
          <>
            <Separator
              orientation="vertical"
              style={{ marginInline: '.5rem' }}
            />
            <Txt fontSize="xs" paddingInlineEnd={1}>
              <Icon
                as={IcoExclamationTriangle}
                color="red.600"
                role="presentation"
                mr={2}
                verticalAlign="text-bottom"
              />
              {warning}
            </Txt>
          </>
        )}
      </MenuItem>
      <MenuSeparator />
    </>
  )
}

type MutationProps = {
  grain: PrintPacketGrain
}

type UsePrintPdfIn = {
  isMultiPart: boolean
  onProgress?: (progress: Progress) => void
  props: PrintPdfProps
}

function usePrintPdf({
  isMultiPart,
  onProgress,
  props: outerProps,
}: UsePrintPdfIn) {
  // messy!
  const { fetchAs } = useUser()
  const queryClient = useQueryClient()
  const { currentRoute, routeData } = useRouter()
  // todo: messy, but can't deal
  const { school } = useLookupInDirData(routeData)

  const isTeacherView = currentRoute === Routes.home_teacher

  return useMutation(async (args: MutationProps) => {
    const { grain } = args

    let step = 'getting the output directory'

    const isSingleDownload =
      isTeacherView && !isMultiPart && grain === 'teacher'
    // prompt for directory on user input
    let rootDirHandle: FileSystemDirectoryHandle
    try {
      if (!isSingleDownload) {
        rootDirHandle = await window.showDirectoryPicker()
        // ask for permission here
        let permission = await rootDirHandle.requestPermission({
          mode: 'readwrite',
        })

        if (permission !== 'granted') {
          // todo: maybe show a toast?
          return
        }
      }
    } catch (error) {
      if (
        error instanceof DOMException &&
        error.stack === 'Error: The user aborted a request.'
      ) {
        console.log(error)
        return // maybe toast?
      } else {
        throw error
      }
    }

    try {
      // prep args, callback
      const printOptions: PrintPacketOptions = {
        grain,
        onPdf: async (
          bytes,
          { packetSnap, outputNumber, partNumber, xpackets }
        ) => {
          ////////////////////////////////////////
          // Get directory structure and filename
          ////////////////////////////////////////
          const isMultiPart = packetSnap.segments.length > 1
          const { section, student } = xpackets[0]
          const { teacher } = outerProps

          let dirParts: string[][] = [
            [slugify(packetSnap.number)],
            // todo: excluding date for now...
            //[formatDate(new Date(), `yyyy-MM-dd_HH'h'mm'm'ss's'`)], // date
            [slugify(school?.name ?? '')],
            [slugify(packetSnap.curriculum.id), slugify(packetSnap.name)], // packet
          ]
          let filenameParts: string[] = []

          const teacherLabel = slugify(teacher.email)
          const sectionLabel = slugify(section.name)
          const studentLabel = student
            ? [slugify(student.lastfirst), student.number]
            : ['_unnamed', sectionLabel, outputNumber.toString()]

          // single pdf for teacher, download
          if (isSingleDownload) {
            // todo: maybe unify this with FileSystem naming...
            // {teacher.lastName}_{packetNumber}_{packetName}.pdf
            filenameParts = [
              slugify(teacher.lastName),
              packetSnap.number,
              slugify(packetSnap.name),
            ]
          } else if (grain === 'teacher') {
            filenameParts = [teacherLabel]
          } else if (grain === 'section') {
            dirParts.push([teacherLabel])
            filenameParts = [sectionLabel]
          } else if (grain === 'student') {
            dirParts.push([teacherLabel], [sectionLabel])
            filenameParts = studentLabel
          }

          // Add partNumber for multi-parters
          if (isMultiPart) {
            filenameParts.push(`part${partNumber}`)
          }

          const filename = `${filenameParts.join('_')}.pdf`

          // Give an indication of progress
          onProgress?.({
            count: outputNumber,
            pdf: filename,
            section: sectionLabel,
          })

          ////////////////////////////////////////
          // Save
          ////////////////////////////////////////
          if (isSingleDownload) {
            // prompt download
            saveAs(bytesToBlob(bytes), filename)
          } else {
            step = 'creating directories'
            let dirHandle = rootDirHandle
            // create directories
            for (let dir of dirParts) {
              const dirName = dir.join('_')
              if (dirName) {
                dirHandle = await dirHandle.getDirectoryHandle(dirName, {
                  create: true,
                })
              }
            }
            // create file
            step = `saving ${filename}`
            const fh = await dirHandle.getFileHandle(filename, { create: true })
            const writable = await fh.createWritable()
            await writable.write(bytes)
            await writable.close()
          }
        },
      }

      // Call print
      await _printPdf(outerProps, printOptions, fetchAs, fetcher)

      // Invalidate packet list
      queryClient.invalidateQueries('dir/packets')
      queryClient.invalidateQueries('school/packetgroup')
    } catch (error) {
      error.friendly ??= `There was a problem ${step}`
      error.values ??= outerProps
      // Send to rollbar
      if (process.env.NODE_ENV === 'production ') {
        rollbar.error(error)
      }
      throw error
    }
  })
}
