import { IcoImageOverride, IcoRadioactive } from '@paper/icons'
import type { XpageSW } from '@paper/schema'
import { PktColors, Z } from '@paper/styles'
import { ReactNode } from 'react'
import { TooltippedIcon, TooltippedIconProps } from '~src/components'
import { formatPastVsNow } from '~src/utils/messages'

type CalloutProps = {
  as: TooltippedIconProps['as']
  color: TooltippedIconProps['color']
  callout: ReactNode
}

function Callout(props: CalloutProps) {
  const { callout, ...rest } = props
  return (
    <TooltippedIcon
      bg="rgb(0,0,0,.2)"
      borderRadius="50%"
      h="72px"
      p={4}
      fontSize="5xl"
      w="72px"
      zIndex={Z.tooltip}
      tooltipProps={{ label: callout, placement: 'left' }}
      {...rest}
    />
  )
}

export function RadioactiveCallout() {
  return (
    <Callout
      as={IcoRadioactive}
      callout={`Warning: This packet was edited after it was printed and may be in an inconsistent state.`}
      color={`${PktColors.Nuclear}.500`}
    />
  )
}

export function OverrideCallout(props: XpageSW) {
  if (!props._overrideType) {
    return null
  }

  let color: string
  let callout: ReactNode
  let by = `by ${props._overridenBy.user}, ${formatPastVsNow(
    props._overridenBy.time
  )}`

  /*
    todo:
    * better styling
    * maybe HoverCard? https://www.radix-ui.com/docs/primitives/components/hover-card
    * better explanations
      * e.g. the reason it's notable that the page was moved is that the printed student name presumably won't match
      * if the page namer supports undos, then moves should link there
      * scanlog should link to scanlog
  */

  switch (props._overrideType) {
    case 'fix':
      color = 'scanFixedGray'
      callout = `This image was assigned to this page via the scan log ${by}`
      break
    case 'move-in':
      color = 'cyan'
      callout = `This page was moved here from another packet ${by}`
      break
    case 'move-out':
      color = 'red'
      callout = `This page was moved to another packet ${by}`
      break
  }

  return (
    <Callout as={IcoImageOverride} callout={callout} color={`${color}.500`} />
  )
}
