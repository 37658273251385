import { IAP, WebappUser } from '@paper/schema'
import { createFetch, fetcher, Fetcher, getInternalUrl } from '@paper/utils'
import { createContext, useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { FullPageLoading } from '~src/components/status'
import { Public } from '~src/pages/public'
import { Telemetry } from '~src/telemetry'
import config from '~src/utils/config'
import rollbar from '~src/utils/rollbar'

const USER_QUERY_KEY = 'user_login'
const TOKEN_KEY = 'token'
const TOKEN_STORED_AT_INIT = localStorage.getItem(TOKEN_KEY)

type IUserContext = {
  fetchAs: Fetcher
  isInternal: boolean
  signOut(manual: boolean): void
  user: WebappUser
}
const UserContext = createContext<IUserContext>(undefined)
export const useUser = () => useContext(UserContext)

export function UserProvider({ children }) {
  const onUser = useSetUser()
  const tokenResult = useUserQuery()
  const context = tokenResult.data

  return (
    <FullPageLoading qResult={tokenResult}>
      {context ? (
        <UserContext.Provider value={context}>{children}</UserContext.Provider>
      ) : (
        <Public onUser={onUser} />
      )}
    </FullPageLoading>
  )
}

const signOut = (manual: boolean) => {
  localStorage.removeItem(TOKEN_KEY)
  rollbar.configure({ payload: { person: null } })
  // signOut functions by reloading the page
  if (manual) {
    // if signout is manual, send home
    window.location.href = '/'
  } else {
    // otherwise (e.g. expired token) reload the page
    window.location.reload()
  }
}

const useUserQuery = () => {
  const url = getInternalUrl(config.api.prefix, IAP.checktoken)
  const headers = { authorization: `Bearer ${TOKEN_STORED_AT_INIT}` }

  return useQuery(
    USER_QUERY_KEY,
    async (): Promise<IUserContext> => {
      // Check token if stored at init
      // If there's no token user gets set via the login process
      if (TOKEN_STORED_AT_INIT) {
        //console.log('**', url)
        try {
          const user: WebappUser = await fetcher.post(url, { headers }).json()
          return loadUserAndCreateContext(user)
        } catch (err) {
          // if the token fail, remove it
          localStorage.removeItem(TOKEN_KEY)
          // 401 is expected when the token is bad
          if (err.response?.status === 401) {
            return undefined
          }
          // Throw unexpected errors
          throw err
        }
      } else {
        // if no token, continue to login screen
        localStorage.removeItem(TOKEN_KEY)
        return undefined
      }
    },
    {
      enabled: !!TOKEN_STORED_AT_INIT, // don't run if no token
      staleTime: Infinity, // run once
    }
  )
}

const loadUserAndCreateContext = (user: WebappUser): IUserContext => {
  const { email, token } = user
  localStorage.setItem(TOKEN_KEY, token)
  rollbar.configure({ payload: { person: { id: email, email } } })
  Telemetry.setUser({ email })

  const fetchAs = createFetch({
    origin: config.api.prefix,
    bearer: user.token,
    on401: () => signOut(false),
  })

  const isInternal = user.domain === 'ponder.co' && user.roles.includes('admin')

  return { fetchAs, isInternal, signOut, user }
}

const useSetUser = () => {
  const queryClient = useQueryClient()
  return (user: WebappUser) => {
    // todo: Not sure if I'm misusing react-query (also, this was written prior to v3)
    queryClient.setQueryData(USER_QUERY_KEY, loadUserAndCreateContext(user))
  }
}
