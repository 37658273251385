import { Button } from '@chakra-ui/react'
import { IcoHome } from '@paper/icons'
import { useRouter } from '@paper/route'
import { Curriculum, Teacher } from '@paper/schema'
import { createContext, ReactNode, useContext, useEffect } from 'react'
import { FullPageLoading } from '~src/components/status'
import { DirRecord, useDirectoryData } from '~src/data/data-directory'
import { Routes } from '~src/routes'
import { Error404Page } from './errors'
import { getEAProps } from './errors/errorUtils'
import { useUser } from './userProvider'

type TeacherContext = {
  browsingAsSelf: boolean
  canPin: boolean
  canTag: boolean
  curriculum: Curriculum
  curriculumRecord: DirRecord<'curriculum'>
  teacher: Teacher
  teacherRecord: DirRecord<'teacher'>
}

const TeacherContext = createContext<TeacherContext>(null)
export const useTeacherContext = () => useContext(TeacherContext)

type TeacherAirlockProps = {
  behavior:
    | 'locked'
    | 'unlocked-clear-curriculum'
    | 'unlocked-dont-clear-curriculum'
  children: ReactNode
}

export function TeacherAirlock(props: TeacherAirlockProps) {
  const { behavior, children } = props

  const { dispatchRoute, dispatchStay, routeData } = useRouter<{
    curriculumId?: string
    teacherId?: string
  }>()

  let { curriculumId, teacherId } = routeData

  const { user, isInternal } = useUser()
  const qResult = useDirectoryData()

  ////////////////////////////////
  // Lookup items from router
  ////////////////////////////////
  let teacherRecord = teacherId && qResult.data?.teacher.map.get(teacherId)
  let teacher = teacherRecord?.item

  let curriculumRecord =
    curriculumId &&
    // allow curriculum to proceed without teacher to support blanks
    (!teacherId || teacherRecord?.curriculumIds.has(curriculumId)) &&
    qResult.data?.curriculum.map.get(curriculumId)

  let curriculum = curriculumRecord?.item

  ////////////////////////////////
  // Apply airlock logic
  ////////////////////////////////
  let needsUrlChange = false
  let is404 = false
  if (qResult.isSuccess) {
    // Add curriculumId if none/invalid
    if (teacherRecord) {
      // no curriculum
      if (!teacherRecord.curriculumIds.has(curriculumId)) {
        if (teacherRecord.curriculumIds.size === 0) {
          // todo: no curricula
        } else {
          // pick
          curriculumId = teacherRecord.curriculumIds.keys().next().value
          needsUrlChange = true
        }
      }
    } else {
      if (behavior === 'locked') {
        is404 = true
      } else if (behavior === 'unlocked-clear-curriculum' && curriculumId) {
        curriculumId = null
        needsUrlChange = true
      }
    }
  }

  ////////////////////////////////
  // Change url if needed
  ////////////////////////////////
  useEffectIf(needsUrlChange, () => {
    console.log('running teacherAirlock', { needsChange: needsUrlChange })
    dispatchStay({ curriculumId, teacherId })
  })

  ////////////////////////////////
  // Render
  ////////////////////////////////
  const browsingAsSelf = user.teacherId === teacher?.id
  let ctx: TeacherContext = {
    browsingAsSelf,
    canPin: browsingAsSelf || isInternal,
    canTag: browsingAsSelf,
    curriculum,
    curriculumRecord,
    teacher,
    teacherRecord,
  }

  let body: ReactNode
  if (qResult.isLoading || needsUrlChange) {
    // todo: need to support different loading types...
    body = <FullPageLoading qResult={{ isLoading: true }} />
  } else if (is404) {
    body = (
      <Error404Page
        actions={
          <Button
            {...getEAProps(<IcoHome />)}
            onClick={() => dispatchRoute(Routes.home_teacher.mergeAction())}
          >
            Go home
          </Button>
        }
      />
    )
  } else {
    body = children
  }

  return <TeacherContext.Provider value={ctx}>{body}</TeacherContext.Provider>
}

const useEffectIf = (condition: boolean, fn: () => any) => {
  useEffect(() => {
    condition && fn()
  }, [condition])
}
