import { Box, Button, Icon, LightMode } from '@chakra-ui/react'
import { IcoTimeGrid, IcoTrafficLight, IcoUnnamed } from '@paper/icons'
import { DirPacket } from '@paper/schema'
import { BigIconFontSize } from '@paper/styles'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { FC, ReactNode, useRef, useState } from 'react'
import { StackProps, Txt } from '~src/components'
import { Routes } from '~src/routes'
import { formatPastVsNow } from '~src/utils/messages'
import { IcoScanIssue } from '../icoScanIssue'
import {
  MenuBody,
  MenuDetailPane,
  MenuDetailsSection,
  MenuSeparator,
  RouterMenuItem,
  useMenu,
} from '../menu'
import { MISupport } from '../miSupport'
import { useSWContext } from '../swContext'
import { useTeacherContext } from '../teacherAirlock'
import { IcoQ, IcoStd } from './swMenuIcons'
import { CrumbMode, CrumbSection, MIHome, MISignOut } from './swMenuItems'
import { SWMenuPacketPicker, SWMenuTeacherPicker } from './swMenuPickers'

type SWMenuProps = {
  icon: FC
  keyboard?: ReactNode
}

export function SWMenu(props: SWMenuProps) {
  const { icon, keyboard } = props

  const tc = useTeacherContext()
  const { curriculum, teacher } = tc
  const swc = useSWContext()

  const { packet } = swc

  const scanIssueCount = packet?.scan?.partial
  const unnamedOpenCount = packet?.scan?.unnamedOpen || 0

  let { menuButtonProps, onOpenChange, open, shroud } = useMenu({
    caret: true,
    shroud: true,
  })

  const [crumbMode, setCrumbMode] = useState<CrumbMode>('')

  const nfpRef = useRef<DirPacket>()
  if (packet) {
    // try to prevent flashing?
    nfpRef.current = packet
  }
  const nonFlashingPacket = nfpRef.current

  //////////////////////////////
  // Nav pane
  //////////////////////////////
  const leftPane = (
    <Box alignSelf="flex-start" overflow="hidden">
      <DropdownMenu.Group>
        <RouterMenuItem
          disabled={!swc.can.q}
          icon={<IcoQ />}
          route={Routes.jump_q}
        >
          Question
        </RouterMenuItem>
        <RouterMenuItem
          disabled={!swc.can.std}
          icon={<IcoStd />}
          route={Routes.jump_std}
        >
          Standard
        </RouterMenuItem>
        <RouterMenuItem
          disabled={!swc.can.time}
          icon={<IcoTimeGrid />}
          route={Routes.time}
        >
          Time
        </RouterMenuItem>
      </DropdownMenu.Group>
      <MenuSeparator />
      <CrumbSection
        crumbMode={crumbMode}
        onChange={setCrumbMode}
        packet={nonFlashingPacket}
        teacher={teacher}
      />
      <MenuSeparator />
      <DropdownMenu.Group>
        <RouterMenuItem
          disabled={!swc.can.tag}
          icon={<IcoTrafficLight />}
          route={Routes.tag}
        >
          Tag tickets
        </RouterMenuItem>
        <RouterMenuItem
          disabled={!swc.can.fix}
          icon={<IcoScanIssue />}
          route={Routes.scanlog}
        >
          <Txt as="span">
            Fix scan issues{' '}
            {teacher && scanIssueCount > 0 && (
              <Txt as="span" fontFamily="mono" fontSize="sm">
                ({scanIssueCount})
              </Txt>
            )}
          </Txt>
        </RouterMenuItem>
        <RouterMenuItem
          disabled={!swc.can.name}
          icon={<IcoUnnamed />}
          route={Routes.setstudent}
        >
          <Txt as="span">
            Fix unnamed{' '}
            {teacher && (
              <Txt as="span" fontFamily="mono" fontSize="sm">
                ({unnamedOpenCount})
              </Txt>
            )}
          </Txt>
        </RouterMenuItem>
      </DropdownMenu.Group>
      <MenuSeparator />
      <DropdownMenu.Group>
        <MISupport />
      </DropdownMenu.Group>
      <MenuSeparator />
      <DropdownMenu.Group>
        <MIHome />
        <MISignOut />
      </DropdownMenu.Group>
    </Box>
  )

  //////////////////////////////
  // Details pane
  //////////////////////////////
  let details: ReactNode
  const stretchGrow: StackProps = { alignItems: 'stretch', flexGrow: 1 }
  if (crumbMode === 'teachers') {
    details = (
      <MenuDetailsSection {...stretchGrow}>
        <SWMenuTeacherPicker />
      </MenuDetailsSection>
    )
  } else if (crumbMode === 'packets') {
    details = (
      <MenuDetailsSection {...stretchGrow}>
        <SWMenuPacketPicker packets={swc.packetSwitchList} />
      </MenuDetailsSection>
    )
  } else {
    let edwAsOf = formatPastVsNow(packet?._edwAsOf)
    edwAsOf = !packet ? '⋯' : edwAsOf ? `Loaded ${edwAsOf}` : 'Not found'

    details = [
      keyboard && (
        <MenuDetailsSection key="ks" heading="Keyboard shortcuts">
          {keyboard}
        </MenuDetailsSection>
      ),
      <MenuDetailsSection key="edw" heading="EDW Standards and Scores">
        <Txt fontSize="xs">{edwAsOf}</Txt>
      </MenuDetailsSection>,
    ].filter((p) => p)
  }

  const rightPane = (
    <MenuDetailPane separator={true}>
      <MenuDetailsSection>
        <Txt>{nonFlashingPacket?.name}</Txt>
        <Txt fontFamily="mono" fontSize="sm">
          {curriculum?.name}
        </Txt>
      </MenuDetailsSection>
      {details}
    </MenuDetailPane>
  )

  //////////////////////////////
  // Put it all together
  //////////////////////////////
  return (
    <DropdownMenu.Root
      modal={false} // some sort of perf issue with modal...
      onOpenChange={onOpenChange}
      open={open}
    >
      <DropdownMenu.Trigger asChild>
        <Button
          data-cy="menu-sw"
          {...menuButtonProps}
          flexShrink={0}
          px={1}
          size="lg"
        >
          <Icon as={icon} fontSize={BigIconFontSize} />
        </Button>
      </DropdownMenu.Trigger>
      {shroud}
      <DropdownMenu.Content asChild>
        <MenuBody width="544px">
          <LightMode>
            <Box display="grid" gridTemplateColumns="1fr auto 1fr">
              {leftPane}
              <Box
                borderRightColor="gray.100"
                borderRightWidth={1}
                className="vr"
                height="100%"
                role="separator"
              />
              {rightPane}
            </Box>
          </LightMode>
        </MenuBody>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
