import { chakraTheme } from './theme-chakra'

const { colors } = chakraTheme
const { gray } = colors

export const gray75 = '#F1F6FA'

export const GRAY_HAS_SCANS = gray['300']

export const GRAY_NO_SCORE = gray['50']

export const GRAY_BAR = gray['300']
export const GRAY_BAR_SELECTED = gray['500']

export const GRAY_TIME = gray['300']
export const GRAY_TIME_BAR = gray['100']

export const PASSAGE_COLOR = colors.passageBlue['500']
export const SCORE_COLOR = colors.purple['500']
export const CORRECT_COLOR = colors.purple['500']
export const MISSING_COLOR = colors.red['400']
export const MISSING_X = colors.red['400']
export const MISSING_PIE_COLOR = colors.pink['200']
export const SELECTION_COLOR = 'rgba(226,202,81,.55)'
export const HEADER_BG = '#403940'
export const HEADER_FG = 'rgba(255,255,255,.92)'
export const BODY_BG = 'white'
export const ANSWERKEY_BG = '#fafafa'
export const DEFAULT_FG = 'rgba(0,0,0,.64)'
export const TheBoxShadow = '0 3px 6px rgba(0, 0, 0, 0.23)'

export const ILL_COLOR = '#006c90'

export const DARK_MODE_FG = 'rgba(255,255,255,.9)' //'#ededed'

export const MENU_HEADER_BG = DEFAULT_FG

export const BigIconFontSize = '2.25rem'

// charts colors
export const OER_COLOR = '#999'
export const PRIMARY_BAR_COLOR = '#595959'

export const ColumnWidths = {
  packetList: 300,
  xpacketList: 216,
}

export const PktColors = {
  NeedsFixing: 'yellow',
  Never: 'gray',
  Nuclear: 'yellow',
  Publish: 'green',
  Print: 'blue',
  Recall: 'red',
  Scanned: 'blue',
}
