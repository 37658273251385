import { Avatar, AvatarGroup, Box, IconButton } from '@chakra-ui/react'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { IcoDragHandle, IcoEdit, IcoX } from '@paper/icons'
import { Passage, Question } from '@paper/schema'
import { SELECTION_COLOR } from '@paper/styles'
import { Dispatch, memo, useMemo } from 'react'
import { QuestionRow } from '~src/blocks/answerKey/items'
import { HStack } from '~src/components'
import { ListAction } from '../formListValueHelpers'
import { SingleQSchema } from '../formSingleQuestion'

// todo: messy interface!
type SortableQListProps = {
  dispatch: Dispatch<ListAction<Question>>
  lastAdded: Question
  values: { passages: Passage[]; questions: Question[] }
  selected: SingleQSchema
}

export function SortableQList(props: SortableQListProps) {
  const { values, ...passThroughProps } = props
  const { dispatch } = passThroughProps
  const { passages, questions } = values
  const passageMap = useMemo(() => {
    return new Map(passages.map((p) => [p.id, p]))
  }, [passages])

  const maxCounts = useMemo(() => {
    let maxOptions = 0
    let maxPassages = 0

    for (let q of questions) {
      maxOptions = Math.max(maxOptions, q.options.length)
      maxPassages = Math.max(maxPassages, q.passageIds.length)
    }

    return { maxOptions, maxPassages }
  }, [questions])

  // sorting
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  )

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      dispatch({
        type: 'move',
        mover(items) {
          const oldIndex = items.findIndex((p) => p.id === active.id)
          const newIndex = items.findIndex((p) => p.id === over.id)
          return arrayMove(items, oldIndex, newIndex)
        },
      })
    }
  }

  return (
    <Box overflowY="auto">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={questions}
          strategy={verticalListSortingStrategy}
        >
          {questions.map((q) => (
            <SortableQ
              {...maxCounts}
              {...passThroughProps}
              key={q.id}
              passageMap={passageMap}
              q={q}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Box>
  )
}

type SortableQProps = Omit<SortableQListProps, 'values'> & {
  maxOptions: number
  maxPassages: number
  passageMap: Map<string, Passage>
  q: Question
}

function SortableQ(props: SortableQProps) {
  const { maxOptions, maxPassages, q, selected } = props

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: q.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  // todo: brittle, maybe measure?...i'm guessing auto grid mojo won't work with drag-and-drop?
  const bubbleColWidth = 66 + 17 * (maxOptions - 2)
  const passageColWidth = 16 * maxPassages + 8
  const gridTemplCols = `32px 48px ${bubbleColWidth}px ${passageColWidth}px`

  return (
    <Box
      alignItems="center"
      bg={
        isDragging
          ? 'gray.50'
          : selected?.q.id === q.id
          ? SELECTION_COLOR
          : null
      }
      borderRadius="16px"
      key={q.id}
      display="grid"
      gridColumnGap={3}
      gridTemplateColumns={gridTemplCols}
      gridTemplateRows="auto"
      justifyItems="start"
      px={1}
      ref={setNodeRef}
      style={style}
      userSelect="none"
      _hover={{ bg: isDragging ? null : 'gray.50' }}
    >
      <IconButton
        aria-label="Sort"
        cursor={(isDragging ? 'grabbing' : 'grab') + '!important'}
        icon={<IcoDragHandle />}
        isRound={true}
        size="sm"
        variant="ghost"
        {...attributes}
        {...listeners}
      />
      <InnerQ {...props} />
    </Box>
  )
}

/**
 * Try memoing as things get a bit sluggish with lots of Qs
 * (?) Maybe a bit less sluggish?
 * Might be worth seeing if dnd-kit is compatible with react-virtual...
 */
const InnerQ = memo(function InnerQ(props: SortableQProps) {
  const { dispatch, lastAdded, passageMap, q } = props
  return (
    <>
      <HStack>
        <IconButton
          aria-label="Edit"
          icon={<IcoEdit />}
          isRound={true}
          onClick={() => dispatch({ type: 'selectToEdit', item: q })}
          size="xs"
          variant="ghost"
        />
        <IconButton
          aria-label="Delete"
          icon={<IcoX />}
          isRound={true}
          onClick={() => dispatch({ type: 'delete', item: q })}
          size="xs"
          variant="ghost"
          _hover={{ bg: 'red.50', color: 'red.600' }}
        />
      </HStack>
      <QuestionRow
        data={q}
        isScrolledTo={lastAdded?.id === q.id}
        noBorder={true}
      />
      <AvatarGroup size="xs" spacing={-2}>
        {q.passageIds.map((pId) => {
          const p = passageMap.get(pId)
          return <Avatar key={p.id} name={p.name} />
        })}
      </AvatarGroup>
    </>
  )
})
