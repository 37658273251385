import { Box } from '@chakra-ui/react'
import { createContext, ReactNode, useContext } from 'react'
import type { UseQueryResult } from 'react-query'
import { StackProps, Txt, VStack } from '~src/components'
import type { ItemProps } from '.'
import type { ListCallbacks } from './listCallbacks'
import type { Crunched } from './listCrunch'

export type ListAdapter<T, RD = any> = {
  id: string
  idFromItem(item: T): string
  idFromRouter(routeData: RD): string
  ItemComponent: (props: ItemProps<T>) => JSX.Element
  itemName: string
  select(item: T): Partial<RD>
}

export type ListDigest<Item = any, OtherData = any, QRD = any> = {
  adapter: ListAdapter<Item>
  qResult: UseQueryResult<QRD>
  success: Success<Item, OtherData>
}

export type Success<T, O = undefined> = ListCallbacks<T> & Crunched<T, O>

type ListProps = {
  children?: ReactNode
  digest: ListDigest
}

/**
 * ListContext is for loaded list, whereas ListDigest includes loading state
 * (not sure it's worth introducing a separate type)
 */
type ListContext<T = any, O = any> = { adapter: ListAdapter<T> } & Success<T, O>
const ListContext = createContext<ListContext>(undefined)
/**
 * @example
 * const { ... } = useListContext<XpacketDigest>()
 */
export function useListContext<T extends ListDigest>(): ListContext<
  // probably a better way, but this makes it easy for the caller to type the return value
  T['success']['selectedItem'],
  T['success']['otherData']
> {
  return useContext(ListContext)
}

/**
 * List intended to be rendered after success
 */
export function List(props: ListProps) {
  const { children, digest } = props
  const { adapter } = digest

  // repack context for success
  // todo: does this need memo?
  const context = { adapter, ...digest.success }

  ///////////////////////
  // render the list
  ///////////////////////
  const { items, onSelect, selectedItem } = digest.success

  // empty due to filters message
  const emptyFromFilters = items.length === 0 && (
    <Txt fontSize="small" textAlign="center">
      There are no {adapter.itemName} with these filters.
    </Txt>
  )

  return (
    <ListContext.Provider value={context}>
      {children}
      <Box
        alignSelf="stretch"
        role="presentation"
        overflowY="auto"
        sx={{ scrollPaddingBottom: '20px' }}
      >
        {emptyFromFilters ||
          items.map((item) => {
            const id = adapter.idFromItem(item)
            return (
              <adapter.ItemComponent
                key={id}
                data={item}
                isSelected={item === selectedItem}
                onSelect={onSelect}
              />
            )
          })}
      </Box>
    </ListContext.Provider>
  )
}

/**
 * Container for the List
 * @example
 * <ListOuterStack>
 *  <List ...>
 *    <Filters />
 *    <EnsureSelected />
 *    <WSKeyboard />
 *  </List>
 * </ListOuterStack>
 */
export function ListOuterStack(props: StackProps) {
  return (
    <VStack
      gap={4}
      height="100%" // either need to set overflowY="hidden" or explicit height
      // overflowY="hidden" hides the chakra mechanism for showing accessibility outline
      {...props}
    />
  )
}
