import { Button, Icon, LightMode } from '@chakra-ui/react'
import { IcoAdd, IcoHome, IcoPacketEntry } from '@paper/icons'
import { useRouter } from '@paper/route'
import { BigIconFontSize } from '@paper/styles'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Fragment, ReactNode } from 'react'
import { MenuBody, MenuItem, MenuSeparator, useMenu } from '~src/blocks/menu'
import { MISupport } from '~src/blocks/miSupport'
import { Routes } from '~src/routes'
import { useMIPacketFix } from '../internal/secretPacketFix'
import { usePublishContext } from './publishProvider'
import { RD_Publish } from './routes'
import { useGoToNew } from './utils'

export function PublishMenu() {
  const { dispatchRoute, routeData } = useRouter<RD_Publish>()
  const goToNew = useGoToNew()
  const { contentId, page } = usePublishContext()
  const miPacketFix = useMIPacketFix(contentId, routeData.packetId)

  let { menuButtonProps, onOpenChange, open, shroud } = useMenu({
    caret: true,
    //defaultOpen: true,
    shroud: true,
  })

  let icon = IcoPacketEntry

  let destinations: ReactNode

  if (page === 'home' || page === 'new') {
    destinations = (
      <Fragment>
        {goToNew && (
          // todo: need canNew
          <MenuItem icon={<IcoAdd />} onSelect={goToNew}>
            Add a new packet
          </MenuItem>
        )}
        <MenuItem
          icon={<IcoHome />}
          onSelect={() => dispatchRoute(Routes.home_curriculum.mergeAction())}
        >
          Exit to curriculum home
        </MenuItem>
        {miPacketFix.enabled && (
          <>
            <MenuSeparator />
            {miPacketFix.menuItem}
          </>
        )}
      </Fragment>
    )
  }

  return (
    <DropdownMenu.Root
      modal={false} // some sort of perf issue with modal...
      onOpenChange={onOpenChange}
      open={open}
    >
      <DropdownMenu.Trigger asChild>
        <Button
          data-cy="menu-ew"
          {...menuButtonProps}
          flexShrink={0}
          px={1}
          size="lg"
        >
          <Icon as={icon} fontSize={BigIconFontSize} />
        </Button>
      </DropdownMenu.Trigger>
      {shroud}
      <DropdownMenu.Content asChild>
        <MenuBody width="272px">
          <LightMode>
            {destinations}
            {destinations && <MenuSeparator />}
            <MISupport />
          </LightMode>
        </MenuBody>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
