import { Box } from '@chakra-ui/react'
import { IcoTimeGrid, IcoTrafficLight } from '@paper/icons'
import { RouteMeta, useLink } from '@paper/route'
import { DirPacket, PacketType } from '@paper/schema'
import { ReactNode } from 'react'
import { IBLink, IBLinkProps } from '~src/components'
import { Routes } from '~src/routes'
import { TagBar } from '../pctBar'
import { useTeacherContext } from '../teacherAirlock'
import { SIBP } from './tableColUtils'

type ViewLinkProps = {
  ctx: DirPacket
  route: RouteMeta<any>
} & Omit<IBLinkProps, 'href' | 'onClick' | 'type'>

export function ViewLink(props: ViewLinkProps) {
  const { ctx, route, ...rest } = props
  // todo: injecting contentId is a bad api
  const linkProps = useLink(
    route.mergeAction({
      contentIdOrNew: ctx.contentId,
      curriculumId: ctx.curriculumId,
      packetId: ctx.id,
      teacherId: ctx.teacherId,
    })
  )
  return (
    <IBLink
      data-cy={`vl${route.path.replaceAll('/', '-')}`}
      {...SIBP}
      {...rest}
      {...linkProps}
    />
  )
}

type ViewBtnProps = Omit<ViewLinkProps, 'action' | 'ctx'>

type ViewKey = 'time' | 'tag' | 'q' | 'std'

const slots: Record<PacketType, readonly ViewKey[]> = {
  assessment: ['q', 'std', 'time'],
  ticket: ['tag', 'time'],
} as const

const maybeTagBarred = (
  icon: ReactNode,
  item: DirPacket,
  isTagBarred: boolean
) => {
  return !isTagBarred
    ? icon
    : ((
        <>
          {icon}{' '}
          <Box
            // todo: label
            display="flex"
            bottom={0}
            left={0}
            height="4px"
            position="absolute"
            width="100%"
          >
            <TagBar {...item} />
          </Box>
        </>
      ) as any)
}

export function ViewNavCell(props: { item: DirPacket }) {
  const { item } = props
  const teacherContext = useTeacherContext()
  const canTag = !!teacherContext?.canTag

  // todo: need some other plan for curriculum view where this won't be known
  if (!item.scan.count) {
    return null
  }

  const views: Record<ViewKey, ViewBtnProps> = {
    q: {
      'aria-label': 'Jump to question',
      icon: 'Q',
      route: Routes.jump_q,
    },
    std: {
      'aria-label': 'Jump to standard',
      icon: 'S',
      route: Routes.jump_std,
    },
    tag: {
      'aria-label': 'Tag',
      icon: maybeTagBarred(<IcoTrafficLight />, item, canTag),
      route: Routes.tag,
    },
    time: {
      'aria-label': 'Time grid',
      icon: maybeTagBarred(<IcoTimeGrid />, item, !canTag),
      route: Routes.time,
    },
  } as const

  // todo: hoist more of this!
  let enabledMap: Record<ViewKey, boolean> = {
    q: item.questions?.length > 0,
    std: item.stds?.length > 0,
    time: true,
    tag: item.type === 'ticket' && canTag,
  }
  let links = slots[item.type]
    .map((key) => (enabledMap[key] ? views[key] : null))
    .filter((p) => p) // slots with empty spaces vs. fit what's there?

  return (
    <Box
      alignItems="center"
      display="grid"
      gridTemplateColumns={`repeat(${links.length}, 1fr)`}
      justifyContent="center"
    >
      {links.map((linkProps, idx) => (
        <ViewLink gridColumn={idx + 1} {...linkProps} key={idx} ctx={item} />
      ))}
    </Box>
  )
}
