import {
  IcoDiscountIlluminate,
  IcoEdit,
  IcoExternalLink,
  IcoLink,
  IconType,
  IcoPrint,
  IcoPublish,
  IcoRadioactive,
  IcoRecall,
} from '@paper/icons'
import { Packetmeta, PublishStage } from '@paper/schema'
import { ILL_COLOR, PktColors } from '@paper/styles'
import { getPrintState } from '@paper/utils'
import { useMemo } from 'react'
import {
  IconColumnProps,
  IconLabel,
  SIBP,
} from '~src/blocks/packetTable/tableColUtils'
import { useCurriculaPicker } from '~src/blocks/pickers'
import {
  ComboBox,
  HStack,
  IBLink,
  TextStack,
  TooltippedIcon,
  TooltippedIconButton,
  Txt,
} from '~src/components'
import { UghColumn } from '~src/components/table'
import { useDirectoryData } from '~src/data/data-directory'
import { formatPastVsNow } from '~src/utils/messages'

type CurrCol = UghColumn<Packetmeta>

const PacketNumber2: CurrCol = {
  props: { width: 64 },
  label: () => <Txt fontFamily="mono">#</Txt>,
  cell: (item) =>
    item._pub === 'published' && (
      <Txt fontFamily="mono" fontWeight={600}>
        {item.number}
      </Txt>
    ),
}

const Curriculum: CurrCol = {
  props: { align: 'start', width: 180 },
  label: () => 'Curriculum',
  cell: (item) => <Txt whiteSpace="pre-line">{item.curriculum.name}</Txt>,
}

export const PublishState: UghColumn<{
  id: string
  _pub: PublishStage
  _updates?: { publish: { time: number; user?: string } }
}> = {
  props: IconColumnProps,
  label: () => <IconLabel icon={IcoPublish} textValue="Publish state" />,
  cell: (item) => {
    let color: string
    let icon: IconType
    let label: string

    if (item._pub === 'published') {
      color = `${PktColors.Publish}.500`
      icon = IcoPublish
      let publishMeta = item._updates?.publish
      label = !publishMeta
        ? 'Published'
        : `Published ${formatPastVsNow(publishMeta.time)}${
            publishMeta.user ? ` by ${publishMeta.user}` : ''
          } `
    } else if (item._pub === 'recalled') {
      color = `${PktColors.Recall}.500`
      icon = IcoRecall
      label = 'Recalled'
    }

    return !icon ? null : (
      <TooltippedIcon aria-label={label} color={color} as={icon} />
    )
  },
}

const PrintState: CurrCol = {
  props: IconColumnProps,
  label: () => <IconLabel icon={IcoPrint} textValue="Print state" />,
  cell: (item) => {
    const { isPrinted, radioactive } = getPrintState(item)
    if (!isPrinted) {
      return null
    }
    if (radioactive) {
      return (
        <TooltippedIcon
          aria-label={`Warning: This packet was edited after it was printed and may be in an inconsistent state.`}
          as={IcoRadioactive}
          color={`${PktColors.Nuclear}.500`}
        />
      )
    } else {
      return (
        <TooltippedIcon
          aria-label={`Printed ${formatPastVsNow(item._printed.first)}`}
          as={IcoPrint}
          color={`${PktColors.Print}.500`}
        />
      )
    }
  },
}

// todo: awkward api...need to be able to thread events outside the columns...
type CurriculumColumnsProps = {
  canEdit: boolean
  showCrossPacketEditor: () => void
  showPublishDialog: (item: Packetmeta) => void
}

export function useCurriulumColumns(props: CurriculumColumnsProps) {
  const { canEdit, showCrossPacketEditor, showPublishDialog } = props

  const EditPublish: CurrCol = {
    props: IconColumnProps,
    label: () => 'Edit',
    cell: (item) => (
      <TooltippedIconButton
        {...SIBP}
        aria-label="Change publish state"
        data-cy="pub-state-edit"
        icon={<IcoEdit />}
        onClick={() => showPublishDialog(item)}
      />
    ),
  }

  const CrossPacketBtn = ({ icon, label }) => {
    return (
      <TooltippedIconButton
        aria-label={label}
        data-cy="cross-packet"
        isDisabled={!canEdit}
        icon={icon}
        isRound={true}
        onClick={showCrossPacketEditor}
        tooltipProps={{ placement: 'top' }}
        variant={canEdit ? 'solid' : 'ghost'}
      />
    )
  }

  const Illuminate: CurrCol = {
    props: IconColumnProps,
    label: () => (
      <CrossPacketBtn
        icon={<IcoDiscountIlluminate />}
        label="Connect to Illuminate"
      />
    ),
    cell: (item) =>
      item._illuminate && (
        <TooltippedIcon
          aria-label={item._illuminate}
          as={IcoDiscountIlluminate}
          color={ILL_COLOR}
        />
      ),
  }

  const EditExternal: CurrCol = {
    props: IconColumnProps,
    label: () => (
      <CrossPacketBtn icon={<IcoLink />} label="Link to external materials" />
    ),
    cell: (item) =>
      !item._linkExternal ? null : (
        <IBLink
          {...SIBP}
          aria-label="external source materials"
          href={item._linkExternal}
          icon={<IcoExternalLink />}
          isExternal={true}
        />
      ),
  }

  return useMemo(() => {
    let columns = [
      PacketNumber2,
      Curriculum,
      PublishState,
      PrintState,
      Illuminate,
      EditExternal,
    ]
    if (canEdit) {
      columns = [EditPublish, ...columns]
    }
    return columns
  }, [canEdit])
}

//////////////////////////
// Packet fixer
//////////////////////////
export const packetfixCols = [
  PacketNumber2,
  Curriculum,
  PublishState,
  PrintState,
]
