import { Box } from '@chakra-ui/react'
import type { ApiTag } from '@paper/api'
import { IAP, Xpacket, XpacketSW, XpacketTag } from '@paper/schema'
import { GRAY_HAS_SCANS } from '@paper/styles'
import { produce } from 'immer'
import { useMutation, useQueryClient } from 'react-query'
import { useUser } from '~src/blocks/userProvider'
import { HStack } from '~src/components'
import { Tags } from '.'

type ListItemTagProps = {
  isSelected?: boolean
  xpacket: XpacketSW
}

/** @deprecated Tag mode no longer lives here, so this needs to be cleaned up */
export function ListItemTag(props: ListItemTagProps) {
  const { isSelected, xpacket } = props

  // todo: I think we'd ideally attach TagKeyboard and the submit logic to TagFrame and similar
  // todo: ...and just subscribe to the result here
  // todo: but it doesn't look like react-query conveniently supports that
  // todo: so display and behavior are fairly coupled here for now
  //const mutation = useSubmitTag()

  // let mutationStatus: ReactNode
  // switch (mutation.status) {
  //   case 'loading':
  //     mutationStatus = <Spinner data-cy="tag-loading" size="xs" speed="1s" />
  //     break
  //   case 'error':
  //     mutationStatus = (
  //       <Box as={IcoX} data-cy="tag-error" fontSize="xs" color="red.500" />
  //     )
  //     break
  // }

  const tagMeta = Tags.find((tag) => tag.id === xpacket.tag)
  const bg =
    xpacket.status === 'missing' ? 'red.400' : tagMeta?.color ?? GRAY_HAS_SCANS

  return (
    <Box
      data-cy={`li-xpacket-tag-${tagMeta?.id ?? 'none'}`}
      display="grid"
      alignItems="center"
      gridTemplateColumns="20px 20px"
      justifyItems="center"
    >
      <HStack
        alignSelf="stretch"
        justifySelf="stretch"
        bg={bg}
        p={1}
        transition="background-color .3s ease"
      >
        {tagMeta && (
          <Box as={tagMeta.icon} color="rgba(0,0,0,.5)" fontSize="xs" />
        )}
      </HStack>
      {/* {mutationStatus} */}
      {/* {isSelected && (
        <TagKeyboard onSubmit={mutation.mutate} xpacketId={xpacket.id} />
      )} */}
    </Box>
  )
}

function useSubmitTag() {
  const { fetchAs } = useUser()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    async (props: ApiTag['body']) => {
      // TODO: Need a loading/error indicator...obviously don't want to make the user wait
      await fetchAs.post(IAP.tag, { json: props })
      return props
    },
    {
      // When mutate is called:
      onMutate: (newTag) => {
        // todo: this got even messier with react-query@3
        const queries = queryClient.getQueryCache().getAll()
        // brittle, this only works if we only keep one /xpackets query in the cache
        const xpacketQuery = queries.find(
          (q) => q.queryKey?.[0] === IAP.dir.listXpackets
        )

        // Cancel any outgoing refetches to this exact key
        // (so they don't overwrite our optimistic update)
        queryClient.cancelQueries(xpacketQuery.queryKey)

        // Keep track of previous value in case we need to revert
        let revertTo: XpacketTag
        // Optimistically update to the new value
        queryClient.setQueryData<Xpacket[]>(xpacketQuery.queryKey, (old) => {
          return produce(old, (draft) => {
            const xp = draft.find((xp) => xp.id === newTag.xpacketId)
            revertTo = xp.tag
            xp.tag = newTag.tag
          })
        })

        // Return a rollback function
        return () =>
          queryClient.setQueryData<Xpacket[]>(xpacketQuery.queryKey, (old) => {
            return produce(old, (draft) => {
              const xp = draft.find((xp) => xp.id === newTag.xpacketId)
              // check if xpacket exists just in case we've changed contexts
              if (xp) {
                xp.tag = revertTo
              }
            })
          })
      },
      // If the mutation fails, use the value returned from onMutate to roll back
      onError: (err, failedPayload, rollback) => {
        rollback()
        // note: Previously I was invalidating the xpacket query in `onSettled` and later in `onError`
        // but that creates race conditions, so rollback manually
        // queryCache.invalidateQueries(xpQueryPred)
      },
    }
  )
  return mutation
}
