import { Box, Text } from '@chakra-ui/react'
import { Passage, Question } from '@paper/schema'
import { CORRECT_COLOR, DEFAULT_FG } from '@paper/styles'
import { elipsize } from '@paper/utils'
import { memo, useRef } from 'react'
import { Txt } from '~src/components'
import { useScrollIntoView, useScrollToSelected } from '~src/utils/useScroll'

export const PagedStatus = ({ isPaged, variant = 'inline' }) => {
  return (
    <Box
      as="span"
      color={isPaged ? undefined : 'red.500'}
      fontFamily="mono"
      fontSize="xs"
      mr={variant === 'inline' ? 1 : undefined}
      pos={variant === 'inline' ? undefined : 'absolute'}
      top={variant === 'inline' ? undefined : '50%'}
      transform={
        variant === 'inline'
          ? undefined
          : `translate(-50%, -${isPaged ? 50 : 57}%)`
      }
    >
      {isPaged ? '✓' : '○'}
    </Box>
  )
}

type PassageProps = {
  data: Passage
  isScrolledTo?: boolean
  maxLength?: number
}
export const PassageLabel = (props: PassageProps) => {
  const { data, isScrolledTo, maxLength } = props
  const domRef = useScrollIntoView(isScrolledTo)
  return (
    <Box fontSize="xs" py={1} ref={domRef} maxW="125px">
      {elipsize(data?.name, maxLength)}
    </Box>
  )
}

type QuestionProps = {
  data: Question
  isScrolledTo?: boolean
  noBorder?: boolean
}
export const QuestionRow = ({
  data,
  isScrolledTo,
  noBorder,
}: QuestionProps) => {
  const domRef = useRef()
  useScrollToSelected(domRef, isScrolledTo)
  const { correct, label, options, type } = data

  const bubbles =
    type === 'GRID-IN' ? (
      <Txt fontFamily="mono" fontSize="xs">
        {type}
      </Txt>
    ) : (
      options.map(({ label: optionLabel }, idx) => {
        const filled = correct.includes(idx)
        return (
          <Bubble
            color={filled ? CORRECT_COLOR : undefined}
            key={idx}
            label={optionLabel}
            filled={filled}
          />
        )
      })
    )

  return (
    <Box
      alignItems="center"
      borderBottom={noBorder ? null : '1px solid #e8e8e8'}
      display="flex"
      flexShrink={0}
      overflow="hidden"
      px="5px"
      py="2px"
      ref={domRef}
      textOverflow="ellipsis"
      userSelect="none"
      whiteSpace="nowrap"
      sx={{
        '> *': {
          flexShrink: 0,
          marginRight: '2px',
        },
      }}
    >
      <Text
        as="span"
        fontFamily="mono"
        fontSize="xs"
        whiteSpace="pre"
        width="20px"
      >
        {label.padStart(2, ' ')}
      </Text>
      {bubbles}
    </Box>
  )
}

type BubbleProps = {
  color?: string
  filled: boolean
  label: string
  size?: number
}

const Bubble = memo(function Bubble(props: BubbleProps) {
  const { color = DEFAULT_FG, filled, label, size = 15 } = props
  return (
    <Box
      as="span"
      alignItems="center"
      bg={filled ? color : undefined}
      border={`1px solid ${color}`}
      borderRadius={`${size}px`}
      color={filled ? 'white' : color}
      display="inline-flex"
      fontFamily="mono"
      fontSize="10.5px"
      fontStyle="italic"
      justifyContent="center"
      height={`${size}px`}
      paddingRight="1px"
      userSelect="none"
      width={`${size}px`}
    >
      {label}
    </Box>
  )
})
