import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
} from 'react'
import { QS_OPTIONS, queryString } from './queryString'
import { reducer } from './reducer'
import {
  BaseData,
  Dispatch,
  IRouterContext,
  NavType,
  NotFoundRenderer,
  RouteDefs,
  State,
} from './types'
import { listenWindow, shallowEqual, useStateAndRef } from './utils'

const goBack = () => history.back()

interface RouterProps {
  children: ReactNode
  notFound: NotFoundRenderer
}

const RouterContext = createContext<IRouterContext>(undefined)
export function useRouter<T extends BaseData = any>() {
  return useContext(RouterContext) as IRouterContext<T>
}

export function createRouter<D extends BaseData>(routes: RouteDefs) {
  // Parse data out of url
  function parseUrl(): State<D> {
    const routeValues = Object.values(routes)
    for (let i = 0; i < routeValues.length; i++) {
      const routeMeta = routeValues[i]
      const results = routeMeta.pathParser.test(window.location.pathname)
      if (results) {
        return {
          routeMeta,
          data: {
            ...results,
            ...queryString.parse(window.location.search, QS_OPTIONS),
          } as D,
        }
      }
    }
    return { routeMeta: null, data: {} } as State<D> // TODO:
  }

  const Router = memo(function Router(props: RouterProps) {
    const { children, notFound } = props
    const [state, setState, stateRef] = useStateAndRef(parseUrl)

    let dispatch: Dispatch = (action) => {
      // Get new state from the reducer
      let newState = reducer(stateRef.current, action)

      // setState + update URL if there are changes
      // todo: maybe check if the url changes instead?
      if (!shallowEqual(newState, stateRef.current)) {
        // (?) yuck, but keep track that we're navigating explicitly
        stateRef.current = newState
        setState(newState)
        // Get url and navigate
        // Don't add to history if this is triggered by an external url change
        if (action.type !== 'syncFromUrl') {
          const url = newState.routeMeta.buildUrl(newState.data)
          if (action.navType === 'replace') {
            history.replaceState(undefined, undefined, url)
          } else {
            history.pushState(undefined, undefined, url)
          }
        }
      }
    }
    dispatch = useCallback(dispatch, [routes, setState, stateRef])

    // Subscribe to history
    useEffect(() => {
      const unlisten = listenWindow('popstate', () => {
        dispatch({ type: 'syncFromUrl', ...parseUrl() })
      })
      return unlisten
    }, [dispatch])

    /**
     * Shortcut to stay on the same route
     */
    const dispatchStay = useCallback(
      (data, navType: NavType = 'replace') => {
        const { routeMeta } = stateRef.current
        dispatch(routeMeta.mergeAction(data, navType))
      },
      [dispatch]
    )

    /**
     * Airlock hook
     */
    const useAirlock = useCallback(
      (data, needUrlChange: boolean) => {
        useLayoutEffect(() => {
          if (needUrlChange) {
            // console.log('useAirlock', data)
            dispatchStay(data)
          }
        }, [needUrlChange])
      },
      [dispatch]
    )

    const value: IRouterContext = {
      currentRoute: state.routeMeta,
      dispatchRoute: dispatch,
      dispatchStay,
      goBack,
      notFound,
      routeData: state.data,
      useAirlock,
    }
    return (
      <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
    )
  })

  return Router
}
