import type { ApiTag } from '@paper/api'
import { useRouter } from '@paper/route'
import { IAP, XpacketTag } from '@paper/schema'
import produce from 'immer'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { TagKeyboard } from '~src/blocks/tags/tagKeyboard'
import { useTeacherContext } from '~src/blocks/teacherAirlock'
import { useUser } from '~src/blocks/userProvider'
import { RD_Tea_Time } from '~src/pages/sw/routes'
import { Routes } from '~src/routes'
import { TeaTimeBaseData } from './data-time'
import { useTeacherTimeContext } from './timeAirlock'

// todo: need to clean up this naming!
type TagGridContext = {
  /** Use is allowed to tag in general, tagging is active, current cell is taggable */
  canTagNow: boolean
  /** Whether the user is allowed to tag on this page */
  canUserTag: boolean
  /** Whether the current cell can be tagged */
  isCurrentCellTaggable: boolean
  /**
   * Whether tag mode is active
   * e.g. we don't want to boot you from tag mode while navigating to an assessment
   * event though it's not taggable
   */
  isTagModeActive: boolean
  statusMap: StatusMap
  toggleTagMode(): void
}
const TagGridContext = createContext<TagGridContext>(undefined)
export const useTagGridContext = () => useContext(TagGridContext)

type TagGridProviderProps = { children: ReactNode }

type StatusMap = Record<string, TagSubmitStatus>
type TagSubmitStatus = {
  status: 'error' | 'loading' | 'success'
  currentValue: XpacketTag
  rollbackValue: XpacketTag
}

export function TagGridProvider(props: TagGridProviderProps) {
  const { currentRoute, dispatchRoute } = useRouter<RD_Tea_Time>()
  const { canTag } = useTeacherContext()
  const { digest, xyCursor } = useTeacherTimeContext()

  const isTagModeRoute = currentRoute === Routes.tag

  // url stuff
  // todo: downside of using route is that current useAirlock only supports query params, so have to rewrite
  // todo: possibly want to explain instead of silently booting
  const needsUrlChange = isTagModeRoute && !canTag

  useLayoutEffect(() => {
    if (needsUrlChange) {
      dispatchRoute(Routes.time.mergeAction())
    }
  }, [needsUrlChange])

  const toggleTagMode = useCallback(
    () =>
      dispatchRoute((isTagModeRoute ? Routes.time : Routes.tag).mergeAction()),
    [isTagModeRoute]
  )

  let isTagModeActive = canTag && isTagModeRoute

  // calculate whether the current cell can be tagged
  let isCurrentCellTaggable =
    canTag &&
    digest.selectedItem &&
    digest.xAxis.selectedItem?.type === 'ticket'

  // todo: need to clean up naming
  let canTagNow = isTagModeActive && isCurrentCellTaggable

  const { statusMap, submitTag } = useSubmitTag()

  let ctx: TagGridContext = {
    canTagNow,
    canUserTag: canTag,
    isCurrentCellTaggable,
    isTagModeActive,
    statusMap,
    toggleTagMode,
  }

  return (
    <TagGridContext.Provider value={ctx}>
      {canTagNow && (
        <TagKeyboard
          isActive={canTagNow}
          onNext={xyCursor.move.down}
          onSubmit={submitTag}
          xpacketId={digest.selectedItem?.id}
        />
      )}
      {props.children}
    </TagGridContext.Provider>
  )
}

function useSubmitTag() {
  const { fetchAs } = useUser()
  const queryClient = useQueryClient()

  // todo: reducer
  const [statusMap, setStatusMap] = useState<StatusMap>(() => {
    console.log('init statusmap')
    return {}
  })

  const mutation = useMutation(
    async (props: ApiTag['body']) => {
      await fetchAs.post(IAP.tag, { json: props })
      return props
    },
    {
      onMutate(newTag) {
        // todo: this got even messier with react-query@3
        const queries = queryClient.getQueryCache().getAll()
        // brittle, this only works if we only keep one /xpackets query in the cache
        const xpacketQuery = queries.find(
          (q) => q.queryKey?.[0] === IAP.dir.listXpackets
        )

        // Cancel any outgoing refetches to this exact key
        // (so they don't overwrite our optimistic update)
        queryClient.cancelQueries(xpacketQuery.queryKey)

        // Keep track of previous value in case we need to revert
        let revertTo: XpacketTag

        // Optimistically update to the new value
        type QD = TeaTimeBaseData
        queryClient.setQueryData<QD>(xpacketQuery.queryKey, (old) => {
          return produce(old, (draft) => {
            const xp = draft.xpackets.find((xp) => xp.id === newTag.xpacketId)
            revertTo = xp.tag
            xp.tag = newTag.tag
          })
        })

        // Update status map
        setStatusMap(
          produce((draft) => {
            draft[newTag.xpacketId] = {
              status: 'loading',
              currentValue: newTag.tag,
              rollbackValue: revertTo,
            }
          })
        )

        // Return a rollback function
        return () =>
          queryClient.setQueryData<QD>(xpacketQuery.queryKey, (old) => {
            return produce(old, (draft) => {
              const xp = draft.xpackets.find((xp) => xp.id === newTag.xpacketId)
              // check if xpacket exists just in case we've changed contexts
              if (xp) {
                xp.tag = revertTo
              }
            })
          })
      },
      // update status map
      onSuccess(data, variables, context) {
        setStatusMap(
          produce((draft) => {
            draft[variables.xpacketId].status = 'success'
          })
        )
      },
      // If the mutation fails, use the value returned from onMutate to roll back
      onError(err, failedPayload, rollback) {
        rollback()
        setStatusMap(
          produce((draft) => {
            draft[failedPayload.xpacketId].status = 'error'
          })
        )
        // todo: do i need to invalidate any query here?
      },
    }
  )

  return { statusMap, submitTag: mutation.mutate }
}
