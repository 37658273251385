import isEqual from 'lodash/isEqual'
import { RefObject, useEffect } from 'react'
import { useStateAndRef } from './useRefs'

type UseScrollWindowProps = {
  itemHeight: number
  itemWidth?: number
  parentRef: RefObject<HTMLDivElement>
  scrollPaddingLeft?: number
  scrollPaddingTop?: number
}

type ScrollWindow = {
  xIdx: number
  xLength: number
  yIdx: number
  yLength: number
}

/**
 * Quick and dirty...
 * * Currently renders extra items under
 */
export function useVirtualGrid(props: UseScrollWindowProps) {
  const { itemHeight, itemWidth = itemHeight, parentRef } = props
  const scrollPaddingLeft = props.scrollPaddingLeft ?? 0
  const scrollPaddingTop = props.scrollPaddingTop ?? 0

  let [value, setValue, valueRef] = useStateAndRef<ScrollWindow>()

  useEffect(() => {
    let unmounted = false
    let el = parentRef.current

    // scroll listener
    const onChange = () => {
      let adjWidth = el.clientWidth - scrollPaddingLeft
      let adjHeight = el.clientHeight - scrollPaddingTop
      let next = {
        xIdx: Math.floor(el.scrollLeft / itemWidth),
        xLength: Math.ceil(adjWidth / itemWidth),
        yIdx: Math.floor(el.scrollTop / itemHeight),
        yLength: Math.ceil(adjHeight / itemHeight),
      }

      if (!unmounted && !isEqual(valueRef.current, next)) {
        setValue(next)
      }
    }

    // initialize
    onChange()

    // listen to scroll and resize
    el.addEventListener('scroll', onChange, {
      capture: false,
      passive: true,
    })

    const resizeObserver = new ResizeObserver(() => {
      onChange()
    })

    resizeObserver.observe(el)

    // clean up
    return () => {
      el.removeEventListener('scroll', onChange)
      resizeObserver.disconnect()
      unmounted = true
    }
  }, [])

  return value
}
