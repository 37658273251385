import type {
  ApiPEGetPackets,
  ApiPEPublish,
  ApiPESubmitAnswerKey,
  ApiPESubmitCrossPacket,
  ApiPESubmitStd,
} from '@paper/api'
import type { PDFDocument } from '@paper/pdf'
import { Curriculum, IAP, Packetmeta } from '@paper/schema'
import { fetcher } from '@paper/utils'
import produce from 'immer'
import { useLayoutEffect, useRef } from 'react'
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query'
import { useUser } from '~src/blocks/userProvider'
import { SubmitPdfProps, _submitPdf } from '~src/data/_submitPdf'
import { __useGetCypressStub__ } from '~src/utils/cypress'
import rollbar from '~src/utils/rollbar'
import { useShallowMemo } from '~src/utils/useMemos'
import { loadPdf } from './loadPdf'
import { EntryStub } from './stubs/entryStubs'

type OnQuery<T> = (qResult: UseQueryResult<T>) => void
/**
 * todo: Is there a built-in way to do this?
 * `useQuery` has `onSettled`, but I think I read it only fires if the query function executes
 */
export function useListenQuery<T>(
  qResult: UseQueryResult<T>,
  isActive: boolean,
  onQuery: OnQuery<T>
) {
  const { dataUpdatedAt, isFetching, status } = qResult

  // todo: feels like this is a particularly ugly way to accomplish...anyway...
  // todo: may be a way to simplify tweaking how isActive works?
  // keep track of whether the query has changed (status or data has updated)
  const listenKey = useShallowMemo({ dataUpdatedAt, status })
  // have a ref to keep track of the most recent value we've fired on
  const lastFiredRef = useRef<typeof listenKey>()
  // check whether the key has changed
  const hasKeyChanged = listenKey !== lastFiredRef.current

  // console.log({ ...listenKey, hasKeyChanged, isFetching, isActive })

  useLayoutEffect(() => {
    // fire (and update ref) if key changes, and active
    // todo: and !isFetching
    // the added wrinkle is that the query can go from idle->success (but fetching)
    // which then caused this to fire with the old data
    // todo: need a better solution to this disaster...maybe revisit with react-query@4
    if (hasKeyChanged && isActive && !isFetching) {
      // console.log('firing', listenKey, qResult.data, qResult)
      lastFiredRef.current = listenKey
      onQuery(qResult)
    }
  }, [listenKey, isActive])
}

const QueryKeys = {
  packets: ({ contentId }): QueryKey => [
    IAP.packetEntry.getPackets,
    { contentId },
  ],
  pdfBytes: ({ contentId }): QueryKey => ['pdfBytes', { contentId }],
}

export function usePacketEntryDataStubber() {
  const queryClient = useQueryClient()
  return (stub: EntryStub) => {
    queryClient.setQueryData(QueryKeys.packets(stub.state), stub.packetData)
  }
}

export type GetPacketEntryData = ApiPEGetPackets['result'] & {
  focusPacket: Packetmeta
}

export function useGetPacketEntryData(
  contentId: string,
  curriculum: Curriculum,
  isActive: boolean,
  onQuery: OnQuery<GetPacketEntryData>
) {
  const { fetchAs } = useUser()
  const queryKey = QueryKeys.packets({ contentId })
  const qResult = useQuery(
    queryKey,
    async () => {
      // todo: react-query is firing with null when going from stub to not...
      if (!contentId) {
        return
      }
      console.log('fetching', contentId)
      let result = await fetchAs(IAP.packetEntry.getPackets, {
        searchParams: { contentId },
      }).json<ApiPEGetPackets['result']>()

      let { packets } = result

      const focusPacket =
        packets?.find((p) => p.curriculum.id === curriculum.id) ?? packets[0]

      return { focusPacket, ...result }
    },
    { enabled: isActive, staleTime: Infinity, cacheTime: Infinity }
  )

  useListenQuery(qResult, isActive, onQuery)

  return qResult
}

type FetchedPdf = { srcBuf: ArrayBuffer; srcDoc: PDFDocument }
type FetchPdfProps = { contentId: string; url: string }
/**
 * Fetch Pdf bytes
 */
export function useFetchPdf(
  props: FetchPdfProps,
  isActive: boolean,
  onQuery: OnQuery<FetchedPdf>
) {
  const qResult = useQuery(
    QueryKeys.pdfBytes({ contentId: props?.contentId }),
    async () => {
      const fetched = await loadPdf(props.url)
      return fetched
    },
    { enabled: !!(isActive && props?.contentId) }
  )
  useListenQuery(qResult, isActive, onQuery)
}

/**
 * We need to invalidate the packets query on certain actions
 * todo: cleaner way to type this?
 * todo: cleaner way to inject?
 */
function useInvalidatePacketsConfig<T, U, V, W>(
  config: UseMutationOptions<T, U, V, W>
) {
  config = config ?? {}
  const queryClient = useQueryClient()
  // pare off the queryKey prefix for the packets query
  const invalidateKey = QueryKeys.packets({ contentId: null })[0] as string
  return produce(config, (draft) => {
    draft.onSuccess = async (...args) => {
      await queryClient.invalidateQueries(invalidateKey, {
        refetchInactive: true,
      })
      return config.onSuccess?.(...args)
    }
  })
}

/////////////////////////
// Submit Pdf
/////////////////////////
export function useSubmitPdf(
  config?: UseMutationOptions<boolean, unknown, SubmitPdfProps>
) {
  const { fetchAs } = useUser()
  config = useInvalidatePacketsConfig(config)
  // Give cypress the opportunity to replace the function
  let getAction = __useGetCypressStub__(_submitPdf, '__cypress_submitPdf__')

  // make this interceptable
  return useMutation(async (props: SubmitPdfProps) => {
    // Awkwardly factored this out because it's useful to call this for the headless tests
    let submitPdf = getAction()
    await submitPdf(props, fetchAs, fetcher)
    return true
  }, config)
}

/////////////////////////
// Submit Answerkey
/////////////////////////
type SubmitAnswerkeyProps = ApiPESubmitAnswerKey['body']
export function useSubmitAnswerkey(
  config?: UseMutationOptions<boolean, unknown, SubmitAnswerkeyProps>
) {
  const { fetchAs } = useUser()
  config = useInvalidatePacketsConfig(config)
  return useMutation(async (props) => {
    let step = 'submitting to Paper'
    try {
      await fetchAs.put(IAP.packetEntry.submitAnswerKey, { json: props })
      // Write to clipboard after submit for example data workaround
      // @ts-expect-error
      window.ANSWER_KEY = JSON.stringify(props)
      console.log('Run the following to copy to clipboard:\ncopy(ANSWER_KEY)')
      return true
    } catch (error) {
      // TODO: Copy/pasty from submitPdf
      error.friendly = `There was a problem ${step}`
      error.values = props
      // Send to rollbar
      if (process.env.NODE_ENV === 'production ') {
        rollbar.error(error)
      }
      throw error
    }
  }, config)
}

/////////////////////////
// Publish
/////////////////////////
type SubmitPublishProps = ApiPEPublish['body']
export function useSubmitPublish(
  config?: UseMutationOptions<undefined, unknown, SubmitPublishProps>
) {
  const { fetchAs } = useUser()
  config = useInvalidatePacketsConfig(config)
  const { mutateAsync, ...result } = useMutation(async (props) => {
    let step = 'publishing'
    try {
      await fetchAs.put(IAP.packetEntry.publish, { json: props })
    } catch (error) {
      // TODO: Copy/pasty from submitPdf
      error.friendly = `There was a problem ${step}`
      error.values = props
      // Send to rollbar
      if (process.env.NODE_ENV === 'production ') {
        rollbar.error(error)
      }
      throw error
    }
  }, config)
  // react-query 2.0 backwards compat
  return [mutateAsync, result] as const
}

/////////////////////////
// External links
/////////////////////////
export function useSubmitCrossPacket(
  config?: UseMutationOptions<boolean, unknown, ApiPESubmitCrossPacket['body']>
) {
  const { fetchAs } = useUser()
  config = useInvalidatePacketsConfig(config)
  const { mutateAsync, ...result } = useMutation(async (props) => {
    await fetchAs.put(IAP.packetEntry.submitCrossPacket, { json: props })
    return true
  }, config)
  // react-query 2.0 backwards compat
  return [mutateAsync, result] as const
}

/**
 * Hook to submit standards
 */
export function useSubmitStds(
  config?: UseMutationOptions<boolean, unknown, ApiPESubmitStd['body']>
) {
  const { fetchAs } = useUser()
  config = useInvalidatePacketsConfig(config)
  return useMutation(async (props) => {
    await fetchAs.put(IAP.packetEntry.submitStds, { json: props })
    return true
  }, config)
}
