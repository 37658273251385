import { Redirect } from '@paper/route'
import { useUser } from '~src/blocks/userProvider'
import { FullPageLoading } from '~src/components/status'
import { useDirectoryData } from '~src/data/data-directory'
import { Routes } from '~src/routes'

export function HomeRedirect() {
  const qResult = useDirectoryData()
  const { user } = useUser()

  return (
    <FullPageLoading qResult={qResult}>
      {() => {
        const isTeacher = qResult.data?.teacher.map.has(user.teacherId)
        const isAdmin = user.roles.includes('admin')
        let action = isTeacher
          ? // teacher view if teacher
            Routes.home_teacher.navigateAction({ teacherId: user.teacherId })
          : isAdmin
          ? // curriculum view if admin
            Routes.home_curriculum.navigateAction({})
          : // default to teacher view for now
            Routes.home_teacher.navigateAction({})
        return <Redirect action={action} />
      }}
    </FullPageLoading>
  )
}
