import Rollbar from 'rollbar'
import config from './config'

export default new Rollbar({
  accessToken: config.rollbarClientKey,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === 'production' && config.meta.where !== 'int',
  payload: {
    ...config.meta,
    environment: config.meta.branch,
  },
})
