import type {
  ApiScanBatchDetails,
  ApiScanBatchList,
  ApiScanFixLookup,
  ApiScanFixSet,
} from '@paper/api'
import { useRouter } from '@paper/route'
import { CrunchBatch, IAP, StatusChunk } from '@paper/schema'
import { orderBy } from 'lodash'
import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ListDigest, useListCallbacks } from '~src/blocks/list'
import { useUser } from '~src/blocks/userProvider'
import { laScanBatch } from '~src/data/listAdapters'
import { SparseAxisLookup } from '~src/pages/pinGrid/pinGridAirlock'
import type { RD_Scanlog } from '../routes'
import { CandidateInputs, useScanlogContext } from './scanlogAirlock'

export type ProblemChunk = StatusChunk & { batchId: string }

export type ScanBatchDigest = ListDigest<
  CrunchBatch,
  {
    isMore: boolean
    noMatches: boolean
    problems: {
      hasAny: boolean
      hasNext: boolean
      hasPrev: boolean
      onNext(): void
      onPrev(): void
    }
    scanUsers: string[]
  }
>

/**
 * Add "invalidate on update" to query keys so I can find them later
 * Another option would be to keep an explicit list...
 */
export const QK_IOU = 'iou'

export function useScanBatchList(): ScanBatchDigest {
  const url = IAP.scanbatch.list
  const { fetchAs } = useUser()
  const { dispatchStay, routeData } = useRouter<RD_Scanlog>()
  const payload: ApiScanBatchList['body'] = {
    // todo: date
    packetId: routeData.packetId,
    scanUser: routeData.scanUser,
    status: routeData.status,
    teacherId: routeData.teacherId,
  }
  const qResult = useQuery([QK_IOU, url, payload], async () => {
    let { isMore, items, scanUsers } = await fetchAs
      .post(url, { json: payload })
      .json<ApiScanBatchList['result']>()
    items = orderBy(items, (sb) => sb.scanDate, 'desc')

    return { isMore, items, scanUsers }
  })

  let allItems = qResult.data?.items

  let items = useMemo(() => {
    return !routeData.xpacketId
      ? allItems
      : allItems?.filter((p) => p.xpacketIds.includes(routeData.xpacketId))
  }, [allItems, routeData.xpacketId])

  let noMatches = allItems?.length && routeData.xpacketId && items.length === 0
  // include all items if no xpacket matches
  if (noMatches) {
    items = allItems
  }

  const listCallbacks = useListCallbacks(items, laScanBatch)

  const toAddressKey = (rd: RD_Scanlog) =>
    `${rd.sb_batchId}_${rd.si_imageId ?? null}`

  // todo: not sure this is the right approach! :)))
  const problemAxisStuff = useMemo(() => {
    // Put together a "problem axis" so it's easy to jump to pins
    // note: this is copy/pasted from pinGridAirlock!
    let problemAxis: RD_Scanlog[] = []
    let problemAxisLookup: SparseAxisLookup = new Map()
    for (let y = 0; y < items?.length; y++) {
      const batch = items[y]

      // start at -1 so we get an entry for none selected
      for (let x = -1; x < batch.chunks.length; x++) {
        let candidate = batch.chunks[x]
        const address = {
          sb_batchId: batch.id,
          si_imageId: candidate?.items[0].id,
        }
        const addressKey = toAddressKey(address)
        if (candidate && candidate.status !== 'success') {
          // this axis only includes errors
          problemAxis.push(address)
          // note: not sure why i did it this way, but the index is where to look BEFORE going -1 or 1
          problemAxisLookup.set(addressKey, {
            [-1]: problemAxis.length - 1,
            1: problemAxis.length - 1,
          })
        } else {
          // add lookup entry for skipped items too
          problemAxisLookup.set(addressKey, {
            [-1]: problemAxis.length, // next one if we're going backwards
            1: problemAxis.length - 1, // prev one if we're going forwards
          })
        }
      }
    }

    return { problemAxis, problemAxisLookup }
  }, [items])

  // todo: not sure this is the right approach! :))) (continued)
  const problems = useMemo(() => {
    let addressKey = toAddressKey(routeData)
    let axisIndices = problemAxisStuff.problemAxisLookup.get(addressKey) ?? {
      [-1]: null,
      [1]: -1,
    }

    let [prevItem, nextItem] = [-1, 1].map(
      (amt) => problemAxisStuff.problemAxis[axisIndices[amt] + amt]
    )

    return {
      hasAny: problemAxisStuff.problemAxis.length > 0,
      hasNext: !!nextItem,
      hasPrev: !!prevItem,
      onNext: () => dispatchStay(nextItem),
      onPrev: () => dispatchStay(prevItem),
    }
  }, [problemAxisStuff, routeData.sb_batchId, routeData.si_imageId])

  return {
    adapter: laScanBatch,
    qResult,
    success: !qResult.isSuccess
      ? null
      : {
          ...listCallbacks,
          empty: allItems?.length === 0,
          items,
          otherData: {
            isMore: qResult.data.isMore,
            noMatches,
            problems,
            scanUsers: qResult.data.scanUsers,
          },
        },
  }
}

/**
 * Grabs scanbatch details
 */
export function useScanBatchDetails(packetIds: string[]) {
  const url = IAP.scanbatch.details
  const { fetchAs } = useUser()
  const { routeData } = useRouter<RD_Scanlog>()
  const { sb_batchId: batchId } = routeData
  const enabled = !!(batchId && packetIds?.length)

  const payload: ApiScanBatchDetails['body'] = { batchId, packetIds }
  const qResult = useQuery(
    [QK_IOU, url, payload],
    async () => {
      let { packetLabels, xpageIds } = await fetchAs
        .post(url, { json: payload })
        .json<ApiScanBatchDetails['result']>()

      return { packetLabels, xpageIdSet: new Set(xpageIds) }
    },
    { enabled, staleTime: Infinity }
  )

  return qResult
}

/**
 * Grabs details about a scanfix candidate
 */
export function useScanFixCandidateDetails(props: CandidateInputs) {
  const { fetchAs } = useUser()

  const qrb = props?.qrbComplete
  const xpageId = props?.xpageId

  const url = IAP.scanfix.lookup
  let json: ApiScanFixLookup['query'] = { qrb, xpageId }

  return useQuery(
    [QK_IOU, url, json],
    async () => {
      let data = await fetchAs
        .post(url, { json })
        .json<ApiScanFixLookup['result']>()
      // todo: handle multiple
      return data[0]
    },
    {
      enabled: !!(qrb || xpageId),
    }
  )
}

/**
 * Scanlog fix mutation...
 */
export function useScanFixMutation() {
  const { fetchAs } = useUser()
  const queryClient = useQueryClient()
  const { reset } = useScanlogContext()

  return useMutation(
    async (args: ApiScanFixSet['body']) => {
      await fetchAs.post(IAP.scanfix.set, { json: args })
    },
    {
      async onSuccess() {
        // clear inputs
        reset()
        // invalidate all the dependent queries
        await queryClient.invalidateQueries(QK_IOU)
      },
    }
  )
}
