import { Box, useColorModeValue } from '@chakra-ui/react'
import * as SeparatorPrimitive from '@radix-ui/react-separator'

export function Separator(props: SeparatorPrimitive.SeparatorProps) {
  const bg = useColorModeValue('gray.100', 'rgba(255,255,255,.2)')
  return (
    <SeparatorPrimitive.Root asChild {...props}>
      <Box
        bg={bg}
        opacity={0.75}
        sx={{
          '&[data-orientation=horizontal]': {
            alignSelf: 'stretch',
            height: '1px',
          },
          '&[data-orientation=vertical]': {
            alignSelf: 'stretch',
            width: '1px',
          },
        }}
      />
    </SeparatorPrimitive.Root>
  )
}
