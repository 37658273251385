import { UseQueryResult } from 'react-query'
import { ListDigest } from '~src/blocks/list'

/**
 * Airlocks and such shouldn't run until we have data
 */
export const isRQReady = (qResult: UseQueryResult) =>
  !qResult.isLoading && !qResult.isFetching

/**
 * Checks if digest needs url change ready+selectedId,but no selected item
 */
export const checkDigestAirlock = (digest: ListDigest) => {
  return (
    digest.success &&
    !digest.qResult.isFetching &&
    digest.success.selectedId &&
    !digest.success.selectedItem
  )
}
