import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  List,
  ListItem,
  ListProps,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react'
import { IcoChevronDown, IcoChevronUp, IcoX } from '@paper/icons'
import { DARK_MODE_FG, DEFAULT_FG, Z } from '@paper/styles'
import {
  useCombobox,
  UseComboboxProps,
  UseComboboxReturnValue,
  UseComboboxStateChange,
} from 'downshift'
import {
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Txt } from '..'
import { alwaysHighlightReducer } from './comboBoxReducer'

////////////////////////
// Types
////////////////////////
export type ComboBoxProps<T = any> = {
  allowAdd?: boolean
  autoFocus?: boolean
  blurOnSelect?: boolean
  busy?: boolean
  caret?: boolean
  clearable?: boolean
  disabled?: boolean
  empty?: ReactNode
  fontFamily?: InputProps['fontFamily']
  inputTextAlign?: InputProps['textAlign'] // todo: bad api!
  isOpen?: boolean
  items: T[]
  itemToString(item: T): string
  label?: ReactNode
  onInputValueChange?: UseComboboxProps<T>['onInputValueChange']
  onChange?(item?: T): void
  onSelect?(item: T): void
  openIfNoSelection?: boolean
  placeholder?: string
  renderItem?(item: T): ReactNode
  round?: boolean // todo: bad api with composability
  selectedItem?: T
  size?: InputProps['size']
  variant?: 'filled' | 'outline'
  width?: InputProps['width'] // todo: bad api with composability
}

type ComboBoxRootProps<T> = ComboBoxProps<T> & { children: ReactNode }
type ComboBoxInputProps = { placeholder?: string }
type ComboBoxListProps = { type?: 'permanent' | 'popover' } & ListProps

type ComboBoxContext<T = any> = UseComboboxReturnValue<T> &
  ComboBoxProps<T> & {
    inputRef: RefObject<HTMLInputElement>
    isListShown: boolean
    overrideOpen: boolean
  }

////////////////////////
// Shell + context
////////////////////////
const ComboBoxContext = createContext<ComboBoxContext<any>>(undefined)

/**
 * @example
 * <ComboBox.Root {...props}>
 *  <ComboBox.Shell>
 *    <ComboBox.Input />
 *    <ComboBox.List />
 *  </ComboBox.Shell />
 * </ComboBox.Root />
 */
function ComboBoxRoot<T>(props: ComboBoxRootProps<T>) {
  // Safe version of itemToString
  const itemToString = (item: T) =>
    !item ? '' : props.itemToString?.(item) ?? item.toString() ?? ''

  const inputRef = useRef<HTMLInputElement>()

  // Straightforward onSelect
  const onSelectedItemChange = useCallback(
    (changes: UseComboboxStateChange<T>) => {
      changes.selectedItem && props.onSelect?.(changes.selectedItem)
      props.onChange?.(changes.selectedItem)
      props.blurOnSelect && inputRef.current.blur()
    },
    [props.blurOnSelect, props.onSelect]
  )

  // downshift doesn't like switching between uncontrolled and controlled...
  // but i want to be able to override...this api seems not great though!
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false)
  let overrideOpen = props.openIfNoSelection && props.selectedItem == null
  let isOpen = (overrideOpen || props.isOpen) ?? uncontrolledIsOpen

  // Get downshift values
  const downshiftOut = useCombobox({
    isOpen,
    items: props.items,
    itemToString,
    onInputValueChange: props.onInputValueChange,
    onIsOpenChange: (changes) => setUncontrolledIsOpen(changes.isOpen),
    onSelectedItemChange,
    selectedItem: props.selectedItem,
    stateReducer: alwaysHighlightReducer,
  })

  // Determine is we show the list
  const isListShown = isOpen && !props.disabled && !props.busy

  // Stuff everything into context
  const ctx = {
    ...downshiftOut,
    ...props,
    inputRef,
    isListShown,
    itemToString,
    overrideOpen,
  }

  return (
    <ComboBoxContext.Provider value={ctx}>
      {props.children}
    </ComboBoxContext.Provider>
  )
}

////////////////////////
// Empty Message
////////////////////////
type EmptyProps = { children?: ReactNode }
function Empty(props: EmptyProps) {
  return (
    // todo: should be less hardcoded
    <Box opacity={0.5} px={4} py={1} textAlign="center" userSelect="none">
      {props.children}
    </Box>
  )
}

/**
 * ComboBox Container
 */
function ComboBoxShell(props: BoxProps) {
  const { fontFamily, getComboboxProps, width } = useContext(ComboBoxContext)

  return (
    <Box
      fontFamily={fontFamily}
      position="relative"
      width={width}
      {...props}
      {...getComboboxProps()}
    >
      {props.children}
    </Box>
  )
}

/**
 * ComboBox Input
 */
function ComboBoxInput(props: ComboBoxInputProps) {
  const {
    autoFocus,
    busy,
    caret,
    clearable,
    disabled,
    getInputProps,
    getToggleButtonProps,
    inputRef,
    inputTextAlign,
    isOpen,
    itemToString,
    openMenu,
    overrideOpen,
    placeholder,
    round,
    reset,
    selectedItem,
    size,
    variant,
  } = useContext(ComboBoxContext)

  // Determine caret/spinner/x
  let inputRight: ReactNode
  const inputRightBtnProps: Omit<IconButtonProps, 'aria-label'> = {
    isRound: round,
    size,
    variant: 'ghost',
  }
  if (busy) {
    inputRight = <Spinner size={size} speed=".5s" />
  } else if (clearable && selectedItem && !disabled) {
    inputRight = (
      <IconButton
        {...inputRightBtnProps}
        aria-label="clear"
        icon={<IcoX />}
        onClick={(event) => {
          event.stopPropagation()
          reset()
        }}
      />
    )
  } else if (caret && !disabled) {
    inputRight = (
      <IconButton
        {...inputRightBtnProps}
        {...getToggleButtonProps()}
        aria-label={isOpen ? 'Close menu' : 'Open menu'}
        icon={isOpen ? <IcoChevronUp /> : <IcoChevronDown />}
      />
    )
  }

  // todo: theme colors...
  let { filledBg, phColor } = useColorModeValue(
    { filledBg: 'gray.50', phColor: DEFAULT_FG },
    { filledBg: '#3b3b3b', phColor: DARK_MODE_FG }
  )

  // Accumulate props from variant etc.
  const variantProps: InputProps = {}
  if (variant === 'filled') {
    variantProps.bg = filledBg
    variantProps.border = 'none'
  }
  if (round) {
    variantProps.borderRadius = '5rem' // setting this to something big seems to magically work for all sizes (?)
  }

  // focus if we're newly overriding open
  // todo: presumably focus should be coordinated outside the combobox
  useEffect(() => {
    if (overrideOpen) {
      // todo: tab element is stealing focus...
      setTimeout(() => {
        inputRef.current?.focus()
      }, 75)
    }
  }, [overrideOpen])

  return (
    <InputGroup
      onClick={() => !isOpen && openMenu()} // todo: option for open on click?
      size={size}
    >
      <Input
        {...variantProps}
        // todo: should i be passing all of these to getInputProps?
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={
          selectedItem
            ? itemToString(selectedItem)
            : props.placeholder ?? (placeholder || null) // coalesce to null to avoid error on false
        }
        // cheating using the placeholder as the selected value
        sx={{
          '::placeholder': {
            color: selectedItem ? phColor : null,
            fontWeight: 300,
          },
        }}
        textAlign={inputTextAlign}
        {...getInputProps({
          ref: inputRef,
          onKeyDown: (event) => event.stopPropagation(),
        })}
      />
      {inputRight && (
        <InputRightElement color={variantProps.color}>
          {inputRight}
        </InputRightElement>
      )}
    </InputGroup>
  )
}

/**
 * ComboBox List
 */
function ComboBoxList(props: ComboBoxListProps) {
  const {
    empty,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    isListShown,
    items,
    itemToString,
    renderItem,
    round,
    selectedItem,
    size,
    variant,
  } = useContext(ComboBoxContext)

  const { type = 'permanent', ...listProps } = props
  const bg = useColorModeValue('white', '#3b3b3b') // todo: actual dark value
  const highlightBg = useColorModeValue('gray.100', '#333333') // todo: centralize these colors

  // default props for the popover version
  // todo: should probably render in an actual popover...
  const defaultProps: ListProps =
    type === 'popover'
      ? {
          borderRadius: round ? '1rem' : '.25rem',
          borderWidth: variant === 'outline' ? '1px' : null,
          boxShadow: '0 0.5em 1em rgb(0 0 0 / 18%)',
          left: 0,
          marginTop: '4px',
          maxHeight: '250px',
          position: 'absolute',
          right: 0,
          zIndex: Z.popup,
        }
      : {}

  return (
    <List
      {...defaultProps}
      bg={bg}
      fontSize={size}
      overflow="auto"
      py={2}
      // todo: downshift complains if i wrap isOpen here
      visibility={isListShown ? 'visible' : 'hidden'}
      {...listProps}
      {...getMenuProps()}
    >
      {isListShown &&
        (!items.length
          ? empty ?? <Empty>No items</Empty>
          : items.map((item, index) => {
              const isSelected = item === selectedItem
              const bg = index === highlightedIndex ? highlightBg : null
              return (
                <ListItem
                  bg={bg}
                  borderLeftColor={isSelected ? 'blue.500' : 'transparent'}
                  borderLeftWidth={4}
                  cursor="pointer"
                  key={index} // todo: itemToKey instead?
                  pl={3} // compensate for border
                  pr={4}
                  py={1}
                  userSelect="none"
                  {...getItemProps({ item, index })}
                >
                  {renderItem?.(item) ?? itemToString(item)}
                </ListItem>
              )
            }))}
    </List>
  )
}

export function ComboBoxLabel(props: BoxProps) {
  const { getLabelProps, size } = useContext(ComboBoxContext)
  return (
    <Txt
      as="label"
      fontSize={size}
      userSelect="none"
      {...props}
      {...getLabelProps()}
    />
  )
}

////////////////////////
// All-in-one ComboBox
////////////////////////
/**
 * All-in-one ComboBox
 * if you need more customization use `<ComboBox.Shell>`
 * @example
 * <ComboBox {...yourProps} />
 */
export function ComboBox<T>(props: ComboBoxProps<T>) {
  const { label, ...rootProps } = props
  return (
    <ComboBoxRoot {...rootProps}>
      {label && <ComboBoxLabel>{label}</ComboBoxLabel>}
      <ComboBoxShell>
        <ComboBoxInput />
        <ComboBoxList type="popover" />
      </ComboBoxShell>
    </ComboBoxRoot>
  )
}

////////////////////////
// Exports
////////////////////////
ComboBox.Root = ComboBoxRoot
ComboBox.Shell = ComboBoxShell
ComboBox.Input = ComboBoxInput
ComboBox.Label = ComboBoxLabel
ComboBox.List = ComboBoxList
ComboBox.Empty = Empty
