import { Icon, IconProps, Tooltip } from '@chakra-ui/react'
import {
  IcoDiscountIlluminate,
  IcoInfo,
  IcoPrint,
  IcoRadioactive,
  IcoRecall,
  IcoStaple,
  IcoUnnamed,
} from '@paper/icons'
import { useLink } from '@paper/route'
import { DirPacket } from '@paper/schema'
import { ILL_COLOR, PktColors } from '@paper/styles'
import { getFullName } from '@paper/utils'
import { Fragment, ReactNode } from 'react'
import { BLink, HStack, TooltippedIcon, Txt, VStack } from '~src/components'
import { UghColumn } from '~src/components/table'
import { useDirectoryData } from '~src/data/data-directory'
import { PublishState } from '~src/pages/publish/publish/publishListCols'
import { Routes } from '~src/routes'
import { formatPastVsNow } from '~src/utils/messages'
import { PageCounts } from '../pageCounts'
import { ScanBar, ScanBarLegend } from '../pctBar'
import { ViewLink, ViewNavCell } from './packetCellViews'
import { IconColumnProps, IconLabel, SIBP } from './tableColUtils'

type Col = UghColumn<DirPacket>

const PacketNumber: Col = {
  props: { width: 64 },
  label: () => <Txt fontFamily="mono">#</Txt>,
  cell: (item) =>
    item.pub.stage === 'recalled' ? (
      <Icon as={IcoRecall} color={`${PktColors.Recall}.500`} />
    ) : (
      <Txt fontFamily="mono" fontWeight={600}>
        {item.number}
      </Txt>
    ),
}

const PacketName: Col = {
  props: { align: 'start' },
  label: () => 'Packet Name',
  cell: (item) => (
    <Fragment>
      {item.pub.radioactive && (
        <Icon as={IcoRadioactive} color={`${PktColors.Nuclear}.500`} mr={1} />
      )}
      {item.name}
    </Fragment>
  ),
}

const PacketViews: Col = {
  props: { width: 104 }, // avoid jumping
  label: () => 'Student work',
  cell: (item) => {
    return <ViewNavCell item={item} />
  },
}

const ScanStatus: Col = {
  props: { width: 160 },
  label: () => (
    <Tooltip label={<ScanBarLegend />}>
      <HStack gap={1}>
        Scan status <Icon as={IcoInfo} />
      </HStack>
    </Tooltip>
  ),
  cell: (item) => {
    if (!item.scan || !item.print.count) {
      return null
    }
    return <ScanStatusButton {...item} />
  },
}

function ScanStatusButton(item: DirPacket) {
  // todo: copy/pasty...
  const linkProps = useLink(
    Routes.scanlog.mergeAction({ packetId: item.id, teacherId: item.teacherId })
  )
  return (
    <BLink {...linkProps} size="sm" variant="ghost" width="160px">
      <ScanBar {...item} />
    </BLink>
  )
}

const Unnamed: Col = {
  props: IconColumnProps,
  label: () => <IconLabel icon={IcoUnnamed} textValue="Unnamed" />,
  cell: (item) => {
    const count = item.scan.unnamedOpen
    if (!count) {
      return null
    }
    return (
      <ViewLink
        {...SIBP}
        aria-label={`View unnamed packets`}
        fontFamily="body"
        fontSize="xs"
        ctx={item}
        icon={count}
        route={Routes.setstudent}
      />
    )
  },
}

const Teacher: Col = {
  props: { align: 'start' },
  label: () => 'Teacher',
  cell: (item) => <TeacherCell {...item} />,
}

function TeacherCell(props: DirPacket) {
  const packet = props
  const dirData = useDirectoryData()
  const teacherMap = dirData.data.teacher.map
  const teacher = teacherMap.get(packet.teacherId).item
  return getFullName(teacher) as any
}

const Curriculum: Col = {
  props: { align: 'start' },
  label: () => 'Curriculum',
  cell: (item) => <CurriculumCell {...item} />,
}

function CurriculumCell(props: DirPacket) {
  const packet = props
  const dirData = useDirectoryData()
  const curriculumMap = dirData.data.curriculum.map
  const item = curriculumMap.get(packet.curriculumId).item
  return (
    <Txt as="span" fontFamily="mono">
      {item.name}
    </Txt>
  )
}

const Staple: Col = {
  props: { width: 80 },
  label: () => (
    <IconLabel icon={IcoStaple} textValue="Page counts for stapling" />
  ),
  cell: (item) => <PageCounts packet={item} variant="compact" />,
}

const Illuminate: Col = {
  props: IconColumnProps,
  label: () => (
    <IconLabel icon={IcoDiscountIlluminate} textValue="Illuminate link" />
  ),
  cell: (item) => {
    // heuristic for mis-entered
    // todo: presumably want this in SWMenu too...
    const foundQs = item.questions[0]?.outOf != null

    let tooltip: ReactNode
    let strLabel: string
    let color: IconProps['color']

    if (!item._illuminate) {
      color = 'gray.50'
      strLabel = 'No Illuminate id entered'
    } else if (!foundQs) {
      color = 'yellow.400'
      tooltip = (
        <VStack alignItems="stretch" fontSize="sm" gap={1} p={1}>
          <Txt>
            <Txt as="span" fontSize="md" fontFamily="mono">
              {item._illuminate}
            </Txt>{' '}
            not found yet, likely due to one of:
          </Txt>
          <Txt>(1) The assessment results aren't in Illuminate yet</Txt>
          <Txt>
            (2) Illuminate results for this assessment haven't reached Paper yet
          </Txt>
          <Txt>(3) A typo in the Illuminate id</Txt>
        </VStack>
      )
    } else {
      color = ILL_COLOR
      tooltip = (
        <VStack alignItems="stretch" fontSize="sm" gap={1} p={1}>
          <Txt as="span" fontSize="md" fontFamily="mono">
            {item._illuminate}
          </Txt>
        </VStack>
      )
    }

    return (
      <TooltippedIcon
        aria-label={strLabel}
        as={IcoDiscountIlluminate}
        color={color}
        tooltipProps={{ placement: 'left', label: tooltip ?? strLabel }}
      />
    )
  },
}

const DirPacketPublishStage: Col = {
  ...PublishState,
  cell: (item) =>
    PublishState.cell({
      id: item.id,
      _pub: item.pub.stage,
      _updates: { publish: { time: item.pub.date, user: null } },
    }),
}

const PrintState: Col = {
  props: IconColumnProps,
  label: () => <IconLabel icon={IcoPrint} textValue="Print state" />,
  cell: (item) => {
    const isPrinted = !!item.print.date

    if (!isPrinted) {
      return null
    }
    if (item.pub?.radioactive) {
      return (
        <TooltippedIcon
          aria-label={`Warning: This packet was edited after it was printed and may be in an inconsistent state.`}
          as={IcoRadioactive}
          color={`${PktColors.Nuclear}.500`}
        />
      )
    } else {
      return (
        <TooltippedIcon
          aria-label={`Printed ${formatPastVsNow(item.print.date)}`}
          as={IcoPrint}
          color={`${PktColors.Print}.500`}
        />
      )
    }
  },
}

export const teacherPacketColumns = [
  PacketNumber,
  PacketName,
  ScanStatus,
  Unnamed,
  PacketViews,
]

export const curriculumPacketColumns = [
  PacketNumber,
  PacketName,
  DirPacketPublishStage,
  PrintState,
  Illuminate,
]

export const schoolPacketColumns = [
  Staple,
  Curriculum,
  Teacher,
  ScanStatus,
  Unnamed,
  PacketViews,
  Illuminate,
  PacketName,
]
