import { useBoolean } from '@chakra-ui/react'
import mousetrap from 'mousetrap'
import { useEffect } from 'react'

export const useKeyboard = (
  key: string,
  fn: () => void,
  action?: 'keypress' | 'keyup' | 'keydown'
) => {
  useEffect(() => {
    mousetrap.bind(key, fn)
    return () => mousetrap.unbind(key, action)
  }, [key, fn])
}

// todo: Surely there is a library for this?
export function useIsKeyPressed(targetKey: string) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useBoolean(false)

  // Add event listeners
  useEffect(() => {
    mousetrap.bind(targetKey, setKeyPressed.on, 'keydown')
    mousetrap.bind(targetKey, setKeyPressed.off, 'keyup')

    // Remove event listeners on cleanup
    return () => {
      mousetrap.unbind(targetKey, setKeyPressed.on, 'keydown')
      mousetrap.unbind(targetKey, setKeyPressed.off, 'keyup')
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount
  return keyPressed
}
