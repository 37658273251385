/**
 * Returns a school year from a date
 * We assume that the school year starts on or after August
 * (Obviously this needs to be configurable if we end up with schools with different calendars)
 * School year is the trailing year
 * @example
 * getSchoolYearFromDate(new Date("2022-07-31"))
 * // 2022
 * getSchoolYearFromDate(new Date("2022-08-01"))
 * // 2023
 *
 */
export function schoolYearFromDate(date: Date | number) {
  if (date == null) {
    return null
  } else if (!(date instanceof Date)) {
    date = new Date(date)
  }

  const year = date.getFullYear()
  return date.getMonth() >= 7 // August
    ? year + 1
    : year
}
