import { useRouter } from '@paper/route'
import { PacketAxisItem, XpacketTag } from '@paper/schema'
import { useCallback, useEffect, useMemo } from 'react'
import { Tags } from '~src/blocks/tags'
import { RD_Tea_Time } from '../routes'

export type SortOrder = 'asc' | 'desc'
export type ColSortBase = { id: string; order: SortOrder }
type ColSortFull = ColSortBase & { label: string }
export type ColSortContext = {
  colSort: ColSortFull[]
  colSortMap: Map<string, ColSortFull>
  setColSort: (list: ColSortBase[]) => void
}

// todo: rename - this is sorting students...
export const useColSortAirlock = (items: PacketAxisItem[]): ColSortContext => {
  const { dispatchStay, routeData } = useRouter<RD_Tea_Time>()
  const { tgs_rowsBy } = routeData

  // Parse tgs_rowsBy for sorting columsn
  // todo: not sure how stable the `items` reference is such that memo may be pointless...
  const { colSort, colSortMap, invalid } = useMemo(() => {
    const rawList = parseQPArray(tgs_rowsBy)
    const validatedList = rawList
      .map((raw): ColSortFull => {
        const [id, order] = raw.split(':') as [string, ColSortBase['order']]
        const label = items.find((p) => p.id === id)?.number
        return { id, label, order }
      })
      .filter(
        (p) => p.id && p.label && (p.order === 'asc' || p.order === 'desc')
      )

    const result = {
      colSort: validatedList,
      colSortMap: new Map(validatedList.map((p) => [p.id, p])),
      invalid: rawList.length !== validatedList.length,
    }
    //console.log('(4.x) colSort', result)
    return result
  }, [items, tgs_rowsBy])

  const setColSort = useCallback((list: ColSortBase[]) => {
    dispatchStay({
      tgs_rowsBy: list.length ? list.map((p) => `${p.id}:${p.order}`) : null,
    })
  }, [])

  // Airlock
  useEffect(() => {
    if (invalid) {
      setColSort(colSort)
    }
  }, [colSort, invalid])

  return { colSort, colSortMap, setColSort }
}

// todo: fairly copy/pasty with colSort
// todo: also not sure how we might want to change this if there are multiple column filters
// todo: filter by score etc.
type TagFilter = { id: string; tag: XpacketTag }
export type TagFilterContext = {
  tagFilter: TagFilter
  tagFilterMap: Map<string, TagFilter>
  setTagFilter(next: TagFilter): void
}

export const usePacketTagFilterAirlock = (
  items: PacketAxisItem[]
): TagFilterContext => {
  const { dispatchStay, routeData } = useRouter<RD_Tea_Time>()
  const { tagFilter, tagFilterMap, invalid } = useMemo(() => {
    const rawList = parseQPArray(routeData.tgf_tag)
    const validatedList = rawList
      .map((raw): TagFilter => {
        const [id, tagString] = raw.split(':')
        const tag = parseInt(tagString) as XpacketTag
        return { id, tag }
      })
      .filter(
        (p) =>
          // item is in axis
          items.find((pai) => pai.id === p.id) &&
          // tag is valid
          Tags.find((t) => t.id === p.tag)
        // considered checking for non-empty, but i think this might be confusing
        // (p) => items.find((pai) => pai.id === p.id)?.stats.tags[p.tag] > 0
      )

    return {
      tagFilter: validatedList[0],
      tagFilterMap: new Map(validatedList.map((p) => [p.id, p])),
      invalid: rawList.length !== validatedList.length,
    }
  }, [routeData.tgf_tag, items])

  const setTagFilter = useCallback((item: TagFilter) => {
    dispatchStay({ tgf_tag: item ? `${item.id}:${item.tag}` : null })
  }, [])

  // Airlock
  useEffect(() => {
    if (invalid) {
      setTagFilter(tagFilter)
    }
  }, [tagFilter, invalid])

  return { tagFilter, tagFilterMap, setTagFilter }
}

/** The querystring library (I think) doesn't know that a value is intended to be an array */
function parseQPArray(qp: string | string[]): string[] {
  if (!qp) {
    return []
  } else if (typeof qp === 'string') {
    return [qp]
  } else {
    return qp
  }
}
