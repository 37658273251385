import { useRouter } from '@paper/route'
import { DirPacket, ScanImageAbbr } from '@paper/schema'
import type { XOR } from '@paper/utils'
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  createContext,
  Dispatch,
  useContext,
  useLayoutEffect,
  useMemo,
  useReducer,
} from 'react'
import { useStaticFn } from '~src/blocks/list/listCallbacks'
import { usePacketListData } from '~src/data/data-packets'
import { ReduxStateTuple } from '~src/pages/publish/publishProvider'
import { checkDigestAirlock, isRQReady } from '~src/utils/airlock'
import type { RD_Scanlog } from '../routes'
import {
  ScanBatchDigest,
  useScanBatchDetails,
  useScanBatchList,
  useScanFixCandidateDetails,
} from './data-scanlog'

type ScanlogContext = {
  candidateInputs: CandidateInputs
  candidateResult: ReturnType<typeof useScanFixCandidateDetails>
  isEditable: boolean
  /** we're not editing when showing the manually-fixed interstitial page. this is used to prevent selecting in the xpacket list */
  isEditing: boolean
  reset(): void
  sbDetails: ReturnType<typeof useScanBatchDetails>
  sbDigest: ScanBatchDigest
  dispatchScanfix: Dispatch<AnyAction>
  selectedPacket: DirPacket
  selectedScanImage: ScanImageAbbr
  targetXpageId: string
}

const ScanlogContext = createContext<ScanlogContext>(null)
export const useScanlogContext = () => useContext(ScanlogContext)

export function ScanlogAirlock({ children }) {
  const { routeData, useAirlock } = useRouter<RD_Scanlog>()

  const { actions, getInitialState, reducer } = scanfixSlice
  const [scanfixState, dispatchScanfix] = useReducer(
    reducer,
    getInitialState()
  ) as ReduxStateTuple<ScanfixState>

  const { candidateInputs } = scanfixState

  const sbDigest = useScanBatchList()
  const sbDetails = useScanBatchDetails(
    sbDigest.success?.selectedItem?.packetIds
  )

  const pld = usePacketListData()
  const selectedPacket = pld?.selectedItem

  const candidateResult = useScanFixCandidateDetails(candidateInputs)

  const selectedBatch = sbDigest.success?.selectedItem
  const selectedScanImage = useMemo(() => {
    return selectedBatch?.chunks
      .flatMap((p) => p.items)
      .find((si) => si.id === routeData.si_imageId)
  }, [routeData.si_imageId, selectedBatch])

  //////////////////
  // airlocks
  //////////////////
  // batch
  useAirlock({ sb_batchId: null }, checkDigestAirlock(sbDigest))

  // image
  useAirlock(
    { si_imageId: null },
    routeData.si_imageId &&
      isRQReady(sbDetails) &&
      checkDigestAirlock(sbDigest) && // selectedScanImage is only 'ready' if sbDigest is ready
      !selectedScanImage
  )

  // Select first batch if xpacketId and there are matches
  const firstBatchId = sbDigest.success?.items[0]?.id
  useAirlock(
    { sb_batchId: firstBatchId },
    routeData.xpacketId &&
      firstBatchId &&
      !selectedBatch &&
      !sbDigest.success?.otherData.noMatches
  )

  // Unselect xpacketId if no packetId
  // todo: is having this many useAirlock calls a problem?
  useAirlock({ xpacketId: null }, routeData.xpacketId && !routeData.packetId)

  const reset = useStaticFn(() => {
    dispatchScanfix(actions.reset())
  })

  // Also clear page selection on si_imageId or packetId change
  useLayoutEffect(() => {
    reset()
  }, [routeData.si_imageId, routeData.packetId])

  // only allow editing images that imgp failed on
  const isEditable = selectedScanImage && selectedScanImage.status !== 'success'

  let ctx: ScanlogContext = {
    candidateInputs,
    candidateResult,
    isEditable,
    isEditing:
      isEditable &&
      (selectedScanImage.status !== 'manual' || scanfixState.editAgain),
    reset,
    sbDetails,
    sbDigest,
    dispatchScanfix,
    selectedPacket,
    selectedScanImage,
    // todo: this is a mess!
    targetXpageId:
      candidateResult.data?.xpageId ?? scanfixState?.candidateInputs.xpageId,
  }

  return (
    <ScanlogContext.Provider value={ctx}>{children}</ScanlogContext.Provider>
  )
}

export type CandidateInputs = {
  qrbComplete?: string
  qrbValue?: string
  xpageId?: string
}

export type ScanfixState = {
  candidateInputs: CandidateInputs
  editAgain?: boolean
}

const getInitialState = (): ScanfixState => ({ candidateInputs: {} })

export const scanfixSlice = createSlice({
  name: 'scanfix',
  initialState: getInitialState,
  reducers: {
    editAgain: () => ({ editAgain: true, candidateInputs: {} }),
    reset: () => getInitialState(),
    selectPage: (
      draft,
      action: PayloadAction<
        XOR<{ qrb: string; complete?: boolean }, { xpageId: string }>
      >
    ) => {
      const { qrb, xpageId } = action.payload
      if (xpageId) {
        draft.candidateInputs = { xpageId }
      } else if (qrb) {
        draft.candidateInputs = { qrbValue: qrb }
        if (action.payload.complete) {
          draft.candidateInputs.qrbComplete = qrb
        }
      }
    },
  },
})
