import { Tag, TagLabel, TagLeftIcon, TagProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

type GhostTagProps = TagProps & { label: ReactNode; leftIcon: any }
export function GhostTag(props: GhostTagProps) {
  const { color, label, leftIcon, ...rest } = props
  return (
    <Tag bg="transparent" color="unset" px={0} size="lg" {...rest}>
      <TagLeftIcon as={leftIcon} color={color} />
      <TagLabel fontSize="xs" fontWeight={300}>
        {label}
      </TagLabel>
    </Tag>
  )
}

export type StatTagProps = { count: number } & Pick<
  TagProps,
  'bg' | 'color' | 'size'
>

export function StatTag(props: StatTagProps) {
  const { count, ...passThrough } = props
  return (
    count > 0 && (
      <Tag color="unset" {...passThrough}>
        {count}
      </Tag>
    )
  )
}
