import { Box, Icon } from '@chakra-ui/react'
import {
  IcoCrumbLeft,
  IcoCrumbRight,
  IcoHome,
  IcoHomeSchool,
  IcoLogOut,
  IcoPacket,
} from '@paper/icons'
import { DirPacket, Teacher } from '@paper/schema'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import * as Toggle from '@radix-ui/react-toggle-group'
import { StackProps, TextStack } from '~src/components'
import { RD_SW, Routes } from '~src/routes'
import { MenuItem, RouterMenuItem } from '../menu'
import { renderUserListItem } from '../pickers'
import { useSWContext } from '../swContext'
import { useUser } from '../userProvider'
import { IcoTeacher } from './swMenuIcons'

type MIHomeProps = { data?: RD_SW }

export function MIHome(props: MIHomeProps) {
  return (
    <>
      <RouterMenuItem
        icon={<IcoHomeSchool />}
        route={Routes.home_school}
        data={props.data}
      >
        School Home
      </RouterMenuItem>
      <RouterMenuItem
        icon={<IcoHome />}
        route={Routes.home_teacher}
        data={props.data}
      >
        Teacher Home
      </RouterMenuItem>
    </>
  )
}

export function MISignOut() {
  const { signOut, user } = useUser()
  return (
    <MenuItem
      icon={<IcoLogOut />}
      onSelect={() => signOut(true)}
      overflow="hidden"
      whiteSpace="nowrap"
    >
      <TextStack>
        <TextStack.Top>Log out</TextStack.Top>
        <TextStack.Bottom opacity={0.8}>{user.email}</TextStack.Bottom>
      </TextStack>
    </MenuItem>
  )
}

export type CrumbMode = '' | 'packets' | 'teachers'
type CrumbSectionProps = {
  crumbMode: CrumbMode
  onChange(value: CrumbMode): void
  packet: DirPacket
  teacher: Teacher
}
export function CrumbSection(props: CrumbSectionProps) {
  const { crumbMode, onChange, packet, teacher } = props
  const { noPacketSwitch } = useSWContext()

  return (
    <DropdownMenu.Group>
      <Toggle.Root
        onValueChange={onChange}
        rovingFocus={false}
        type="single"
        value={crumbMode}
      >
        <Toggle.Item asChild value="teachers">
          <MenuItem icon={<IcoTeacher />}>
            <CrumbBody isSelected={crumbMode === 'teachers'}>
              {teacher ? renderUserListItem(teacher) : 'Blank images'}
            </CrumbBody>
          </MenuItem>
        </Toggle.Item>
        <Toggle.Item asChild disabled={noPacketSwitch} value="packets">
          <MenuItem disabled={noPacketSwitch} icon={<IcoPacket />}>
            <CrumbBody fontFamily="mono" isSelected={crumbMode === 'packets'}>
              {packet?.number ?? '⋯'}
            </CrumbBody>
          </MenuItem>
        </Toggle.Item>
      </Toggle.Root>
    </DropdownMenu.Group>
  )
}

function CrumbBody(props: StackProps & { isSelected: boolean }) {
  let { children, isSelected, ...rest } = props
  return (
    <Box
      alignItems="center"
      display="grid"
      flexGrow={1}
      gridColumnGap={2}
      gridTemplateColumns="1fr auto"
      {...rest}
    >
      {children}
      <Icon
        as={isSelected ? IcoCrumbLeft : IcoCrumbRight}
        color={isSelected ? 'blue.500' : null}
        fontSize="sm"
      />
    </Box>
  )
}
