import type { ApiSignUrls } from '@paper/api'
import { IAP } from '@paper/schema'
import { useQuery } from 'react-query'
import { useUser } from '~src/blocks/userProvider'
import config from './config'

const OneHourInMs = 1000 * 60 * 60

export type LocationKey = { key: string }

/**
 * @deprecated Use `useSignedWildcardMapper` instead
 */
export const useSignedUrls = (locations: LocationKey[]) => {
  const { fetchAs } = useUser()

  // note: domain added to content-security-policy in index.html
  // loc.key is null when no scan is present
  const slots = locations.map(
    (loc) => loc.key && `${config.cdnOrigin}/${loc.key}`
  )
  // Filter out nulls since we don't need to sign them
  const unsigneds = slots.filter((key) => !!key)
  const hasImages = unsigneds.length > 0

  // todo: Similar code in data-packetEntry for the pdf
  return useQuery(
    [IAP.signUrls, unsigneds],
    async () => {
      const signeds = await fetchAs
        .post(IAP.signUrls, { json: unsigneds })
        .json<ApiSignUrls['result']>()

      // include all slots
      return slots.map((url) => signeds[url])
    },
    { enabled: hasImages, staleTime: OneHourInMs }
  )
}

/**
 * Returns a QueryResult with a function that appends the signature to a url
 * todo: It currently signs any url under /pages/ or /img-entry/
 */
export const useSignedWildcardMapper = (example: string) => {
  const { fetchAs } = useUser()
  const payload = example && [getWildcard(example)]
  const url = IAP.signUrls
  const result = useQuery(
    [url, payload],
    async () => {
      const signeds = await fetchAs
        .post(url, { json: payload })
        .json<ApiSignUrls['result']>()
      // return signature
      return signeds[payload[0]].split('?')[1]
    },
    { enabled: !!example, staleTime: OneHourInMs }
  )

  const sig = result.isSuccess && result.data
  const data = !sig ? undefined : (url: string) => (url ? `${url}?${sig}` : url)

  return { ...result, data }
}

const getWildcard =
  // #142 - complicating things is that the blank images are under a different path
  (url) => {
    const paths = ['/pages/', '/img-entry/']
    for (let path of paths) {
      if (url.includes(path)) {
        return url.split(path)[0] + `${path}*`
      }
    }
    // if we made it here, there was no match
    // todo: rollbar?
    console.warn(`Unhandled wildcard url: ${url}`)
  }
