import { IconButton, List, ListItem } from '@chakra-ui/react'
import { IcoAdd } from '@paper/icons'
import { Curriculum, Packetmeta } from '@paper/schema'
import { orderBy } from 'lodash'
import { useMemo, useState } from 'react'
import { SimplePicker } from '~src/blocks/pickers/filterPicker'
import { HStack, Txt, VStack } from '~src/components'
import { useDirectoryData } from '~src/data/data-directory'

type CurriculumListerProps = {
  curriculum: Curriculum
  onSelect?: (curriculum: Curriculum) => void
  packets: Packetmeta[]
}

export function CurriculumLister(props: CurriculumListerProps) {
  const { curriculum, onSelect, packets } = props
  const { data } = useDirectoryData()

  const [subject, setSubject] = useState(curriculum.subject)
  const [level, setLevel] = useState(curriculum.level)

  const size = 'sm'

  // get a list of subjects and levels for the filters
  const { levels, subjects } = useMemo(() => {
    let subjects = new Set<string>()
    let levels = new Set<string>()

    data?.curriculum.items.forEach((c) => {
      subjects.add(c.subject)
      levels.add(c.level)
    })

    return {
      levels: orderBy([...levels], (p) => (p === 'K' ? -Infinity : p)), // stopgap force K first
      subjects: orderBy([...subjects]),
    }
  }, [data?.curriculum])

  // get matching curriculum, while leaving out already included
  const curricula = useMemo(() => {
    const used = new Set(packets.map((p) => p.curriculum.id))
    return data?.curriculum.items.filter(
      (c) => c.level === level && c.subject === subject && !used.has(c.id)
    )
  }, [data?.curriculum, subject, level, packets])

  if (!data) {
    return null
  }

  return (
    <VStack alignItems="stretch" gap={4}>
      <Txt>Publish this packet to additional curricula:</Txt>
      <HStack gap={4}>
        <SimplePicker
          filterType="startsWith"
          itemPlural="subjects"
          items={subjects}
          onChange={setSubject}
          selectedId={subject}
          size={size}
        />
        <SimplePicker
          filterType="startsWith"
          itemPlural="levels"
          items={levels}
          onChange={setLevel}
          selectedId={level}
          size={size}
        />
      </HStack>
      <List spacing={2}>
        {curricula.map((c) => (
          <ListItem alignItems="center" display="flex" key={c.id}>
            <IconButton
              aria-label={`Publish packet to ${c.name}`}
              data-cy="add-curriculum"
              icon={<IcoAdd />}
              mr={2}
              onClick={() => onSelect(c)}
              size={size}
            />
            {c.name}
          </ListItem>
        ))}
        {curricula.length === 0 && (
          <Txt>There are no curricula that match these filters.</Txt>
        )}
      </List>
    </VStack>
  )
}
