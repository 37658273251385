import { routemeta } from '@paper/route'
import type { RunSrc } from '@paper/schema'
import { StagingApprover } from './approver'
import { PacketFixPage } from './secretPacketFix'

export type RD_PacketFix = {
  contentId: string
  packetId?: string
}

const internal_packetfix = routemeta<RD_PacketFix>({
  path: '/internal/packetfix',
  queryParams: ['contentId', 'packetId'],
  render() {
    return <PacketFixPage />
  },
})

export type RD_Approver = { runSrc: RunSrc }

const internal_approver_staging = routemeta<RD_Approver>({
  path: '/internal/approver',
  queryParams: ['runSrc'],
  render() {
    return <StagingApprover />
  },
})

export const internalRoutes = {
  internal_approver_staging,
  internal_packetfix,
}
