import { useLayoutEffect, useRef, useState } from 'react'

/**
 * `useState` but resets state to `initialValue` if `resetOnChange` changes
 */
export function useStateResetOnChange<T>(initialValue: T, resetOnChange: any) {
  const resetOnChangeRef = useRef(resetOnChange)
  const [value, setValue] = useState<T>(initialValue)
  useLayoutEffect(() => {
    if (resetOnChangeRef.current !== resetOnChange) {
      // todo: is there a nicer way to handle prop changes inside the component?
      // in this case, i actually want to remount if src changes
      // could accomplish this by (more or less) <ImagePage key={src} ... />
      // but that forces the caller to remember
      // this approach has an extra render...
      resetOnChangeRef.current = resetOnChange
      setValue(initialValue)
    }
  }, [resetOnChange])
  return [value, setValue] as const
}
