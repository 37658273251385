import {
  Box,
  BoxProps,
  IconButton,
  Image,
  Link,
  LinkProps,
} from '@chakra-ui/react'
import { IcoZoomOut } from '@paper/icons'
import { useLink } from '@paper/route'
import { forwardRef, ReactNode } from 'react'
import { HStack, StackProps, Txt, VStack } from '~src/components'
import { Routes } from '~src/routes'
import { useStateResetOnChange } from '~src/utils/useState'
import { useStaticFn } from '../list/listCallbacks'
import { useSWContext } from '../swContext'
import { useDragTransform } from './useDragTransform'

// todo: proper accessiblity attributes
// todo: zoom is a mess!

type ImgStatus = 'error' | 'loading' | 'no-src' | 'success'
type ImagePage = {
  callouts?: ReactNode
  noSrc?: ReactNode
  pageNumber?: number
  src: string
  zoomEnabled?: boolean
}
export const ImagePage = (props: ImagePage) => {
  const { callouts, pageNumber, src, noSrc, zoomEnabled } = props

  let initialStatus: ImgStatus = src ? 'loading' : 'no-src'
  const [status, setStatus] = useStateResetOnChange<ImgStatus>(
    initialStatus,
    src
  )
  const onError = useStaticFn(() => setStatus('error'))
  const onLoad = useStaticFn(() => setStatus('success'))

  const showPageNumber = status !== 'loading' && Number.isInteger(pageNumber)

  const { bind, transform, transformCss, setTransform } = useDragTransform(
    !zoomEnabled
  )

  let content: ReactNode

  if (status === 'no-src') {
    content = noSrc || null
  } else if (status === 'error') {
    content = (
      <NonImagePage data-cy="image-error" color="red.500">
        <NonImagePage.Heading>
          There was an error accessing this image.
        </NonImagePage.Heading>
      </NonImagePage>
    )
  } else {
    content = (
      <Image
        {...bind()}
        cursor={
          !zoomEnabled
            ? undefined
            : !transform.isZoomed
            ? 'zoom-in'
            : transform.isDragging
            ? 'grabbing'
            : 'grab'
        }
        data-cy={status === 'success' ? 'image-loaded' : null}
        height="100%"
        loading="lazy"
        objectFit="contain"
        objectPosition="top"
        onError={onError}
        onLoad={onLoad}
        opacity={status === 'success' ? 1 : 0}
        src={src}
        sx={{ touchAction: 'none' }}
        transform={transformCss.transform}
        transformOrigin={transformCss.transformOrigin}
      />
    )
  }

  // Wrap in div to avoid jumping on xpacket change
  return (
    <Box
      bg="gray.50"
      borderBottomColor="gray.100"
      borderBottomWidth={1}
      height="100%"
      position="relative"
    >
      {callouts && (
        <HStack gap={1} position="absolute" right={1} top={1}>
          {callouts}
        </HStack>
      )}
      {transform.isZoomed && (
        <IconButton
          aria-label="Reset zoom"
          colorScheme="teal"
          icon={<IcoZoomOut />}
          isRound={true}
          onClick={() => setTransform({ isDragging: false, isZoomed: false })}
          position="absolute"
          top={1}
          left={1}
          zIndex={1}
        />
      )}
      {showPageNumber && (
        <Box
          alignItems="center"
          bgColor="blue.500"
          borderRadius="50%"
          color="white"
          display="flex"
          fontWeight={500}
          justifyContent="center"
          height={8}
          left={-1}
          position="absolute"
          top={-1}
          userSelect="none"
          width={8}
        >
          {pageNumber}
        </Box>
      )}
      {content}
    </Box>
  )
}

/**
 * Shell for a page that's not an image (e.g. error message)
 */
const NonImagePage = (props: StackProps) => {
  return (
    <VStack
      alignItems="center"
      bg="gray.50"
      gap={3}
      height="100%"
      p={8}
      sx={{ aspectRatio: '8.5 / 11' }}
      {...props}
    />
  )
}

NonImagePage.Heading = (props: BoxProps) => {
  return <Txt fontFamily="mono" fontSize="2xl" {...props} />
}

NonImagePage.Body = (props: BoxProps) => {
  return <Txt {...props} />
}

export const NoScan = () => {
  const scanlogLinkProps = useLink(Routes.scanlog.mergeAction())

  // only include namer link if swc.can.name (otherwise it will 404)
  const swc = useSWContext()
  const unnamedOpenCount = swc?.packet?.scan?.unnamedOpen
  const namerLinkProps = useLink(Routes.setstudent.mergeAction())

  const linkStyleProps: LinkProps = {
    color: 'red.400',
    display: 'inline',
    fontWeight: 500,
  }

  const namerLinkSection =
    !swc?.can.name || !unnamedOpenCount ? null : (
      <>
        part of an{' '}
        <Link {...linkStyleProps} {...namerLinkProps}>
          unnamed packet
        </Link>
        ,{' '}
      </>
    )

  return (
    <NonImagePage data-cy="image-missing">
      <NonImagePage.Heading>
        This page is not in the system.
      </NonImagePage.Heading>
      <NonImagePage.Body>
        It was either unreadable (see the{' '}
        <Link {...linkStyleProps} {...scanlogLinkProps}>
          scan log
        </Link>
        ), {namerLinkSection}
        or hasn't been scanned yet.
      </NonImagePage.Body>
    </NonImagePage>
  )
}

export const MovedPage = () => {
  // todo: this is a placeholder...
  // possible improvements:
  // link to where it was moved
  // display who and when moved/reconcile with callout
  // link to undo (if that were implemented)
  // ability to see original image
  return (
    <NonImagePage data-cy="image-moved">
      <NonImagePage.Heading>Moved</NonImagePage.Heading>
    </NonImagePage>
  )
}

type PageButtonProps = {
  disabled?: boolean
  idx: number
  onClick(idx: number): void
  pageNumber: number
  selected: boolean
  src: string
}

export const ImagePageButton = forwardRef<HTMLButtonElement, PageButtonProps>(
  function ImagePageButton(props, ref) {
    const { disabled, idx, onClick, pageNumber, selected, src } = props

    return (
      <Box
        as="button"
        bg="white"
        cursor={disabled ? 'not-allowed !important' : undefined}
        data-cy="page-btn"
        data-selected={selected}
        disabled={disabled}
        height="unset"
        onClick={() => onClick(idx)}
        outline="none" // todo: keyboard accessibility
        position="relative"
        // @ts-expect-error
        ref={ref}
        transition="transform .1s ease"
        type="button"
        _active={{
          transform: !disabled ? 'scale(.95)' : undefined,
        }}
      >
        <Box
          display="flex"
          opacity={!selected ? 0.3 : 1}
          pointerEvents={disabled ? 'none' : undefined}
          transition="opacity .5s ease"
          _hover={{ opacity: !selected ? 0.6 : 1 }}
        >
          <ImagePage
            pageNumber={selected && pageNumber}
            src={src}
            zoomEnabled={false}
          />
        </Box>
      </Box>
    )
  }
)
