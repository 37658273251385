import { Box, Button, Icon, List, ListIcon, ListItem } from '@chakra-ui/react'
import type { ApiFixerGetPacket, ApiFixerUpdatePacket } from '@paper/api'
import { IcoChevronRight, IcoSecretDoor } from '@paper/icons'
import { useRouter } from '@paper/route'
import { IAP, Packetmeta } from '@paper/schema'
import { BigIconFontSize } from '@paper/styles'
import produce from 'immer'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { NotFoundError } from '~src/blocks/errors'
import { TabImageViewer } from '~src/blocks/imageViewer'
import { RouteActionMenuItem } from '~src/blocks/menu'
import { useCurriculaPicker } from '~src/blocks/pickers'
import { useUser } from '~src/blocks/userProvider'
import {
  AppTitle,
  BaseHeader,
  ComboBox,
  HStack,
  Txt,
  VStack,
} from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { ExpandeeProps, UghTable } from '~src/components/table'
import { Routes } from '~src/routes'
import { TableContainer } from '../home/homePanel'
import { WarningBullet } from '../publish/openPdf'
import { packetfixCols } from '../publish/publish/publishListCols'
import { RD_PacketFix } from './routes'

/**
 * Throws 404 if not internal
 */
export function useLimitToInternal() {
  const { isInternal, user } = useUser()

  // Limit access to ponder.co admins
  const canAccess = isInternal

  if (!canAccess) {
    throw new NotFoundError({
      friendly: `There's nothing at this URL for you!`,
      thing: 'page',
      value: location.pathname,
    })
  }
}

export function SecretHeader({ title, children = null }) {
  title = `Secret ${title}`
  return (
    <BaseHeader mb={4} stackGap="2rem">
      <AppTitle title={title} />
      <Button onClick={() => window.history.back()} p={0} variant="ghost">
        <Icon as={IcoSecretDoor} fontSize={BigIconFontSize} />
      </Button>
      <Txt fontSize="lg">{title}</Txt>
      {children}
    </BaseHeader>
  )
}

export function PacketFixPage() {
  useLimitToInternal()

  const { dispatchStay, routeData } = useRouter<RD_PacketFix>()
  const { fetchAs } = useUser()
  const { contentId } = routeData

  const url = IAP.unstable.fixer.get
  const searchParams = { contentId }

  const [packets, setPackets] = useState<Packetmeta[]>()

  const qResult = useQuery([url, searchParams], async () => {
    const packets = await fetchAs(url, {
      searchParams,
    }).json<ApiFixerGetPacket['result']>()

    if (!packets?.length) {
      throw new NotFoundError({ thing: 'contentId', value: contentId })
    }

    // todo: probably not supposed to do this...
    setPackets(packets)

    return packets
  })

  // all packets have the same content
  const content = packets?.[0]?.content
  const selectedPacket = packets?.find((p) => p.id === routeData.packetId)

  // submitter
  const mutation = useMutation(async (json: ApiFixerUpdatePacket['body']) => {
    await fetchAs.post(IAP.unstable.fixer.update, { json })
  })

  const submitChanges = () => {
    // get packet subset
    let pkts = packets.map((p) => ({ id: p.id, curriculum: p.curriculum }))
    console.log('[fixer]submitting', { packets: pkts })

    mutation.mutate(
      {
        contentId: packets[0].contentId,
        packets: pkts,
      },
      { onSuccess: () => window.location.reload() }
    )
  }

  const Expandee = (props: ExpandeeProps<Packetmeta>) => {
    const { item } = props
    const isPrinted = !!item._printed.first

    const comboProps = useCurriculaPicker({
      excludeSet: new Set(qResult.data?.map((p) => p.curriculum.id)),
    })

    return (
      <VStack p={4}>
        {isPrinted ? (
          <Txt>
            You can't change the curriculum once a packet has been printed.
          </Txt>
        ) : (
          <ComboBox
            {...comboProps}
            caret={true}
            onSelect={(c) =>
              setPackets(
                produce((draft) => {
                  draft.find((p) => p.id === item.id).curriculum = c
                })
              )
            }
            placeholder="Change the curriculum"
            width="300px"
          />
        )}
      </VStack>
    )
  }

  return (
    <FullPageLoading qResult={qResult}>
      <BaseHeader.Container>
        <SecretHeader title="Packet Fixer" />
        <BaseHeader.Body overflowX="auto">
          {content && (
            <Box
              display="grid"
              gridColumnGap={8}
              gridTemplateColumns="640px 1fr"
              pb={8}
              px={8}
            >
              <TabImageViewer
                imageType="blank"
                pages={content.images}
                pkt={content}
              />
              <TableContainer fontSize="sm">
                <HStack gap={4} mb={4}>
                  <Button
                    alignSelf="start"
                    colorScheme="red"
                    onClick={submitChanges}
                    size="lg"
                  >
                    Submit
                  </Button>
                  <List fontSize="xs" spacing={1}>
                    <WarningBullet>
                      This tool is still an offroad stopgap, so be careful!
                    </WarningBullet>
                    <WarningBullet>
                      It's intended for the scenario where we accidentally
                      create a packet with the wrong curriculum and (fairly
                      immediately) realize and want to fix it.
                    </WarningBullet>
                    <ListItem ml={3}>
                      <ListIcon as={IcoChevronRight} />
                      In the non-secret app, you can recall-then-attach (to any
                      curriculum), but in some situations it may be preferable
                      to move the packet instead.
                    </ListItem>
                    <ListItem ml={3}>
                      <ListIcon as={IcoChevronRight} />
                      You should not use the tool if it's likely that others are
                      interacting with the packet (editing/publishing/printing)
                    </ListItem>
                    <WarningBullet>
                      After you press submit, the page will reload with the
                      (hopefully) newly updated curriculum.
                    </WarningBullet>
                    <ListItem ml={3}>
                      <ListIcon as={IcoChevronRight} />
                      If the values instead revert to the old values, then it
                      failed silently, probably 🐛
                    </ListItem>
                  </List>
                </HStack>
                <UghTable
                  aria-label="Publish list"
                  columns={packetfixCols}
                  data={packets}
                  Expandee={Expandee}
                  expandeeHeight={360}
                  onSelect={(item) => dispatchStay({ packetId: item.id })}
                  selectedId={selectedPacket?.id}
                  spacingX="normal"
                  spacingY="airy"
                />
              </TableContainer>
            </Box>
          )}
        </BaseHeader.Body>
      </BaseHeader.Container>
    </FullPageLoading>
  )
}

/**
 * `{ enabled, menuItem }` for the secret packet fixer
 */
export function useMIPacketFix(contentId: string, packetId: string) {
  const { isInternal } = useUser()
  const enabled = !!(isInternal && contentId)
  return {
    enabled,
    menuItem: !enabled ? null : (
      <RouteActionMenuItem
        action={Routes.internal_packetfix.navigateAction({
          contentId,
          packetId,
        })}
        icon={<IcoSecretDoor />}
      >
        Curriculum Fixer
      </RouteActionMenuItem>
    ),
  }
}
