/**
 * TODO: This whole thing is bad, but something is better than nothing
 */
import {
  Box,
  Button,
  FormControl,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'
import { IcoFillRows } from '@paper/icons'
import { Packetmeta } from '@paper/schema'
import {
  CrossPacketFormData,
  yupCrossPacketSchema,
} from '@paper/schema/validation'
import { Form, Formik, useFormikContext } from 'formik'
import { produce } from 'immer'
import { Fragment } from 'react'
import {
  BLink,
  HStack,
  TooltippedIconButton,
  Txt,
  VStack,
} from '~src/components'
import { ButtonStack, FormLabel, SimpleTextField2 } from '../formHelpers'
import { useSubmitCrossPacket } from '../data-publish'

type CrossPacketDialogProps = {
  isOpen: boolean
  onClose(): void
  packets: Packetmeta[]
}
export function CrossPacketDialog(props: CrossPacketDialogProps) {
  const { isOpen, onClose, packets } = props
  const [submit] = useSubmitCrossPacket({
    // TODO: Error handling
    onSuccess: props.onClose,
  })
  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay>
        <Formik
          initialValues={{ packets }}
          onReset={() => onClose()}
          onSubmit={async (values) => {
            await submit(values)
          }}
          validateOnMount={true}
          validationSchema={yupCrossPacketSchema}
        >
          <CrossPacketForm />
        </Formik>
      </ModalOverlay>
    </Modal>
  )
}

const GridFieldLabel = ({ centered = false, helperText = null, label }) => {
  return (
    <VStack as={FormControl} alignItems={centered ? 'center' : undefined}>
      <FormLabel isTruncated={true} pb={0} pr={0}>
        {label}
      </FormLabel>
      {/* <FormHelperText my={2}>{helperText}</FormHelperText> */}
    </VStack>
  )
}

function CrossPacketForm() {
  const { dirty, errors, isSubmitting, isValid, setFieldValue, values } =
    useFormikContext<CrossPacketFormData>()

  const headings = [
    <GridFieldLabel key={0} label="Curriculum" />,
    <GridFieldLabel key={1} label="Link to source materials" />,
    <GridFieldLabel key={2} label="Illuminate assessment title" />,
  ]

  const { packets } = values

  return (
    <ModalContent as={Form} borderRadius={4} maxW="1000px">
      <ModalBody overflow="hidden" pt={6}>
        <Heading
          as="h3"
          fontSize="xl"
          fontWeight={300}
          mb={8}
          userSelect="none"
        >
          External Links and References
        </Heading>
        <Box
          display="grid"
          gridColumnGap={16}
          gridRowGap={2}
          gridTemplateColumns={`auto repeat(2, 1fr)`}
        >
          {headings}
          {packets.map((pm, idx) => {
            const { curriculum, _linkExternal } = pm
            const isSourceLinkDisabled =
              // @ts-expect-error typescript?
              !!errors.packets?.[idx]?._linkExternal ||
              !packets[idx]._linkExternal

            return (
              <Fragment key={pm.id}>
                <Txt
                  fontSize="xs"
                  isTruncated={true}
                  lineHeight="32px"
                  textAlign="start"
                >
                  {curriculum.name}
                </Txt>
                <FillAll
                  onClick={() =>
                    setFieldValue(
                      'packets',
                      fillAll(values.packets, '_linkExternal', idx)
                    )
                  }
                >
                  <SimpleTextField2
                    name={`packets.[${idx}]._linkExternal`}
                    inputProps={{ 'data-cy': 'cp-external', size: 'sm' }}
                    label={null}
                  />
                  <BLink
                    colorScheme="red"
                    flexShrink={0}
                    fontSize="xs"
                    height="32px"
                    href={_linkExternal}
                    isDisabled={isSourceLinkDisabled}
                    isExternal={true}
                    ml={1}
                    size="sm"
                    variant="link"
                  >
                    test
                  </BLink>
                </FillAll>
                <FillAll
                  onClick={() =>
                    setFieldValue(
                      'packets',
                      fillAll(packets, '_illuminate', idx)
                    )
                  }
                >
                  <SimpleTextField2
                    name={`packets.[${idx}]._illuminate`}
                    inputProps={{
                      'data-cy': 'cp-illuminate',
                      fontFamily: 'mono',
                      size: 'sm',
                      placeholder:
                        'Paste the Illuminate assessement title here',
                    }}
                    label={null}
                  />
                </FillAll>
              </Fragment>
            )
          })}
        </Box>
      </ModalBody>
      <ModalFooter>
        <ButtonStack>
          <Button size="sm" type="reset" variant="outline">
            Cancel
          </Button>
          <Button
            data-cy="cp-submit"
            isDisabled={!dirty || isSubmitting || !isValid}
            size="sm"
            type="submit"
          >
            Submit
          </Button>
        </ButtonStack>
      </ModalFooter>
    </ModalContent>
  )
}

const fillAll = (
  values: Packetmeta[],
  key: keyof Packetmeta,
  index: number
) => {
  return produce(values, (draft) => {
    const fillValue = draft[index][key]
    // @ts-expect-error
    draft.forEach((pm) => (pm[key] = fillValue))
  })
}

const FillAll = ({ children, onClick }) => {
  return (
    <HStack alignItems="flex-start">
      {children}
      <TooltippedIconButton
        aria-label="Use this value for all rows"
        borderRadius="50%"
        icon={<IcoFillRows />}
        onClick={onClick}
        size="sm"
        variant="ghost"
        tooltipProps={{ placement: 'bottom' }}
      />
    </HStack>
  )
}
