import type { ApiPESubmitPdf } from '@paper/api'
import { IAP } from '@paper/schema'
import { asyncMap, Fetcher, HTTPError } from '@paper/utils'
import zip from 'lodash/zip'

export type SubmitPdfProps = {
  _images: Blob[] | ArrayBuffer[]
  data: ApiPESubmitPdf['body']
  srcBuf: ArrayBuffer
}

// Awkardly factored this out because it's useful to call in the headless integration test
export async function _submitPdf(
  props: SubmitPdfProps,
  fetchAs: Fetcher,
  fetch: Fetcher,
  ignoreConflict?: boolean
) {
  let step = 'submitting to Paper' // keep track for error message

  try {
    // Submit pdf portion of packetmeta
    const {
      imageUploadUrls,
      packetId,
      pdfUploadUrl,
      pdfUploadHeaders,
      updateTime,
    } = await fetchAs
      .post(IAP.packetEntry.submitPdf, { json: props.data })
      .json<ApiPESubmitPdf['result']>()

    // Then upload the PDF
    step = 'uploading the PDF'
    // Make [data, uploadUrl] pairs
    const pairs = zip(
      [props.srcBuf, ...props._images],
      [pdfUploadUrl, ...imageUploadUrls],
      [pdfUploadHeaders] // no image headers at the moment
    )

    // Do the uploads
    await asyncMap(pairs, ([data, uploadUrl, headers]) => {
      // uploadUrl is undefined if we already have the PDF
      return uploadUrl && fetch.put(uploadUrl, { body: data, headers })
    })

    // Then verify that the upload succeeded
    step = 'verifying your upload'
    try {
      await fetchAs.post(IAP.packetEntry.verifyPdf, {
        json: { packetId, updateTime },
      })
    } catch (error) {
      // todo: this messiness for example data
      // if a packet is submitted simultaneously, there can be a conflict.  for example data, we want to ignore this
      if (ignoreConflict && (error as HTTPError).response?.status == 409) {
        return packetId
      } else {
        throw error
      }
    }

    return packetId
  } catch (error) {
    error.friendly = `There was a problem ${step}`
    error.values = props
    throw error
  }
}
