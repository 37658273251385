import { Box, Button, Icon, LightMode } from '@chakra-ui/react'
import { IcoArrowBack } from '@paper/icons'
import { BigIconFontSize } from '@paper/styles'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { FC, ReactNode } from 'react'
import { MISupport } from '~src/blocks/miSupport'
import { MIHome, MISignOut } from '~src/blocks/swMenu/swMenuItems'
import {
  MenuBody,
  MenuDetailPane,
  MenuDetailsSection,
  MenuItem,
  MenuSeparator,
  useMenu,
} from '../../blocks/menu'
import { usePinGridContext } from './pinGridAirlock'

type PinGridMenuProps = {
  icon: FC
  keyboard?: ReactNode
}

/**
 * todo: copy/paste from SWMenu
 */
export function PinGridMenu(props: PinGridMenuProps) {
  const { icon, keyboard } = props
  const ctx = usePinGridContext()

  const cell = ctx.digest.selectedXY

  const homeRouteData = cell
    ? {
        curriculumId: cell.curriculumId,
        packetId: cell.packetId,
        teacherId: cell.teacherId,
      }
    : null

  // const tc = useTeacherContext()
  // const { curriculum, teacher } = tc
  // const swc = useSWContext()

  // const { packet } = swc

  let { menuButtonProps, onOpenChange, open, shroud } = useMenu({
    caret: true,
    shroud: true,
  })

  // const nfpRef = useRef<DirPacket>()
  // if (packet) {
  //   // try to prevent flashing?
  //   nfpRef.current = packet
  // }
  // const nonFlashingPacket = nfpRef.current
  //////////////////////////////
  // Nav pane
  //////////////////////////////
  const leftPane = (
    <Box alignSelf="flex-start" overflow="hidden">
      <DropdownMenu.Group>
        <MenuItem
          icon={<IcoArrowBack />}
          onSelect={() => window.history.back()}
        >
          Back
        </MenuItem>
        <MenuSeparator />
        <MISupport />
        <MenuSeparator />
        <MIHome data={homeRouteData} />
        <MISignOut />
      </DropdownMenu.Group>
    </Box>
  )

  //////////////////////////////
  // Details pane
  //////////////////////////////
  let details: ReactNode
  // const stretchGrow: StackProps = { alignItems: 'stretch', flexGrow: 1 }

  // let edwAsOf = formatPastVsNow(packet?._edwAsOf)
  // edwAsOf = !packet ? '⋯' : edwAsOf ? `Loaded ${edwAsOf}` : 'Not found'

  details = [
    keyboard && (
      <MenuDetailsSection key="ks" heading="Keyboard shortcuts">
        {keyboard}
      </MenuDetailsSection>
    ),
    // <MenuDetailsSection key="edw" heading="EDW Standards and Scores">
    //   <Txt fontSize="xs">{edwAsOf}</Txt>
    // </MenuDetailsSection>,
  ].filter((p) => p)

  const rightPane = (
    <MenuDetailPane separator={true}>
      {/* <MenuDetailsSection>
        <Txt fontSize="sm"></Txt>
      </MenuDetailsSection> */}
      {details}
    </MenuDetailPane>
  )

  //////////////////////////////
  // Put it all together
  //////////////////////////////
  return (
    <DropdownMenu.Root
      modal={false} // some sort of perf issue with modal...
      onOpenChange={onOpenChange}
      open={open}
    >
      <DropdownMenu.Trigger asChild>
        <Button
          data-cy="menu-pingrid"
          {...menuButtonProps}
          flexShrink={0}
          px={1}
          size="lg"
        >
          <Icon as={icon} fontSize={BigIconFontSize} />
        </Button>
      </DropdownMenu.Trigger>
      {shroud}
      <DropdownMenu.Content asChild>
        <MenuBody width="544px">
          <LightMode>
            <Box display="grid" gridTemplateColumns="1fr auto 1fr">
              {leftPane}
              <Box
                borderRightColor="gray.100"
                borderRightWidth={1}
                className="vr"
                height="100%"
                role="separator"
              />
              {rightPane}
            </Box>
          </LightMode>
        </MenuBody>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
