export type StdInst = {
  bucket: 'APC' | 'CCSS' | 'Custom'
  cat: string
  catLevels: string[]
  guids: string[]
  subSet: string
  subTitle: string
}

export type FullStd = {
  desc: string
  std: string
  insts: StdInst[]
}
