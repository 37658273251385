export * from './listItemTag'
export * from './tagFilter'
export * from './tagInstruction'

import {
  IcoTagBroaderIssue,
  IcoTagClose,
  IcoTagGotIt,
  IcoTagReview,
} from '@paper/icons'
import { XpacketTag } from '@paper/schema'

export type Tag = {
  color: string
  icon: any
  id: XpacketTag
  keyChar: string
  label: string
}

export const Tags: Tag[] = [
  {
    id: 3,
    label: 'Got it',
    color: 'green.400',
    keyChar: 'j',
    icon: IcoTagGotIt,
  },
  {
    id: 2,
    label: 'Close',
    color: '#bfee90',
    keyChar: 'k',
    icon: IcoTagClose,
  },
  {
    id: 1,
    label: 'Review',
    color: 'yellow.400',
    keyChar: 'l',
    icon: IcoTagReview,
  },
  {
    id: -1,
    label: 'Broader issue',
    color: 'orange.400',
    keyChar: ';',
    icon: IcoTagBroaderIssue,
  },
]
