import { Box } from '@chakra-ui/react'
import { Passage } from '@paper/schema'
import { Field as FormikField, FieldProps } from 'formik'
import { SimplePicker } from '~src/blocks/pickers/filterPicker'
import { FieldUI, SimpleTextField2 } from '../formHelpers'
import { SubFormButtons } from './formListValueHelpers'

export type SinglePassageSchema = Passage & { _new?: boolean }

type PassageFormProps = { onUnselect(): void }

export const SinglePassageForm = ({ onUnselect }: PassageFormProps) => {
  return (
    <Box maxW={400}>
      {/* <Wiz.FormHeading children="Passage Editor" /> */}
      <SimpleTextField2 label="name" name="name" />
      <GenreField />
      <SimpleTextField2 label="lexile (optional)" name="lexile" />
      <SubFormButtons onUnselect={onUnselect} />
    </Box>
  )
}

const GenreField = () => {
  return (
    <FormikField name="genre">
      {({ field, form, meta }: FieldProps<string>) => (
        <FieldUI
          input={
            <SimplePicker
              filterType="contains"
              getId={(item) => item}
              getLabel={(item) => item}
              items={GENRES}
              itemPlural="genres"
              onChange={(value) => form.setFieldValue(field.name, value)}
              selectedId={field.value}
              variant="outline"
            />
          }
          label="genre (optional)"
          meta={meta}
          w="100%"
        />
      )}
    </FormikField>
  )
}

// TODO: Temporary and bad
const GENRES = [
  'Info: Audio/Video',
  'Info: Biography',
  'Info: Expository Non-Fiction',
  'Info: Historical Non-Fiction',
  'Info: Journalism',
  'Info: News Article',
  'Info: Other',
  'Info: Scientific Article',
  'Info: Video/Audio',
  'Narrative: Drama',
  'Narrative: Fantasy',
  'Narrative: Fiction',
  'Narrative: Folktale/Myth/Legend',
  'Narrative: Historical Fiction',
  'Narrative: Memoir/Non-Fiction',
  'Narrative: Poetry',
  'Narrative: Realistic Fiction',
  'Narrative: Video/Audio',
]
