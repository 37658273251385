import { Button, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import {
  IcoImage,
  IcoPacket,
  IcoPdf,
  IcoPrint,
  IcoPublish,
  IcoRadioactive,
  IcoRecall,
  IcoSearch,
  IcoTicket,
  IcoUnnamed,
  IcoX,
} from '@paper/icons'
import { useRouter } from '@paper/route'
import { PktColors } from '@paper/styles'
import { pick } from 'lodash'
import { forwardRef } from 'react'
import { IcoScanIssue } from '~src/blocks/icoScanIssue'
import { HStack, StackProps, ToggleGroup } from '~src/components'
import {
  PacketFilterKeys,
  RD_HomeCurriculum,
  RD_HomeTeacher,
  Routes,
} from '~src/routes'

// could context these...
const shape = 'circle'
const size = 'sm'

export function PacketListFilters() {
  const { currentRoute, routeData, dispatchStay } = useRouter()

  return (
    <HStack flexWrap="wrap" gap={6} justifyContent="center">
      <PacketTextFilter />
      <PacketTypeFilter />
      {currentRoute === Routes.home_curriculum && <PublishFilter />}
      <PrintFilter />
      {currentRoute === Routes.home_teacher && <ScannedFilter />}
      <Button
        data-cy="filter-pl-clear"
        isDisabled={
          // todo: probably move these utils out, would also be useful for useMemo the data.filter implementation
          !Object.values(pick(routeData, PacketFilterKeys)).some(
            (p) => p != null
          )
        }
        onClick={() =>
          dispatchStay(
            Object.fromEntries(PacketFilterKeys.map((key) => [key, null]))
          )
        }
        size="xs"
      >
        Clear
      </Button>
    </HStack>
  )
}

function PacketTextFilter() {
  const { dispatchStay, routeData } = useRouter<RD_HomeTeacher>()
  const label = 'Filter by name or number'
  return (
    <InputGroup size={size} width="auto">
      <InputLeftElement color="gray.400" children={<IcoSearch />} zIndex={0} />
      <Input
        aria-label={label}
        borderRadius="5rem"
        data-cy="pl_filterText"
        onChange={(event) =>
          dispatchStay({ pl_filterText: event.target.value || null })
        }
        placeholder={label}
        value={routeData.pl_filterText ?? ''}
      />
    </InputGroup>
  )
}

/**
 * FilterStack?
 */
const FStack = forwardRef<HTMLDivElement, Omit<StackProps, 'gap'>>(
  function FStack(props, ref) {
    return <HStack gap={1} {...props} ref={ref} />
  }
)

function PacketTypeFilter() {
  const { dispatchStay, routeData } = useRouter<RD_HomeTeacher>()
  return (
    <ToggleGroup.Root
      onChange={(pl_packetType) => dispatchStay({ pl_packetType })}
      shape={shape}
      size={size}
      type="single"
      value={routeData.pl_packetType}
    >
      <FStack>
        <ToggleGroup.Icon
          aria-label="Tickets"
          icon={<IcoTicket />}
          value="ticket"
        />
        <ToggleGroup.Icon
          aria-label="Exams"
          icon={<IcoPacket />}
          value="assessment"
        />
      </FStack>
    </ToggleGroup.Root>
  )
}

function PrintFilter() {
  const { dispatchStay, routeData } = useRouter<RD_HomeTeacher>()
  return (
    <ToggleGroup.Root
      onChange={(pl_printStatus) => dispatchStay({ pl_printStatus })}
      shape={shape}
      size={size}
      type="single"
      value={routeData.pl_printStatus}
    >
      <FStack>
        <ToggleGroup.Icon
          aria-label="Not yet printed"
          icon={<IcoX />}
          value="not"
        />
        <ToggleGroup.Icon
          aria-label="Printed"
          colorScheme={PktColors.Print}
          data-cy="filter-pl-printed"
          icon={<IcoPrint />}
          value="printed"
        />
        <ToggleGroup.Icon
          aria-label="Edited after printing"
          colorScheme={PktColors.Nuclear}
          icon={<IcoRadioactive />}
          value="radioactive"
        />
      </FStack>
    </ToggleGroup.Root>
  )
}

function ScannedFilter() {
  const { dispatchStay, routeData } = useRouter<RD_HomeTeacher>()
  return (
    <ToggleGroup.Root
      onChange={(pl_scanStatus) => dispatchStay({ pl_scanStatus })}
      shape={shape}
      size={size}
      type="single"
      value={routeData.pl_scanStatus}
    >
      <FStack>
        <ToggleGroup.Icon
          aria-label="Scanned"
          colorScheme={PktColors.Scanned}
          icon={<IcoImage />}
          value="scanned"
        />
        <ToggleGroup.Icon
          aria-label="Partial packets"
          colorScheme={PktColors.NeedsFixing}
          icon={<IcoScanIssue />}
          value="partial"
        />
        <ToggleGroup.Icon
          aria-label="Unnamed packets"
          colorScheme={PktColors.NeedsFixing}
          icon={<IcoUnnamed />}
          value="unnamed"
        />
      </FStack>
    </ToggleGroup.Root>
  )
}

function PublishFilter() {
  const { dispatchStay, routeData } = useRouter<RD_HomeCurriculum>()
  return (
    <ToggleGroup.Root
      onChange={(pl_pub) => dispatchStay({ pl_pub })}
      shape={shape}
      size={size}
      type="single"
      value={routeData.pl_pub}
    >
      <FStack>
        <ToggleGroup.Icon
          aria-label="Not yet published"
          icon={<IcoPdf />}
          value="uploaded"
        />
        <ToggleGroup.Icon
          aria-label="Published"
          icon={<IcoPublish />}
          value="published"
          colorScheme={PktColors.Publish}
        />
        <ToggleGroup.Icon
          aria-label="Recalled"
          icon={<IcoRecall />}
          value="recalled"
          colorScheme={PktColors.Recall}
        />
      </FStack>
    </ToggleGroup.Root>
  )
}
