import { CrunchBatch } from '@paper/schema'
import { GRAY_HAS_SCANS, MISSING_COLOR } from '@paper/styles'
import { sumBy } from 'lodash'
import { HStack, StatTag, StatTagProps, TextStack, Txt } from '~src/components'
import { UghColumn } from '~src/components/table'
import { formatPastVsNow } from '~src/utils/messages'

type Col = UghColumn<CrunchBatch>

const batchNumber: Col = {
  props: { width: 64 },
  label: () => '#',
  cell: (item) => (
    <Txt fontFamily="mono" fontWeight={600}>
      {item.sourceName.split('__')[0]}
    </Txt>
  ),
}

const dateAndUser: Col = {
  props: { align: 'start', width: 250 },
  label: () => 'Batch',
  cell: (item) => (
    <TextStack>
      <TextStack.Top>{formatPastVsNow(item.scanDate)}</TextStack.Top>
      <TextStack.Bottom>{`by ${item.scanUser}`}</TextStack.Bottom>
    </TextStack>
  ),
}

const stats: Col = {
  props: { width: 104 },
  label: () => 'Stats',
  cell: (item) => {
    let problemKeys: (keyof CrunchBatch['stats'])[] = [
      'error',
      'no-qr',
      'wrong-location',
    ]
    const problemCount = sumBy(problemKeys, (k) => item.stats[k] ?? 0)

    const stats: StatTagProps[] = [
      { count: item.stats.pending, bg: 'black', color: 'white' },
      { count: item.stats.success, bg: GRAY_HAS_SCANS },
      { count: problemCount, bg: MISSING_COLOR, color: 'white' },
      { count: item.stats.manual, bg: 'scanFixedGray.500', color: 'white' },
    ]
    return (
      <HStack flexWrap="wrap" gap={1} sx={{ '>*': { flexShrink: 0 } }}>
        {stats.map((props, idx) => (
          <StatTag key={idx} size="sm" {...props} />
        ))}
      </HStack>
    )
  },
}

export const scanBatchColumns = [batchNumber, dateAndUser, stats]
