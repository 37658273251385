import type { ApiApproverGet, ApiApproverSubmit } from '@paper/api'
import { CM, IAP, Invalid, RunSrc, Stage } from '@paper/schema'
import { pushToMap } from '@paper/utils'
import dotProp from 'dot-prop'
import { orderBy } from 'lodash'
import { useMutation, useQuery } from 'react-query'
import { useUser } from '~src/blocks/userProvider'
import { Txt } from '~src/components'
import { UghColumn } from '~src/components/table'

export type StageDisplay<T = any> = {
  id: string
  data: T
} & Stage<T> // table expects id...

export type InvalidDisplay<T = any> = { id: string } & Invalid<T>

export type DisplayMeta<T> = {
  columns: UghColumn<StageDisplay<T>>[]
  sort?: (items: StageDisplay<T>[]) => StageDisplay<T>[]
}

type DisplayMetas = { [K in keyof CM]?: DisplayMeta<CM[K]> }

const StatusSort: Record<Stage['status'], number> = {
  removed: 1,
  added: 2,
  modified: 3,
  unchanged: 4,
  frozen: 5,
}

/** Turns arrays and objects into strings */
export const reactify = (datapoint: any) => {
  if (Array.isArray(datapoint)) {
    return datapoint.join(', ')
  } else if (datapoint && typeof datapoint === 'object') {
    return JSON.stringify(datapoint)
  } else {
    return datapoint
  }
}

function simpleCol<T extends object>(key: string): UghColumn<StageDisplay<T>> {
  return {
    props: { align: 'start' },
    label: () => key,
    cell(item) {
      let datapoint = dotProp.get(item.data, key)
      let children = reactify(datapoint)
      return <Txt fontFamily={key === 'id' ? 'mono' : 'body'}>{children}</Txt>
    },
  }
}

export const displayMeta: DisplayMetas = {
  curriculum: {
    columns: ['id', 'name'].map(simpleCol),
    sort: (items) =>
      orderBy(
        items,
        [(stage) => stage.data.years[0], (stage) => stage.data.name],
        ['desc', 'asc']
      ),
  },
  teacher: {
    columns: ['id', 'email', 'firstName', 'lastName'].map(simpleCol),
    sort: (items) => orderBy(items, (p) => p.data.email),
  },
  section: {
    columns: [
      'sectionId',
      'name',
      'curriculum.name',
      'schoolId',
      'teacherId',
    ].map(simpleCol),
    sort: (items) =>
      orderBy(items, (p) => [p.data.schoolId, p.data.curriculum.name]),
  },
  student: {
    columns: ['id', 'lastfirst'].map(simpleCol),
    sort: (items) => orderBy(items, (p) => p.data.lastfirst),
  },
  auth_domain: {
    columns: ['domain'].map(simpleCol),
    sort: (items) => orderBy(items, (p) => p.data.domain),
  },
}

export const useApproverData = (runSrc: RunSrc) => {
  const { fetchAs } = useUser()

  const searchParams = { runSrc }
  return useQuery([IAP.unstable.approver, searchParams], async () => {
    const result = await fetchAs
      .get(IAP.unstable.approver, { searchParams })
      .json<ApiApproverGet['result']>()

    if (!result) {
      return null
    }

    const { meta, invalids: _invalids, items: _items } = result

    // table expects id, also handy to coalesce `next` and `old`
    let items = _items.map((p): StageDisplay => {
      return { id: p._id, data: p.next ?? p.old, ...p }
    })
    let invalids = _invalids.map((p): InvalidDisplay => {
      return { id: p._id, ...p }
    })

    const itemMap = new Map<string, StageDisplay[]>()
    for (let stage of items) {
      pushToMap(itemMap, stage.collection, stage)
    }

    for (let [collectionName, items] of itemMap.entries()) {
      let meta = displayMeta[collectionName] as DisplayMeta<any>

      if (meta?.sort) {
        // item-specific sort
        items = meta.sort(items)
      }
      itemMap.set(
        collectionName,
        // also sort by status
        orderBy(items, (p) => StatusSort[p.status])
      )
    }

    // todo: very copy/pasty!
    const invalidMap = new Map<string, InvalidDisplay[]>()
    for (let invalid of invalids) {
      pushToMap(invalidMap, invalid.src, invalid)
    }

    return { meta, items, itemMap, invalidMap }
  })
}

export const useApproverSubmit = () => {
  const { fetchAs } = useUser()
  return useMutation(
    async (props: ApiApproverSubmit['body']) => {
      await fetchAs.post(IAP.unstable.approver, { json: props })
    },
    {
      onSuccess() {
        window.location.reload() // refresh the page as a cheat to get changes
      },
    }
  )
}
