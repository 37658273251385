import { Button, Icon } from '@chakra-ui/react'
import { TagCounts, XpacketTag } from '@paper/schema'
import { HStack, Txt } from '~src/components'
import { Tags } from '.'

type TagFilterProps = {
  onSelect(id: XpacketTag): void
  stats?: TagCounts
  value: XpacketTag
}

export function TagFilter(props: TagFilterProps) {
  const { onSelect, stats, value } = props

  return (
    <HStack alignItems="baseline" gap={1} justifyContent="center">
      {Tags.map((tag) => {
        // todo: figure out type...
        const isSelected = tag.id.toString() === value?.toString()
        // c/p ListItemTag
        const colorSelected = 'rgba(0,0,0,.5)'
        const count = stats?.[tag.id]
        return (
          <Button
            aria-label={tag.label}
            bg={isSelected ? tag.color : undefined}
            color={isSelected ? colorSelected : undefined}
            disabled={count === 0}
            key={tag.id}
            minWidth="48px"
            onClick={() => onSelect(tag.id)}
            px={2}
            size="sm"
            variant="ghost"
            _hover={{
              bg: isSelected ? undefined : tag.color,
              color: colorSelected,
            }}
          >
            <Icon as={tag.icon} mr={1} />
            <Txt as="span" fontSize="xs">
              {count}
            </Txt>
          </Button>
        )
      })}
    </HStack>
  )
}
