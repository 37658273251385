import type { ApiSchoolPrintable } from '@paper/api'
import type {
  ApiSchoolPacketGroup,
  SchoolGridIn,
  SchoolGridOut,
} from '@paper/api/server/routes/sch_packets'
import { useRouter } from '@paper/route'
import { DirPacket, IAP } from '@paper/schema'
import { sortNumeric } from '@paper/utils'
import { useEffect, useMemo } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { useStaticFn } from '~src/blocks/list/listCallbacks'
import { PacketExpandee } from '~src/blocks/packetTable'
import { schoolPacketColumns } from '~src/blocks/packetTable/packetTableCols'
import { usePickerBase, useSchoolPicker } from '~src/blocks/pickers'
import { useUser } from '~src/blocks/userProvider'
import { ComboBox, TextStack, Txt } from '~src/components'
import { LoadingDots } from '~src/components/status'
import { UghTable } from '~src/components/table'
import { useDirectoryData } from '~src/data/data-directory'
import type { RD_HomeSchool } from '~src/routes'
import { HomePanel, TableContainer } from './homePanel'

const useSchoolSth = (schoolId: string) => {
  const { fetchAs } = useUser()
  const url = IAP.school.listPackets
  const payload: ApiSchoolPrintable['body'] = { schoolId }
  const enabled = !!schoolId
  const qResult = useQuery(
    [url, payload],
    async () => {
      let result = await fetchAs
        .post(url, { json: payload })
        .json<ApiSchoolPrintable['result']>()

      sortNumeric(result.packetGroups, (item) => item.number)

      return result
    },
    { enabled }
  )

  return qResult
}

const useSchoolPacketList = (spec: SchoolGridIn[]) => {
  const { fetchAs } = useUser()
  const url = IAP.school.getPacketGroup
  const payload: ApiSchoolPacketGroup['body'] = { spec }
  const enabled = !!spec
  const qResult = useQuery(
    [url, payload],
    async () => {
      let result = await fetchAs
        .post(url, { json: payload })
        .json<ApiSchoolPacketGroup['result']>()

      return result
    },
    { enabled }
  )

  return qResult
}

export function SchoolPanel() {
  const { dispatchStay, routeData } = useRouter<RD_HomeSchool>()
  const { data: dirData } = useDirectoryData()

  const school = dirData?.school.map.get(routeData.schoolId)?.item
  const schComboProps = useSchoolPicker({ value: school?.id })

  const qResult = useSchoolSth(school?.id)

  // select a packet group if there's packetId passed in
  const pgFromPacketId =
    !routeData.pgId &&
    routeData.packetId &&
    qResult.isSuccess &&
    qResult.data.packetGroups.find((pg) =>
      // todo: probably create a lookup!
      pg.packets.some((p) => p.id === routeData.packetId)
    )?.id

  // select a school if there's a teacherId passed in
  const schoolFromTeacherId =
    !routeData.schoolId &&
    routeData.teacherId &&
    dirData?.teacher.map.get(routeData.teacherId)?.schoolIds.keys().next().value

  // todo: for reasons that aren't 100% clear, these are interleaving updates when using useLayoutEffect
  // todo: the router uses useLayoutEffect in its useStateAndRef, so maybe there's a race condition :(
  useEffect(() => {
    if (pgFromPacketId) {
      dispatchStay({ pgId: pgFromPacketId })
    }
  }, [pgFromPacketId])

  useEffect(() => {
    if (schoolFromTeacherId) {
      dispatchStay({ schoolId: schoolFromTeacherId })
    }
  }, [schoolFromTeacherId])

  let pg =
    routeData.pgId &&
    qResult.data?.packetGroups.find((pg) => pg.id === routeData.pgId)
  let spec: SchoolGridIn[] = pg?.packets.flatMap(
    ({ curriculumId, id: packetId }) => {
      // for each packet, grab its teachers
      const { sections } = qResult.data.sbc.find(
        (item) => item.curriculum.id === curriculumId
      )
      const teacherIds = new Set(sections.map(({ teacher }) => teacher.id))

      return [...teacherIds].map((teacherId) => ({
        curriculumId,
        packetId,
        teacherId,
      }))
    }
  )
  const qResult2 = useSchoolPacketList(spec)

  const pgComboProps = usePickerBase(
    { isLoading: qResult.isLoading, data: qResult.data?.packetGroups },
    {
      filterer: (filters, item, inputValue) =>
        filters.contains(item.number, inputValue),
      selector: (item, value) => item.id === value,
      value: routeData.pgId,
    }
  )

  return (
    <HomePanel
      name={school?.name ?? 'School'}
      table={<SchoolPacketTable qResult={qResult2} />}
      pickers={
        <>
          <ComboBox
            {...schComboProps}
            caret={true}
            inputTextAlign="center"
            onChange={(item) => dispatchStay({ schoolId: item.id })}
            openIfNoSelection={true}
            placeholder="Select a school"
            renderItem={(item) => (
              <Txt as="span" fontSize="sm">
                {item.name}
              </Txt>
            )}
            round={true}
            size="md"
            variant="filled"
            width="300px"
          />
          <ComboBox
            {...pgComboProps}
            caret={true}
            fontFamily="mono"
            inputTextAlign="center"
            itemToString={(pg) => pg.number}
            onChange={(item) => dispatchStay({ pgId: item.id })}
            openIfNoSelection={!!schComboProps.selectedItem}
            placeholder={
              !!schComboProps.selectedItem && 'Select a packet group'
            }
            renderItem={renderPacketGroup}
            round={true}
            size="md"
            variant="filled"
            width="300px"
          />
        </>
      }
    />
  )
}

function renderPacketGroup(
  pg: ApiSchoolPrintable['result']['packetGroups'][0]
) {
  return (
    <TextStack>
      <TextStack.Top>{pg.number}</TextStack.Top>
      <TextStack.Bottom fontFamily="body">
        {pg.packets.length === 1
          ? pg.packets[0].name
          : `${pg.packets.length} packets`}
      </TextStack.Bottom>
    </TextStack>
  )
}

type SchoolPacketTableProps = { qResult: UseQueryResult<SchoolGridOut[]> }
function SchoolPacketTable(props: SchoolPacketTableProps) {
  const { dispatchStay, routeData } = useRouter<RD_HomeSchool>()

  // todo:
  const data = useMemo(() => {
    return props.qResult.data?.map((item) => item.packet)
  }, [props.qResult.data])

  // todo: copy/pasted from <PacketTable />

  const getId = useStaticFn((item: any) => {
    return item.teacherId + (item.id ?? item.packetId)
  })

  if (props.qResult.isIdle) {
    return null // waiting for outside inputs
  } else if (props.qResult.isLoading) {
    return (
      <TableContainer>
        <LoadingDots />
      </TableContainer>
    )
  }

  return (
    <TableContainer>
      <UghTable
        aria-label="Packet list"
        columns={schoolPacketColumns}
        data={data}
        data-cy="home-packet-table"
        Expandee={PacketExpandee}
        expandeeHeight={56}
        getId={getId}
        height="100%"
        onSelect={(item: DirPacket) =>
          dispatchStay({
            curriculumId: item.curriculumId,
            packetId: item.id,
            teacherId: item.teacherId,
          })
        }
        selectedId={getId(routeData)}
      />
    </TableContainer>
  )
}
