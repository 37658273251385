import {
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Icon,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagProps,
  Tbody,
  Td,
  Tr,
  VStack,
} from '@chakra-ui/react'
import {
  IcoApproverStaged,
  IcoCheckmark,
  IcoExclamationTriangle,
  IcoX,
} from '@paper/icons'
import { useRouter } from '@paper/route'
import { BulkSummary, RunSrc } from '@paper/schema'
import dotProp from 'dot-prop'
import produce from 'immer'
import { sumBy, times } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import { BaseHeader, ToggleGroup, Txt } from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { UghColumn, UghTable } from '~src/components/table'
import { formatPastVsNow } from '~src/utils/messages'
import { TableContainer } from '../home/homePanel'
import {
  displayMeta,
  DisplayMeta,
  InvalidDisplay,
  reactify,
  StageDisplay,
  useApproverData,
  useApproverSubmit,
} from './data-approver'
import type { RD_Approver } from './routes'
import { SecretHeader, useLimitToInternal } from './secretPacketFix'

const runSrcs: RunSrc[] = ['airtable', 'scheduled']

export function StagingApprover() {
  const { dispatchStay, routeData } = useRouter<RD_Approver>()
  useLimitToInternal()

  const runSrc = routeData.runSrc ?? 'airtable'

  const qResult = useApproverData(runSrc)

  return (
    <FullPageLoading qResult={qResult}>
      <BaseHeader.Container>
        <SecretHeader title="Staging Approver">
          <ToggleGroup.Root
            colorScheme="red"
            onChange={(next) => dispatchStay({ runSrc: next })}
            type="single"
            value={runSrc}
          >
            <ButtonGroup isAttached={true}>
              {runSrcs.map((p) => (
                <ToggleGroup.Button key={p} value={p}>
                  {p}
                </ToggleGroup.Button>
              ))}
            </ButtonGroup>
          </ToggleGroup.Root>
        </SecretHeader>
        <BaseHeader.Body overflowX="auto">
          {qResult.isSuccess && <ApproverBody {...qResult} runSrc={runSrc} />}
        </BaseHeader.Body>
      </BaseHeader.Container>
    </FullPageLoading>
  )
}

// chakra doesn't like non-TabPanel components as children of TabPanels
const makeTabPanel = (key: string, children: ReactNode) => {
  return (
    <TabPanel key={key} display="flex" flexDirection="column" gap={4} px={1}>
      {children}
    </TabPanel>
  )
}

type ApproverBodyProps = {
  data: ReturnType<typeof useApproverData>['data']
  runSrc: RunSrc
}
/** @deprecated soooo messy */
function ApproverBody(props: ApproverBodyProps) {
  const { data, runSrc } = props
  const submitter = useApproverSubmit()

  const upsertTabs = runSrc === 'airtable' ? Object.entries(displayMeta) : []
  const invalidTabs = [...(data?.invalidMap ?? [])]

  const [oked, setOked] = useState(
    times(upsertTabs.length + invalidTabs.length, () => false)
  )

  useEffect(() => {
    data?.meta.error && console.error('error:', data.meta.error)
  }, [data?.meta.error])

  if (!data?.meta) {
    return <Txt px={6}>No {runSrc} runs</Txt>
  } else if (data.meta.status === 'creating') {
    return (
      <Txt px={6}>
        Creation of a changeset is in progress, see github actions for its
        status or check back later.
      </Txt>
    )
  }

  const IcoWritten = IcoCheckmark

  // whether there is an error
  const hasFailed = data.meta.status.startsWith('failed-')

  // whether there is something to submit
  const hasChanges = data.items.some(
    (p) =>
      p.status === 'added' || p.status === 'modified' || p.status === 'removed'
  )
  // whether submit is possible pre-checklist
  const canSubmit =
    hasChanges && data.meta.status === 'open' && submitter.isIdle
  // if checklist is complete
  const submitDisabled = !canSubmit || !oked.every((p) => p)

  let absIdx = 0
  const tabs = []
  const tabPanels = []

  // non-staged updates
  for (let { collectionName, bulkSummary } of data.meta.direct) {
    const isOk = bulkSummary?.ok === 1
    const statKeys: (keyof BulkSummary)[] = [
      'nInserted',
      'nMatched',
      'nModified',
      'nRemoved',
      'nUpserted',
    ]
    const tabKey = `non-staged-${collectionName}`
    tabs.push(
      <Tab key={tabKey}>
        {bulkSummary && (
          <Icon
            as={isOk ? IcoWritten : IcoX}
            color={isOk ? null : 'red.500'}
            mr={2}
          />
        )}
        {collectionName}
      </Tab>
    )
    tabPanels.push(
      makeTabPanel(
        tabKey,
        !bulkSummary ? (
          <Txt px={2}>No changes</Txt>
        ) : (
          <Table fontFamily="mono" size="sm">
            <Tbody>
              <Tr>
                <Td>ok</Td>
                <Td>{isOk.toString()}</Td>
              </Tr>
              {statKeys.map((statKey) => (
                <Tr key={statKey}>
                  <Td>{statKey}</Td>
                  <Td>{bulkSummary[statKey]}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )
      )
    )
  }
  // validation errors
  for (let [tabKey, invalids] of invalidTabs) {
    let idx = absIdx
    const panelData = invalids
    tabs.push(
      <Tab key={tabKey}>
        <Icon as={IcoExclamationTriangle} color="red.500" mr={2} />
        {tabKey} ({panelData.length})
      </Tab>
    )
    tabPanels.push(
      makeTabPanel(
        tabKey,
        <InvalidPanel
          canSubmit={canSubmit}
          data={panelData}
          isOked={oked[idx]}
          setOked={(next) =>
            setOked(
              produce((draft) => {
                draft[idx] = next
              })
            )
          }
        />
      )
    )
    absIdx += 1
  }
  // staged
  for (let [tabKey, meta] of upsertTabs) {
    let idx = absIdx
    const panelData = data.itemMap.get(tabKey) ?? []
    const changeCount = sumBy(panelData, (p) =>
      p.status === 'frozen' || p.status === 'unchanged' ? 0 : 1
    )
    tabs.push(
      <Tab key={tabKey}>
        <Icon
          as={data.meta.status === 'open' ? IcoApproverStaged : IcoWritten}
          mr={2}
        />
        {tabKey} ({changeCount})
      </Tab>
    )
    tabPanels.push(
      makeTabPanel(
        tabKey,
        <StagePanel
          canSubmit={canSubmit}
          data={panelData}
          isOked={oked[idx]}
          // @ts-expect-error typescript gets lost with Object.entries and/or mapped type
          meta={meta}
          setOked={(next) =>
            setOked(
              produce((draft) => {
                draft[idx] = next
              })
            )
          }
        />
      )
    )
    absIdx += 1
  }

  return (
    <VStack alignItems="stretch" flexGrow={1} gap={2} px={2}>
      <HStack gap={2}>
        {runSrc === 'airtable' && (
          <>
            <Button
              colorScheme="red"
              onClick={() => submitter.mutate({ runId: data.meta.runId })}
              isDisabled={submitDisabled}
              isLoading={submitter.isLoading}
            >
              Submit
            </Button>
            {submitter.isError && <Txt color="red.500">Failed!</Txt>}
            <Txt>
              Changeset status:{' '}
              <Tag colorScheme={hasFailed ? 'red' : null}>
                {hasChanges || hasFailed ? data.meta.status : 'No changes'}
              </Tag>
            </Txt>
          </>
        )}
        <Txt>Created: {formatPastVsNow(data.meta.runId)}.</Txt>
        {runSrc === 'scheduled' ? null : data.meta.appliedBy ? (
          <Txt>
            Applied: {formatPastVsNow(data.meta.appliedAt)} by{' '}
            {data.meta.appliedBy}
          </Txt>
        ) : (
          <Txt>
            Last applied run:{' '}
            {data.meta.prevAppliedRunId
              ? formatPastVsNow(data.meta.prevAppliedRunId)
              : 'never'}
          </Txt>
        )}
      </HStack>
      <Tabs
        colorScheme="gray"
        defaultIndex={0}
        display="flex"
        flexDirection="column"
        isLazy={true}
        overflow="hidden"
        size="sm"
      >
        <TabList>{tabs}</TabList>
        <TabPanels display="flex" overflow="hidden">
          {tabPanels}
        </TabPanels>
      </Tabs>
    </VStack>
  )
}

type StagePanelProps<T> = {
  canSubmit: boolean
  data: StageDisplay[]
  meta: DisplayMeta<T>
  isOked: boolean
  setOked: (next: boolean) => void
}

const printIf = (thing: any, label: string) => {
  if (thing) {
    console.log(`%c* ${label}`, `font-weight: bold`)
    console.log(thing)
  }
}

function StagePanel<T>(props: StagePanelProps<T>) {
  const { canSubmit, data, meta, isOked, setOked } = props

  const [selectedId, setSelectedId] = useState('')

  return (
    <>
      <Checkbox
        colorScheme="red"
        isChecked={isOked}
        isDisabled={!canSubmit}
        onChange={(event) => setOked(event.target.checked)}
      >
        I approve these changes
      </Checkbox>
      <TableContainer fontSize="sm">
        <UghTable
          aria-label={`Changes`}
          columns={[Status, ...(meta?.columns ?? [])]}
          data={data}
          onSelect={(item: StageDisplay) => {
            setSelectedId(item.id)
            console.clear()
            console.log(`ID: ${item.id}`)
            printIf(item.next, `"next" item data`)
            printIf(item.old, `"old" item data`)
            printIf(item.modifiedKeys, `modified keys`)
            printIf(item.errors, `validation errors`)
            printIf(item, `full details`)
          }}
          selectedId={selectedId}
          spacingX="normal"
        />
      </TableContainer>
    </>
  )
}

const Status: UghColumn<StageDisplay> = {
  props: { width: 150 },
  label: () => 'Status',
  cell(item) {
    let colorScheme: TagProps['colorScheme']
    let message: ReactNode
    // todo: not 100% sure we'll ever hit this as is because
    if (item.valid === 'error') {
      colorScheme = 'red'
      message = 'invalid'
    } else {
      message = item.status
      switch (item.status) {
        case 'added':
          colorScheme = 'blue'
          break
        case 'unchanged':
        case 'frozen':
          colorScheme = 'gray'
          break
        case 'removed':
          colorScheme = 'red'
          break
        case 'modified':
          colorScheme = 'yellow'
          break
      }
    }

    return <Tag colorScheme={colorScheme}>{message}</Tag>
  },
}

type InvalidPanelProps<T> = {
  canSubmit: boolean
  data: InvalidDisplay[]
  // meta: DisplayMeta<T>
  isOked: boolean
  setOked: (next: boolean) => void
}

function InvalidPanel<T>(props: InvalidPanelProps<T>) {
  const { canSubmit, data, isOked, setOked } = props

  const [selectedId, setSelectedId] = useState('')

  // todo: just need to specify for each input...
  const maybeCols = [
    'name',
    'tea.lastName',
    'tea.firstName',
    'courseName',
    'sec.sectionId',
    'sec.schoolName',
  ]
    .filter((key) => dotProp.has(data[0]?.value, key))
    .map((key): UghColumn<InvalidDisplay> => {
      return {
        props: { width: 200 },
        label: () => key,
        cell(item) {
          return reactify(dotProp.get(item.value, key))
        },
      }
    })

  // generate some columns haha
  const columns = [
    {
      label: () => 'errors',
      cell(item: InvalidDisplay) {
        return item.errors.join(', ')
      },
    },
    ...maybeCols,
  ].filter((p) => p)

  return (
    <>
      <Checkbox
        colorScheme="red"
        isChecked={isOked}
        isDisabled={!canSubmit}
        onChange={(event) => setOked(event.target.checked)}
      >
        I'm aware of these validation errors ({data.length})
      </Checkbox>
      <TableContainer fontSize="sm">
        <UghTable
          aria-label={`Changes`}
          columns={columns}
          data={data}
          onSelect={(item: InvalidDisplay) => {
            setSelectedId(item.id)
            console.clear()
            console.log(`ID: ${item.id}`)
            printIf(item.errors, `errors`)
            printIf(item.errorPaths, `errorPaths`)
            printIf(item.value, `value`)
            printIf(item, `full details`)
          }}
          selectedId={selectedId}
          spacingX="normal"
        />
      </TableContainer>
    </>
  )
}
