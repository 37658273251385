import { Button } from '@chakra-ui/react'
import { useRouter } from '@paper/route'
import { ENDASH, getFullName } from '@paper/utils'
import { useStaticFn } from '~src/blocks/list/listCallbacks'
import { useSWContext } from '~src/blocks/swContext'
import { Separator, Txt, VStack } from '~src/components'
import { RD_SW } from '../routes'
import { useScanXpacketDigest } from './data-scanXpackets'
import { scanfixSlice, useScanlogContext } from './scanlogAirlock'
import { ScanXpacketColumn } from './scanXpackets'

export function ScanlogXpacketColumn() {
  const {
    dispatchScanfix,
    isEditing,
    sbDetails,
    selectedPacket,
    selectedScanImage,
    targetXpageId,
  } = useScanlogContext()

  const swContext = useSWContext()
  const digest = useScanXpacketDigest(selectedPacket, swContext.teacher?.id)
  const { dispatchStay } = useRouter<RD_SW>()

  const { selectPage } = scanfixSlice.actions
  const onSelectPage = useStaticFn((xpageId: string) => {
    dispatchScanfix(selectPage({ xpageId }))
  })

  const selectedXpacket = digest.success?.selectedItem

  return (
    <ScanXpacketColumn
      canSelectPage={(page) =>
        // don't allow assigning to a slot that already has an image
        isEditing && !page.imgp && !page.fix?.key && !page.movedIn?.key
      }
      digest={digest}
      // todo: this should probably be a render prop like how list works...
      header={() => (
        <VStack gap={2}>
          <Txt fontSize="sm">
            <Txt as="span">
              {getFullName(swContext.teacher)}
              <Txt as="span" fontFamily="mono">
                /{selectedPacket?.number}
              </Txt>
            </Txt>{' '}
            {ENDASH} Page-by-page scan status
          </Txt>

          <Txt fontSize="xs">
            {!selectedXpacket ? (
              `Select a student to filter batches`
            ) : (
              <>
                Filtering batches by{' '}
                <Txt as="span" fontFamily="mono">
                  {selectedXpacket.student?.lastfirst.slice(0, 24) ??
                    '<unnamed>'}
                </Txt>
                <Button
                  colorScheme="teal"
                  ml={1.5}
                  onClick={() => dispatchStay({ xpacketId: null })}
                  size="xs"
                  variant="link"
                >
                  (clear)
                </Button>
              </>
            )}
          </Txt>

          <Separator orientation="horizontal" style={{ marginBlock: '6px' }} />
        </VStack>
      )}
      highlightedXpageIdSet={sbDetails.data?.xpageIdSet}
      idle={() => (
        <Txt fontSize="sm" opacity={0.7} textAlign="center">
          Select a packet to see page-by-page status
        </Txt>
      )}
      onSelectPage={onSelectPage}
      packet={selectedPacket}
      selectedXpageId={selectedScanImage?.data?.xpageId}
      targetXpageId={targetXpageId}
    />
  )
}
