import { Box } from '@chakra-ui/react'
import { createContext, useContext, useLayoutEffect } from 'react'
import { FullPageLoading } from '~src/components/status'
import { PdfImageCapture } from './pdfImageCapture'
import { useWizState_INTERNAL } from './wizState'

type WizContext = ReturnType<typeof useWizState_INTERNAL>
const WizContext = createContext<WizContext>(null)
export const useWizContext = () => useContext(WizContext)

export function WizProvider({ children }) {
  const ctx = useWizState_INTERNAL()
  const { captureProps } = ctx

  return (
    <WizContext.Provider value={ctx}>
      <FullPageLoading
        qResult={{ isLoading: ctx.isLoading }}
        type={ctx.isInit ? 'transparent' : 'opaque'}
      >
        {children}
      </FullPageLoading>
      {captureProps && (
        <Box
          top={0}
          position="absolute"
          pointerEvents="none"
          visibility="hidden"
        >
          <PdfImageCapture {...captureProps} />
        </Box>
      )}
    </WizContext.Provider>
  )
}

export function useSetWizCurStepError(error) {
  const { setCurStepError } = useWizContext()
  useLayoutEffect(() => {
    setCurStepError(error)
  }, [error])
}
