import { memo, ReactElement } from 'react'
import { useRouter } from './Router'

export const ActiveRoute = memo(function ActiveRoute() {
  const { currentRoute, notFound, routeData } = useRouter()
  if (currentRoute) {
    return currentRoute.render(routeData) as ReactElement
  }

  return notFound(new Error(`There is nothing at this URL`)) as ReactElement
})
