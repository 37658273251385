import { List, ListIcon, ListItem, ListItemProps } from '@chakra-ui/react'
import { IcoWarningCircle } from '@paper/icons'
import { useRouter } from '@paper/route'
import { useCurriculaPicker } from '~src/blocks/pickers'
import { ComboBox, HStack, StackProps, Txt, VStack } from '~src/components'
import { usePublishContext } from '../publishProvider'
import { RD_Publish } from '../routes'
import { Wiz } from '../publishLayout'
import { PdfInput } from './pdfInput'

export function OpenPdf() {
  const { openPdf } = usePublishContext()

  return (
    <Wiz.FormArea as={HStack} alignItems="flex-start" flexGrow={1} gap={12}>
      <HelpPdf minWidth="200px" />
      <PdfInput
        data-cy="drop-target"
        flexGrow={1}
        flexShrink={0}
        height="100%"
        onPdf={openPdf}
        width="400px"
      />
    </Wiz.FormArea>
  )
}

type WarningBulletProps = ListItemProps
export const WarningBullet = (props: WarningBulletProps) => {
  const { children, ...liProps } = props
  return (
    <ListItem {...liProps}>
      <ListIcon as={IcoWarningCircle} color="yellow.500" />
      {children}
    </ListItem>
  )
}

export const HelpPdf = (props: StackProps) => {
  const { dispatchStay, routeData } = useRouter<RD_Publish>()
  const curComboProps = useCurriculaPicker({ value: routeData.curriculumId })

  return (
    <VStack alignItems="stretch" gap={4} {...props}>
      <Txt>
        Use the box to the right to open the PDF you'd like to add to Paper.
      </Txt>
      <List fontSize="sm" spacing={3}>
        <WarningBullet>A PDF can only contain one packet.</WarningBullet>
        <WarningBullet>
          You should only add PDFs that are finalized and{' '}
          <Txt as="span" fontWeight="bold">
            ready to be printed
          </Txt>
          .
        </WarningBullet>
        <WarningBullet>
          Verify that you're uploading to the correct curriculum.
        </WarningBullet>
        <ComboBox
          {...curComboProps}
          caret={curComboProps.items.length > 1}
          fontFamily="mono"
          inputTextAlign="center"
          itemToString={(c) => c.name}
          // todo: need to check permissions once per-curriculum exist
          onChange={(item) => dispatchStay({ curriculumId: item.id })}
          round={true}
          size="md"
          variant="filled"
          width="240px"
        />
      </List>
    </VStack>
  )
}
