import { Box } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { AppTitle, HStack } from '~src/components'
import { useHorribleFadeIn } from '~src/utils/useAnimation'

type HomePanelProps = {
  filters?: ReactNode
  name: string
  pickers: ReactNode
  table: ReactNode
}

// may eventually need more slot composition flexibility
export function HomePanel(props: HomePanelProps) {
  const { filters, name, pickers, table } = props

  return (
    <AppTitle title={[name, 'Home']}>
      <Box
        display="grid"
        gridRowGap={6}
        gridTemplateRows="max-content 1fr"
        height="100%"
        overflowX="auto"
        overflowY="hidden"
        pb={6}
        pt={2}
        width="100%"
      >
        <HStack flexWrap="wrap" gap={8} justifyContent="center" p={1}>
          <HStack flexWrap="wrap" gap={4} justifyContent="center">
            {pickers}
          </HStack>
          {filters}
        </HStack>
        <Box fontSize="sm" overflow="hidden">
          {table}
        </Box>
      </Box>
    </AppTitle>
  )
}

export function TableContainer({
  children,
  fontSize = undefined,
  maxWidth = undefined,
  px = undefined,
}) {
  const opacity = useHorribleFadeIn(true)

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      fontSize={fontSize}
      maxWidth={maxWidth}
      opacity={opacity}
      overflowY="hidden"
      px={px}
      transition="opacity 400ms ease-in"
    >
      {children}
    </Box>
  )
}
