import { Path } from 'path-parser'
import { ReactElement } from 'react'
import { QS_OPTIONS, queryString } from './queryString'
import type { Action, BaseData, MergeType, NavType } from './types'
import { pick } from './utils'

interface RouteMetaArgs<D extends BaseData> {
  parent?: RouteMeta<any>
  path: string
  queryParams?: readonly (keyof D)[]
  render: (data: D) => ReactElement
}

type NavAction<D extends BaseData> = (data: D, navType?: NavType) => Action

type MergeAction<D extends BaseData> = (
  data?: Partial<D>,
  navType?: NavType
) => Action

export interface RouteMeta<D extends BaseData = any> extends RouteMetaArgs<D> {
  base: string
  buildUrl: (data: D) => string
  mapData: (data: {}) => D
  mergeAction: MergeAction<D>
  navigateAction: NavAction<D>
  pathParser: Path
  queryParams: readonly (keyof D)[]
}

export function routemeta<D extends BaseData>({
  parent,
  path,
  queryParams = [],
  render,
}: RouteMetaArgs<D>): RouteMeta<D> {
  let self: RouteMeta<D>
  // `base` gets inherited from `parent`
  const base = parent ? parent.base : path

  // Calculate path
  if (parent) {
    path = parent.path + path
  }

  const pathParser = new Path(path)

  // Pick out data relevant to this route
  const mapData = (state: D) =>
    pick(state, [...pathParser.params, ...queryParams]) as D

  // Url builder
  const buildUrl = (data: D) => {
    // Path
    let url = pathParser.build(data)
    // Query params
    const search = queryString.stringify(pick(data, queryParams), QS_OPTIONS)
    if (search) {
      url = `${url}?${search}`
    }
    return url
  }

  interface ActionFactoryFactory {
    (mergeType: 'overwrite'): NavAction<D>
    (mergeType: 'merge'): MergeAction<D>
  }

  const factory: ActionFactoryFactory =
    (mergeType: MergeType) =>
    (data = {} as D, navType: NavType = 'push'): Action => {
      return {
        type: 'navigate',
        mergeType,
        navType,
        data,
        routeMeta: self,
      }
    }

  self = {
    base,
    buildUrl,
    mapData,
    mergeAction: factory('merge'),
    navigateAction: factory('overwrite'),
    parent,
    path,
    pathParser,
    queryParams,
    render,
  }

  return self
}
