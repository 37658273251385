import { Button, ButtonProps } from '@chakra-ui/react'
import { IcoPrint, IcoRadioactive } from '@paper/icons'
import { useRouter } from '@paper/route'
import { PdfKeySet } from '@paper/schema'
import { PktColors } from '@paper/styles'
import { pick } from 'lodash'
import { Fragment } from 'react'
import { HStack, TooltippedIcon, Txt } from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { FullPageMessage } from '~src/components/status/fullPage'
import { useSubmitAnswerkey, useSubmitPdf } from '../data-publish'
import { usePublishContext } from '../publishProvider'
import type { RD_Publish } from '../routes'
import { useWizContext } from './wizProvider'

const btnSize = 'sm'

export function WizHeader() {
  const {
    canNext,
    canPrev,
    formik,
    goNext,
    goPrev,
    isNew,
    isPrinted,
    isSubmitStep,
    type,
    willBeRadioactive,
  } = useWizContext()

  const pdfSubmitter = useSubmitPdf()
  const busy = formik.isSubmitting || pdfSubmitter.isLoading

  const nextOrSubmit = isSubmitStep ? (
    type === 'pdf' ? (
      <SubmitPdfButton />
    ) : (
      <SubmitAnswerkeyButton />
    )
  ) : (
    <Button
      data-cy="wiz-next"
      disabled={!canNext || busy}
      onClick={goNext}
      size={btnSize}
    >
      Next
    </Button>
  )

  return (
    <Fragment>
      <HStack flexShrink={0} gap={4} overflow="hidden">
        <Button
          data-cy="wiz-prev"
          disabled={!canPrev || busy}
          onClick={goPrev}
          size={btnSize}
        >
          Prev
        </Button>
        {nextOrSubmit}
        {type === 'pdf' && (
          <Fragment>
            {!isNew && (
              <TooltippedIcon
                aria-label={
                  isPrinted ? 'This packet has been printed' : 'Not yet printed'
                }
                as={IcoPrint}
                color={isPrinted ? `${PktColors.Print}.500` : 'gray.100'}
                fontSize="3xl"
                transition="color 700ms ease-in"
              />
            )}
            {isPrinted && (
              <TooltippedIcon
                aria-label={
                  willBeRadioactive ? 'Radioactive' : 'Not radioactive'
                }
                as={IcoRadioactive}
                color={
                  willBeRadioactive ? `${PktColors.Nuclear}.500` : 'gray.100'
                }
                fontSize="3xl"
                transition="color 700ms ease-in"
              />
            )}
          </Fragment>
        )}
      </HStack>
      <Button ml="auto" size="sm" onClick={() => window.location.reload()}>
        Discard and exit
      </Button>
    </Fragment>
  )
}

const pdfKeySetArr: PdfKeySet[] = ['name', 'pages', 'parts', 'style', 'type']

function SubmitPdfButton() {
  const { dispatchStay } = useRouter<RD_Publish>()
  const { finishEdit } = usePublishContext()
  const { _capturedBlanks, canSubmit, contentId, formik, curriculum, srcBuf } =
    useWizContext()

  const submitter = useSubmitPdf()
  const onClick = () => {
    submitter.mutateAsync(
      {
        data: {
          contentId,
          contentSet: pick(formik.values, pdfKeySetArr),
          curriculum,
        },
        _images: _capturedBlanks,
        srcBuf,
      },
      {
        onSuccess() {
          dispatchStay({ contentIdOrNew: contentId, dev_step: null })
          finishEdit()
        },
      }
    )
  }

  // not using formik.submit right now...
  const busy = submitter.isLoading

  return (
    <FullPageLoading
      loadMsg={
        <FullPageMessage.Text textAlign="center">
          Submitting and Uploading...
          <Txt fontSize="md" mt={2}>
            This may take a while for large PDFs.
          </Txt>
        </FullPageMessage.Text>
      }
      qResult={submitter}
      type="transparent"
    >
      <SubmitButton disabled={!canSubmit || busy} onClick={onClick} />
    </FullPageLoading>
  )
}

function SubmitAnswerkeyButton() {
  const { dispatchStay } = useRouter<RD_Publish>()
  const { finishEdit } = usePublishContext()
  const { canSubmit, contentId, formik } = useWizContext()

  const submitter = useSubmitAnswerkey()
  const onClick = () => {
    submitter.mutateAsync(
      {
        contentId,
        contentSet: {
          pages: formik.values.pages,
          passages: formik.values.passages,
          questions: formik.values.questions,
        },
      },
      {
        onSuccess() {
          dispatchStay({ dev_step: null }) // messy, only for dev
          finishEdit()
        },
      }
    )
  }

  // not using formik.submit right now...
  const busy = submitter.isLoading

  submitter.isError && console.log(submitter)

  return (
    <FullPageLoading
      loadMsg={
        <FullPageMessage.Text textAlign="center">
          Submitting...
        </FullPageMessage.Text>
      }
      qResult={submitter}
      type="transparent"
    >
      <SubmitButton disabled={!canSubmit || busy} onClick={onClick} />
    </FullPageLoading>
  )
}

function SubmitButton(props: ButtonProps) {
  return (
    <Button
      colorScheme="red"
      data-cy="wiz-submit"
      size={btnSize}
      type="submit"
      {...props}
    >
      Submit
    </Button>
  )
}
